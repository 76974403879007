/**
 * Retrieve all available platforms
 */
export const getPlatforms = /* GraphQL */ `
  query GetPlatforms {
    getPlatforms {
      id
      name
      type
    }
  }
`

export const getPlatformsAccounts = /* GraphQL */ `
  query getPlatformAccounts($groupId: ID!) {
    getPlatformAccounts(groupId: $groupId) {
      followers {
        low
        high
      }
      groupId
      platform
      url
    }
  }
`
