import { Storage } from 'aws-amplify'

export interface RemoteFile {
  expiresAt: string
  path?: string
  url: string
}

export interface RemoteImage {
  large?: RemoteFile | null
  medium?: RemoteFile | null
  original?: RemoteFile
  small?: RemoteFile | null
}

export type RemoteFileInput = string

export const extension = (file: File): string => file.name.split('.').pop()

export const upload = async (
  name: string,
  file: File,
  progressCallback?: (progress: { key: string; loaded: number; total: number }) => void,
): Promise<RemoteFileInput> => {
  const path = `${name}.${extension(file)}`

  await Storage.put(path, file, {
    contentType: file.type,
    level: 'public',
    progressCallback,
  })

  return path
}
