import styled from 'styled-components'

export type CrossAxisAlignment = 'flex-start' | 'center' | 'flex-end' | 'baseline' | 'stretch'

export type MainAxisAlignment = 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly'

export interface FlexProps {
  crossAxisAlignment?: CrossAxisAlignment
  direction?: 'row' | 'column'
  gap?: string
  mainAxisAlignment?: MainAxisAlignment
  reversed?: boolean
  wrap?: boolean
  className?: string
}

/**
 * This component uses flex-box to position its content.
 *
 * NB: This component is inspired by, but not identical to the Flutter
 * equivalent.
 * @see https://api.flutter.dev/flutter/widgets/Flex-class.html
 */
const Flex = styled.div<FlexProps>`
  display: flex;
  ${(props) => (props.wrap ? `flex-wrap: wrap;` : '')}
  flex-direction: ${(props) => `${props.direction}${props.reversed ? '-reverse' : ''}`};
  justify-content: ${(props) => props.mainAxisAlignment};
  align-items: ${(props) => props.crossAxisAlignment};
  ${(props) => (props.gap ? `gap: ${props.gap};` : '')}
`

export default Flex
