/**
 * Retrieve collaboration settings based on GroupID
 */
export const getCollaborationSettings = /* GraphQL */ `
  query GetCollaborationSettings($groupId: ID!, $role: CollaborationSettingsGroupRole!) {
    getCollaborationSettings(groupId: $groupId, role: $role) {
      id
      royaltyShareEnabled
      fixedFeeEnabled
      fixedFeeMinimumFee
      paypalMerchantId
      paypalEmail
      role
      platforms {
        platform {
          id
          name
        }
      }
      genres {
        genre {
          id
          name
        }
        subgenres {
          id
          name
        }
      }
      contentCategories {
        contentCategoryId
        contentCategory {
          name
        }
      }
    }
  }
`

/**
 * Add collaboration settings based on GroupID
 */
export const addCollaborationSettings = /* GraphQL */ `
  mutation AddCollaborationSettings($groupId: ID!, $input: CollaborationSettingsInput!) {
    addCollaborationSettings(groupId: $groupId, input: $input) {
      id
      royaltyShareEnabled
      fixedFeeEnabled
      fixedFeeMinimumFee
      role
      platforms {
        platform {
          id
          name
        }
      }
      genres {
        genre {
          id
          name
        }
        subgenres {
          id
          name
        }
      }
      contentCategories {
        contentCategoryId
      }
    }
  }
`

/**
 * Edit collaboration settings based on GroupID
 */
export const editCollaborationSettings = /* GraphQL */ `
  mutation EditCollaborationSettings($id: ID!, $input: CollaborationSettingsInput!) {
    editCollaborationSettings(id: $id, input: $input) {
      id
      royaltyShareEnabled
      fixedFeeEnabled
      fixedFeeMinimumFee
      role
      platforms {
        platform {
          id
          name
        }
      }
      genres {
        genre {
          id
          name
        }
        subgenres {
          id
          name
        }
      }
      contentCategories {
        contentCategoryId
      }
    }
  }
`
