const IconsWithoutKey = {
  artist: {
    glyph: '\\e939',
  },
  beamedNote: {
    glyph: '\\e928',
  },
  brand: {
    glyph: '\\e93a',
  },
  campaign: {
    glyph: '\\e93b',
  },
  check: {
    glyph: '\\e91c',
  },
  chevronDown: {
    glyph: '\\e901',
  },
  chevronLeft: {
    glyph: '\\e902',
  },
  chevronRight: {
    glyph: '\\e903',
  },
  chevronUp: {
    glyph: '\\e900',
  },
  comments: {
    glyph: '\\e929',
  },
  cross: {
    glyph: '\\e90c',
  },
  danger: {
    glyph: '\\e90a',
  },
  denial: {
    glyph: '\\e943',
  },
  dislike: {
    glyph: '\\e92a',
  },
  distribution: {
    glyph: '\\e93c',
  },
  dollar: {
    glyph: '\\e92e',
  },
  download: {
    glyph: '\\e91b',
  },
  dragHandle: {
    glyph: '\\e90f',
  },
  facebook: {
    glyph: '\\e910',
  },
  fan: {
    glyph: '\\e93d',
  },
  filter: {
    glyph: '\\e931',
  },
  gift: {
    glyph: '\\e99f',
  },
  growthTickerDown: {
    glyph: '\\e932',
  },
  growthTickerUp: {
    glyph: '\\e933',
  },
  hamburger: {
    glyph: '\\e914',
  },
  hashtag: {
    glyph: '\\e930',
  },
  hide: {
    glyph: '\\e935',
  },
  hour: {
    glyph: '\\e942',
  },
  info: {
    glyph: '\\e908',
  },
  instagram: {
    glyph: '\\e912',
  },
  license: {
    glyph: '\\e938',
  },
  licensing: {
    glyph: '\\e93e',
  },
  like: {
    glyph: '\\e92b',
  },
  live: {
    glyph: '\\e941',
  },
  loader: {
    glyph: '\\e906',
  },
  lock: {
    glyph: '\\e944',
  },
  lockOpen: {
    glyph: '\\e945',
  },
  mapPin: {
    glyph: '\\e927',
  },
  minus: {
    glyph: '\\e905',
  },
  nft: {
    glyph: '\\e93f',
  },
  pause: {
    glyph: '\\e90d',
  },
  pencil: {
    glyph: '\\e91d',
  },
  percent: {
    glyph: '\\e92f',
  },
  play: {
    glyph: '\\e90e',
  },
  playBorder: {
    glyph: '\\e937',
  },
  plus: {
    glyph: '\\e904',
  },
  publisher: {
    glyph: '\\e940',
  },
  question: {
    glyph: '\\ea09',
  },
  repeat: {
    glyph: '\\e917',
  },
  settings: {
    glyph: '\\e936',
  },
  share: {
    glyph: '\\e92c',
  },
  show: {
    glyph: '\\e934',
  },
  skipBackward: {
    glyph: '\\e915',
  },
  skipForward: {
    glyph: '\\e916',
  },
  snapchat: {
    glyph: '\\e91e',
  },
  soundcloud: {
    glyph: '\\e920',
  },
  spotify: {
    glyph: '\\e921',
  },
  square: {
    glyph: '\\ea53',
  },
  success: {
    glyph: '\\e90b',
  },
  tikTok: {
    glyph: '\\e922',
  },
  triller: {
    glyph: '\\e923',
  },
  twitch: {
    glyph: '\\e924',
  },
  twitter: {
    glyph: '\\e911',
  },
  upload: {
    glyph: '\\e907',
  },
  views: {
    glyph: '\\e92d',
  },
  volumeHigh: {
    glyph: '\\e918',
  },
  volumeLow: {
    glyph: '\\e91a',
  },
  volumeNone: {
    glyph: '\\e919',
  },
  warning: {
    glyph: '\\e909',
  },
  youtube: {
    glyph: '\\e925',
  },
} satisfies Record<string, IconDataWithoutKey>

interface IconDataWithoutKey {
  glyph: string
}

export interface IconData<Key extends string = string> extends IconDataWithoutKey {
  key: Key
}

export const isIconData = (input: unknown): input is IconData =>
  typeof input === 'object' && input !== null && 'glyph' in input && typeof input.glyph === 'string'

export type IconsType = { [Key in keyof typeof IconsWithoutKey]: IconData<Key> }

export const Icons = Object.fromEntries(
  Object.entries(IconsWithoutKey).map(([key, data]) => [key, { ...data, key }]),
) as IconsType
