import styled from 'styled-components'

export interface FlexibleProps {
  basis?: string
  grow?: number
  shrink?: number
}

/**
 * This component can grow and shrink inside a FlexBox, Row or Column.
 *
 * NB: This component is inspired by, but not identical to the Flutter
 * equivalent.
 * @see https://api.flutter.dev/flutter/widgets/Flexible-class.html
 */
const Flexible = styled.div<FlexibleProps>`
  flex-grow: ${({ grow = 1 }) => grow};
  flex-shrink: ${(props) => props.shrink};
  flex-basis: ${(props) => props.basis};
`

export default Flexible
