import React from 'react'

const icons = {
  apple: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_17_20703)">
        <path
          d="M21.792 18.7038C21.429 19.5423 20.9994 20.3141 20.5016 21.0237C19.8231 21.9911 19.2676 22.6607 18.8395 23.0325C18.1758 23.6429 17.4647 23.9555 16.7032 23.9733C16.1566 23.9733 15.4973 23.8177 14.73 23.5022C13.9601 23.1881 13.2525 23.0325 12.6056 23.0325C11.9271 23.0325 11.1994 23.1881 10.4211 23.5022C9.64153 23.8177 9.01355 23.9821 8.53342 23.9984C7.80322 24.0295 7.07539 23.7081 6.3489 23.0325C5.88521 22.6281 5.30523 21.9348 4.61043 20.9526C3.86498 19.9037 3.25211 18.6875 2.77198 17.3009C2.25777 15.8031 2 14.3528 2 12.9487C2 11.3403 2.34754 9.95308 3.04367 8.7906C3.59076 7.85685 4.31859 7.12028 5.22953 6.57955C6.14046 6.03883 7.12473 5.76328 8.18469 5.74565C8.76467 5.74565 9.52524 5.92505 10.4704 6.27764C11.4129 6.6314 12.0181 6.8108 12.2834 6.8108C12.4817 6.8108 13.154 6.60103 14.2937 6.18282C15.3714 5.79498 16.281 5.6344 17.0262 5.69765C19.0454 5.86061 20.5624 6.65659 21.5712 8.09062C19.7654 9.18481 18.8721 10.7174 18.8898 12.6834C18.9061 14.2147 19.4617 15.4891 20.5535 16.5009C21.0483 16.9705 21.6009 17.3335 22.2156 17.5912C22.0823 17.9779 21.9416 18.3482 21.792 18.7038ZM17.161 0.480625C17.161 1.6809 16.7225 2.80159 15.8484 3.83889C14.7937 5.07204 13.5179 5.78461 12.1343 5.67217C12.1167 5.52818 12.1065 5.37663 12.1065 5.21737C12.1065 4.06511 12.6081 2.83196 13.4989 1.8237C13.9436 1.31319 14.5092 0.888716 15.1951 0.550104C15.8796 0.216543 16.5269 0.0320773 17.1358 0.000488281C17.1536 0.160946 17.161 0.321414 17.161 0.48061V0.480625Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_17_20703">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  check: (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M26.9342 9.88562L25.0486 8L13.921 19.1276L8.55261 13.7593L6.66699 15.6449L13.9182 22.896L14.0981 22.7161L14.1009 22.7189L26.9342 9.88562Z"
        fill="white"
      />
    </svg>
  ),
  facebook: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_17_20693)">
        <path
          d="M12 0C5.37264 0 0 5.37264 0 12C0 17.6275 3.87456 22.3498 9.10128 23.6467V15.6672H6.62688V12H9.10128V10.4198C9.10128 6.33552 10.9498 4.4424 14.9597 4.4424C15.72 4.4424 17.0318 4.59168 17.5685 4.74048V8.06448C17.2853 8.03472 16.7933 8.01984 16.1822 8.01984C14.2147 8.01984 13.4544 8.76528 13.4544 10.703V12H17.3741L16.7006 15.6672H13.4544V23.9122C19.3963 23.1946 24.0005 18.1354 24.0005 12C24 5.37264 18.6274 0 12 0Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_17_20693">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  google: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12 9.81836V14.4656H18.4582C18.1746 15.9602 17.3236 17.2257 16.0472 18.0766L19.9417 21.0984C22.2108 19.0039 23.5199 15.9276 23.5199 12.273C23.5199 11.4221 23.4436 10.6039 23.3017 9.81849L12 9.81836Z"
        fill="white"
      />
      <path
        d="M5.27461 14.2842L4.39625 14.9566L1.28711 17.3783C3.26165 21.2947 7.30862 24.0002 11.9995 24.0002C15.2394 24.0002 17.9557 22.9311 19.9412 21.0984L16.0467 18.0765C14.9776 18.7965 13.614 19.2329 11.9995 19.2329C8.87951 19.2329 6.22868 17.1275 5.27952 14.2911L5.27461 14.2842Z"
        fill="white"
      />
      <path
        d="M1.28718 6.62158C0.469042 8.23606 0 10.0579 0 11.9997C0 13.9415 0.469042 15.7633 1.28718 17.3778C1.28718 17.3886 5.27997 14.2796 5.27997 14.2796C5.03998 13.5596 4.89812 12.796 4.89812 11.9996C4.89812 11.2031 5.03998 10.4395 5.27997 9.71951L1.28718 6.62158Z"
        fill="white"
      />
      <path
        d="M11.9997 4.77818C13.767 4.77818 15.3379 5.38907 16.5925 6.56727L20.0288 3.13095C17.9452 1.18917 15.2398 0 11.9997 0C7.30887 0 3.26165 2.69454 1.28711 6.62183L5.27978 9.72001C6.22882 6.88362 8.87976 4.77818 11.9997 4.77818Z"
        fill="white"
      />
    </svg>
  ),
  logo: (
    <svg xmlns="http://www.w3.org/2000/svg" width="181" height="23" viewBox="0 0 181 23" fill="none">
      <g clipPath="url(#clip0_17_20713)">
        <path
          d="M24.822 2.15625C19.3526 2.15625 15.375 6.6928 15.375 12.5782C15.375 18.5207 19.3272 23 24.7967 23C30.2929 23 34.2437 18.4928 34.2437 12.5782C34.2437 6.72066 30.2915 2.15625 24.822 2.15625ZM29.5343 12.5766C29.5343 15.6082 27.2938 18.0659 24.5302 18.0659C21.7665 18.0659 19.526 15.6082 19.526 12.5766C19.526 9.54502 21.7665 7.08725 24.5302 7.08725C27.2938 7.08725 29.5343 9.54502 29.5343 12.5766Z"
          fill="#00EF86"
        />
        <path
          d="M178.172 0C179.921 0 181.001 1.27011 181.001 2.86777C181.001 4.42369 179.935 5.75 178.212 5.75C176.503 5.75 175.41 4.49435 175.41 2.88223C175.411 1.28456 176.505 0 178.172 0ZM178.2 5.20888C179.621 5.20888 180.427 4.22458 180.427 2.86938C180.427 1.49972 179.607 0.542725 178.172 0.542725C176.805 0.542725 175.985 1.52702 175.985 2.88223C175.987 4.19568 176.807 5.20888 178.2 5.20888ZM177.9 3.20979V4.42209H177.34V1.34076H178.255C178.912 1.34076 179.267 1.69722 179.267 2.26885C179.267 2.66867 179.063 2.95448 178.817 3.06849L179.377 4.42369H178.789L178.339 3.21139H177.9V3.20979ZM177.9 1.86903V2.68312H178.311C178.543 2.68312 178.666 2.51131 178.666 2.26885C178.666 2.02639 178.529 1.86903 178.311 1.86903H177.9Z"
          fill="#00EF86"
        />
        <path
          d="M0 12.5489C0 6.66409 4.04923 2.15625 9.8589 2.15625C12.1777 2.15625 13.9624 2.8732 15.2946 3.85015L14.0153 8.15647C13.0301 7.43951 11.618 6.75027 10.0189 6.75027C6.60783 6.75027 4.74314 9.41957 4.74314 12.5489C4.74314 15.6782 6.60783 18.406 10.0189 18.406C11.618 18.406 13.11 17.7167 14.0952 16.9998L15.3745 21.3061C14.0423 22.2815 12.1777 23 9.8589 23C3.83649 23 0 18.4645 0 12.5489Z"
          fill="#00EF86"
        />
        <path d="M35.6416 23V1.4375H40.0326V18.0243H45.4254V23H35.6416Z" fill="#00EF86" />
        <path d="M47.5205 23V1.4375H51.9115V18.0243H57.3043V23H47.5205Z" fill="#00EF86" />
        <path
          d="M64.5451 18.3723L63.2297 23H58.7031L65.1357 2.15625H69.7416L76.1742 23H71.6476L70.3323 18.3723H64.5451ZM67.4384 7.7016L65.703 14.2452H69.1762L67.4384 7.7016Z"
          fill="#00EF86"
        />
        <path
          d="M84.4379 23H77.5723V2.15625H84.224C88.0029 2.15625 90.2645 4.25858 90.2645 7.81807C90.2645 9.59784 89.5183 11.3172 88.2423 12.1181C90.0792 12.9778 90.8503 14.6686 90.8503 16.8042C90.8521 21.0137 88.0041 23 84.4379 23ZM82.0967 6.57224V10.3384H84.0387C85.0771 10.3384 85.6362 9.53745 85.6362 8.41079C85.6362 7.31436 85.1039 6.57224 83.9858 6.57224H82.0967ZM82.0967 14.4016V18.6412H84.3054C85.5031 18.6412 86.1678 17.6925 86.1678 16.5055C86.1678 15.2613 85.5292 14.4 84.331 14.4L82.0967 14.4016Z"
          fill="#00EF86"
        />
        <path
          d="M108.32 23H103.879V14.8409H97.3862V23H92.9453V2.15625H97.3862V10.2563H103.879V2.15625H108.32V23Z"
          fill="#00EF86"
        />
        <path
          d="M132.779 14.7745V2.15625H136.872V14.7745C136.872 16.9992 137.972 18.3751 139.792 18.3751C141.635 18.3751 142.663 16.9992 142.663 14.7447V2.15625H146.756V14.7447C146.756 19.5454 144.195 23 139.768 23C135.269 23 132.779 19.6631 132.779 14.7745Z"
          fill="#00EF86"
        />
        <path
          d="M161.938 6.80753L158.034 8.67384C157.649 7.78452 157.072 6.69214 155.615 6.69214C154.707 6.69214 154.103 7.26604 154.103 8.04152C154.103 10.7695 162.131 10.4817 162.131 16.627C162.131 20.3305 159.601 23 155.395 23C151.079 23 149.347 19.7274 148.853 18.2056L152.784 16.167C153.307 17.344 154.352 18.4057 155.67 18.4057C156.797 18.4057 157.375 17.8887 157.375 16.9994C157.375 14.3298 149.402 14.5591 149.402 8.12765C149.402 4.50881 151.904 2.15625 155.725 2.15625C159.848 2.15625 161.442 5.28582 161.938 6.80753Z"
          fill="#00EF86"
        />
        <path
          d="M164.228 23V2.15625H174.491V6.71213H168.932V10.1956H173.665V14.7212H168.932V18.4441H174.71V23H164.228Z"
          fill="#00EF86"
        />
        <path
          d="M110.417 2.15625V23H129.286V2.15625H110.417ZM125.119 18.086H115.149V7.07184H125.119V18.086Z"
          fill="#00EF86"
        />
      </g>
      <defs>
        <clipPath id="clip0_17_20713">
          <rect width="181" height="23" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
}

export { icons }
