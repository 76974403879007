/**
 *
 * TODO: Mostly copied from ledo-web - should be re-used via @collabhouse/shared
 *
 */

import { Body, BrandColors, Expanded, FontFamily, Label, MediaQueries, rem, SizedBox } from '@collabhouse/shared'
import React, { type FunctionComponent, type ReactChild } from 'react'
import styled from 'styled-components'

import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs'
import Grid, { GridItem } from '../../components/grid/Grid'
import { type Breadcrumb } from '../../hooks/useBreadcrumbs'
import Container from './Container'

interface InformationHero {
  body?: string
  breadcrumbs?: Breadcrumb[]
  image?: ReactChild
  label?: string
  title: string
  titleAs?: string
}

const InformationHero: FunctionComponent<React.PropsWithChildren<InformationHero>> = ({
  titleAs = 'h1',
  label,
  title,
  body,
  image,
  breadcrumbs,
}) => {
  // Also show breadcrumbs when array is empty to reserve space and prevent jumps
  const showBreadcrumbs = Boolean(breadcrumbs)

  return (
    <>
      <HeroContainer>
        <Visual>{image}</Visual>
        <StyledContainer center={true}>
          {showBreadcrumbs && (
            <>
              <SizedBox
                height={{
                  desktop: 0,
                  mobile: rem(20),
                }}
              />

              <BreadcrumbsHeader>
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              </BreadcrumbsHeader>
            </>
          )}

          {/** Correct for extra height of breadcrumbs(header) */}
          {showBreadcrumbs ? (
            <SizedBox
              height={{
                desktop: rem(60),
                mobile: rem(68),
              }}
            />
          ) : (
            <SizedBox height={rem(128)} />
          )}

          {label && (
            <Grid columns={12}>
              <GridItem mobile={5}>
                <Label colored>{label}</Label>
              </GridItem>
            </Grid>
          )}

          <SizedBox height={rem(16)} />

          <Grid columns={12}>
            <GridItem mobile={12} desktop={9}>
              {/* @ts-expect-error this seems like a bug because it works when you change bigTitleAs to any string value */}
              <CustomBigTitle as={titleAs}>{title}</CustomBigTitle>
            </GridItem>
          </Grid>

          {body && (
            <>
              <SizedBox
                height={{
                  desktop: rem(40),
                  mobile: rem(32),
                }}
              />
              <Grid columns={12}>
                <GridItem mobile={12} desktop={5}>
                  <Body emphasis size="large">
                    {body}
                  </Body>
                </GridItem>
              </Grid>
            </>
          )}

          <SizedBox height={{ desktop: rem(128) }} />
        </StyledContainer>
      </HeroContainer>
      <SizedBox height={{ desktop: rem(96), mobile: rem(80) }} />
    </>
  )
}

const StyledContainer = styled(Container)`
  position: relative;
  min-height: 100%;
`

const HeroContainer = styled.div`
  position: relative;
  width: 100%;

  ${MediaQueries.desktop} {
    height: 40vh;
    min-height: ${rem(516)};
    max-height: ${rem(1024)};
  }
`

const Visual = styled(Expanded)`
  background-color: ${BrandColors.violentViolet.toString()};
  position: absolute;
  right: 0;
  top: 0;
  height: ${rem(228)};
  width: 55%;

  ${MediaQueries.desktop} {
    height: 100%;
    width: 50%;
  }
`

const CustomBigTitle = styled.h1`
  color: inherit;
  font-style: inherit;
  font-weight: bold;
  font-family: ${FontFamily.regular};
  text-transform: uppercase;
  font-size: ${rem(40)};
  line-height: ${rem(40)};

  ${MediaQueries.desktop} {
    font-size: ${rem(120)};
    line-height: ${rem(112)};
  }
`

const BreadcrumbsHeader = styled.header`
  min-height: ${rem(40)};

  ${MediaQueries.desktop} {
    min-height: ${rem(68)};
  }
`

export default InformationHero
