import { useLocation } from '@reach/router'
import { Body, BrandColors, Flex, IconButton, Icons, rem, SizedBox } from '@collabhouse/shared'
import Icon from '@collabhouse/shared/images/icon.svg'
import React, { type FunctionComponent, useMemo, useState } from 'react'
import styled from 'styled-components'
import UAParser from 'ua-parser-js'

import { routes } from '@/utils/routes'

const AddToHomescreen: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { pathname } = useLocation()
  const [hidden, setHidden] = useState(false)
  const [browser, device] = useMemo(() => {
    const ua = new UAParser(typeof window !== 'undefined' ? window.navigator.userAgent : null)
    return [ua.getBrowser(), ua.getDevice()] as const
  }, [])

  const isRegistrationFlow = pathname === `${routes.createGroup.path}/`

  const shouldShow = useMemo((): boolean => {
    const isBrowser = typeof window !== 'undefined'

    if (hidden || isRegistrationFlow || !isBrowser) return false

    // detects if app was already added to homescreen
    if (
      window.matchMedia('(display-mode: standalone)').matches ||
      ('standalone' in window.navigator && window.navigator.standalone)
    ) {
      return false
    }

    switch (device.type) {
      case 'tablet':
      case 'mobile':
        break
      default:
        return false
    }

    switch (browser.name) {
      case 'Chrome':
      case 'Chromium':
      case 'Edge':
        // these browsers already show their own PWA messages, no need to show ours
        return false
      default:
        break
    }

    return true
  }, [hidden, browser, device, isRegistrationFlow])

  return (
    shouldShow && (
      <Container
        onClick={() =>
          alert(
            browser.name.toLowerCase().includes('safari')
              ? `
    1. Open the Share menu
    2. Tap on the "Add to Home Screen" button`
              : `
    1. Tap the 3-dots-button to open the menu.
    2. Choose 'Install'
    `,
          )
        }
      >
        <Flex crossAxisAlignment="center">
          <img width={32} src={Icon} />
          <SizedBox width={rem(8)} />
          <Body as="span">Add Collabhouse to your homescreen</Body>
        </Flex>
        <IconButton
          type="button"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setHidden(true)
          }}
          icon={Icons.cross}
        />
      </Container>
    )
  )
}

const Container = styled.div`
  padding: ${rem(12)} ${rem(12)};
  justify-content: space-between;
  align-items: center;
  background: ${BrandColors.haiti.toString()};
  z-index: 1;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;

  ${Body} {
    color: ${BrandColors.springGreen.toString()};
  }
`

export default AddToHomescreen
