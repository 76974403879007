import { type GraphQLResult } from '@aws-amplify/api-graphql'
import { API, graphqlOperation } from 'aws-amplify'
import React, { createContext, type FC, type ReactNode, useCallback, useEffect, useState } from 'react'

import * as platformOperations from '../graphql/platforms'
import { useUser } from '../hooks/useUser'

export interface Platform {
  id: string
  name: string
  type?: string
}

export interface PlatformsContext {
  platforms: Platform[]
}

export const platformsContext = createContext<PlatformsContext>({
  platforms: [],
})

interface PlatformsProviderProps {
  children: ReactNode | ((context: PlatformsContext) => ReactNode)
}

const PlatformsProvider: FC<PlatformsProviderProps> = (props) => {
  const { isLoggedIn } = useUser()
  const [platforms, setPlatforms] = useState([])

  /**
   * Retrieve platforms.
   */
  const getPlatforms = useCallback(async () => {
    const result = (await API.graphql(graphqlOperation(platformOperations.getPlatforms))) as GraphQLResult<{
      getPlatforms: Platform[]
    }>

    return result.data?.getPlatforms
  }, [])

  /**
   * Refreshes all the necessary state.
   */
  const hydrate = useCallback(async () => {
    setPlatforms(await getPlatforms())
  }, [getPlatforms])

  /**
   * Clears the provider's state.
   */
  const clear = useCallback(() => {
    setPlatforms([])
  }, [])

  /**
   * Hydrate or clear platforms based on logged in state.
   */
  useEffect(() => {
    if (isLoggedIn) {
      void hydrate()
    } else {
      clear()
    }
  }, [isLoggedIn, hydrate, clear])

  const context = {
    platforms,
  }

  return (
    <platformsContext.Provider value={context}>
      {typeof props.children === 'function' ? props.children(context) : props.children}
    </platformsContext.Provider>
  )
}

export default PlatformsProvider
