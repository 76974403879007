export const getUserProfiles = /* GraphQL */ `
  query GetUserProfiles {
    getUserProfiles {
      id
      name
      avatar {
        small {
          url
          expiresAt
          expiresIn
        }
        medium {
          url
          expiresAt
          expiresIn
        }
        large {
          url
          expiresAt
          expiresIn
        }
      }
      userId
    }
  }
`

export const getMyUserProfile = /* GraphQL */ `
  query GetMyUserProfile {
    getMyUserProfile {
      id
      name
      avatar {
        small {
          url
          expiresAt
          expiresIn
        }
        medium {
          url
          expiresAt
          expiresIn
        }
        large {
          url
          expiresAt
          expiresIn
        }
      }
      walletAddress
      userId
      address {
        name
        companyName
        addressLine1
        addressLine2
        postalCode
        city
        district
        country
      }
      bankAccount {
        accountHolderName
        type
        details {
          ... on IbanBankAccountDetails {
            iban
          }
        }
        currencyCode
      }
      paypalAccount {
        email
      }
      vatDetails {
        isVatLiable
        vatNumber
        chamberOfCommerceNumber
      }
      paymentSettings {
        entityType
      }
      email
      generalTermsStatus
    }
  }
`

export const addMyUserProfile = /* GraphQL */ `
  mutation AddMyUserProfile($input: AddUserProfileInput!) {
    addMyUserProfile(input: $input) {
      id
      name
      avatar {
        small {
          url
          expiresAt
          expiresIn
        }
        medium {
          url
          expiresAt
          expiresIn
        }
        large {
          url
          expiresAt
          expiresIn
        }
      }
      userId
    }
  }
`

export const editMyUserProfile = /* GraphQL */ `
  mutation EditMyUserProfile($input: EditUserProfileInput!) {
    editMyUserProfile(input: $input) {
      id
      name
      avatar {
        small {
          url
          expiresAt
          expiresIn
        }
        medium {
          url
          expiresAt
          expiresIn
        }
        large {
          url
          expiresAt
          expiresIn
        }
      }
      userId
    }
  }
`

export const addUserProfileAndGroup = /* GraphQL */ `
  mutation AddUserProfileAndGroup($input: AddUserProfileAndGroupInput!) {
    addUserProfileAndGroup(input: $input) {
      email
      groups {
        id
        createdAt
      }
    }
  }
`

export const acceptGeneralTerms = /* GraphQL */ `
  mutation AcceptGeneralTerms {
    acceptGeneralTerms {
      id
    }
  }
`
