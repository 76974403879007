import React, { type ComponentProps, type FunctionComponent, type MouseEventHandler, type ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { Opacities } from '../../tokens/opacities'
import { rem } from '../../utils/rem'
import AspectRatio from '../AspectRatio'
import Caption from '../Caption'
import Center from '../Center'
import Column from '../Column'
import DesktopOnly from '../DesktopOnly'
import Expanded from '../Expanded'
import Flexible from '../Flexible'
import Label from '../Label'
import MobileOnly from '../MobileOnly'
import Padding from '../Padding'
import PlayPauseButton from '../PlayPauseButton'
import Row from '../Row'
import SizedBox from '../SizedBox'

export interface TrackCardProps extends ComponentProps<typeof Card> {
  buyer?: string
  isHovering?: boolean
  isPlaying?: boolean
  onClick?: MouseEventHandler
  previewAudioUrl?: string
  reduceOpacityWhenPlaying?: boolean
  seperator?: ReactNode
  subtitle?: ReactNode
  thumbnail?: ReactNode
  title?: string
}

const TrackCard: FunctionComponent<React.PropsWithChildren<TrackCardProps>> = ({
  thumbnail,
  title,
  subtitle,
  onClick,
  previewAudioUrl,
  isPlaying,
  isHovering,
  reduceOpacityWhenPlaying = false,
  seperator,
  ...props
}) => (
  <Card
    crossAxisAlignment="center"
    {...props}
    tabIndex={onClick ? 1 : null}
    onClick={onClick}
    isPlaying={isPlaying}
    reduceOpacityWhenPlaying={reduceOpacityWhenPlaying}
  >
    {thumbnail && (
      <Flexible shrink={0} grow={0}>
        <SizedBox width={{ desktop: rem(48), mobile: rem(44) }}>
          <AspectRatio aspectRatio={1}>
            <Expanded>
              <Center>
                {thumbnail}
                {previewAudioUrl && (
                  <MobileOnly>
                    <AbsolutePlayPauseButton
                      size="small"
                      playing={isPlaying}
                      data-gtm-name={isPlaying ? 'cta-audio-player-pause' : 'cta-audio-player-play'}
                      isHovered={isHovering}
                    />
                  </MobileOnly>
                )}
              </Center>
            </Expanded>
          </AspectRatio>
        </SizedBox>
      </Flexible>
    )}
    {thumbnail && (seperator ?? <SizedBox width={{ desktop: rem(20), mobile: rem(16) }} />)}
    {previewAudioUrl && (
      <>
        <DesktopOnly>
          <PlayPauseButton
            size="small"
            playing={isPlaying}
            data-gtm-name={isPlaying ? 'cta-audio-player-pause' : 'cta-audio-player-play'}
            isHovered={isHovering}
          />
        </DesktopOnly>
        <DesktopOnly>
          <Padding right={{ desktop: rem(20), mobile: rem(16) }} />
        </DesktopOnly>
      </>
    )}
    {(title || subtitle) && (
      <StyledColumn>
        {title && (
          <Label size="small" noWrap title={title}>
            {title}
          </Label>
        )}
        {subtitle && (
          <Subtitle size="small" noWrap title={typeof subtitle === 'string' ? subtitle : undefined}>
            {subtitle}
          </Subtitle>
        )}
      </StyledColumn>
    )}
  </Card>
)

const Card = styled(Row)<{
  isPlaying?: boolean
  reduceOpacityWhenPlaying: boolean
}>`
  outline: none;

  ${({ isPlaying, reduceOpacityWhenPlaying }) =>
    reduceOpacityWhenPlaying &&
    isPlaying &&
    css`
      img {
        opacity: ${Opacities.thirtyfive};
      }
    `}
`

const StyledColumn = styled(Column)`
  overflow: hidden;
  flex: 1 1 1px;
  width: 1px; /* needed for some reason in order to enable it to shrink and have the content be ellipsed */
`

const Subtitle = styled(Caption)`
  opacity: ${Opacities.fiftyfive.toString()};
`

const AbsolutePlayPauseButton = styled(PlayPauseButton)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
`

export default TrackCard
