import React from 'react'
import styled, { css } from 'styled-components'

import { BrandColors, SystemColors } from '../../tokens/colors'
import { MediaQueries } from '../../tokens/mediaQueries'
import { Opacities } from '../../tokens/opacities'
import { Transitions } from '../../tokens/transitions'
import { FontFamily } from '../../tokens/typography'
import { rem } from '../../utils/rem'
import Button, { type ButtonProps } from '../Button'
import SizedBox from '../SizedBox'

export type SecondaryButtonSize = 'small' | 'large'

export interface SecondaryButtonProps extends ButtonProps {
  active?: boolean
  size?: SecondaryButtonSize
}

const SecondaryButton = styled(Button).attrs<SecondaryButtonProps>(({ size = 'large' }) => ({
  separator: <SizedBox width={rem(size === 'small' ? 8 : 10)} />,
}))<SecondaryButtonProps>`
  transition: ${Transitions.micro};
  border: 1px solid ${BrandColors.white.toString()};
  outline: 1px solid transparent;
  outline-offset: ${rem(2)};
  color: ${BrandColors.white.toString()};
  font-family: ${FontFamily.regular};
  font-weight: bold;

  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          font-size: ${rem(14)};
          line-height: ${rem(16)};
          padding: 0 ${rem(12)};
          height: ${rem(32)};

          ${MediaQueries.desktop} {
            font-size: ${rem(16)};
            line-height: ${rem(20)};
            padding: 0 ${rem(20)};
            height: ${rem(40)};
          }
        `
      case 'large':
      default:
        return css`
          font-size: ${rem(16)};
          line-height: ${rem(20)};
          padding: 0 ${rem(24)};
          height: ${rem(48)};

          ${MediaQueries.desktop} {
            font-size: ${rem(18)};
            padding: 0 ${rem(32)};
            height: ${rem(56)};
          }
        `
    }
  }}

  &:hover {
    background: ${BrandColors.white.alpha(Opacities.eight).toString()};
  }

  &:focus {
    background: ${BrandColors.white.alpha(Opacities.eight).toString()};
    outline-color: ${SystemColors.info.toString()};
  }

  &:active {
    background: ${BrandColors.white.alpha(Opacities.twelve).toString()};
  }

  &:disabled {
    opacity: ${Opacities.fiftyfive};
  }
`

export default SecondaryButton
