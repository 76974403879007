import { Link } from '@gatsbyjs/reach-router'
import { Icon, Icons, List, Opacities, Opacity, Padding, rem, TextButton } from '@collabhouse/shared'
import React, { type FunctionComponent } from 'react'
import styled from 'styled-components'

import { type Breadcrumb } from '../../hooks/useBreadcrumbs'
import { replaceAll } from '../../utils/string'

export interface BreadcrumbsProps {
  breadcrumbs: Breadcrumb[]
}

const Breadcrumbs: FunctionComponent<React.PropsWithChildren<BreadcrumbsProps>> = ({ breadcrumbs }) => {
  const amountOfBreadcrumbs = breadcrumbs?.length || 0

  if (amountOfBreadcrumbs <= 1) {
    return null
  }

  return (
    <List
      direction="row"
      crossAxisAlignment="center"
      separator={
        <Padding horizontal={rem(4)}>
          <Opacity opacity={Opacities.thirtyfive}>
            <Icon icon={Icons.chevronRight} size={rem(16)} />
          </Opacity>
        </Padding>
      }
    >
      {breadcrumbs.map((breadcrumb, index) => (
        <StyledTextButton
          variant={index === amountOfBreadcrumbs - 1 ? 'tertiary' : 'quaternary'}
          as={Link}
          key={index}
          to={breadcrumb.pathname}
          data-gtm-name={`breadcrumb-navigation${replaceAll(breadcrumb.pathname, '/', '-')}`}
          title={breadcrumb.label}
        >
          {breadcrumb.label}
        </StyledTextButton>
      ))}
    </List>
  )
}

export default Breadcrumbs

const StyledTextButton = styled(TextButton)`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
