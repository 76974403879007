import React, { type SVGProps } from 'react'

interface SuccessIconProps extends SVGProps<SVGSVGElement> {
  size?: number
}

export const SuccessIcon = React.forwardRef<SVGSVGElement, SuccessIconProps>((props, ref) => {
  const { size = 24, ...rest } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...rest}
    >
      <path
        fill="currentColor"
        d="M6.76 11.757 11.003 16l7.071-7.071-1.415-1.414-5.656 5.657-2.829-2.829-1.414 1.414Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1ZM3 12a9 9 0 1 1 18 0 9 9 0 0 1-18 0Z"
        clipRule="evenodd"
      />
    </svg>
  )
})
SuccessIcon.displayName = 'SuccessIcon'
