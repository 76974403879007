import { Breakpoints } from '@collabhouse/shared'
import { useEffect, useState } from 'react'

export const useWindow = (): {
  height: number
  isDesktop: boolean
  isMobile: boolean
  width: number
} => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [isMobile, setIsMobile] = useState(false)

  const handleWindowResize = () => {
    setWidth(Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0))
    setHeight(Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0))
  }

  useEffect(() => {
    setIsMobile(width < Breakpoints.desktop)
  }, [width])

  useEffect(() => {
    handleWindowResize()

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.addEventListener('resize', handleWindowResize)
    }
  }, [])

  return { height, isDesktop: !isMobile, isMobile, width }
}
