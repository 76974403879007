import React, { type FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

import { MediaQueries } from '../../tokens/mediaQueries'
import { rem } from '../../utils/rem'
import BoxedCheckboxInput, { type BoxedCheckboxInputProps } from '../BoxedCheckboxInput'
import FormField, { type FormFieldProps } from '../FormField'
import Grid, { GridItem } from '../Grid'

export interface BoxedCheckboxFieldProps extends Omit<FormFieldProps, 'htmlFor'> {
  columns?: number
  inputs: BoxedCheckboxInputProps[]
}

const BoxedCheckboxField: FunctionComponent<React.PropsWithChildren<BoxedCheckboxFieldProps>> = ({
  inputs,
  columns = 1,
  ...props
}) => (
  <FormField {...props}>
    <InputGrid>
      {inputs.map((input) => (
        <StyledGridItem span={12 / columns} key={input.id} direction={input.direction}>
          <BoxedCheckboxInput {...input} disabled={props.disabled} error={!!props.error} />
        </StyledGridItem>
      ))}
    </InputGrid>
  </FormField>
)

export default BoxedCheckboxField

const InputGrid = styled(Grid)`
  column-gap: ${rem(8)};
  row-gap: ${rem(8)};

  ${MediaQueries.desktop} {
    column-gap: ${rem(16)};
    row-gap: ${rem(12)};
  }

  & > * {
    display: flex;
  }
`

const StyledGridItem = styled(GridItem)<Pick<BoxedCheckboxInputProps, 'direction'>>(
  ({ direction }) =>
    direction === 'horizontal' &&
    css`
      align-self: stretch;
    `,
)
