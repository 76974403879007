import styled from 'styled-components'

import { BrandColors } from '../../tokens/colors'
import { Opacities } from '../../tokens/opacities'

const Divider = styled('hr')`
  display: block;
  margin: 0;
  padding: 0;
  border: none;
  text-decoration: none;
  width: 100%;
  height: 1px;
  background: ${BrandColors.white.alpha(Opacities.twenty).toString()};
`

export default Divider
