import { type GraphQLResult } from '@aws-amplify/api-graphql'
import { API, graphqlOperation } from 'aws-amplify'
import React, { createContext, type FunctionComponent, type ReactNode, useCallback, useEffect, useState } from 'react'

import * as contentCategoriesOperations from '../graphql/contentCategories'
import { useUser } from '../hooks/useUser'

export interface ContentCategory {
  id: string
  name: string
  type?: string
}

export interface ContentCategoriesContext {
  contentCategories: ContentCategory[]
}

export const contentCategoriesContext = createContext<ContentCategoriesContext>({
  contentCategories: [],
})

interface ContentCategoriesProviderProps {
  children: ReactNode | ((context: ContentCategoriesContext) => ReactNode)
}

const ContentCategoriesProvider: FunctionComponent<ContentCategoriesProviderProps> = (props) => {
  const { isLoggedIn } = useUser()
  const [contentCategories, setContentCategories] = useState([])

  const getContentCategories = useCallback(async () => {
    const result = (await API.graphql(
      graphqlOperation(contentCategoriesOperations.getContentCategories),
    )) as GraphQLResult<{ getContentCategories: ContentCategory[] }>

    return result.data?.getContentCategories
  }, [])

  /**
   * Refreshes all the necessary state.
   */
  const hydrate = useCallback(async () => {
    setContentCategories(await getContentCategories())
  }, [getContentCategories])

  /**
   * Clears the provider's state.
   */
  const clear = useCallback(() => {
    setContentCategories([])
  }, [])

  /**
   * Hydrate or clear content categories based on logged in state.
   */
  useEffect(() => {
    if (isLoggedIn) {
      void hydrate()
    } else {
      clear()
    }
  }, [isLoggedIn, hydrate, clear])

  const context: ContentCategoriesContext = {
    contentCategories,
  }

  return (
    <contentCategoriesContext.Provider value={context}>
      {typeof props.children === 'function' ? props.children(context) : props.children}
    </contentCategoriesContext.Provider>
  )
}

export default ContentCategoriesProvider
