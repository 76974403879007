export const FORMATTER = Intl.NumberFormat('nl-NL', {
  currency: 'EUR',
  style: 'currency',
})

export const createNftClaimMessage = (email: string, amount: number, nftId: string) =>
  `This message verifies I (${email}) want to claim ${FORMATTER.format(amount)} for my NFT with id ${nftId}`

export const createConnectWalletMessage = (userId: string, email: string) =>
  `This message verifies that I want to connect this wallet to my Collabhouse account with email ${email} and ID ${userId}.`
