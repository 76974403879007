import type { ValueOf } from 'type-fest'

export const fontFamily = {
  body: ['var(--font-inter)', 'sans-serif'],
  sans: ['var(--font-inter)', 'sans-serif'],
  title: ['var(--font-geomanist)', 'sans-serif'],
}

enum FontWeights {
  BOLD = 700,
  REGULAR = 400,
}

type FontDefinition = { fontWeight: FontWeights; lineHeight: string }

type FontSizes = Record<(typeof primitiveFontSizes)[number], string>

const fontDefinitions: Record<string, FontDefinition> = {
  base: {
    fontWeight: FontWeights.REGULAR,
    lineHeight: '1.5',
  },
  button: {
    fontWeight: FontWeights.REGULAR,
    lineHeight: '1',
  },
  buttonBold: {
    fontWeight: FontWeights.BOLD,
    lineHeight: '1',
  },
  caption: {
    fontWeight: FontWeights.REGULAR,
    lineHeight: '1.25',
  },
  label: {
    fontWeight: FontWeights.BOLD,
    lineHeight: '1.5',
  },
  title: {
    fontWeight: FontWeights.BOLD,
    lineHeight: '1.2',
  },
}

const primitiveFontSizes = ['xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl', '6xl', '7xl'] as const

const fontSize: FontSizes = primitiveFontSizes.reduce(
  (tree, size) => ({
    ...tree,
    [size]: `var(--font-size-${size})`,
  }),
  {} as FontSizes,
)

/* eslint-disable sort-keys-fix/sort-keys-fix */
export const semanticFontSizes: Record<string, [ValueOf<typeof fontSize>, FontDefinition]> = {
  'title-2xl': [fontSize['7xl'], fontDefinitions.title],
  'title-xl': [fontSize['6xl'], fontDefinitions.title],
  'title-lg': [fontSize['5xl'], fontDefinitions.title],
  'title-md': [fontSize['4xl'], fontDefinitions.title],
  'title-sm': [fontSize['3xl'], fontDefinitions.title],
  'title-xs': [fontSize['2xl'], fontDefinitions.title],
  'title-xxs': [fontSize.xl, fontDefinitions.title],
  'body-lg': [fontSize.lg, fontDefinitions.base],
  'body-md': [fontSize.md, fontDefinitions.base],
  'body-sm': [fontSize.sm, fontDefinitions.base],
  'body-xs': [fontSize.xs, fontDefinitions.base],
  'label-lg': [fontSize.lg, fontDefinitions.label],
  'label-md': [fontSize.md, fontDefinitions.label],
  'label-sm': [fontSize.sm, fontDefinitions.label],
  'label-light-lg': [fontSize.lg, fontDefinitions.base],
  'label-light-md': [fontSize.md, fontDefinitions.base],
  'label-light-sm': [fontSize.sm, fontDefinitions.base],
  'label-light-xs': [fontSize.xs, fontDefinitions.base],
  'button-lg': [fontSize.lg, fontDefinitions.button],
  'button-md': [fontSize.md, fontDefinitions.button],
  'button-sm': [fontSize.sm, fontDefinitions.button],
  'button-bold-lg': [fontSize.lg, fontDefinitions.buttonBold],
  'button-bold-md': [fontSize.md, fontDefinitions.buttonBold],
  'button-bold-sm': [fontSize.sm, fontDefinitions.buttonBold],
  'caption-md': [fontSize.sm, fontDefinitions.caption],
  'caption-sm': [fontSize.xs, fontDefinitions.caption],
}
/* eslint-enable sort-keys-fix/sort-keys-fix */
