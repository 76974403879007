import React, { type FunctionComponent, useCallback, useRef } from 'react'
import styled, { css } from 'styled-components'
import { v4 as uuid } from 'uuid'

import { Opacities } from '../../tokens/opacities'
import { rem } from '../../utils/rem'
import Caption from '../Caption'
import Expanded from '../Expanded'
import FormField, { type FormFieldProps } from '../FormField'
import Label from '../Label'
import Row from '../Row'
import SizedBox from '../SizedBox'
import Toggle, { type ToggleProps } from '../Toggle'

export type ToggleFieldVariant = 'default' | 'medium'

export interface ToggleFieldProps extends Omit<FormFieldProps, 'htmlFor'> {
  id?: string
  reversed?: boolean
  toggle: Omit<ToggleProps, 'error'>
  variant?: ToggleFieldVariant
}

const ToggleField: FunctionComponent<React.PropsWithChildren<ToggleFieldProps>> = ({
  id,
  label,
  toggle,
  variant = 'default',
  reversed,
  ...props
}) => {
  const idRef = useRef(id ?? uuid())

  const getLabel = useCallback(() => {
    switch (variant) {
      case 'medium':
        return (
          <Caption as="label" htmlFor={idRef.current} size="medium">
            {label}
          </Caption>
        )
      default:
        return (
          <Label as="label" htmlFor={idRef.current} size="large">
            {label}
          </Label>
        )
    }
  }, [variant, label])

  return (
    <FormField {...props}>
      <Row crossAxisAlignment="center" mainAxisAlignment="space-between" reversed={reversed}>
        <LabelWrapper disabled={props.disabled}>{getLabel()}</LabelWrapper>
        <SizedBox width={rem(16)} />
        <Toggle id={idRef.current} error={Boolean(props.error)} {...toggle} disabled={props.disabled} />
      </Row>
    </FormField>
  )
}

export default ToggleField

interface LabelWrapperProps {
  disabled?: boolean
}

const LabelWrapper = styled(Expanded)<LabelWrapperProps>`
  ${({ disabled = false }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: ${Opacities.thirtyfive};
    `}
`
