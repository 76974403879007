import { createGlobalStyle } from 'styled-components'

const GlobalScale = createGlobalStyle`
html {
  min-width: 320px;
  font-size: 16px;

    @media (min-width: 320px){
      font-size: max(12px, calc(12px + 0.5vw));
    }

    @media (min-width: 360px){
      font-size: max(12px, calc(11px + 1.3888888888888888vw));
    }

    @media (min-width: 768px){
      font-size: max(12px, calc(16px + 0.75vw));
    }

    @media (min-width: 1024px){
      font-size: max(12px, calc(8px + 0.5vw));
    }

    @media (min-width: 1440px){
      font-size: max(12px, calc(10px + 0.4166666667vw));
    }
  }

  body {
    scroll-behavior: smooth;
  }

  body #walletconnect-wrapper a:not([class]) {
    color: black !important;
    padding: 8px;
  }
`

export default GlobalScale
