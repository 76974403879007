import {
  blackcurrantTheme,
  GlobalBackground,
  GlobalFonts,
  GlobalHeight,
  GlobalReset,
  GlobalText,
  SnackbarContainer,
  SnackbarProvider,
} from '@collabhouse/shared'
import React, { type FunctionComponent, type PropsWithChildren } from 'react'
import { Helmet } from 'react-helmet'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ThemeProvider } from 'styled-components'

import AddToHomescreen from '@/components/add-to-homescreen/AddToHomescreen'
import GlobalScale from '@/components/app/global-styles/GlobalScale'
import GlobalTypography from '@/components/app/global-styles/GlobalTypography'
import { Trackers } from '@/components/app/Trackers'
import ErrorBoundary from '@/components/error-boundary/ErrorBoundary'
import GroupFormSteppedProvider from '@/components/group-form-stepped/GroupFormSteppedProvider'

if (typeof window !== 'undefined' && 'serviceWorker' in window.navigator) {
  // this is a useless serviceworker. it does nothing.
  // it only exists to make google chrome happy and ensure it allows us to add to homescreen.
  void window.navigator.serviceWorker.register('/service-worker.js', {
    scope: '/',
  })
}

if (typeof window !== 'undefined') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  window.Buffer = window.Buffer || require('buffer').Buffer
}
interface AppProps {
  rootSelector?: string
}

const App: FunctionComponent<React.PropsWithChildren<PropsWithChildren<AppProps>>> = ({
  rootSelector = '#___gatsby, #gatsby-focus-wrapper',
  ...props
}) => (
  <>
    <Helmet defaultTitle="Collabhouse" titleTemplate={`Collabhouse - %s`} />
    <Trackers />

    <ThemeProvider theme={blackcurrantTheme}>
      <GlobalReset />
      <GlobalHeight rootSelector={rootSelector} />
      <GlobalBackground />
      <GlobalFonts />
      <GlobalScale />
      <GlobalText />
      <GlobalTypography />
      <SnackbarProvider container={SnackbarContainer}>
        <GroupFormSteppedProvider>
          <ErrorBoundary>{props.children}</ErrorBoundary>
        </GroupFormSteppedProvider>
      </SnackbarProvider>
      <ReactQueryDevtools />
      <AddToHomescreen />
    </ThemeProvider>
  </>
)

export default App
