import { Script } from 'gatsby'
import * as React from 'react'

export const Trackers = () => {
  if (process.env.AWS_STAGE !== 'production') {
    return
  }

  return (
    <>
      <Script src="https://cdn.blueconic.net/collabhouse.js" />
    </>
  )
}
