import React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '../../utils/tailwind'
import { Icons } from '../../tokens/icons'
import { DangerIcon, WarningIcon, InfoIcon, SuccessIcon } from '../Icon/StatusIcons'
import Icon from '../Icon'

const alertVariants = cva('w-full rounded-lg p-4 text-content-primary', {
  defaultVariants: {
    variant: 'info',
  },
  variants: {
    variant: {
      error: 'bg-background-negative',
      info: 'bg-background-informative',
      success: 'bg-background-positive',
      warning: 'bg-background-warning',
    },
  },
})

const Alert = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants> & { onClose?: () => void }
>(({ className, variant, onClose, ...props }, ref) => {
  const renderIcon = () => {
    switch (variant) {
      case 'warning':
        return <WarningIcon className="text-content-warning" />
      case 'error':
        return <DangerIcon className="text-content-negative" />
      case 'success':
        return <SuccessIcon className="text-content-positive" />
      case 'info':
      default:
        return <InfoIcon className="text-content-informative" />
    }
  }
  return (
    <div ref={ref} role="alert" className={cn(alertVariants({ variant }), className)} {...props}>
      <div className="flex items-start gap-3 ">
        <div className="flex-shrink-0">{renderIcon()}</div>
        <div>{props.children}</div>
        {onClose && (
          <div className="flex-shrink-0 mt-px ml-auto">
            <button
              aria-label="Close"
              type="button"
              onClick={onClose}
              className={cn(
                'h-[44px] w-[44px]',
                'text-content-tertiary hover:text-content-primary',
                'transition-colors ease-out duration-200',
              )}
            >
              <Icon icon={Icons['cross']} aria-hidden="true" />
            </button>
          </div>
        )}
      </div>
    </div>
  )
})
Alert.displayName = 'Alert'

const AlertTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ className, ...props }, ref) => <h5 ref={ref} className={cn('mb-1 text-label-md', className)} {...props} />,
)
AlertTitle.displayName = 'AlertTitle'

const AlertDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn('text-body-sm text-content-secondary', className)} {...props} />
  ),
)
AlertDescription.displayName = 'AlertDescription'

export { Alert, AlertDescription, AlertTitle }
