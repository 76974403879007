/**
 * Prefixes all the keys in the 2nd layer of an object with the key-name of their parent.
 * Used to namespace definitions for configuration.
 * @example { foo: { bar: true } } would become { 'foo-bar': true }
 */
export const flattenNamespacedObject = (obj: Record<string, Record<string, string>>): Record<string, string> =>
  Object.entries(obj).reduce(
    (child, [section, defs]) => ({
      ...child,
      ...Object.entries(defs).reduce((def, [name, value]) => ({ ...def, [`${section}-${name}`]: value }), {}),
    }),
    {},
  )

export const mergeObjects = <T extends object>(mergeTarget: Partial<T>, mergeSubject: T): T => {
  const result: Record<string, unknown> = { ...mergeTarget }
  const subject = mergeSubject as Record<string, unknown>

  Object.keys(mergeSubject).forEach((key) => {
    if (typeof subject[key] === 'object') {
      if (Array.isArray(subject[key])) {
        /* TODO: Find a way to make this unique without using Set, which causes errors */
        result[key] = [...((result[key] as unknown[]) ?? []), ...subject[key]]
      } else {
        result[key] = mergeObjects(result[key] as object, subject[key] as object)
      }
    } else {
      result[key] = subject[key]
    }
  })
  return result as T
}
