import { useCallback } from 'react'
import TagManager from 'react-gtm-module'

import { inBrowser } from '../utils/inBrowser'

/**
 * This hook is used to update the Google Tag Manager data layer.
 */
export const useDataLayer = (): {
  update: (data: Record<string, unknown>) => void
} => {
  const update = useCallback((data: Record<string, unknown>) => {
    if (inBrowser()) {
      TagManager.dataLayer({ dataLayer: data })
    }
  }, [])

  return { update }
}
