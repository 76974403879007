import { AwsRum, type AwsRumConfig } from 'aws-rum-web'

export interface GenerateAwsRumClientProps {
  applicationId: string
  applicationVersion: string
  awsRegion: string
  enabled: boolean
  endpoint?: string
  identityPoolId?: string
}

/**
 * Domains we DON'T want to send XRay tracing headers to.
 * Will avoid CORS issues, etc.
 */
const tracingBlacklist: RegExp[] = [/^((?!sendbird).)*$/]

type AwsRumShim = {
  recordEvent: AwsRum['recordEvent']
}

const shim: AwsRumShim = {
  recordEvent: (eventName: string, eventData: object) => console.log({ eventData, eventName }),
}

export const generateAwsRumClient = ({
  enabled,
  endpoint,
  identityPoolId,
  applicationId,
  applicationVersion,
  awsRegion,
}: GenerateAwsRumClientProps): AwsRum | AwsRumShim => {
  if (!enabled || typeof window === 'undefined') {
    console.log('AWS RUM Client not enabled, events will be logged locally.')
    return shim
  }

  try {
    const rumConfig: AwsRumConfig = {
      allowCookies: true,
      enableXRay: true,
      endpoint,
      identityPoolId,
      sessionSampleRate: 1,
      telemetries: [
        'performance',
        'errors',
        [
          'http',
          {
            addXRayTraceIdHeader: tracingBlacklist,
          },
        ],
      ],
    }

    const AwsRumClient = new AwsRum(applicationId, applicationVersion, awsRegion, rumConfig)

    return AwsRumClient
  } catch (error) {
    console.error('Error during AWS RUM setup:', error)
    return shim
  }
}
