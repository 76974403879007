/**
 * Purpose: manage state of the multistep form.
 */
import React, { createContext, type ReactNode, useCallback, useEffect, useMemo, useState } from 'react'

import { type GroupFormSteppedContext, type RegistrationStep, Role } from '@/components/group-form-stepped//types'
import { finalStep, initialSteps, roleBasedFlows } from '@/components/group-form-stepped/flow'
import { type SearchSpotifyArtist, type VerifySocialAccountsResult } from '@/graphql/generated'

function getRegistrationFlowForRole(role: Role): RegistrationStep[] {
  return [...initialSteps, ...roleBasedFlows[role], ...finalStep]
}

function stepForward(currentStep: RegistrationStep, role: Role): RegistrationStep | undefined {
  return getStep(
    currentStep,
    role,
    (currentIndex) => currentIndex + 1,
    (currentIndex, length) => currentIndex !== -1 && currentIndex < length - 1,
  )
}

function stepBack(currentStep: RegistrationStep, role: Role): RegistrationStep | undefined {
  return getStep(
    currentStep,
    role,
    (currentIndex) => currentIndex - 1,
    (currentIndex) => currentIndex !== -1 && currentIndex > 0,
  )
}

export const groupFormSteppedContext = createContext<GroupFormSteppedContext>({
  gotoNextStep: () => null,
  gotoPreviousStep: () => null,
  isFinished: false,
  isSubmitting: false,
  onCancel: () => null,
  progress: 1,
  resetSteps: () => null,
  role: undefined,
  setArtistProfile: () => null,
  setIsFinished: () => null,
  setIsSubmitting: () => null,
  setRole: () => null,
  setSocials: () => null,
  step: initialSteps[0],
  totalSteps: 4,
})

export function getStep(
  currentStep: RegistrationStep,
  role: Role,
  stepModifier: (currentIndex: number) => number,
  condition: (currentIndex: number, length: number) => boolean,
): RegistrationStep | undefined {
  const registrationFlow = getRegistrationFlowForRole(role)
  const currentIndex = registrationFlow.findIndex((step) => step.id === currentStep.id)
  if (condition(currentIndex, registrationFlow.length)) {
    return registrationFlow[stepModifier(currentIndex)]
  }
  return undefined
}

const GroupFormSteppedProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [step, setStep] = useState<RegistrationStep>(initialSteps[0])
  const [role, setRole] = useState<Role>(Role.Undefined)
  const [socials, setSocials] = useState<VerifySocialAccountsResult>(undefined)
  const [progress, setProgress] = useState<number>(0)
  const [totalSteps, setTotalSteps] = useState<number>(4)
  const [artistProfile, setArtistProfile] = useState<SearchSpotifyArtist>(undefined)
  const [isFinished, setIsFinished] = useState<boolean>(false)

  useEffect(() => {
    const registrationFlow = getRegistrationFlowForRole(role)
    setTotalSteps(registrationFlow.length - 1)
  }, [role])

  useEffect(() => {
    const registrationFlow = getRegistrationFlowForRole(role)
    const currentIndex = registrationFlow.findIndex((flowSteps) => flowSteps.id === step.id)
    setProgress(currentIndex)
  }, [step, role])

  const nextStep = useCallback(() => {
    const nextStep = stepForward(step, role)
    if (nextStep) {
      setStep(nextStep)
    }
  }, [step, role])

  const previousStep = useCallback(() => {
    const previousStep = stepBack(step, role)
    if (previousStep) {
      setStep(previousStep)
    }
  }, [step, role])

  const resetSteps = useCallback(() => {
    setStep(initialSteps[0])
  }, [])

  const onCancel = useCallback(() => {
    // Implement cancel logic?
  }, [])

  const providerValue = useMemo(
    () => ({
      artistProfile,
      gotoNextStep: nextStep,
      gotoPreviousStep: previousStep,
      isFinished,
      isSubmitting,
      onCancel,
      progress,
      resetSteps: resetSteps,
      role,
      setArtistProfile: setArtistProfile,
      setIsFinished,
      setIsSubmitting: setIsSubmitting,
      setRole: setRole,
      setSocials,
      socials,
      step,
      totalSteps,
    }),
    [
      step,
      progress,
      totalSteps,
      artistProfile,
      socials,
      nextStep,
      previousStep,
      setArtistProfile,
      onCancel,
      setSocials,
      role,
      isSubmitting,
      setIsSubmitting,
      resetSteps,
      isFinished,
      setIsFinished,
    ],
  )

  return <groupFormSteppedContext.Provider value={providerValue}>{children}</groupFormSteppedContext.Provider>
}

export default GroupFormSteppedProvider
