import { default as React, type FC } from 'react'

import { icons } from './icons'

interface Props {
  icon: 'facebook' | 'google' | 'apple' | 'logo' | 'check'
}

const SvgIcon: FC<Props> = ({ icon }) => <>{icons[icon]}</>

export default SvgIcon
