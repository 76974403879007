import styled from 'styled-components'

import { MediaQueries } from '../../tokens/mediaQueries'

export interface ResponsiveSize {
  desktop?: string | number
  mobile?: string | number
}

export interface SizedBoxProps {
  height?: string | number | ResponsiveSize
  width?: string | number | ResponsiveSize
}

const getWidth = (width: SizedBoxProps['width'], isMobile = false): string => {
  if (!width || (!isMobile && typeof width !== 'object')) {
    return ''
  }

  let computedWidth

  if (typeof width === 'object') {
    const { mobile, desktop } = width

    if (isMobile && mobile) {
      computedWidth = typeof mobile === 'string' ? mobile : `${mobile}px`
    }

    if (!isMobile && desktop) {
      computedWidth = typeof desktop === 'string' ? desktop : `${desktop}px`
    }
  } else if (typeof width === 'string') {
    computedWidth = width
  } else if (typeof width === 'number') {
    computedWidth = `${width}px`
  }

  return computedWidth ? `width: ${computedWidth};` : ''
}

const getHeight = (height: SizedBoxProps['height'], isMobile = false): string => {
  if (!height || (!isMobile && typeof height !== 'object')) {
    return ''
  }

  let computedHeight

  if (typeof height === 'object') {
    const { mobile, desktop } = height

    if (isMobile && mobile) {
      computedHeight = typeof mobile === 'string' ? mobile : `${mobile}px`
    }

    if (!isMobile && desktop) {
      computedHeight = typeof desktop === 'string' ? desktop : `${desktop}px`
    }
  } else if (typeof height === 'string') {
    computedHeight = height
  } else if (typeof height === 'number') {
    computedHeight = `${height}px`
  }

  return computedHeight ? `height: ${computedHeight};` : ''
}

/**
 * A SizedBox can be used to provide a component with a specifically sized
 * parent. Or it could be used as a spacer component, to prevent the need for
 * margins on other components.
 *
 * NB: This component is inspired by, but not identical to the Flutter
 * equivalent.
 * @see https://api.flutter.dev/flutter/widgets/SizedBox-class.html
 */
const SizedBox = styled.div<SizedBoxProps>`
  ${null}
  flex-shrink: 0;
  ${({ width }) => getWidth(width, true)};
  ${({ height }) => getHeight(height, true)};

  ${MediaQueries.desktop} {
    ${({ width }) => getWidth(width)};
    ${({ height }) => getHeight(height)};
  }
`

export default SizedBox
