import { BrandColors, Opacities, rem, SystemColors, Transitions } from '@collabhouse/shared'
import { createGlobalStyle } from 'styled-components'

/**
 * Basic typography styling
 * Necessary for pages generated by a json file, for example FAQ pages
 */
const GlobalTypography = createGlobalStyle`
  body {
    a:not([class]) {
      font-weight: bold;
      text-decoration: none;
      background-image: linear-gradient(
        ${BrandColors.white.toString()},
        ${BrandColors.white.toString()}
      );
      background-repeat: no-repeat;
      background-position: 0 100%;
      background-size: 100% 1px;
      padding-bottom: ${rem(1)};
      transition: ${Transitions.micro};
      outline: none;
      color: ${BrandColors.white.toString()};

      :hover {
        opacity: ${Opacities.fiftyfive};
      }

      :active {
        opacity: ${Opacities.fiftyfive};
      }

      :focus {
        background-image: linear-gradient(
          ${SystemColors.info.toString()},
          ${SystemColors.info.toString()}
        );
      }

      :disabled {
        opacity: ${Opacities.thirtyfive};
      }
    }

    ul:not([class]) {
      margin: ${rem(10)} 0 ${rem(10)} ${rem(10)};

      li {
        list-style: '- ';
      }
    }
  }
`

export default GlobalTypography
