import React, { type FC, type SVGProps } from 'react'

interface ClockIconProps extends SVGProps<SVGSVGElement> {
  size?: number
}

export const ClockIcon: FC<ClockIconProps> = ({ size = 24, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width={size} height={size} {...props}>
    <path fill="currentColor" d="M13 11.382V6h-2v6.618l4.553 2.276.894-1.788L13 11.382Z" />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1ZM3 12a9 9 0 1 1 18 0 9 9 0 0 1-18 0Z"
      clipRule="evenodd"
    />
  </svg>
)
