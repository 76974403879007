import styled from 'styled-components'

import { MediaQueries } from '../../tokens/mediaQueries'

const MobileOnly = styled.div`
  ${MediaQueries.desktop} {
    display: none;
  }
`
export default MobileOnly
