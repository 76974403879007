import React, { type ButtonHTMLAttributes, type FunctionComponent, type ReactNode } from 'react'
import styled from 'styled-components'

import { rem } from '../../utils/rem'
import BaseButton from '../BaseButton'
import Row from '../Row'
import SizedBox from '../SizedBox'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  leading?: ReactNode
  separator?: ReactNode
  trailing?: ReactNode
}

const Button: FunctionComponent<React.PropsWithChildren<ButtonProps>> = ({
  children,
  leading,
  trailing,
  separator = <SizedBox width={rem(10)} />,
}) => (
  <>
    {leading && (
      <>
        <Row crossAxisAlignment="center">{leading}</Row>
        {separator}
      </>
    )}
    {children}
    {trailing && (
      <>
        {separator}
        <Row crossAxisAlignment="center">{trailing}</Row>
      </>
    )}
  </>
)

const StyledButton = styled(BaseButton).attrs<ButtonProps>((props) => ({
  children: <Button {...props} />,
}))<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
`

export default StyledButton
