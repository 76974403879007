export default class Timer {
  callback: () => void
  delay: number
  id: number | undefined
  startedAt: number
  remaining: number

  constructor(callback: () => void, delay: number) {
    this.callback = callback
    this.delay = delay
    this.startedAt = 0
    this.remaining = 0
    this.start()
  }

  public start(): void {
    this.remaining = this.delay
    this.resume()
  }

  public pause(): void {
    window.clearTimeout(this.id)
    this.remaining -= Date.now() - this.startedAt
  }

  public resume(): void {
    this.startedAt = Date.now()
    window.clearTimeout(this.id)
    this.id = window.setTimeout(this.callback, this.remaining)
  }

  public stop(): void {
    window.clearTimeout(this.id)
    this.remaining = 0
  }

  public reset(): void {
    window.clearTimeout(this.id)
    this.start()
  }

  public extend(delay: number): void {
    this.delay = this.remaining + delay
    this.start()
  }
}
