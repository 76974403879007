/* eslint-disable sort-keys-fix/sort-keys-fix */
import defaultColors from 'tailwindcss/colors'
import { type ValueOf } from 'type-fest'

import { flattenNamespacedObject } from '../util'

const primitives = {
  springGreen: '#00EF86',
  darkBlue: '#370CC9',
  violentViolet: '#180753',
  lightSteel: '#3B3450',
  steelGray: {
    DEFAULT: 'rgb(37 30 60)',
    '/80': 'rgba(37 30 60 / 80%);',
    '/65': 'rgba(37 30 60 / 65%);',
  },
  haiti: '#1D172F',
  blackcurrant: {
    DEFAULT: 'rgb(17 8 30)',
    '/65': 'rgba(17 8 30 / 65%)',
    '/55': 'rgba(17 8 30 / 55%)',
    '/35': 'rgba(17 8 30 / 35%)',
    '/20': 'rgba(17 8 30 / 20%)',
    '/12': 'rgba(17 8 30 / 12%)',
    '/8': 'rgba(17 8 30 / 8%)',
    '/4': 'rgba(17 8 30 / 4%)',
  },
  white: {
    DEFAULT: 'rgb(255 255 255)',
    '/75': 'rgba(255 255 255 / 75%)',
    '/55': 'rgba(255 255 255 / 55%)',
    '/35': 'rgba(255 255 255 / 35%)',
    '/20': 'rgba(255 255 255 / 20%)',
    '/12': 'rgba(255 255 255 / 12%)',
    '/8': 'rgba(255 255 255 / 8%)',
    '/4': 'rgba(255 255 255 / 4%)',
  },
  green: {
    '/12': 'rgba(66 243 51 / 12%)',
    DEFAULT: 'rgb(66 243 51)',
  },
  red: {
    '/12': 'rgba(255 58 70 / 12%)',
    DEFAULT: 'rgb(255 58 70)',
  },
  yellow: {
    '/12': 'rgba(243 204 0 / 12%)',
    DEFAULT: 'rgb(243 204 0)',
  },
  blue: {
    '/12': 'rgba(81 160 255 / 12%)',
    DEFAULT: 'rgb(81 160 255)',
  },
} as const

type Primitives = typeof primitives

type PrimitiveValue = ValueOf<{
  [Key in keyof Primitives]: Primitives[Key] extends string ? Primitives[Key] : ValueOf<Primitives[Key]>
}>

const semanticColors = {
  content: {
    primary: primitives.white.DEFAULT,
    secondary: primitives.white['/75'],
    tertiary: primitives.white['/55'],
    subdued: primitives.white['/35'],
    'primary-inverse': primitives.blackcurrant.DEFAULT,
    'secondary-inverse': primitives.blackcurrant['/65'],
    'tertiary-inverse': primitives.blackcurrant['/55'],
    'subdued-inverse': primitives.blackcurrant['/35'],
    emphasized: primitives.springGreen,
    highlight: primitives.darkBlue,
    positive: primitives.green.DEFAULT,
    negative: primitives.red.DEFAULT,
    warning: primitives.yellow.DEFAULT,
    informative: primitives.blue.DEFAULT,
  },
  background: {
    primary: primitives.blackcurrant.DEFAULT,
    secondary: primitives.haiti,
    tertiary: primitives.steelGray.DEFAULT,
    subdued: primitives.lightSteel,
    'primary-inverse': primitives.white.DEFAULT,
    'secondary-inverse': primitives.white['/55'],
    'tertiary-inverse': primitives.white['/35'],
    'subdued-inverse': primitives.white['/12'],
    emphasized: primitives.springGreen,
    highlight: primitives.darkBlue,
    positive: primitives.green['/12'],
    negative: primitives.red['/12'],
    warning: primitives.yellow['/12'],
    informative: primitives.blue['/12'],
  },
  'background-ui': {
    'overlay-modal': primitives.steelGray['/65'],
    'overlay-dialog': primitives.blackcurrant['/65'],
    'background-hover': primitives.white['/4'],
    'overlay-image': primitives.blackcurrant['/20'],
    'background-press': primitives.white['/8'],
  },
  border: {
    primary: primitives.white['/75'],
    secondary: primitives.white['/55'],
    tertiary: primitives.white['/35'],
    subdued: primitives.white['/20'],
    'subdued-extra': primitives.white['/12'],
    negative: primitives.red.DEFAULT,
    informative: primitives.blue.DEFAULT,
  },
} as const satisfies Record<string, Record<string, PrimitiveValue>>

const oldBrandColors = {
  black: '#000',
  blackcurrant: { 100: '#0A0114', 200: '#251E3C', DEFAULT: '#130B21' },
  // #0A0114
  gradient: { from: '#170F23', to: '#190F2C' },
  gray: defaultColors.gray,
  lavenderBlue: { 500: '#9ACAE9', DEFAULT: '#370CC9' },
  tickleMePink: '#FF758F',
  white: '#fff',
}

export const colors = {
  ...oldBrandColors,
  ...flattenNamespacedObject(semanticColors),
  current: 'currentColor',
  transparent: 'transparent',
}
