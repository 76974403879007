export const addProductArtist = /* GraphQL */ `
  mutation AddProductArtist($productId: ID!, $artistId: ID!, $input: ProductArtistInput!) {
    addProductArtist(productId: $productId, artistId: $artistId, input: $input) {
      productId
      artistId
      primary
      position
    }
  }
`

export const removeProductArtist = /* GraphQL */ `
  mutation RemoveProductArtist($productId: ID!, $artistId: ID!) {
    removeProductArtist(productId: $productId, artistId: $artistId) {
      productId
      artistId
      primary
    }
  }
`
