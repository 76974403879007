import SendbirdChat, { ConnectionHandler, SessionHandler, UserEventHandler } from '@sendbird/chat'
import { GroupChannelModule, type SendbirdGroupChat } from '@sendbird/chat/groupChannel'
import { navigate } from 'gatsby'
import React, { createContext, type FunctionComponent, type ReactNode, useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { type SendbirdSession } from '@/graphql/generated'
import { fetchChannel, fetchRefreshSendbirdSession } from '@/graphql/sendbird'
import { useGroup } from '@/hooks/useGroup'
import { routes } from '@/utils/routes'

const USER_EVENT_HANDLER_ID = 'sendbirdUserEventHandler'
const CONNECTION_HANDLER_ID = 'sendbirdConnectionHandler'

export interface SendbirdContext {
  channelUrl: string | null
  sendbird: SendbirdGroupChat | null
  sendbirdSession: SendbirdSession | null
  setTargetGroupId: (id: string) => void
  startChatError: string | null
  unreadMessageCount: number
}

export const chatContext = createContext<SendbirdContext>({
  channelUrl: null,
  sendbird: null,
  sendbirdSession: null,
  setTargetGroupId: undefined,
  startChatError: null,
  unreadMessageCount: 0,
})

interface ChatProviderProps {
  children: ReactNode
}

const ChatProvider: FunctionComponent<React.PropsWithChildren<ChatProviderProps>> = (props) => {
  const [sendbird, setSendbird] = useState<SendbirdGroupChat | null>(null)
  const [sendbirdSession, setSendbirdSession] = useState<SendbirdSession | null>(null)
  const [targetGroupId, setTargetGroupId] = useState<string | null>(null)
  const [startChatError, setStartChatError] = useState<string | null>(null)
  const [channelUrl, setChannelUrl] = useState<string | null>(null)
  const [unreadMessageCount, setUnreadMessageCount] = useState(0)
  const { group } = useGroup()

  const requestingChatWithSomeone = !!group?.id && !!targetGroupId && !(group?.id === targetGroupId)

  // Get the channel URL if a target group is given
  useQuery(
    ['sendbird-start-chat', group?.id, targetGroupId],
    () =>
      fetchChannel({
        initiatingGroupId: group.id,
        targetGroupId: targetGroupId,
      }),
    {
      enabled: requestingChatWithSomeone,
      onError: (error) => {
        console.error('Error fetching chat channel:', error)

        setStartChatError(error instanceof Error ? error.message : `${error}`)
      },
      onSuccess: (data) => {
        setStartChatError(null)

        try {
          if (!data.data?.channelUrl) {
            throw new Error('There was a problem registering a new channel.')
          }
          setChannelUrl(data.data?.channelUrl)
        } catch (error) {
          console.error('Unable to create a new channel:', error)
        }
      },
    },
  )

  // Get sendbird ID if no target group is given
  const refreshSendbirdSession = useQuery(
    ['refresh-sendbird-session', group?.id],
    () => fetchRefreshSendbirdSession({ input: { groupId: group?.id } }),
    {
      enabled: !!group?.id,
      onError: (error) => {
        console.error('Error refreshing sendbird session', error)

        // The user doesn't have a sendbird ID yet.
        void navigate(routes.collaboration.overview.url)
      },
      onSuccess: (data) => setSendbirdSession(data.data),
    },
  )

  // Refresh the sendbird session (if there was none) when the channel url changed.
  useEffect(() => {
    if (!sendbirdSession && channelUrl) {
      void refreshSendbirdSession.refetch()
    }
  }, [sendbirdSession, channelUrl, refreshSendbirdSession])

  // Initialize sendbird
  const createSendbird = () => {
    const sendbird = SendbirdChat.init({
      appId: process.env.SENDBIRD_APP_ID,
      modules: [new GroupChannelModule()],
    }) as unknown as SendbirdGroupChat

    sendbird.addConnectionHandler(
      CONNECTION_HANDLER_ID,
      new ConnectionHandler({
        onConnected: (_userId: string) => {
          void (async () => {
            try {
              await sendbird.setChannelInvitationPreference(true)

              // Keep unread count up-to-date.
              setUnreadMessageCount(await sendbird.groupChannel.getTotalUnreadMessageCount())
              sendbird.removeUserEventHandler(USER_EVENT_HANDLER_ID)
              sendbird.addUserEventHandler(
                USER_EVENT_HANDLER_ID,
                new UserEventHandler({
                  onTotalUnreadMessageCountChanged: (unreadCount) =>
                    setUnreadMessageCount(unreadCount.groupChannelCount),
                }),
              )
            } catch (error) {
              console.error(error)
            }
          })()
        },
      }),
    )

    sendbird.setSessionHandler(
      new SessionHandler({
        onSessionError: (error: Error) => console.error('Sendbird session error', error),
        onSessionTokenRequired: (resolve, reject) => {
          void (async () => {
            try {
              // TODO: Temporary work around while the UIKit does not support a session handler to be provided on App level.
              // By temporarily not rendering the chat, and then rendering it again when the session is refreshed, the UIKit
              // correctly uses the new token.
              setSendbirdSession(null)

              const result = await refreshSendbirdSession.refetch()
              if (result?.data?.data?.token) {
                resolve(result.data.data.token)
              } else {
                reject(
                  new Error(
                    `Could not refresh sendbird session token ${
                      result?.data.errors?.map((error) => error.message).join(', ') ?? ''
                    }`,
                  ),
                )
              }
            } catch (error) {
              reject(error)
            }
          })()
        },
      }),
    )

    return sendbird
  }
  useEffect(
    () => (sendbird ? undefined : setSendbird(createSendbird())),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sendbird],
  )

  // Connect once the sendbird session is available.
  useEffect(() => {
    ;(async () => {
      if (!sendbird || !sendbirdSession) return
      await sendbird.connect(sendbirdSession.userId, sendbirdSession.token)
    })()
      .then(undefined, console.error)
      .catch(console.error)
  }, [sendbird, sendbirdSession])

  return (
    <chatContext.Provider
      value={{
        channelUrl,
        sendbird,
        sendbirdSession,
        setTargetGroupId,
        startChatError,
        unreadMessageCount,
      }}
    >
      {props.children}
    </chatContext.Provider>
  )
}

export default ChatProvider
