import React, { type FunctionComponent, type ReactNode } from 'react'
import styled from 'styled-components'

import { BrandColors } from '../../tokens/colors'
import { Opacities } from '../../tokens/opacities'
import { rem } from '../../utils/rem'
import Caption from '../Caption'
import Column from '../Column'
import Expanded from '../Expanded'
import Flex, { type FlexProps } from '../Flex'
import Label from '../Label'
import Padding from '../Padding'
import Row from '../Row'
import SizedBox, { type ResponsiveSize } from '../SizedBox'
import VerticalDivider from '../VerticalDivider'

export interface ListItemProps extends Pick<React.HTMLProps<'div'>, 'style' | 'className'> {
  border?: boolean
  divider?: boolean
  hoverTitle?: string
  leading?: ReactNode
  leadingPadding?: string | number | ResponsiveSize
  mainProps?: FlexProps
  minHeight?: number
  subtitle?: ReactNode
  title?: ReactNode
  trailing?: ReactNode
  verticalPadding?: number
}

const ListItem: FunctionComponent<React.PropsWithChildren<ListItemProps>> = ({
  leading,
  leadingPadding = { desktop: rem(16), mobile: rem(8) },
  title,
  hoverTitle,
  subtitle,
  children,
  trailing,
  divider = false,
  minHeight,
  border,
  mainProps,
  verticalPadding,
  ...props
}) => (
  <Item {...props} border={border} title={hoverTitle}>
    <Padding as={Expanded} vertical={rem(verticalPadding ?? 12)}>
      <Row crossAxisAlignment="stretch" mainAxisAlignment="space-between">
        {minHeight && <SizedBox height={rem(minHeight - (verticalPadding ?? 12) * 2)} />}
        {leading && (
          <>
            <SizedBox width={leadingPadding} />
            <Row crossAxisAlignment="center">{leading}</Row>
          </>
        )}

        <>
          <SizedBox
            width={{
              desktop: rem(16),
              mobile: rem(16),
              ...(leading
                ? typeof leadingPadding === 'object'
                  ? { mobile: leadingPadding.mobile }
                  : { mobile: leadingPadding }
                : undefined),
            }}
          />
          <Main direction="row" {...mainProps}>
            {(title || subtitle) && (
              <TextWrapper mainAxisAlignment="center" style={{ overflow: 'hidden' }}>
                {title && (
                  <Label size="medium" noWrap>
                    {title}
                  </Label>
                )}
                {subtitle && <Caption size="medium">{subtitle}</Caption>}
              </TextWrapper>
            )}
            {children}
          </Main>
          <SizedBox width={{ desktop: rem(16), mobile: rem(8) }} />
        </>

        {divider && (
          <Row>
            <VerticalDivider />
          </Row>
        )}

        {trailing && (
          <>
            <SizedBox width={{ desktop: rem(8), mobile: rem(0) }} />
            <Row crossAxisAlignment="center">{trailing}</Row>
            <SizedBox width={{ desktop: rem(8), mobile: rem(0) }} />
          </>
        )}
      </Row>
    </Padding>
  </Item>
)

const Item = styled.div<{ border?: boolean }>`
  position: relative;
  width: 100%;
  background: ${BrandColors.haiti.toString()};
  ${({ border }) => border && `border-left: 4px solid ${BrandColors.white.toString()};`}
`

const Main = styled(Flex)`
  flex-grow: 1;
  overflow: hidden;
`

const TextWrapper = styled(Column)`
  overflow: hidden;

  ${Caption} {
    color: ${BrandColors.white.alpha(Opacities.sixtyfive).toString()};
  }
`

export default ListItem
