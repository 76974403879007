// PhoneNumberField.tsx
import React, { type FunctionComponent, useRef, useState } from 'react'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import { BrandColors } from '../../tokens/colors'
import { MediaQueries } from '../../tokens/mediaQueries'
import { Opacities } from '../../tokens/opacities'
import { rem } from '../../utils/rem'
import CountryCodeSelect from '../CountryCodeSelect'
import FormField, { type FormFieldProps } from '../FormField'
import Input, { type InputProps } from '../Input'
import { type SelectFieldProps } from '../SelectField'

export interface PhoneNumberFieldProps extends Omit<FormFieldProps, 'htmlFor'> {
  countryCodeSelect?: SelectFieldProps
  id?: string
  input?: Omit<InputProps, 'disabled' | 'error'>
  light?: boolean
  name?: string
  onChange?: (value: string) => void
}

const PhoneNumberField: FunctionComponent<React.PropsWithChildren<PhoneNumberFieldProps>> = ({
  id,
  input,
  countryCodeSelect,
  light = false,
  onChange,
  ...props
}) => {
  // Destructure props
  const { disabled, error } = props

  const idRef = useRef(id ?? uuid())
  const [selectedCountryCode, setSelectedCountryCode] = useState('+31')

  const handleCountryCodeChange = (value: string) => {
    setSelectedCountryCode(value)
  }

  const handlePhoneNumberChange = (value: string) => {
    const trimmedValue = value.replace(selectedCountryCode, '').trim()
    const combinedValue = trimmedValue === '' ? '' : `${selectedCountryCode} ${trimmedValue}`

    onChange?.(combinedValue)
  }

  return (
    <FormField {...props} htmlFor={idRef.current}>
      <PhoneNumberInputWrapper>
        <CountryCodeSelectWrapper>
          <CountryCodeSelect
            disabled={disabled}
            light={light}
            select={{
              onChange: (e) => handleCountryCodeChange(e as string),
              value: selectedCountryCode,
              ...countryCodeSelect?.select,
            }}
          />
        </CountryCodeSelectWrapper>
        <PhoneNumberInput
          {...input}
          light={light}
          id={idRef.current}
          disabled={disabled}
          error={!!error}
          value={`${input?.value || ''}`}
          onChange={(e) => handlePhoneNumberChange(e.target.value)}
        />
      </PhoneNumberInputWrapper>
    </FormField>
  )
}

export default PhoneNumberField

const CountryCodeSelectWrapper = styled.div`
  width: 100%;
  margin-right: 0;

  ${MediaQueries.desktop} {
    width: ${rem(250)};
    margin-right: ${rem(16)};
  }
`

const PhoneNumberInputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${MediaQueries.desktop} {
    flex-wrap: nowrap;
  }
`

const PhoneNumberInput = styled(Input)`
  letter-spacing: ${rem(0.2)};
  flex-grow: 1;
  &:hover {
    border: 1px solid ${BrandColors.white.alpha(Opacities.thirtyfive).toString()};
  }
`
