import React, { type FunctionComponent } from 'react'
import styled from 'styled-components'

import { BrandColors } from '../../tokens/colors'
import { MediaQueries } from '../../tokens/mediaQueries'
import { Opacities } from '../../tokens/opacities'
import { Transitions } from '../../tokens/transitions'
import { rem } from '../../utils/rem'
import Label from '../Label'

interface Props {
  currentStep: number
  totalSteps: number
}

const ProgressIndicator: FunctionComponent<React.PropsWithChildren<Props>> = ({
  currentStep,
  totalSteps,
  ...props
}) => (
  <Wrapper {...props}>
    <svg viewBox={`0 0 100 100`}>
      <rect
        x="0"
        y="0"
        width="100%"
        height="100%"
        stroke={BrandColors.white.alpha(Opacities.fiftyfive).toString()}
        strokeWidth="5"
        fill="transparent"
      />
      <path
        stroke={BrandColors.springGreen.toString()}
        strokeWidth="5"
        d="M0 0 L0 100 L100 100 L100 0 L0 0"
        fill="transparent"
        strokeDasharray={400}
        strokeDashoffset={400 * (currentStep / totalSteps - 1)}
      />
    </svg>
    <div>
      <Label color={BrandColors.springGreen.toString()}>{currentStep} </Label>
      <Label color={BrandColors.white.alpha(Opacities.fiftyfive).toString()}>/ {totalSteps}</Label>
    </div>
  </Wrapper>
)

export default ProgressIndicator

const Wrapper = styled.div`
  background-color: ${BrandColors.steelGray.toString()};
  position: relative;
  min-width: ${rem(54)};
  height: ${rem(54)};
  display: flex;
  justify-content: center;
  align-items: center;

  ${MediaQueries.desktop} {
    min-width: ${rem(66)};
    height: ${rem(66)};
  }

  & > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    path {
      transition: stroke-dashoffset ${Transitions.micro};
    }
  }
`
