import Paginate, { type PaginationLocale, type PaginationProps } from 'rc-pagination'
import locale from 'rc-pagination/lib/locale/en_US'
import React, { type FC } from 'react'
import { useQueryParamString } from 'react-use-query-param-string'
import styled, { css } from 'styled-components'

import { BrandColors } from '../../tokens/colors'
import { Icons } from '../../tokens/icons'
import Center from '../Center'
import Icon from '../Icon'
import SecondaryButton from '../SecondaryButton'

const Pagination: FC<React.PropsWithChildren<PaginationProps>> = (props) => {
  const [pageParam, setPage] = useQueryParamString('page', '1')
  const currentPage = +pageParam || 1

  return (
    <Container>
      <Center>
        <Paginate
          {...props}
          style={{ flexWrap: 'wrap' }}
          defaultPageSize={props.pageSize}
          locale={locale as PaginationLocale}
          itemRender={(page, type) =>
            type === 'jump-prev' || type === 'jump-next' ? (
              <Jumper onClick={() => setPage(page.toString())}>{'…'}</Jumper>
            ) : (
              <Button
                isActive={type === 'page' && currentPage === page}
                onClick={() => setPage(page.toString())}
                size="small"
              >
                {type === 'prev' && <Icon icon={Icons.chevronLeft} />}
                {type === 'page' && page}

                {type === 'next' && <Icon icon={Icons.chevronRight} />}
              </Button>
            )
          }
        />
      </Center>
    </Container>
  )
}

const Button = styled(SecondaryButton)<{ isActive: boolean }>`
  padding: 1rem;

  ${({ isActive }) =>
    isActive &&
    css`
      background: ${BrandColors.white.hex()} !important;
      color: ${BrandColors.black.hex()} !important;
    `}
`

const Jumper = styled.div`
  width: 2rem;
  cursor: pointer;
  height: 2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

const Container = styled.div`
  li + li {
    margin-left: 0.6rem;
  }

  ul {
    display: flex;
  }
`

export default Pagination
