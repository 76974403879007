import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { BrandColors } from '../../tokens/colors'
import { Opacities } from '../../tokens/opacities'
import { rem } from '../../utils/rem'

export interface LabelSelectorFieldProps {
  labels: { id: string; label: string }[]
  multiple: boolean
  name: string
  onChange: (value: string[]) => void
  selectionLimit?: number
  value: string[]
}

// This component is designed to work either as a standalone component, like with Storybook,
// or as part of a Formik form. When it's "controlled" by Formik, the value and onChange props
// are used to manage the state. When it's not controlled, the internal state (useState hook)
// is used.
const LabelSelectorField: React.FC<LabelSelectorFieldProps> = ({
  labels,
  multiple,
  selectionLimit = Number.MAX_SAFE_INTEGER,
  value: externalValue,
  onChange: externalOnChange,
}) => {
  const [internalSelectedLabels, setInternalSelectedLabels] = useState<string[]>([])
  const isControlled = externalOnChange !== undefined
  const selectedLabels = isControlled ? externalValue : internalSelectedLabels

  const handleLabelClick = (id: string) => {
    let newSelectedLabels: string[] = []

    const isSelected = selectedLabels.includes(id)

    if (multiple && selectionLimit > 1 && selectedLabels.length === selectionLimit && !isSelected) {
      return
    }

    if (multiple) {
      if (isSelected) {
        newSelectedLabels = selectedLabels.filter((item) => item !== id)
      } else if (selectedLabels.length < selectionLimit) {
        newSelectedLabels = [...selectedLabels, id]
      }
    } else {
      newSelectedLabels = [id]
    }

    if (isControlled) {
      externalOnChange(newSelectedLabels)
    } else {
      setInternalSelectedLabels(newSelectedLabels)
    }
  }

  return (
    <LabelContainer>
      {labels.map(({ id, label }) => (
        <LabelPill
          key={id}
          onClick={() => handleLabelClick(id)}
          isSelected={selectedLabels.includes(id)}
          isDisabled={multiple && selectedLabels.length >= selectionLimit && !selectedLabels.includes(id)}
        >
          {label}
        </LabelPill>
      ))}
    </LabelContainer>
  )
}

const LabelContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

interface LabelPillProps {
  isDisabled: boolean
  isSelected: boolean
}

const LabelPill = styled.div<LabelPillProps>`
  background: ${BrandColors.white.alpha(Opacities.eight).toString()};
  color: ${BrandColors.white.toString()};
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  margin: 0.5rem 1rem 0.5rem 0;
  font-weight: 500;
  padding: ${rem(16)};
  transition: ${({ isDisabled }) => (isDisabled ? 'none' : 'background 0.2s, border 0.3s')};
  border: 1px solid transparent;
  transform: scale(1);

  &:hover {
    ${({ isDisabled }) =>
      !isDisabled &&
      css`
        background: ${BrandColors.white.alpha(Opacities.twelve).toString()};
      `}
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      border-color: ${BrandColors.white.toString()};
    `}


  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: ${Opacities.thirtyfive};
    `}
}
`

export default LabelSelectorField
