import Big from 'big.js'
import { format } from 'date-fns'

import { ValidationRules } from '../../tokens/validation'

export type FormErrors<T extends Record<string, unknown>> = Partial<Record<keyof T, string | boolean>>

export type ErrorMessageFn = (label: string, options?: { preposition?: string; verb?: string }) => string

export type DateRangeErrorMessageFn = (
  label: string,
  options?: { maxLabel?: string; minLabel?: string; verb?: string },
) => string

export const validateRequired = (value: unknown): ErrorMessageFn => {
  if (!value) {
    return (label, { preposition = 'a', verb = 'fill in' } = {}) => `Please ${verb} ${preposition} ${label}.`
  }
}

export const validateNumeric = (value: string): ErrorMessageFn => {
  if (value) {
    if (!ValidationRules.numeric.exec(value)) {
      return (label: string) => `The ${label} can only contain numbers.`
    }
  }
}

export const validateAlphaNumeric = (value: string): ErrorMessageFn => {
  if (value) {
    if (!ValidationRules.alphaNumeric.exec(value)) {
      return (label: string) => `The ${label} can only contain letters and numbers.`
    }
  }
}

export const validateEmail = (email: string): ErrorMessageFn => {
  if (!ValidationRules.email.exec(email)) {
    return (_label, { verb = 'fill in' } = {}) => `Please ${verb} a valid email address.`
  }
}

export const validatePassword = (password: string): ErrorMessageFn => {
  if (!ValidationRules.password.exec(password)) {
    return (_label, { verb = 'fill in' } = {}) => `Please ${verb} a valid password.`
  }
}

const validateBoolean = (boolean: boolean, value: boolean): ErrorMessageFn => {
  if (boolean !== value) {
    return (label, { verb = 'accept', preposition = 'the' } = {}) => `Please ${verb} ${preposition} ${label}.`
  }
}

export const validateTrue = (boolean: boolean): ErrorMessageFn => validateBoolean(boolean, true)

export const validateFalse = (boolean: boolean): ErrorMessageFn => validateBoolean(boolean, false)

export const validateStringLength = (string: string, min = 0, max = Infinity): ErrorMessageFn => {
  if (string) {
    if (string.length < min) {
      return (label: string) =>
        `The ${label} cannot contain less than ${min} characters. You are currently at ${string.length} characters.`
    }

    if (string.length > max) {
      return (label: string) =>
        `The ${label} cannot contain more than ${max} characters. You are currently at ${string.length} characters.`
    }
  }
}

export const validateLowerAndUppercase = (string: string): ErrorMessageFn => {
  if (string) {
    if (!ValidationRules.lowerAndUppercase.test(string)) {
      return () => 'Please include both lower and uppercase characters'
    }
  }
}

export const validateNumberAndSymbol = (string: string): ErrorMessageFn => {
  if (string) {
    if (!ValidationRules.number.test(string) || !ValidationRules.symbol.test(string)) {
      return () => 'Include at least one number and symbol'
    }
  }
}

export const validateArrayLength = <T>(array: T[] | FileList, min = 0, max = Infinity): ErrorMessageFn => {
  if (array) {
    if (array.length < min) {
      return (label, { verb = 'select' } = {}) => `Please ${verb} at least ${min} ${label}.`
    }

    if (array.length > max) {
      return (label, { verb = 'select' } = {}) => `Please ${verb} at most ${max} ${label}.`
    }
  }
}

export const validateYear = (year: string, min = 1900, max = new Date().getFullYear() + 1): ErrorMessageFn => {
  if (year) {
    if (!ValidationRules.year.exec(year)) {
      return (_label, { verb = 'fill in' } = {}) => `Please ${verb} a valid year (yyyy).`
    }

    if (+year < min) {
      return (label: string) => `The ${label} cannot be before ${min}.`
    }

    if (+year > max) {
      return (label: string) => `The ${label} cannot be after ${max}.`
    }
  }
}

export const validateDate = (date: string): ErrorMessageFn => {
  if (date) {
    if (!ValidationRules.date.exec(date)) {
      return (_label, { verb = 'fill in' } = {}) => `Please ${verb} a valid date (yyyy-mm-dd).`
    }
  }
}

export const validateDateRange = (date: string, min?: Date, max?: Date): DateRangeErrorMessageFn => {
  if (min) {
    if (new Date(date) < min) {
      return (label: string, { minLabel = format(min, 'yyyy-MM-dd') }) => `The ${label} cannot be before ${minLabel}.`
    }
  } else if (max) {
    if (new Date(date) > max) {
      return (label: string, { maxLabel = format(min, 'yyyy-MM-dd') }) => `The ${label} cannot be after ${maxLabel}.`
    }
  }
}

export const validateMaximumFractionDigits = (val: number, dp = 1): boolean =>
  val && new Big(val).round(dp, Big.roundDown).eq(val)

export const validateUrl = (userInput: string): boolean => {
  try {
    return Boolean(new URL(userInput))
  } catch (_error) {
    return false
  }
}

export const validateTextField = (value: unknown): ErrorMessageFn => {
  if (typeof value === 'string') {
    const regex = new RegExp(
      '/[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2580-\u27BF]|\uD83E[\uDD10-\uDDFF]| /g',
    )
    const containsUnsupportedChars = typeof value === 'string' ? regex.test(value) : false
    if (containsUnsupportedChars) {
      return (label, { preposition = 'a', verb = 'fill in' } = {}) => `Please ${verb} ${preposition} ${label}.`
    }
  }
}
export const validateVersion = (input: string): ErrorMessageFn => {
  if (input.toLowerCase().split(' ').includes('original')) {
    return () =>
      `If multiple versions of your release exist, include the release version (e.g. remastered or instrumental). If this is the “Original” or only version, leave this field blank. If this is a remix, please also indicate the remixer e.g. “Aphex Twin Remix”.`
  }
}
