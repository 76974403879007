import { type ClassValue, clsx } from 'clsx'
import { extendTailwindMerge } from 'tailwind-merge'
import { tailwindMergeExtension } from '@collabhouse/config/tailwind/merge'

const twMerge = extendTailwindMerge(tailwindMergeExtension)

/**
 * A utility function to merge multiple class names together.
 * @example
 * cn({
 *   'text-red-500': !isActive,
 *   'bg-blue-500': isActive
 * }, 'p-4')
 * @param inputs - The class names to merge.
 * @returns The merged class names.
 */
export function cn(...inputs: ClassValue[]): string {
  return twMerge(clsx(inputs))
}
