import React, {
  type FunctionComponent,
  type MouseEvent,
  type MouseEventHandler,
  type PropsWithChildren,
  useRef,
} from 'react'
import styled from 'styled-components'

import { BrandColors } from '../../tokens/colors'
import { Opacities } from '../../tokens/opacities'
import Column, { type ColumnProps } from '../Column'
import Portal from '../Portal'

export interface OverlayProps extends ColumnProps {
  onClick?: MouseEventHandler
  portal?: string
  visible?: boolean
}

const Overlay: FunctionComponent<React.PropsWithChildren<PropsWithChildren<OverlayProps>>> = ({
  visible = true,
  onClick,
  portal,
  ...props
}) => {
  const dialogColumn = useRef<HTMLDivElement | null>(null)

  return (
    <Portal portal={portal}>
      <OverlayColumn
        {...props}
        visible={visible}
        ref={dialogColumn}
        onClick={(event: MouseEvent) => {
          if (event.target === dialogColumn.current) {
            onClick?.(event)
          }
        }}
      />
    </Portal>
  )
}

export default Overlay

const OverlayColumn = styled(Column).attrs({
  crossAxisAlignment: 'center',
  mainAxisAlignment: 'center',
})<OverlayProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${BrandColors.steelGray.alpha(Opacities.sixtyfive).toString()};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: 0.2s ease-out;
  transition-property: opacity;
  pointer-events: ${(props) => !props.visible && 'none'};
  z-index: 2;
`
