import styled, { css } from 'styled-components'

import { BrandColors } from '../../tokens/colors'
import { MediaQueries } from '../../tokens/mediaQueries'
import { FontFamily } from '../../tokens/typography'
import { rem } from '../../utils/rem'
import Text, { type TextProps } from '../Text'

export type NumeralTitleSize = 'xSmall' | 'small' | 'medium' | 'large'

export interface ResponsiveSize {
  desktop: NumeralTitleSize
  mobile: NumeralTitleSize
}

export interface NumeralTitleProps extends TextProps {
  colored?: boolean
  size?: NumeralTitleSize | ResponsiveSize
}

const NumeralTitle = styled(Text).attrs({
  as: 'span',
})<NumeralTitleProps>(
  ({ size = 'large', colored }) => css`
    font-family: ${FontFamily.regular};
    text-transform: uppercase;
    color: ${colored ? BrandColors.springGreen.toString() : BrandColors.white.toString()};

    ${() => css`
      ${typeof size === 'string' ? getSizeStyles(size).mobile : getSizeStyles(size.mobile).mobile}

      ${MediaQueries.desktop} {
        ${typeof size === 'string' ? getSizeStyles(size).desktop : getSizeStyles(size.desktop).desktop}
      }
    `}
  `,
)

export default NumeralTitle

const getSizeStyles = (size: NumeralTitleSize) => {
  switch (size) {
    case 'large':
      return {
        desktop: css`
          font-size: ${rem(56)};
          line-height: ${rem(48)};
        `,
        mobile: css`
          font-size: ${rem(48)};
          line-height: ${rem(40)};
        `,
      }

    case 'medium':
      return {
        desktop: css`
          font-size: ${rem(48)};
          line-height: ${rem(40)};
        `,
        mobile: css`
          font-size: ${rem(40)};
          line-height: ${rem(32)};
        `,
      }

    case 'small':
      return {
        desktop: css`
          font-size: ${rem(36)};
          line-height: ${rem(32)};
        `,
        mobile: css`
          font-size: ${rem(32)};
          line-height: ${rem(28)};
        `,
      }
    case 'xSmall':
      return {
        desktop: css`
          font-size: ${rem(26)};
          line-height: ${rem(28)};
        `,
        mobile: css`
          font-size: ${rem(20)};
          line-height: ${rem(22)};
        `,
      }
  }
}
