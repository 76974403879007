import React, { forwardRef, type InputHTMLAttributes } from 'react'
import cn from 'classnames'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  hideIcon?: boolean
}

export const Checkbox = forwardRef<HTMLInputElement, Props>(({ hideIcon, className, ...props }, ref) => (
  <div className={cn('relative flex', { 'gap-3': !hideIcon })}>
    <input
      type="checkbox"
      className={cn('appearance-none cursor-pointer peer shrink-0 disabled:cursor-default', {
        'w-6 h-6': !hideIcon,
      })}
      ref={ref}
      {...props}
    />
    {!hideIcon && (
      <div
        className={cn(
          'transition-colors',
          'absolute flex items-center justify-center w-6 h-6 pointer-events-none',
          'border rounded-sm border-border-subdued',
          'peer-hover:border-border-tertiary',
          'peer-active:border-border-secondary',
          {
            'peer-checked:[&>svg]:block [&>svg]:hidden': !hideIcon,
            'peer-checked:bg-background-primary-inverse': !hideIcon,
          },
          'peer-disabled:border-border-subdued',
          'peer-[&:disabled:checked]:bg-content-subdued',
          'peer-[&:disabled:checked]:border-transparent',
          className,
        )}
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <path stroke="#11081E" strokeLinecap="square" strokeWidth="2" d="m7 13 3 3 7-7" />
        </svg>
      </div>
    )}
    {props.label && (
      <label
        htmlFor={props.id}
        className={
          hideIcon
            ? cn('peer-checked:font-medium peer-checked:text-content-primary', 'text-content-tertiary')
            : cn(
                'text-content-primary',
                'text-label-light-md peer-disabled:text-content-subdued',
                'cursor-pointer peer-disabled:cursor-default',
              )
        }
      >
        {props.label}
      </label>
    )}
  </div>
))

Checkbox.displayName = 'Checkbox'
