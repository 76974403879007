import { type GraphQLResult } from '@aws-amplify/api-graphql'
import { type CognitoUser } from 'amazon-cognito-identity-js'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import React, { createContext, type Dispatch, type FC, type ReactNode, useCallback, useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'

import { type Address } from '../graphql/address'
import {
  type AddGroupMutationVariables,
  type GeneralTermsStatus,
  type GetMyGroupsQuery,
  type PaypalAccount,
} from '../graphql/generated'
import * as groupOperations from '../graphql/group'
import { type PaymentSettings } from '../graphql/groupPaymentSettings'
import { getMyUserProfile } from '../graphql/userProfile'
import { type VatDetails } from '../graphql/vatDetails'
import { useDataLayer } from '../hooks/useDataLayer'
import { useQueryParameter } from '../hooks/useQueryParameter'
import { redirectToLoginIfNotLoggedIn } from '../utils/redirect'
import { type RemoteImage, upload } from '../utils/storage'
import { type Group, type GroupInput } from './GroupProvider'

export interface AcceptedTerm {
  acceptedAt?: string
  id?: string
  terms?: string
}

export interface UserContext {
  addGroup: (input: GroupInput) => Promise<Group>
  groups?: GetMyGroupsQuery['getMyGroups']
  hydrate: () => Promise<void>
  isCreatingProfile: boolean
  isLoading: boolean
  isLoggedIn: boolean
  ready: boolean

  /**
   * Will automatically redirect to the login page (or the given destination) if no user is logged in.
   *
   * Will return true while the page that depends on the logged in user should wait rendering (e.g. while the function
   * returns true the page should return null).
   *
   * @example
   * const Component = () => {
   *   const { redirectToLoginIfNotLoggedIn } = useUser()
   *
   *   if (redirectToLoginIfNotLoggedIn()) return null
   *
   *   return <YourComponents />
   * }
   */
  redirectToLoginIfNotLoggedIn: (destination?: string) => boolean
  selectGroup: Dispatch<string>
  selectedGroupId?: string
  setIsCreatingProfile: Dispatch<boolean>
  setUser: Dispatch<CognitoUser>
  switchingGroups: boolean
  user?: CognitoUser
  userProfile?: UserProfile
}

export interface BankAccount {
  accountHolderName: string
  details: IbanBankAccountDetails | SortCodeBankAccountDetails
  type: BankAccountType
}

export enum BankAccountType {
  iban = 'iban',
  sortCode = 'sortCode',
}

export interface IbanBankAccountDetails {
  iban: string
}

export interface SortCodeBankAccountDetails {
  accountNumber: string
  sortCode: string
}

export interface UserProfile {
  acceptedTerms?: AcceptedTerm[]
  address?: Address
  avatar?: RemoteImage
  bankAccount?: BankAccount
  email: string
  generalTermsStatus: GeneralTermsStatus
  id: string
  name?: string
  paymentSettings?: PaymentSettings
  paypalAccount?: PaypalAccount
  userId: string
  vatDetails?: VatDetails
  walletAddress?: string
}

export interface AddUserProfileInput {
  avatar?: File
  email: string
  name?: string
}

export const userContext = createContext<UserContext>({
  addGroup: () => undefined,
  groups: undefined,
  hydrate: async () => undefined,
  isCreatingProfile: undefined,
  isLoading: true,
  isLoggedIn: undefined,
  ready: false,
  redirectToLoginIfNotLoggedIn: () => true,
  selectGroup: () => undefined,
  setIsCreatingProfile: () => undefined,
  setUser: () => undefined,
  switchingGroups: false,
  user: undefined,
  userProfile: undefined,
})

interface UserProviderProps {
  children: ((context: UserContext) => ReactNode) | ReactNode
}

type Loadable<T> =
  | { data?: undefined; state: 'idle' }
  | { data?: undefined; state: 'loading' }
  | { data: T; state: 'done' }
  | { data?: undefined; error: Error; state: 'error' }

const UserProvider: FC<UserProviderProps> = ({ children }) => {
  /////////////////
  ///// STATE /////
  /////////////////

  const [isLoadingUser, setIsLoadingUser] = useState(true)
  const [isLoadingProfileAndGroup, setIsLoadingProfileAndGroup] = useState(true)
  const [user, setUser] = useState<CognitoUser>(undefined)
  const [_userAttributes, setUserAttributes] = useState<Record<string, string>>(undefined)
  const [userProfile, setUserProfile] = useState<UserProfile>(undefined)
  const [groups, setGroups] = useState<Loadable<GetMyGroupsQuery['getMyGroups']>>({
    state: 'idle',
  })
  const [ready, setReady] = useState(false)
  const [selectedGroupId, setSelectedGroupId] = useState<string | null | undefined>(null)
  const [isCreatingProfile, setIsCreatingProfile] = useState<boolean>(false)
  const { update: updateDataLayer } = useDataLayer()
  const { value: groupIdQueryParamValue } = useQueryParameter('groupId')
  const [switchingGroups, setSwitchingGroups] = useState(false)

  //////////////////
  ///// PUBLIC /////
  //////////////////

  /**
   * Select the group with the given ID.
   */

  const selectGroup = useCallback(
    (id?: string) => {
      console.log('selectGroup', { id })

      setSelectedGroupId(id)

      if (!isLoadingUser && !userProfile) {
        setSwitchingGroups(false)
      } else {
        setSwitchingGroups(true)
      }
    },
    [setSelectedGroupId, isLoadingUser, userProfile],
  )

  // Verify group selection, and if incorrect reset it or pick a sensible value.
  useEffect(() => {
    // When no user is logged in, we can't load the group,
    if (!isLoadingUser && !userProfile) {
      // No group can be selected.
      setSwitchingGroups(false)
      setReady(true)
      if (selectedGroupId !== undefined) {
        selectGroup(undefined)
      }

      return
    }

    // Make sure groups are loaded.
    if (groups.state === 'error') {
      throw groups.error
    } else if (groups.state !== 'done') {
      return
    }

    // If selection is valid, stop.
    const foundGroup = selectedGroupId !== undefined && groups.data?.find((group) => group.id === selectedGroupId)
    if (foundGroup) {
      setSwitchingGroups(false)
      setReady(true)
      window.localStorage.setItem('selectedGroupId', JSON.stringify(selectedGroupId))
      return
    }

    // Try stored group ID, if any.
    try {
      const storedGroupId = JSON.parse(window.localStorage.getItem('selectedGroupId'))
      if (typeof storedGroupId === 'string' && storedGroupId !== selectedGroupId) {
        // Prevent infinite loop.
        window.localStorage.removeItem('selectedGroupId')

        selectGroup(storedGroupId)
        return
      }
    } catch {
      // Ignore errors.
    }

    // Or try first group of possible groups, if any.
    if (groups.data && groups.data.length > 0) {
      selectGroup(groups.data[0].id)
      return
    }

    // No group can be selected.
    setSwitchingGroups(false)
    setReady(true)
    if (selectedGroupId !== undefined) {
      selectGroup(undefined)
    }
  }, [setSelectedGroupId, switchingGroups, selectGroup, selectedGroupId, groups, isLoadingUser, userProfile])

  // Select group if groupId is provided as query parameter.
  useEffect(() => {
    if (groupIdQueryParamValue && groupIdQueryParamValue.length > 0) {
      selectGroup(groupIdQueryParamValue)
    } else if (selectedGroupId === null) {
      selectGroup(undefined)
    }
  }, [selectGroup, selectedGroupId, groupIdQueryParamValue])

  /**
   * Create a new group for the user.
   */
  const addGroup = async ({ avatar, ...input }: GroupInput) => {
    // Upload the avatar.
    const remoteAvatar = avatar ? await upload(uuid(), avatar) : undefined

    // Create a new group.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const addGroupResult: any = await API.graphql(
      graphqlOperation(groupOperations.addGroup, {
        input: {
          ...input,
          avatar: remoteAvatar,
          role: input.role,
        },
      } satisfies AddGroupMutationVariables),
    )

    await hydrate()

    return addGroupResult.data.addGroup as Group
  }

  ///////////////////
  ///// PRIVATE /////
  ///////////////////

  /**
   * Returns the profile of the user.
   *
   * @returns {UserProfile}
   */
  const getUserProfile = useCallback(async () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result: any = await API.graphql(graphqlOperation(getMyUserProfile))

    return result.data?.getMyUserProfile as UserProfile
  }, [])

  /**
   * Retrieve the user's groups.
   * @todo Move to User model.
   */
  const getGroups = useCallback(async () => {
    const result = await (API.graphql(graphqlOperation(groupOperations.getMyGroups)) as Promise<
      GraphQLResult<GetMyGroupsQuery>
    >)

    return result.data.getMyGroups
  }, [])

  /**
   * Hydrate the provider.
   */
  const hydrate = useCallback(
    async (tries = 3) => {
      try {
        setUserProfile(await getUserProfile())
      } catch (error) {
        if (tries > 0) {
          console.error(`Error while hydrating user, retrying ...`)

          await hydrate(tries - 1)
        } else {
          console.error(`Error while hydrating user:`, error)

          setUserProfile(undefined)
        }

        return
      }

      setGroups({ state: 'loading' })

      try {
        const groups = await getGroups()
        setGroups({ data: groups, state: 'done' })
      } catch {
        setGroups({ error: new Error('Failed to load groups'), state: 'error' })
      }
    },
    [getUserProfile, getGroups],
  )

  /**
   * Clears the provider's state.
   */
  const clear = useCallback(async () => {
    setUserProfile(undefined)
    setGroups({ state: 'idle' })
  }, [])

  ///////////////////
  ///// EFFECTS /////
  ///////////////////

  // Initialize the provider.
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(setUser)
      .catch(() => setUser(undefined))
      .finally(() => setIsLoadingUser(false))
  }, [])

  // Update the state when the user changes.
  useEffect(() => {
    if (!isLoadingUser) {
      const action = user ? hydrate : clear

      setIsLoadingProfileAndGroup(true)

      void action().finally(() => {
        // We can only be sure we're done loading when Auth.currentAuthenticatedUser() is done.
        setIsLoadingProfileAndGroup(false)
      })
    }
  }, [user, clear, hydrate, isLoadingUser])

  // Get the user attributes from cognito.
  useEffect(() => {
    if (user) {
      user.getUserAttributes((error, attributes) => {
        if (error) {
          return
        }

        setUserAttributes(
          Object.fromEntries(attributes.map((attribute) => [attribute.getName(), attribute.getValue()])),
        )
      })
    } else {
      setUserAttributes(undefined)
      setUserProfile(undefined)
      setGroups({ state: 'idle' })
    }
  }, [user])

  // Update user tracking data.
  useEffect(() => {
    if (groups.state !== 'done') return

    updateDataLayer({
      event: 'user-activity',
      user: {
        // TODO: Fix typing
        amountOfGroups: groups.data ? groups.data.length : null,

        email: user?.['attributes']['email'],
        userName: user?.getUsername(),
      },
    })
  }, [user, groups, updateDataLayer])

  const isLoggedIn = !!user
  const isLoading = isLoadingUser || isLoadingProfileAndGroup

  const redirectToLoginIfNotLoggedIn_ = useCallback(
    (destination?: string): boolean => isLoading || redirectToLoginIfNotLoggedIn(isLoggedIn, !isLoading, destination),
    [isLoading, isLoggedIn],
  )

  //////////////////
  ///// RENDER /////
  //////////////////

  const context: UserContext = {
    addGroup,
    groups: groups.state === 'done' ? groups.data : undefined,
    hydrate,
    isCreatingProfile,
    isLoading,
    isLoggedIn,
    ready,
    redirectToLoginIfNotLoggedIn: redirectToLoginIfNotLoggedIn_,
    selectGroup,
    selectedGroupId,
    setIsCreatingProfile,
    setUser,
    switchingGroups,
    user,
    userProfile,
  }

  return (
    <userContext.Provider value={context}>
      {!switchingGroups && (typeof children === 'function' ? children(context) : children)}
    </userContext.Provider>
  )
}

export default UserProvider

export const UserConsumer = userContext.Consumer
