export const addAcceptedTerms = /* GraphQL */ `
  mutation AddAcceptedTerms($terms: String) {
    addAcceptedTerms(input: { terms: $terms }) {
      acceptedAt
      id
      terms
    }
  }
`

export const getMyAcceptedTerms = /* GraphQL */ `
  query GetMyAcceptedTerms {
    getMyAcceptedTerms {
      acceptedAt
      id
      terms
    }
  }
`
