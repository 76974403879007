/**
 * Retrieve all available content categoriess
 */
export const getContentCategories = /* GraphQL */ `
  query GetContentCategories {
    getContentCategories {
      id
      name
    }
  }
`
