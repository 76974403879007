import styled, { css } from 'styled-components'

import { MediaQueries } from '../../tokens/mediaQueries'

export interface GridProps {
  columns?: number
  gutter?: string | { desktop: string; mobile: string }
}

const Grid = styled.div<GridProps>(
  ({ columns = 12, gutter = 0 }) => css`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(${columns}, 1fr);
    align-items: start;
    grid-gap: ${typeof gutter === 'object' ? gutter.mobile : gutter};

    ${typeof gutter === 'object'
      ? css`
          ${MediaQueries.desktop} {
            grid-gap: ${gutter.desktop};
          }
        `
      : ''}
  `,
)

export default Grid

export interface GridItemProps {
  span?: number
}

export const GridItem = styled.div<GridItemProps>(
  ({ span = 1 }) => css`
    grid-column-start: span ${span};
  `,
)
