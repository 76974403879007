import { fromUnixTime } from 'date-fns'
import {
  AudioPlayer,
  AudioPlayerProvider,
  AudioProgressProvider,
  Center,
  IndeterminateProgress,
  rem,
} from '@collabhouse/shared'
import { default as React, type FunctionComponent, type ReactNode, useEffect } from 'react'
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query'

import App from '@/components/app/App'
import { initializeSentry } from '@/components/app/Sentry'
import DrawerNavigation from '@/components/drawer-navigation/DrawerNavigation'
import ErrorBoundary from '@/components/error-boundary/ErrorBoundary'
import ExpandedAudioPlayer from '@/components/expanded-audio-player/ExpandedAudioPlayer'
import TermsGate from '@/components/terms-gate/TermsGate'
import TopBar from '@/components/top-bar/TopBar'
import BreadcrumbsProvider from '@/providers/BreadcrumbsProvider'
import ChatProvider from '@/providers/ChatProvider'
import ContentCategoriesProvider from '@/providers/ContentCategoriesProvider'
import DrawerProvider from '@/providers/DrawerProvider'
import GenresProvider from '@/providers/GenresProvider'
import GroupProvider from '@/providers/GroupProvider'
import PlatformsProvider from '@/providers/PlatformsProvider'
import TermsProvider from '@/providers/TermsProvider'
import UserProvider, { type UserContext } from '@/providers/UserProvider'
import EnsureCorrectHost from '@/templates/app/EnsureCorrectHost'

initializeSentry()
const queryCache = new QueryCache()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 2,
    },
  },
  queryCache,
})

interface LayoutProps {
  children: ((context: UserContext) => ReactNode) | ReactNode
  layout?: string
}

const Layout: FunctionComponent<LayoutProps> = ({ children, layout }) => {
  useEffect(() => {
    if (process.env.REACT_QUERY_CACHE_DEBUG === 'true') {
      debugCache()
    }
  }, [])

  return layout === 'barebones' ? (
    <>{typeof children === 'function' ? null : children}</>
  ) : (
    <EnsureCorrectHost>
      <QueryClientProvider client={queryClient}>
        <BreadcrumbsProvider>
          <App>
            <UserProvider>
              {(context: UserContext) => {
                const { isLoggedIn, isLoading, selectedGroupId, isCreatingProfile } = context

                return isLoading ? (
                  <Loader />
                ) : (
                  <GenresProvider>
                    <ContentCategoriesProvider>
                      <PlatformsProvider>
                        <TermsProvider>
                          <GroupProvider id={selectedGroupId}>
                            <ChatProvider>
                              <AudioPlayerProvider>
                                <AudioProgressProvider>
                                  <DrawerProvider>
                                    <TermsGate>
                                      <ErrorBoundary>
                                        {isLoggedIn && !isCreatingProfile ? <TopBar /> : null}
                                        {typeof children === 'function' ? children(context) : children}
                                      </ErrorBoundary>
                                    </TermsGate>
                                    {isLoggedIn && <DrawerNavigation />}
                                  </DrawerProvider>
                                  <AudioPlayer />
                                  <ExpandedAudioPlayer />
                                </AudioProgressProvider>
                              </AudioPlayerProvider>
                            </ChatProvider>
                          </GroupProvider>
                        </TermsProvider>
                      </PlatformsProvider>
                    </ContentCategoriesProvider>
                  </GenresProvider>
                )
              }}
            </UserProvider>
          </App>
        </BreadcrumbsProvider>
      </QueryClientProvider>
    </EnsureCorrectHost>
  )
}

export default Layout

const Loader = React.memo(() => (
  <Center>
    <IndeterminateProgress size={{ desktop: rem(48), mobile: rem(36) }} />
  </Center>
))

Loader.displayName = 'Loader'

export const debugCache = (): void => {
  console.log(
    '%c Ledo App Cache',
    `margin-top: 16px; padding: 8px; background: #11081E; color: #00EF86; font-family: Söhne,sans-serif; font-weight: bold;`,
  )
  console.log(`--------------------------------------------------------------------`)
  queryCache.getAll().map((query, i) => {
    console.log(
      `%c Query #${i + 1}: %c ${query.queryHash} `,
      `background: #ddd; color: #000; padding: 4px; border-radius: 2px`,
      `background: #11081E; color: #00EF86; padding: 4px; border-radius: 2px;
       margin-left: 1ch;`,
    )
    console.log(
      `%c Last update: %c ${fromUnixTime(query.state.dataUpdatedAt)} `,
      `background: #ddd; color: #000; padding: 4px; border-radius: 2px`,
      `background: #11081E; color: #00EF86; padding: 4px; border-radius: 2px;
       margin-left: 1ch;`,
    )
    console.log(`--------------------------------------------------------------------`)
  })
}
