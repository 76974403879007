import { BeatportGenre } from 'ledo-api/src/shared/distribution-genres'
import { SelectField, type SelectFieldProps } from '@collabhouse/shared'
import React, { type FunctionComponent } from 'react'

interface DistributionSubgenreSelectFieldProps extends SelectFieldProps {
  genre: string
}

const DistributionSubgenreSelectField: FunctionComponent<
  React.PropsWithChildren<DistributionSubgenreSelectFieldProps>
> = (props) => (
  <SelectField
    {...props}
    select={{
      ...props.select,
      isClearable: true,
      isSearchable: true,
      options: distributionSubgenreOptions[props.genre] ?? [],
    }}
    disabled={props.disabled || !distributionSubgenreOptions[props.genre]}
  />
)

export default DistributionSubgenreSelectField

const beatportGenreOptions = Object.entries(BeatportGenre).map((item) => ({
  label: item[1],
  value: item[0],
}))

export const distributionSubgenreOptions = {
  dance: beatportGenreOptions,
  electronic: beatportGenreOptions,
}
