export const ValidationRules = {
  alphaNumeric: /^[a-zA-Z0-9]+$/,

  date: /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/,

  divisionShare: /^\d{0,2}([.,][0-9]?)?$|^100$/,

  /**
   * @see https://emailregex.com/
   */
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

  lowerAndUppercase: /(?=.*[a-z])(?=.*[A-Z])/,

  noNumeric: /^(\D*)$/,

  number: /\d+/,

  numeric: /^\d+$/,
  /**
   * 12 characters, allow numbers, uppercase and lowercase characters and symbols, whitespace not allowed
   */
  password: /^(?=.*[0-9a-zA-Z#$^+=!*()@%&,.])^(?!.* ).{12,}/,
  positiveNumber: /^[+]?([.]\d+|\d+[.]?\d*)$/,
  singleCharacterWord: /(?:^|\s)\S{1,1}(?:\s|$)/,
  symbol: /[^a-zA-Z0-9]/,
  time: /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/,
  // https://codegolf.stackexchange.com/a/480
  url: /(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/gi,

  year: /^[1-9]{1}[0-9]{3}$/,
}
