import styled, { css } from 'styled-components'

import { MediaQueries } from '../../tokens/mediaQueries'

export interface ResponsiveSize {
  desktop?: string
  mobile?: string
}

export type Padding = string | ResponsiveSize

export interface PaddingProps {
  all?: Padding
  bottom?: Padding
  horizontal?: Padding
  left?: Padding
  padding?: Padding
  right?: Padding
  top?: Padding
  vertical?: Padding
}

/**
 * This component insets its children by the given padding.
 *
 * NB: This component is inspired by, but not identical to the Flutter
 * equivalent.
 * @see https://api.flutter.dev/flutter/widgets/Padding-class.html
 */
const Padding = styled.div<PaddingProps>((props) => {
  const padding = responsiveSize(props.padding)
  const top = responsiveSize(props.top)
  const left = responsiveSize(props.left)
  const right = responsiveSize(props.right)
  const bottom = responsiveSize(props.bottom)
  const vertical = responsiveSize(props.vertical)
  const horizontal = responsiveSize(props.horizontal)
  const all = responsiveSize(props.all)

  return css`
    padding: ${padding.mobile};
    padding-top: ${top.mobile || vertical.mobile || all.mobile};
    padding-right: ${right.mobile || horizontal.mobile || all.mobile};
    padding-bottom: ${bottom.mobile || vertical.mobile || all.mobile};
    padding-left: ${left.mobile || horizontal.mobile || all.mobile};

    ${MediaQueries.desktop} {
      padding: ${padding.desktop};
      padding-top: ${top.desktop || vertical.desktop || all.desktop};
      padding-right: ${right.desktop || horizontal.desktop || all.desktop};
      padding-bottom: ${bottom.desktop || vertical.desktop || all.desktop};
      padding-left: ${left.desktop || horizontal.desktop || all.desktop};
    }
  `
})

const responsiveSize = (padding?: Padding): ResponsiveSize => {
  switch (typeof padding) {
    case 'string':
      return {
        desktop: padding,
        mobile: padding,
      }

    case 'object':
      return padding as ResponsiveSize

    case 'undefined':
    default:
      return {
        desktop: '',
        mobile: '',
      }
  }
}

export default Padding
