import * as React from 'react'

const IconChat = ({ stroke = 'white', ...props }) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M21 2.5V19H7L3 21.5V2.5H21Z" stroke={stroke} strokeWidth="1.5" />
    <path d="M7 8H17" stroke={stroke} strokeWidth="1.5" />
    <path d="M7 12.5H14" stroke={stroke} strokeWidth="1.5" />
  </svg>
)

export default IconChat
