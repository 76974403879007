import { API, graphqlOperation } from 'aws-amplify'
import { IndeterminateProgress, SecondaryButton, Snackbar, useSnackbars } from '@collabhouse/shared'
import { type ButtonProps } from '@collabhouse/shared'
import React, { type ComponentType, type FC, useState } from 'react'
import styled from 'styled-components'

import { disconnectWallet } from '../../graphql/wallet'
import { useUser } from '../../hooks/useUser'
import { useDisconnect } from '../../utils/connectors'
import ConnectWalletDialog from '../connect-wallet-dialog/ConnectWalletDialog'

const ConnectWalletButton: FC<
  React.PropsWithChildren<{
    button?: ComponentType<React.PropsWithChildren<ButtonProps>>
    onClick?: () => void
  }>
> = ({ onClick, button: Button = StyledSecondaryButton }) => {
  const disconnect = useDisconnect()
  const [isDisconnecting, setIsDisconnecting] = useState(false)
  const [walletDialogVisible, setWalletDialogVisible] = useState(false)
  const { addSnackbar } = useSnackbars()
  const { userProfile, hydrate } = useUser()

  return (
    <>
      {userProfile?.walletAddress ? (
        <Button
          disabled={isDisconnecting}
          leading={isDisconnecting && <IndeterminateProgress />}
          onClick={() => {
            void (async () => {
              onClick?.()
              setIsDisconnecting(true)
              try {
                await API.graphql(graphqlOperation(disconnectWallet))
                disconnect()
                await hydrate()
              } catch (ex) {
                console.error(ex)
                addSnackbar(<Snackbar variant="danger">Something went wrong, please try again later.</Snackbar>)
              } finally {
                setIsDisconnecting(false)
              }
            })()
          }}
        >
          Disconnect wallet
        </Button>
      ) : (
        <Button
          onClick={() => {
            onClick?.()
            setWalletDialogVisible(true)
          }}
        >
          Connect wallet
        </Button>
      )}
      <ConnectWalletDialog visible={walletDialogVisible} onClose={() => setWalletDialogVisible(false)} />
    </>
  )
}

const StyledSecondaryButton = styled(SecondaryButton)`
  font-weight: 500;
`

export default ConnectWalletButton
