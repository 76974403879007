import React from 'react'

const AddUserIcon = ({ color = 'white', ...props }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M17 13V17H19V13H23V11H19V7H17V11H13V13H17Z" fill={color} />
    <path d="M0 14H12V16H0V14Z" fill={color} />
    <circle cx="6" cy="8" r="5" stroke={color} strokeWidth="2" />
    <path d="M12 14L12 20L10 20L10 14L12 14Z" fill={color} />
    <path d="M2 14L2 20L-1.43059e-07 20L1.19209e-07 14L2 14Z" fill={color} />
  </svg>
)

export default AddUserIcon
