import React, { type SVGProps } from 'react'

interface DangerIconProps extends SVGProps<SVGSVGElement> {
  size?: number
}

export const DangerIcon = React.forwardRef<SVGSVGElement, DangerIconProps>((props, ref) => {
  const { size = 24, ...rest } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...rest}
    >
      <path d="M13 15H11V17H13V15Z" fill="currentColor" />
      <path d="M13 7H11V13H13V7Z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 12L18 22.3923H6L0 12L6 1.60767L18 1.60767L24 12ZM16.8453 3.60767L21.6906 12L16.8453 20.3923H7.1547L2.3094 12L7.1547 3.60767L16.8453 3.60767Z"
        fill="currentColor"
      />
    </svg>
  )
})
DangerIcon.displayName = 'DangerIcon'
