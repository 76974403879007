import { useLocation } from '@gatsbyjs/reach-router'
import {
  AbstractMark,
  Avatar,
  Body,
  BrandColors,
  Flex,
  Icon,
  Icons,
  MediaQueries,
  Portal,
  Portals,
  rem,
  Row,
  SecondaryButton,
  SizedBox,
} from '@collabhouse/shared'
import { default as React, type FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import ConnectWalletButton from '@/components/connect-wallet-button/ConnectWalletButton'
import DesktopOnly from '@/components/desktop-only/DesktopOnly'
import InternalLink from '@/components/internal-link/InternalLink'
import MobileOnly from '@/components/mobile-only/MobileOnly'
import NavigationListItem from '@/components/navigation-list-tem/NavigationListItem'
import Dropdown from '@/components/top-bar/dropdown/Dropdown'
import NavDropdown from '@/components/top-bar/nav-dropdown/NavDropdown'
import { useDrawer } from '@/hooks/useDrawer'
import { useGroup } from '@/hooks/useGroup'
import { useSendbird } from '@/hooks/useSendbird'
import { useUser } from '@/hooks/useUser'
import { useWindow } from '@/hooks/useWindow'
import IconChat from '@/templates/sendbird/IconChat'
import buildNavLinks from '@/utils/buildNavLinks'
import { getImageSize, ImageSize } from '@/utils/imageSize'
import matchesActiveOnArray from '@/utils/matchesActiveOnArray'
import { routes } from '@/utils/routes'

export const topBarMobileHeight = 64
export const topBarDesktopHeight = 88

const TopBar: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { selectGroup, groups } = useUser()
  const { group } = useGroup()
  const [open, setOpen] = useState(false)
  const [showTeams, setShowTeams] = useState<boolean>(false)
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const { setDrawerOpen } = useDrawer()
  const { pathname } = useLocation()
  const { isDesktop, isMobile } = useWindow()
  const { unreadMessageCount } = useSendbird()

  const navigationItems = useMemo(() => buildNavLinks(group), [group])

  const closeDropdown = useCallback(() => {
    setOpen(false)
    setShowTeams(false)
  }, [])

  const onWindowClick = useCallback(
    (event: MouseEvent) => {
      const containsDropdown = dropdownRef?.current.contains(event.target as Node)

      const isSwitchTeamButton = (event.target as Element).classList.contains('switchTeamsButton')

      if (open && !containsDropdown && !isSwitchTeamButton) {
        closeDropdown()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [closeDropdown, dropdownRef],
  )

  useEffect(() => {
    if (open) {
      window.addEventListener('click', onWindowClick)

      return () => {
        window.removeEventListener('click', onWindowClick)
      }
    }
  }, [onWindowClick, open])

  return (
    <Portal portal={Portals.navigation}>
      <Bar mainAxisAlignment="space-between" crossAxisAlignment="center">
        {!!group && (
          <MobileOnly>
            <NavigationListItem
              direction="row"
              onClick={() => {
                setDrawerOpen(true)
              }}
              data-gtm-name="drawer-navigation-open"
            >
              <Icon icon={Icons.hamburger} />
            </NavigationListItem>
          </MobileOnly>
        )}
        <LogoWrapper mainAxisAlignment="center" crossAxisAlignment="center">
          <NavigationListItem
            active={pathname === routes.home.path}
            direction="row"
            to={routes.home.url}
            data-gtm-name="main-navigation-logo"
            featured={isDesktop}
          >
            <span>
              <Logo colored />
            </span>
          </NavigationListItem>
          {!!group && (
            <DesktopOnly>
              <Row>
                {navigationItems
                  .filter(({ hidden }) => !hidden)
                  .map((item) => {
                    switch (item.type) {
                      case 'LINK':
                      case 'EXTERNAL':
                        // eslint-disable-next-line no-case-declarations
                        const { type, ...link } = item
                        return (
                          <NavigationListItem
                            direction="row"
                            active={matchesActiveOnArray(pathname, item.activeOn)}
                            key={item.title}
                            featured={item.featured}
                            linkType={item.type}
                            {...link}
                          >
                            <span>{item.title}</span>
                          </NavigationListItem>
                        )
                      case 'DROPDOWN':
                        return (
                          <NavDropdown
                            activeOn={item.children.reduce<string[]>(
                              (arr, cur) => [
                                ...arr,
                                ...(Array.isArray(cur.activeOn) ? [...cur.activeOn] : [cur.activeOn]),
                              ],
                              [],
                            )}
                            key={item.title}
                            title={item.title}
                            featured={!!item.featured}
                          >
                            {item.children}
                          </NavDropdown>
                        )
                      default:
                        return null
                    }
                  })}
              </Row>
            </DesktopOnly>
          )}
        </LogoWrapper>
        <SizedBox width={rem(24)} />
        <ButtonsWrapper crossAxisAlignment="center">
          {pathname.includes('nft') && (
            <>
              <ConnectWalletButton />
              <SizedBox width={rem(16)} />
            </>
          )}
          {navigationItems
            .filter(({ hidden }) => !hidden)
            .filter((item) => item.type === 'TOOLBAR')
            .map((item) => (
              <InternalLink key={item.title} to={routes.chat.url} style={{ backgroundImage: 'none' }}>
                <NavItemWithBadge active={matchesActiveOnArray(pathname, item.activeOn)}>
                  <IconChat />
                  {unreadMessageCount > 0 && <BadgeNumber>{unreadMessageCount}</BadgeNumber>}
                </NavItemWithBadge>
              </InternalLink>
            ))}
          <SizedBox width={rem(16)} />
        </ButtonsWrapper>
        <DropdownButton direction="row" onClick={() => setOpen(!open)} data-gtm-name="main-navigation-avatar">
          <Avatar
            src={getImageSize(group?.avatar, ImageSize.small)?.url}
            size={{ desktop: rem(40), mobile: rem(32) }}
          />
          {group && isDesktop && (
            <>
              <SizedBox width={rem(16)} />
              <TeamName>{group.name}</TeamName>
            </>
          )}
        </DropdownButton>
      </Bar>
      <Dropdown
        closeDropdown={closeDropdown}
        groups={groups}
        isMobile={isMobile}
        open={open}
        selectedGroup={group}
        selectGroup={selectGroup}
        ref={dropdownRef}
        showTeams={showTeams}
        setShowTeams={setShowTeams}
      />
    </Portal>
  )
}

export default TopBar

const Bar = styled(Row)`
  position: fixed;
  top: 0;
  background: ${BrandColors.steelGray.toString()};
  width: 100%;
  height: ${rem(topBarMobileHeight)};
  padding: ${rem(16)} 0;
  z-index: 2;

  ${MediaQueries.desktop} {
    height: ${rem(topBarDesktopHeight)};
    padding: ${rem(24)} 0;
  }
`

const LogoWrapper = styled(Row)`
  position: absolute;
  width: 100%;
  pointer-events: none;

  ${MediaQueries.desktop} {
    position: relative;
    width: initial;
    pointer-events: initial;
  }
`

const Logo = styled(AbstractMark)`
  pointer-events: initial;
  height: ${rem(34)};

  ${MediaQueries.desktop} {
    height: ${rem(38)};
  }
`

const DropdownButton = styled(NavigationListItem)`
  max-width: ${rem(320)};
`

const TeamName = styled(Body).attrs({
  emphasis: true,
  size: 'medium',
})`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ButtonsWrapper = styled(Flex)`
  display: none;

  ${MediaQueries.desktop} {
    display: flex;
    margin-left: auto;
  }
`

const NavItemWithBadge = styled(SecondaryButton)`
  padding: 0 ${rem(9)};
  height: ${rem(44)};
  border: 1px solid ${({ active }) => (active ? 'rgba(255, 255, 255, 0.8)' : 'rgba(255, 255, 255, 0.2)')};
  background-color: ${({ active }) => (active ? 'inherit' : 'transparent')};
  position: relative;
`

const BadgeNumber = styled.span`
  background-color: ${BrandColors.springGreen.toString()};
  border-radius: 50%;
  color: black;
  font-size: 0.75em;
  line-height: 100%;
  padding: 0.25em 0.5em;
  position: absolute;
  right: ${rem(-12)};
  top: ${rem(-12)};
`
