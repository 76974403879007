import { useState } from 'react'

const inBrowser = () => typeof window !== 'undefined'

export const useLocalStorage = <T>(key: string, initialValue: T) => {
  /**
   * Store the value in state, wrapped inside a function that retrieves the
   * value from local storage and JSON-decodes it.
   */
  const [storedValue, setStoredValue] = useState(() => {
    if (!inBrowser()) {
      return initialValue
    }
    const item = window.localStorage.getItem(key)
    return item ? JSON.parse(item) : initialValue
  })
  /**
   * This function wraps the useState setter to persist the value into local
   * storage.
   */
  const setValue = (value: T | ((storedValue: T) => T)) => {
    const valueToStore: T = value instanceof Function ? value(storedValue) : value
    setStoredValue(valueToStore)
    if (inBrowser()) {
      window.localStorage.setItem(key, JSON.stringify(valueToStore))
    }
  }
  return [storedValue, setValue]
}
