import { type GraphQLResult } from '@aws-amplify/api-graphql'
import { API, graphqlOperation } from 'aws-amplify'
import React, { createContext, type FunctionComponent, type ReactNode, useCallback, useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { useLocalStorage } from '@collabhouse/shared'

import { distributionSubgenreOptions } from '@/components/distribution-subgenre-select-field/DistributionSubgenreSelectField'
import { type Address } from '@/graphql/address'
import * as collaborationSettingsOperations from '@/graphql/collaborationSettings'
import {
  type AddManagerMutationVariables,
  type AddProductArtistMutationVariables,
  type AddProductMutation,
  type AddProductMutationVariables,
  type DistributionGenre,
  type DistributionSubgenre,
  type EditGroupMutationVariables,
  type Group as GeneratedGroup,
  type GroupRole,
  type ParentalAdvisory,
  type PaypalAccount,
  type SupportedChats,
} from '@/graphql/generated'
import * as groupOperations from '@/graphql/group'
import { getGroupMemberInvitations, type GroupMemberInvitation } from '@/graphql/groupMemberInvitation'
import { type PaymentSettings } from '@/graphql/groupPaymentSettings'
import { type PayoutSettings } from '@/graphql/groupPayoutSettings'
import { addManager } from '@/graphql/manager'
import * as platformAccountOperations from '@/graphql/platformAccount'
import { type PlatformAccount, type PlatformAccountInput } from '@/graphql/platformAccount'
import { getPlatformsAccounts } from '@/graphql/platforms'
import * as productOperations from '@/graphql/product'
import { addProductArtist } from '@/graphql/productArtist'
import { type VatDetails } from '@/graphql/vatDetails'
import { useDataLayer } from '@/hooks/useDataLayer'
import { useUser } from '@/hooks/useUser'
import { type Product, type ProductInput } from '@/providers/ProductProvider'
import { type BankAccount, type UserProfile } from '@/providers/UserProvider'
import { redirectIfNoArtistGroup, redirectIfNoGroup } from '@/utils/redirect'
import { type RemoteImage, upload } from '@/utils/storage'

export interface AvailableSubgenre {
  genreId: string
  id: string
  name: string
}

export interface AvailableGenres {
  id: string
  name: string
  subgenres: AvailableSubgenre[]
}

export interface AvailablePlatforms {
  id: string
  name: string
  type?: string
}

export interface AvailableContentCategories {
  id: string
  name: string
  type?: string
}

export interface SelectedPlatform {
  id?: string
  name: string
}

export interface SelectedPlatforms {
  id?: string
  platform: SelectedPlatform
}

export interface SelectedGenre {
  id: string
  name: string
}

export interface SelectedSubGenres {
  id: string
  name: string
}

export interface SelectedGenres {
  genre: SelectedGenre
  subgenres?: SelectedSubGenres[]
}

export interface ContentCategory {
  name: string
}

export interface SelectedContentCategories {
  contentCategory?: ContentCategory
  contentCategoryId: string
}

export interface CollaborationSettings {
  contentCategories: SelectedContentCategories[]
  fixedFeeEnabled: boolean
  fixedFeeMinimumFee?: number
  genres: SelectedGenres[]
  id?: string
  mainGenre?: string
  paypalEmail?: string
  paypalMerchantId?: string
  platformAccounts?: PlatformAccount[]
  platforms?: SelectedPlatforms[]
  role?: string
  royaltyShareEnabled: boolean
  subGenres?: string[]
  website?: string
}

export interface CollaborationProposal {
  collaborationEndDate?: string
  collaborationStartDate: string
  compensation: {
    fixedFee?: number
    paymentEndDate?: string
    paymentStartDate: string
    royaltySharePercentage?: number
  }
  fromGroupId: string
  message?: string
  postAmount: number
  postFrequency: number
  postPeriod: string
  posts: [
    {
      platform: string
      type: string
    },
  ]
  toGroupId: string
  trackId: string
  type: string
}

export interface CollaborationGenres {
  genre: {
    name: string
  }
  id: string
}

export interface CollaborationPlatforms {
  id: string
  platform: {
    name: string
  }
}

export interface ArtistCollaborationSettings {
  fixedFeeEnabled: boolean
  genres: [CollaborationGenres]
  id: string
  platforms: [CollaborationPlatforms]
  royaltyShareEnabled: boolean
}

export interface InfluencerCollaborationSettings {
  fixedFeeEnabled: boolean
  genres: [CollaborationGenres]
  id: string
  platforms: [CollaborationPlatforms]
  royaltyShareEnabled: boolean
}

export interface Group extends Pick<GeneratedGroup, 'role' | 'configured' | 'myMenu'> {
  address?: Address
  artistCollaborationSettings: ArtistCollaborationSettings
  avatar?: RemoteImage
  bankAccount?: BankAccount
  biography?: string
  countryCode: string
  id: string
  influencerCollaborationSettings: InfluencerCollaborationSettings
  manager: {
    distributedProducts: Product[]
    groupId: string
    id: string
    musicLibraryProducts: Product[]
  }
  members?: {
    userId: string
    userProfile: UserProfile
  }[]
  name: string
  paymentSettings: PaymentSettings
  payoutSettings: PayoutSettings
  paypalAccount?: PaypalAccount
  platformAccounts: PlatformAccount[]
  supportedChats?: SupportedChats
  vatDetails?: VatDetails
}

export interface GroupInput {
  avatar?: File
  biography?: string
  countryCode: string
  name: string
  role?: GroupRole
  website?: string
}

export interface Artist {
  appleMusicId: string
  fugaId: string
  id: string
  spotifyId: string
}

export interface ArtistInput {
  appleMusicId: string
  spotifyId: string
}

export interface GroupContext {
  addCollaborationSettings: (groupId: string, input: Partial<CollaborationSettings>) => Promise<CollaborationSettings>
  addPlatformAccount: (input: PlatformAccountInput) => Promise<GraphQLResult<PlatformAccount>>
  addProduct: (input: ProductInput, primaryArtists: string[], featuringArtists: string[]) => Promise<Product>
  editCollaborationSettings: (id: string, input: Partial<CollaborationSettings>) => Promise<CollaborationSettings>
  editGroup: (input: GroupInput) => Promise<GraphQLResult<Group>>
  editGroupRole: (role: GroupRole) => Promise<void>
  editPlatformAccount: (id: string, input: PlatformAccountInput) => Promise<GraphQLResult<PlatformAccount>>
  getCollaborationSettings: (groupId: string, role: string) => Promise<CollaborationSettings>
  getPlatformAccounts: (id: string) => Promise<PlatformAccount[]>
  group?: Group | undefined
  groupMemberInvitations: GroupMemberInvitation[]
  hydrate: () => Promise<void>
  hydrateGroupMemberInvitations: () => Promise<void>
  ready: boolean

  /**
   * Will automatically redirect to the home page (or the given destination) if no group could be found or the group
   * does not have the role 'artist'.
   *
   * Will return true while the page that depends on the loaded group should wait rendering (e.g. while the function
   * returns true the page should return null).
   *
   * @example
   * const Component = () => {
   *   const { group: artistGroup, redirectIfNoArtistGroup } = useUser()
   *
   *   if (redirectIfNoArtistGroup()) return null
   *
   *   return <YourComponents artistGroup={artistGroup} />
   * }
   */
  redirectIfNoArtistGroup: (destination?: string) => boolean

  /**
   * Will automatically redirect to the home page (or the given destination) if no group could be found.
   *
   * Will return true while the page that depends on the loaded group should wait rendering (e.g. while the function
   * returns true the page should return null).
   *
   * @example
   * const Component = () => {
   *   const { group, redirectIfNoGroup } = useUser()
   *
   *   if (redirectIfNoGroup()) return null
   *
   *   return <YourComponents group={group} />
   * }
   */
  redirectIfNoGroup: (destination?: string) => boolean

  removePlatformAccount: (id: string) => Promise<GraphQLResult<PlatformAccount>>
}

export const groupContext = createContext<GroupContext>({
  addCollaborationSettings: () => undefined,
  addPlatformAccount: () => undefined,
  addProduct: () => undefined,
  editCollaborationSettings: () => undefined,
  editGroup: () => undefined,
  editGroupRole: () => undefined,
  editPlatformAccount: () => undefined,
  getCollaborationSettings: () => undefined,
  getPlatformAccounts: () => undefined,
  group: undefined,
  groupMemberInvitations: [],
  hydrate: () => undefined,
  hydrateGroupMemberInvitations: () => undefined,
  ready: false,
  redirectIfNoArtistGroup: () => true,
  redirectIfNoGroup: () => true,
  removePlatformAccount: () => undefined,
})

interface GroupProviderProps {
  children: ReactNode
  id?: string
}

const GroupProvider: FunctionComponent<React.PropsWithChildren<GroupProviderProps>> = ({ id, ...props }) => {
  const { isLoggedIn, hydrate: hydrateUser, isLoading: isLoadingUser, ready: isUserReady, switchingGroups } = useUser()
  const [ready, setReady] = useState<boolean>(false)
  const [group, setGroup] = useState<Group>(undefined)
  const [groupMemberInvitations, setGroupMemberInvitations] = useState<GroupMemberInvitation[]>([])
  const { update: updateDataLayer } = useDataLayer()
  const [_selectedGroupRoles, setSelectedGroupRoles] = useLocalStorage('selectedGroupRoles', undefined)

  //////////////////////
  ///// INITIALIZE /////
  //////////////////////

  /**
   * Retrieves a group owned by the user.
   */
  const getGroup = useCallback(async () => {
    const fetchGroup = async () => {
      const response = await API.graphql(graphqlOperation(groupOperations.getMyGroup, { id }))

      return response as GraphQLResult<{
        getMyGroup: Group
      }>
    }

    let result = await fetchGroup()

    // does group have manager? if not, create it
    if (!result.data.getMyGroup?.manager) {
      try {
        await API.graphql(
          graphqlOperation(addManager, {
            groupId: result.data.getMyGroup.id,
          } satisfies AddManagerMutationVariables),
        )
      } catch (error) {
        console.error(error)
      } finally {
        // now fetch the group again (yikes!)
        result = await fetchGroup()
      }
    }

    if (result.data?.getMyGroup?.role) {
      setSelectedGroupRoles(result.data?.getMyGroup?.role)
    }

    return result.data?.getMyGroup
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  /**
   * Refreshes all the necessary state.
   */
  const hydrate = useCallback(
    async (tries = 3) => {
      try {
        if (!id) {
          setGroup(undefined)
          return
        }

        setGroup(await getGroup())
      } catch (error) {
        if (tries > 0) {
          console.error(`Error while hydrating group ${id}, retrying ...`)

          await hydrate(tries - 1)
        } else {
          console.error(`Error while hydrating group ${id}:`, error)

          setGroup(undefined)
        }
      }
    },
    [id, setGroup, getGroup],
  )

  const hydrateGroupMemberInvitations = useCallback(async () => {
    try {
      if (id) {
        const { data } = await getGroupMemberInvitations({ groupId: id })
        setGroupMemberInvitations(data ?? [])
      } else {
        setGroupMemberInvitations([])
      }
    } catch (_error) {
      setGroupMemberInvitations([])
    }
  }, [id])

  // Initialize the provider.
  useEffect(() => {
    if (!isUserReady) {
      return
    }
    if (isLoadingUser) {
      return
    }

    const init = async () => {
      if (isLoggedIn) {
        await Promise.all([hydrate(), hydrateGroupMemberInvitations()])

        setReady(true)

        updateDataLayer({
          event: 'login',
        })
      } else {
        setReady(true)
      }
    }

    void init()
  }, [isUserReady, hydrate, hydrateGroupMemberInvitations, isLoggedIn, updateDataLayer, isLoadingUser])

  /////////////////
  ///// GROUP /////
  /////////////////

  /**
   * Updates the group.
   */
  const editGroup = async ({ avatar, ...input }: GroupInput) => {
    // Upload the avatar.
    const remoteAvatar = avatar ? await upload(uuid(), avatar) : undefined

    // Update the Group.
    const editResult = (await API.graphql(
      graphqlOperation(groupOperations.editGroup, {
        id: group.id,
        input: {
          avatar: remoteAvatar,
          biography: input.biography,
          countryCode: input.countryCode,
          name: input.name,
          role: input.role,
          website: input.website,
        },
      } satisfies EditGroupMutationVariables),
    )) as GraphQLResult<Group>

    await hydrate()
    await hydrateUser()

    /**
     * Group avatar sometimes doesn't update because BE isn't ready
     * TODO: check for a better solution, for example wait until the avatar is processed see: https://codedazur.atlassian.net/browse/LEDO-6103
     */
    if (remoteAvatar) {
      setTimeout(() => {
        void (async () => {
          await hydrate()
          await hydrateUser()
        })()
      }, 2500)
    }

    return editResult
  }

  const editGroupRole = async (role: GroupRole): Promise<void> => {
    await groupOperations.editGroupRole({
      groupId: group.id,
      role,
    })

    setGroup({ ...group, role })
  }

  ////////////////////
  ///// PRODUCTS /////
  ////////////////////

  /**
   * Creates a new product for the group.
   * @param input
   * @todo Refactor this huge function.
   */
  const addProduct = async (
    input: ProductInput,
    primaryArtists: string[],
    featuringArtists: string[],
  ): Promise<Product> => {
    // Process genres and subgenres.
    const genres = {
      alternateGenre: input.alternateGenre ?? null,
      alternateSubgenre: distributionSubgenreOptions[input.alternateGenre] ? (input.alternateSubgenre ?? null) : null,
      genre: input.genre,
      subgenre: distributionSubgenreOptions[input.genre] ? (input.subgenre ?? null) : null,
    }

    // Process years.
    const years = {
      copyrightYear: input.copyrightYear ? parseInt(input.copyrightYear) : undefined,
      phonogramCopyrightYear: input.phonogramCopyrightYear ? parseInt(input.phonogramCopyrightYear) : undefined,
      recordingYear: input.recordingYear ? parseInt(input.recordingYear) : undefined,
    }

    // Process artists.
    const artists = [
      ...primaryArtists.filter(Boolean).map((id, position) => ({
        id,
        position,
        primary: true,
      })),
      ...featuringArtists.filter(Boolean).map((id, position) => ({
        id,
        position,
        primary: false,
      })),
    ]

    // Upload the product artwork.
    const remoteArtwork = input.artwork ? await upload(uuid(), input.artwork) : undefined

    const productVars = {
      alternateGenre: genres.alternateGenre as DistributionGenre,
      alternateSubgenre: genres.alternateSubgenre as DistributionSubgenre,
      artwork: remoteArtwork,
      catalogNumber: input.catalogNumber,
      consumerReleaseDate: input.consumerReleaseDate,
      copyrightText: input.copyrightText,
      copyrightYear: years.copyrightYear,
      genre: genres.genre as DistributionGenre,
      label: input.label.trim(),
      language: input.language,
      name: input.name,
      originalReleaseDate: input.originalReleaseDate,
      parentalAdvisory: input.parentalAdvisory as ParentalAdvisory,
      phonogramCopyrightText: input.phonogramCopyrightText,
      phonogramCopyrightYear: years.phonogramCopyrightYear,
      recordingLocation: input.recordingLocation,
      recordingYear: years.recordingYear,
      releaseVersion: input.releaseVersion,
      subgenre: genres.subgenre as DistributionSubgenre,
    }

    // Create product.
    const addProductResult = (await API.graphql(
      graphqlOperation(productOperations.addProduct, {
        input: {
          ...productVars,
          ean: input.upc ? input.upc : null,
        },
        managerId: group.manager.id,
      } satisfies AddProductMutationVariables),
    )) as GraphQLResult<AddProductMutation>

    // Add additional artists to product.
    await Promise.all(
      artists.map(async ({ id: artistId, primary, position }) => {
        await API.graphql(
          graphqlOperation(addProductArtist, {
            artistId,
            input: {
              position,
              primary,
            },
            productId: addProductResult.data.addProduct.id,
          } satisfies AddProductArtistMutationVariables),
        )
      }),
    )

    await hydrate()

    return addProductResult.data.addProduct as unknown as Product
  }

  ////////////////////////////
  ///// PLATFORM ACCOUNT /////
  ////////////////////////////

  const getPlatformAccounts = async (groupId: string) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const result: any = await API.graphql(graphqlOperation(getPlatformsAccounts, { groupId }))
      return result.data.getPlatformAccounts
    } catch (error) {
      if (error) {
        return error.message
      }
    }
  }

  const addPlatformAccount = useCallback(
    async (input: PlatformAccountInput) => {
      const result = platformAccountOperations.addPlatformAccount({
        groupId: group.id,
        input,
      })

      await hydrate()

      return result
    },
    [group, hydrate],
  )

  const editPlatformAccount = useCallback(
    async (id: string, input: PlatformAccountInput) => {
      const result = await platformAccountOperations.editPlatformAccount({
        id,
        input,
      })

      await hydrate()

      return result
    },
    [hydrate],
  )

  const removePlatformAccount = useCallback(
    async (id: string) => {
      const result = await platformAccountOperations.removePlatformAccount({
        id,
      })

      await hydrate()

      return result
    },
    [hydrate],
  )

  ///////////////////////////////////
  ///// COLLABORATION SETTINGS /////
  /////////////////////////////////

  /**
   * Retrieve collaboration settings based on GroupID
   */
  const getCollaborationSettings = async (groupId: string, role: string) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const result: any = await API.graphql(
        graphqlOperation(collaborationSettingsOperations.getCollaborationSettings, { groupId, role }),
      )
      return result.data?.getCollaborationSettings
    } catch (error) {
      if (error) {
        return error.message
      }
    }
  }

  /**
   * Add collaboration settings based on GroupID
   */
  const addCollaborationSettings = async (groupId: string, input: CollaborationSettings) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const result: any = await API.graphql(
        graphqlOperation(collaborationSettingsOperations.addCollaborationSettings, {
          groupId,
          input,
        }),
      )

      // Todo: call setGroup instead when having typed API call
      await hydrate()

      return result.data?.addCollaborationSettings
    } catch (error) {
      if (error) {
        return error.message
      }
    }
  }

  /**
   * Add collaboration settings based on GroupID
   */
  const editCollaborationSettings = async (id: string, input: CollaborationSettings) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const result: any = await API.graphql(
        graphqlOperation(collaborationSettingsOperations.editCollaborationSettings, {
          id,
          input,
        }),
      )

      // Todo: call setGroup instead when having typed API call
      await hydrate()

      return result.data?.editCollaborationSettings
    } catch (error) {
      if (error) {
        return error.message
      }
    }
  }

  const redirectIfNoGroup_ = useCallback(
    (destination?: string): boolean => !ready || switchingGroups || redirectIfNoGroup(group, ready, destination),
    [group, switchingGroups, ready],
  )

  const redirectIfNoArtistGroup_ = useCallback(
    (destination?: string): boolean => !ready || switchingGroups || redirectIfNoArtistGroup(group, ready, destination),
    [group, switchingGroups, ready],
  )

  /////////////////////
  ///// COMPONENT /////
  /////////////////////

  return (
    <groupContext.Provider
      value={{
        addCollaborationSettings,
        addPlatformAccount,
        addProduct,
        editCollaborationSettings,
        editGroup,
        editGroupRole,
        editPlatformAccount,
        getCollaborationSettings,
        getPlatformAccounts,
        group,
        groupMemberInvitations,
        hydrate,
        hydrateGroupMemberInvitations,
        ready,
        redirectIfNoArtistGroup: redirectIfNoArtistGroup_,
        redirectIfNoGroup: redirectIfNoGroup_,
        removePlatformAccount,
      }}
    >
      {props.children}
    </groupContext.Provider>
  )
}

export default GroupProvider

export const GroupConsumer = groupContext.Consumer
