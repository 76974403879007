export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  AWSDate: any
  AWSDateTime: any
  AWSEmail: any
  AWSIPAddress: any
  AWSJSON: any
  AWSPhone: any
  AWSTime: any
  AWSTimestamp: any
  AWSURL: any
}

export type AcceptProductInput = {
  channels: Array<Scalars['String']>
  productId: Scalars['ID']
}

export type AcceptedTerms = {
  __typename?: 'AcceptedTerms'
  acceptedAt?: Maybe<Scalars['AWSDateTime']>
  id?: Maybe<Scalars['ID']>
  terms?: Maybe<Scalars['String']>
}

export type AcceptedTermsInput = {
  terms?: InputMaybe<Scalars['String']>
}

export type AccountingResult = {
  __typename?: 'AccountingResult'
  file: File
  fileName: Scalars['String']
}

export type AddDraftDeliveryInput = {
  draftInfluencerMessage?: InputMaybe<Scalars['String']>
  draftUrl?: InputMaybe<Scalars['String']>
}

export type AddFugaProductInput = {
  alternateGenre?: InputMaybe<DistributionGenre>
  alternateSubgenre?: InputMaybe<DistributionSubgenre>
  artists: Array<FugaProductArtist>
  artwork: Scalars['String']
  catalogNumber: Scalars['String']
  consumerReleaseDate: Scalars['AWSDate']
  copyrightText: Scalars['String']
  copyrightYear: Scalars['Int']
  genre: DistributionGenre
  label: Scalars['String']
  language: Scalars['String']
  name: Scalars['String']
  originalReleaseDate: Scalars['AWSDate']
  parentalAdvisory: ParentalAdvisory
  phonogramCopyrightText: Scalars['String']
  phonogramCopyrightYear: Scalars['Int']
  recordingLocation?: InputMaybe<Scalars['String']>
  recordingYear?: InputMaybe<Scalars['Int']>
  releaseVersion?: InputMaybe<Scalars['String']>
  subgenre?: InputMaybe<DistributionSubgenre>
  upc?: InputMaybe<Scalars['String']>
}

export type AddProductInput = {
  alternateGenre?: InputMaybe<DistributionGenre>
  alternateSubgenre?: InputMaybe<DistributionSubgenre>
  artwork: Scalars['String']
  catalogNumber: Scalars['String']
  consumerReleaseDate: Scalars['AWSDate']
  copyrightText: Scalars['String']
  copyrightYear: Scalars['Int']
  ean?: InputMaybe<Scalars['String']>
  genre: DistributionGenre
  label: Scalars['String']
  language: Scalars['String']
  name: Scalars['String']
  originalReleaseDate: Scalars['AWSDate']
  parentalAdvisory: ParentalAdvisory
  phonogramCopyrightText: Scalars['String']
  phonogramCopyrightYear: Scalars['Int']
  recordingLocation?: InputMaybe<Scalars['String']>
  recordingYear?: InputMaybe<Scalars['Int']>
  releaseVersion?: InputMaybe<Scalars['String']>
  subgenre?: InputMaybe<DistributionSubgenre>
}

export type AddTrackInput = {
  alternateGenre?: InputMaybe<DistributionGenre>
  alternateSubgenre?: InputMaybe<DistributionSubgenre>
  audio: Scalars['String']
  audioLanguage?: InputMaybe<Scalars['String']>
  bpm: Scalars['Int']
  genre: DistributionGenre
  isrc?: InputMaybe<Scalars['String']>
  language?: InputMaybe<Scalars['String']>
  lyrics?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  originalFilename?: InputMaybe<Scalars['String']>
  parentalAdvisory: ParentalAdvisory
  previewLength?: InputMaybe<Scalars['Int']>
  previewStart: Scalars['Int']
  recordingLocation?: InputMaybe<Scalars['String']>
  recordingYear?: InputMaybe<Scalars['Int']>
  subgenre?: InputMaybe<DistributionSubgenre>
  version?: InputMaybe<Scalars['String']>
}

export type AddUserProfileAndGroupInput = {
  group: GroupInput
  groupMemberInvitations?: InputMaybe<Array<GroupMemberInvitationInput>>
  socialAccounts?: InputMaybe<Array<VerifySocialAccountInput>>
  userProfile?: InputMaybe<AddUserProfileInput>
}

export type AddUserProfileInput = {
  avatar?: InputMaybe<Scalars['String']>
  email: Scalars['AWSEmail']
  name?: InputMaybe<Scalars['String']>
}

export type Address = {
  __typename?: 'Address'
  addressLine1: Scalars['String']
  addressLine2?: Maybe<Scalars['String']>
  city: Scalars['String']
  companyName?: Maybe<Scalars['String']>
  country: Scalars['String']
  district?: Maybe<Scalars['String']>
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  postalCode: Scalars['String']
}

export type AddressInput = {
  addressLine1: Scalars['String']
  addressLine2?: InputMaybe<Scalars['String']>
  city: Scalars['String']
  companyName?: InputMaybe<Scalars['String']>
  country: Scalars['String']
  district?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  postalCode: Scalars['String']
}

export type AdminAddPaymentPriceModelPromotionInput = {
  amount?: InputMaybe<Scalars['Float']>
  endDate?: InputMaybe<Scalars['AWSDateTime']>
  groupId?: InputMaybe<Scalars['ID']>
  paymentPriceModel: ProductPaymentPriceModel
  percentage?: InputMaybe<Scalars['Int']>
  startDate: Scalars['AWSDateTime']
}

export type AdminArtist = {
  __typename?: 'AdminArtist'
  appleMusicId?: Maybe<Scalars['ID']>
  createdAt: Scalars['AWSDateTime']
  createdByManager?: Maybe<Manager>
  fugaId?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  linkedArtistGroup?: Maybe<AdminGroup>
  linkedArtistGroupId?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  products?: Maybe<Array<Product>>
  spotifyId?: Maybe<Scalars['ID']>
  tracks?: Maybe<Array<AdminTrack>>
  updatedAt: Scalars['AWSDateTime']
}

export type AdminArtistResults = {
  __typename?: 'AdminArtistResults'
  data: Array<AdminArtist>
  total: Scalars['Int']
}

export type AdminCreateOrUpdateDiscoverItemInput = {
  artistName?: InputMaybe<Scalars['String']>
  background?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  playlistId?: InputMaybe<Scalars['ID']>
  position: Scalars['Int']
  productId?: InputMaybe<Scalars['ID']>
  trackId?: InputMaybe<Scalars['ID']>
  type: AdminDiscoverItemType
}

export type AdminDiscoverItem = {
  __typename?: 'AdminDiscoverItem'
  artistName?: Maybe<Scalars['String']>
  background?: Maybe<Scalars['String']>
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  playlist?: Maybe<Playlist>
  playlistId?: Maybe<Scalars['ID']>
  position: Scalars['Int']
  product?: Maybe<Product>
  productId?: Maybe<Scalars['ID']>
  track?: Maybe<AdminTrack>
  trackId?: Maybe<Scalars['ID']>
  type: AdminDiscoverItemType
}

export enum AdminDiscoverItemType {
  Playlist = 'playlist',
  Product = 'product',
  Track = 'track',
}

export type AdminGetDiscoverItemsInput = {
  page?: InputMaybe<Scalars['Int']>
  pageSize?: InputMaybe<Scalars['Int']>
}

export type AdminGetDiscoverItemsResponse = {
  __typename?: 'AdminGetDiscoverItemsResponse'
  items: Array<AdminDiscoverItem>
  total: Scalars['Int']
}

export type AdminGetPaymentPriceModelPromotionsInput = {
  page: Scalars['Int']
  size: Scalars['Int']
}

export type AdminGetPaymentPriceModelPromotionsResponse = {
  __typename?: 'AdminGetPaymentPriceModelPromotionsResponse'
  data: Array<PaymentPriceModelPromotion>
  total: Scalars['Int']
}

export type AdminGroup = {
  __typename?: 'AdminGroup'
  address?: Maybe<Address>
  artistCollaborationSettings?: Maybe<CollaborationSettings>
  avatar?: Maybe<Image>
  bankAccount?: Maybe<BankAccount>
  biography?: Maybe<Scalars['String']>
  brandType?: Maybe<BrandType>
  budgetHigh?: Maybe<Scalars['Int']>
  budgetLow?: Maybe<Scalars['Int']>
  configured: Scalars['Boolean']
  contentTypes?: Maybe<Array<ContentType>>
  countryCode?: Maybe<Scalars['String']>
  createdAt: Scalars['AWSDateTime']
  disabledAt?: Maybe<Scalars['AWSDateTime']>
  genres?: Maybe<Array<GenreTag>>
  id: Scalars['ID']
  influencerCollaborationSettings?: Maybe<CollaborationSettings>
  interests?: Maybe<Array<Interest>>
  invoices?: Maybe<Array<Maybe<Invoice>>>
  linkedToArtist?: Maybe<Artist>
  manager?: Maybe<Manager>
  members?: Maybe<Array<GroupMember>>
  myMenu?: Maybe<Array<Maybe<MyMenuType>>>
  name: Scalars['String']
  notifications?: Maybe<Array<Maybe<Notification>>>
  oldRoles?: Maybe<Array<OldGroupRoles>>
  paymentSettings?: Maybe<GroupPaymentSettings>
  payoutSettings?: Maybe<GroupPayoutSettings>
  paypalAccount?: Maybe<PaypalAccount>
  platformAccounts?: Maybe<Array<PlatformAccount>>
  ratedAt?: Maybe<Scalars['AWSDateTime']>
  rating?: Maybe<Scalars['Int']>
  role?: Maybe<GroupRole>
  slug: Scalars['String']
  supportedChats: SupportedChats
  updatedAt: Scalars['AWSDateTime']
  vatDetails?: Maybe<VatDetails>
  website?: Maybe<Scalars['String']>
}

export type AdminGroupSearchResults = {
  __typename?: 'AdminGroupSearchResults'
  data?: Maybe<Array<Maybe<AdminGroup>>>
  total: Scalars['Int']
}

export type AdminTrack = {
  __typename?: 'AdminTrack'
  alternateGenre?: Maybe<DistributionGenre>
  alternateSubgenre?: Maybe<DistributionSubgenre>
  audio: File
  audioLanguage?: Maybe<Scalars['String']>
  audioLength?: Maybe<Scalars['Int']>
  audioWaveForm?: Maybe<Scalars['String']>
  bpm: Scalars['Int']
  clearance?: Maybe<MusicLibraryClearanceType>
  collaborations?: Maybe<Array<Maybe<Collaboration>>>
  compressedAudio?: Maybe<File>
  contributors?: Maybe<Array<Contributor>>
  createdAt: Scalars['AWSDateTime']
  cyaniteMetaData?: Maybe<CyaniteMetaData>
  deletedAt?: Maybe<Scalars['AWSDateTime']>
  fugaId?: Maybe<Scalars['ID']>
  genre: DistributionGenre
  groupShares?: Maybe<Array<Maybe<GroupShare>>>
  groups?: Maybe<Array<Maybe<Group>>>
  hasNfts: Scalars['Boolean']
  id: Scalars['ID']
  inMusicLibrary: Scalars['Boolean']
  language?: Maybe<Scalars['String']>
  lyrics?: Maybe<Scalars['String']>
  managers?: Maybe<Array<Manager>>
  musicLibraryTrackConfig?: Maybe<MusicLibraryTrackConfig>
  name: Scalars['String']
  nonFungibleTokenCollections?: Maybe<Array<Maybe<NonFungibleTokenCollection>>>
  originalFilename?: Maybe<Scalars['String']>
  parentalAdvisory: ParentalAdvisory
  previewAudio?: Maybe<File>
  previewLength?: Maybe<Scalars['Int']>
  previewStart: Scalars['Int']
  product?: Maybe<Product>
  ratedAt?: Maybe<Scalars['AWSDateTime']>
  rating: Scalars['Int']
  recordingLocation?: Maybe<Scalars['String']>
  recordingYear?: Maybe<Scalars['Int']>
  subgenre?: Maybe<DistributionSubgenre>
  trackArtists?: Maybe<Array<TrackArtist>>
  trackCode?: Maybe<TrackCode>
  updatedAt: Scalars['AWSDateTime']
  version?: Maybe<Scalars['String']>
}

export type AdminTracksSearchResults = {
  __typename?: 'AdminTracksSearchResults'
  data?: Maybe<Array<Maybe<AdminTrack>>>
  total: Scalars['Int']
}

export type AnalyticsChartInput = {
  endDate: Scalars['AWSDate']
  saleType: SaleType
  selectionType: SelectionType
  startDate: Scalars['AWSDate']
}

export type AnalyticsChartResponse = {
  __typename?: 'AnalyticsChartResponse'
  name?: Maybe<Scalars['String']>
  totals?: Maybe<Array<Maybe<Scalars['Int']>>>
}

export type AnalyticsFilters = {
  assets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  dsps?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  products?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  territories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type AnalyticsListInput = {
  endDate: Scalars['AWSDate']
  offset?: InputMaybe<Scalars['Int']>
  saleType: SaleType
  selectionType: SelectionType
  size?: InputMaybe<Scalars['Int']>
  startDate: Scalars['AWSDate']
}

export enum AnalyticsListPeriodType {
  Custom = 'custom',
  ThirtyDays = 'thirtyDays',
  Total = 'total',
}

export type AnalyticsListResponse = {
  __typename?: 'AnalyticsListResponse'
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  periodType?: Maybe<AnalyticsListPeriodType>
  sub30Totals?: Maybe<AnalyticsListTotals>
  totals?: Maybe<AnalyticsListTotals>
}

export type AnalyticsListTotals = {
  __typename?: 'AnalyticsListTotals'
  count?: Maybe<Scalars['Int']>
  percentage?: Maybe<Scalars['Float']>
}

export type AnalyticsTotalsInput = {
  endDate: Scalars['AWSDate']
  selectionType: SelectionType
  startDate: Scalars['AWSDate']
}

export type AnalyticsTotalsResponse = {
  __typename?: 'AnalyticsTotalsResponse'
  download_count?: Maybe<Scalars['Int']>
  download_percentage?: Maybe<Scalars['Float']>
  stream_count?: Maybe<Scalars['Int']>
  stream_percentage?: Maybe<Scalars['Float']>
}

export type Artist = {
  __typename?: 'Artist'
  appleMusicId?: Maybe<Scalars['ID']>
  fugaId?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  linkedArtistGroup?: Maybe<Group>
  linkedArtistGroupId?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  products?: Maybe<Array<Product>>
  spotifyId?: Maybe<Scalars['ID']>
  tracks?: Maybe<Array<Track>>
}

export type ArtistInput = {
  appleMusicId?: InputMaybe<Scalars['ID']>
  linkArtistToGroup?: InputMaybe<Scalars['Boolean']>
  name: Scalars['String']
  spotifyId?: InputMaybe<Scalars['ID']>
}

export type ArtistSearchFilter = {
  offset: Scalars['Int']
  onlyUnlinked?: InputMaybe<Scalars['Boolean']>
  search?: InputMaybe<Scalars['String']>
  size: Scalars['Int']
}

export type ArtistSearchResults = {
  __typename?: 'ArtistSearchResults'
  data?: Maybe<Array<Maybe<PublicArtist>>>
  total: Scalars['Int']
}

export type ArtistsBulkImportInput = {
  fileName: Scalars['String']
}

export type AssetDetails = {
  __typename?: 'AssetDetails'
  collaborations?: Maybe<Array<Collaboration>>
  licenses?: Maybe<Array<SyncLicense>>
  nfts?: Maybe<Array<SoldNonFungibleToken>>
  royaltySplits?: Maybe<TrackRoyalties>
  track?: Maybe<AssetTrack>
}

export enum AssetDetailsTab {
  Collabs = 'collabs',
  Licences = 'licences',
  Nfts = 'nfts',
  Splits = 'splits',
  Stats = 'stats',
}

export type AssetTrack = {
  __typename?: 'AssetTrack'
  alternateGenre?: Maybe<DistributionGenre>
  alternateSubgenre?: Maybe<DistributionSubgenre>
  audioLength?: Maybe<Scalars['Int']>
  bpm: Scalars['Int']
  compressedAudio?: Maybe<File>
  contributors?: Maybe<Array<Contributor>>
  cyaniteMetaData?: Maybe<CyaniteMetaData>
  fugaId?: Maybe<Scalars['ID']>
  genre: DistributionGenre
  id: Scalars['ID']
  name: Scalars['String']
  previewAudio?: Maybe<File>
  product: PublicProduct
  recordingLocation?: Maybe<Scalars['String']>
  recordingYear?: Maybe<Scalars['Int']>
  subgenre?: Maybe<DistributionSubgenre>
  trackArtists?: Maybe<Array<PublicTrackArtist>>
  trackCode?: Maybe<TrackCode>
  version?: Maybe<Scalars['String']>
}

export type AssetTrackPaginationResults = {
  __typename?: 'AssetTrackPaginationResults'
  data?: Maybe<Array<Maybe<AssetTrack>>>
  total: Scalars['Int']
}

export type AssetsTracksFilter = {
  filters?: InputMaybe<Array<InputMaybe<AssetsTracksFilterOptions>>>
  offset: Scalars['Int']
  size: Scalars['Int']
  sort?: InputMaybe<AssetsTracksSortOptions>
}

export enum AssetsTracksFilterOptions {
  Collaborations = 'COLLABORATIONS',
  Licenses = 'LICENSES',
  Nfts = 'NFTS',
  RoyaltySplits = 'ROYALTY_SPLITS',
}

export enum AssetsTracksSortOptions {
  ArtistNameAsc = 'ARTIST_NAME_ASC',
  LatestReleaseAsc = 'LATEST_RELEASE_ASC',
  TrackNameAsc = 'TRACK_NAME_ASC',
}

export type AvailableContributor = {
  __typename?: 'AvailableContributor'
  name: Scalars['String']
  needsFullName: Scalars['Boolean']
  needsPublisher: Scalars['Boolean']
  required: Scalars['Boolean']
  role: ContributorRole
}

export type AvailableContributors = {
  __typename?: 'AvailableContributors'
  compositionAndLyrics: Array<AvailableContributor>
  performers: Array<AvailableContributor>
  productionAndEngineering: Array<AvailableContributor>
}

export type BankAccount = {
  __typename?: 'BankAccount'
  accountHolderName: Scalars['String']
  currencyCode: CurrencyCode
  details: BankAccountDetails
  id: Scalars['ID']
  type: BankAccountType
}

export type BankAccountDetails = IbanBankAccountDetails | SortCodeBankAccountDetails

export type BankAccountDetailsInput = {
  accountNumber?: InputMaybe<Scalars['String']>
  iban?: InputMaybe<Scalars['String']>
  sortCode?: InputMaybe<Scalars['String']>
}

export type BankAccountInput = {
  accountHolderName: Scalars['String']
  currencyCode: CurrencyCode
  details: BankAccountDetailsInput
  type: BankAccountType
}

export enum BankAccountType {
  Iban = 'iban',
  SortCode = 'sortCode',
}

export enum BrandType {
  Agency = 'agency',
  Brand = 'brand',
  Freelance = 'freelance',
  NonProfit = 'nonProfit',
  Other = 'other',
  ProductionCompany = 'productionCompany',
  SchoolUniversity = 'schoolUniversity',
  YoutubeCreatorPodcaster = 'youtubeCreatorPodcaster',
}

export type CampaignSubmissionDataItem = {
  label: Scalars['String']
  value?: InputMaybe<Scalars['String']>
}

export type ChannelFilter = {
  channelId?: InputMaybe<Scalars['String']>
  statuses?: InputMaybe<Array<Scalars['String']>>
}

export enum CharacterTag {
  Bold = 'bold',
  Cool = 'cool',
  Epic = 'epic',
  Ethereal = 'ethereal',
  Heroic = 'heroic',
  Luxurious = 'luxurious',
  Magical = 'magical',
  Mysterious = 'mysterious',
  Playful = 'playful',
  Powerful = 'powerful',
  Retro = 'retro',
  Sophisticated = 'sophisticated',
  Sparkling = 'sparkling',
  Sparse = 'sparse',
  Unpolished = 'unpolished',
  Warm = 'warm',
}

export type ChatInput = {
  initiatingGroupId: Scalars['ID']
  targetGroupId: Scalars['ID']
}

export type ClaimNftResponse = {
  __typename?: 'ClaimNftResponse'
  total: Scalars['String']
}

export type Collaboration = {
  __typename?: 'Collaboration'
  canceledAt?: Maybe<Scalars['AWSDateTime']>
  canceledBy?: Maybe<PublicUserProfile>
  canceledReason?: Maybe<Scalars['String']>
  createdAt: Scalars['AWSDateTime']
  from: PublicGroup
  id: Scalars['ID']
  state?: Maybe<CollaborationState>
  terms?: Maybe<Array<Maybe<CollaborationTerms>>>
  to: PublicGroup
}

export type CollaborationCloseInput = {
  reason?: InputMaybe<Scalars['String']>
  status: CollaborationCloseStatus
}

export enum CollaborationCloseStatus {
  Accepted = 'ACCEPTED',
  Canceled = 'CANCELED',
  Declined = 'DECLINED',
}

export type CollaborationCompensationInput = {
  fixedFee?: InputMaybe<Scalars['Float']>
  paymentEndDate?: InputMaybe<Scalars['AWSDate']>
  paymentStartDate?: InputMaybe<Scalars['AWSDate']>
  royaltySharePercentage?: InputMaybe<Scalars['Float']>
}

export type CollaborationContentCategory = {
  __typename?: 'CollaborationContentCategory'
  contentCategory?: Maybe<ContentCategory>
  contentCategoryId?: Maybe<Scalars['String']>
  id: Scalars['ID']
}

export type CollaborationCounterProposalInput = {
  collaborationEndDate?: InputMaybe<Scalars['AWSDate']>
  collaborationStartDate?: InputMaybe<Scalars['AWSDate']>
  compensation: CollaborationCompensationInput
  createdByGroupId: Scalars['ID']
  message?: InputMaybe<Scalars['String']>
  postAmount: Scalars['Int']
  postFrequency?: InputMaybe<Scalars['Int']>
  postPeriod?: InputMaybe<PostFrequencyPeriod>
  posts: Array<InputMaybe<CollaborationPostInput>>
  postsType?: InputMaybe<Scalars['String']>
  trackId: Scalars['ID']
  type: CollaborationType
}

export type CollaborationGenre = {
  __typename?: 'CollaborationGenre'
  genre: Genre
  id: Scalars['ID']
  subgenres?: Maybe<Array<Maybe<Subgenre>>>
  targetId: Scalars['String']
  targetType: Scalars['String']
}

export type CollaborationGenreInput = {
  id: Scalars['ID']
  subgenres?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
}

export type CollaborationInput = {
  collaborationEndDate?: InputMaybe<Scalars['AWSDate']>
  collaborationStartDate?: InputMaybe<Scalars['AWSDate']>
  compensation: CollaborationCompensationInput
  createdByGroupId: Scalars['ID']
  fromGroupId: Scalars['ID']
  message?: InputMaybe<Scalars['String']>
  postAmount: Scalars['Int']
  postFrequency?: InputMaybe<Scalars['Int']>
  postPeriod?: InputMaybe<PostFrequencyPeriod>
  posts: Array<InputMaybe<CollaborationPostInput>>
  postsType?: InputMaybe<Scalars['String']>
  toGroupId: Scalars['ID']
  trackId: Scalars['ID']
  type: CollaborationType
}

export type CollaborationPaginationFilter = {
  offset: Scalars['Int']
  size: Scalars['Int']
  timelineStatus: CollaborationTimelineStatus
}

export type CollaborationPaginationResults = {
  __typename?: 'CollaborationPaginationResults'
  data?: Maybe<Array<Maybe<Collaboration>>>
  total: Scalars['Int']
}

export type CollaborationPlatform = {
  __typename?: 'CollaborationPlatform'
  id: Scalars['ID']
  platform?: Maybe<Platform>
  platformId?: Maybe<Scalars['String']>
}

export type CollaborationPost = {
  __typename?: 'CollaborationPost'
  id: Scalars['ID']
  platform?: Maybe<Platform>
  proofOfDeliveries?: Maybe<Array<Maybe<ProofOfDelivery>>>
}

export type CollaborationPostInput = {
  platformId: Scalars['ID']
}

export type CollaborationSearchFilter = {
  offset: Scalars['Int']
  search?: InputMaybe<Scalars['String']>
  size: Scalars['Int']
  timelineStatus: CollaborationTimelineStatus
}

export type CollaborationSearchResults = {
  __typename?: 'CollaborationSearchResults'
  data?: Maybe<Array<Maybe<Collaboration>>>
  total: Scalars['Int']
}

export type CollaborationSettings = {
  __typename?: 'CollaborationSettings'
  contentCategories?: Maybe<Array<Maybe<CollaborationContentCategory>>>
  fixedFeeEnabled: Scalars['Boolean']
  fixedFeeMinimumFee?: Maybe<Scalars['Float']>
  genres?: Maybe<Array<Maybe<CollaborationGenre>>>
  id: Scalars['ID']
  paypalEmail?: Maybe<Scalars['String']>
  paypalMerchantId?: Maybe<Scalars['String']>
  platformAccounts?: Maybe<Array<Maybe<PlatformAccount>>>
  platforms?: Maybe<Array<Maybe<CollaborationPlatform>>>
  role?: Maybe<CollaborationSettingsGroupRole>
  royaltyShareEnabled: Scalars['Boolean']
}

export enum CollaborationSettingsGroupRole {
  Artist = 'artist',
  Influencer = 'influencer',
}

export type CollaborationSettingsInput = {
  contentCategories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  fixedFeeEnabled: Scalars['Boolean']
  fixedFeeMinimumFee?: InputMaybe<Scalars['Float']>
  genres?: InputMaybe<Array<InputMaybe<CollaborationGenreInput>>>
  paypalEmail?: InputMaybe<Scalars['String']>
  platforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  role: CollaborationSettingsGroupRole
  royaltyShareEnabled: Scalars['Boolean']
}

export enum CollaborationState {
  Accepted = 'ACCEPTED',
  Canceled = 'CANCELED',
  Closed = 'CLOSED',
  Declined = 'DECLINED',
  DraftAccepted = 'DRAFT_ACCEPTED',
  DraftDelivered = 'DRAFT_DELIVERED',
  DraftFeedback = 'DRAFT_FEEDBACK',
  Error = 'ERROR',
  Ongoing = 'ONGOING',
  OngoingPayment = 'ONGOING_PAYMENT',
  Paid = 'PAID',
  PaymentPending = 'PAYMENT_PENDING',
  PaymentRequired = 'PAYMENT_REQUIRED',
  Proposed = 'PROPOSED',
  UpcomingPayment = 'UPCOMING_PAYMENT',
}

export type CollaborationTerms = {
  __typename?: 'CollaborationTerms'
  acceptedAt?: Maybe<Scalars['AWSDateTime']>
  acceptedBy?: Maybe<PublicUserProfile>
  collaborationEndDate?: Maybe<Scalars['AWSDate']>
  collaborationStartDate?: Maybe<Scalars['AWSDate']>
  createdAt: Scalars['AWSDateTime']
  createdBy?: Maybe<PublicUserProfile>
  createdByGroup?: Maybe<PublicGroup>
  declinedAt?: Maybe<Scalars['AWSDateTime']>
  declinedBy?: Maybe<PublicUserProfile>
  declinedReason?: Maybe<Scalars['String']>
  fixedFee?: Maybe<Scalars['Float']>
  id: Scalars['ID']
  message?: Maybe<Scalars['String']>
  order?: Maybe<Order>
  paymentEndDate?: Maybe<Scalars['AWSDate']>
  paymentStartDate?: Maybe<Scalars['AWSDate']>
  postAmount?: Maybe<Scalars['Int']>
  postFrequency?: Maybe<Scalars['Int']>
  postPeriod?: Maybe<PostFrequencyPeriod>
  posts?: Maybe<Array<Maybe<CollaborationPost>>>
  postsType?: Maybe<Scalars['String']>
  royaltySharePercentage?: Maybe<Scalars['Float']>
  track?: Maybe<CollaborationTrack>
  type?: Maybe<CollaborationType>
  version?: Maybe<Scalars['Int']>
}

export enum CollaborationTimelineStatus {
  Active = 'active',
  All = 'all',
  Canceled = 'canceled',
  Finalized = 'finalized',
  Pending = 'pending',
}

export type CollaborationTrack = {
  __typename?: 'CollaborationTrack'
  alternateGenre?: Maybe<DistributionGenre>
  alternateSubgenre?: Maybe<DistributionSubgenre>
  audioLength?: Maybe<Scalars['Int']>
  bpm: Scalars['Int']
  compressedAudio?: Maybe<File>
  genre: DistributionGenre
  id: Scalars['ID']
  name: Scalars['String']
  previewAudio?: Maybe<File>
  product: PublicProduct
  recordingLocation?: Maybe<Scalars['String']>
  recordingYear?: Maybe<Scalars['Int']>
  subgenre?: Maybe<DistributionSubgenre>
  trackArtists?: Maybe<Array<PublicTrackArtist>>
  version?: Maybe<Scalars['String']>
}

export enum CollaborationType {
  FixedFee = 'fixedFee',
  RoyaltyShare = 'royaltyShare',
}

export type ContentCategory = {
  __typename?: 'ContentCategory'
  id: Scalars['String']
  name: Scalars['String']
}

export enum ContentType {
  Animals = 'animals',
  ArtAndPhotography = 'artAndPhotography',
  AthleteAndSports = 'athleteAndSports',
  Automotive = 'automotive',
  Beauty = 'beauty',
  Business = 'business',
  ComedyAndEntertainment = 'comedyAndEntertainment',
  Culture = 'culture',
  Dance = 'dance',
  Family = 'family',
  Fashion = 'fashion',
  Food = 'food',
  Gaming = 'gaming',
  HealthAndFitness = 'healthAndFitness',
  Lifestyle = 'lifestyle',
  Music = 'music',
  ScienceAndEducation = 'scienceAndEducation',
  Technology = 'technology',
  Travel = 'travel',
}

export type Contributor = {
  __typename?: 'Contributor'
  id: Scalars['ID']
  name: Scalars['String']
  position: Scalars['Int']
  publisher?: Maybe<Scalars['String']>
  role: ContributorRole
  trackId: Scalars['ID']
}

export type ContributorInput = {
  name: Scalars['String']
  position: Scalars['Int']
  publisher?: InputMaybe<Scalars['String']>
  role: ContributorRole
}

export enum ContributorRole {
  Accordion = 'accordion',
  BackgroundVocals = 'backgroundVocals',
  BassGuitar = 'bassGuitar',
  Cello = 'cello',
  Clarinet = 'clarinet',
  Composer = 'composer',
  Conductor = 'conductor',
  Drums = 'drums',
  Flute = 'flute',
  Guitar = 'guitar',
  Harmonica = 'harmonica',
  Harp = 'harp',
  Keyboards = 'keyboards',
  Lyricist = 'lyricist',
  Orchestra = 'orchestra',
  Organ = 'organ',
  Percussion = 'percussion',
  Piano = 'piano',
  Producer = 'producer',
  Publisher = 'publisher',
  Rap = 'rap',
  Remixer = 'remixer',
  Saxophone = 'saxophone',
  Synthesizer = 'synthesizer',
  Tambourine = 'tambourine',
  Trombone = 'trombone',
  Trumpet = 'trumpet',
  Viola = 'viola',
  Violin = 'violin',
  Vocals = 'vocals',
  Writer = 'writer',
}

export type CreateCollaborationOrderInput = {
  amount?: InputMaybe<Scalars['Float']>
  currency?: InputMaybe<Scalars['String']>
  payee?: InputMaybe<Scalars['String']>
  referenceId?: InputMaybe<Scalars['String']>
}

export type CreateFlatFeeOrderResponse = {
  __typename?: 'CreateFlatFeeOrderResponse'
  paypalOrderId?: Maybe<Scalars['String']>
  productId: Scalars['String']
}

export type CreateNftCollectionInput = {
  count: Scalars['Int']
  groupId: Scalars['ID']
  price: Scalars['Float']
  totalShare: Scalars['Float']
  trackId: Scalars['ID']
}

export type CreateNftPurchaseOrderInput = {
  collectionId: Scalars['ID']
}

export type CreateNftPurchaseOrderResponse = {
  __typename?: 'CreateNftPurchaseOrderResponse'
  nftPurchaseId: Scalars['ID']
  paypalOrderId?: Maybe<Scalars['String']>
  price: Scalars['Float']
  totalOrderAmount: Scalars['Float']
  vatAmount?: Maybe<Scalars['Float']>
}

export type CreateSyncLicenseOrderInput = {
  groupId: Scalars['ID']
  licenseCategory: SyncLicenseCategory
  licenseDuration: SyncLicenseDurations
  licenseTrackLength: SyncLicenseTrackLengths
  projectDescription: Scalars['String']
  projectName: Scalars['String']
  trackId: Scalars['ID']
}

export type CreateSyncLicenseOrderResponse = {
  __typename?: 'CreateSyncLicenseOrderResponse'
  paypalOrderId?: Maybe<Scalars['String']>
  price: Scalars['Float']
  syncLicenseId: Scalars['ID']
  totalOrderAmount: Scalars['Float']
  vatAmount?: Maybe<Scalars['Float']>
}

export enum CurrencyCode {
  Eur = 'EUR',
  Gbp = 'GBP',
}

export type CyaniteMetaData = {
  __typename?: 'CyaniteMetaData'
  arousal?: Maybe<Scalars['Float']>
  bpmRangeAdjusted?: Maybe<Scalars['Int']>
  characters?: Maybe<Array<Maybe<CharacterTag>>>
  createdAt: Scalars['AWSDateTime']
  cyaniteMetaDataInstrumentPresence?: Maybe<CyaniteMetaDataInstrumentPresence>
  cyaniteMetaDataKeywords?: Maybe<Array<Maybe<CyaniteMetaDataKeyword>>>
  cyaniteMetaDataTags?: Maybe<Array<Maybe<CyaniteMetaDataTag>>>
  emotionalDynamics?: Maybe<EmotionalDynamics>
  emotionalProfile?: Maybe<EmotionalProfile>
  energyDynamics?: Maybe<EnergyDynamics>
  energyLevel?: Maybe<EnergyLevel>
  genres?: Maybe<Array<Maybe<GenreTag>>>
  id: Scalars['ID']
  instruments?: Maybe<Array<Maybe<InstrumentTag>>>
  keyPredictionConfidence?: Maybe<Scalars['Float']>
  keyPredictionValue?: Maybe<KeyPredictionValue>
  moods?: Maybe<Array<Maybe<MoodTag>>>
  moodsAdvanced?: Maybe<Array<Maybe<MoodAdvancedTag>>>
  movements?: Maybe<Array<Maybe<MovementTag>>>
  musicalEraTag?: Maybe<Scalars['String']>
  predominantVoiceGender?: Maybe<PredominantVoiceGender>
  subgenres?: Maybe<Array<Maybe<SubgenreTag>>>
  updatedAt: Scalars['AWSDateTime']
  valence?: Maybe<Scalars['Float']>
  voiceoverDegree?: Maybe<Scalars['Float']>
  voiceoverExists?: Maybe<Scalars['Boolean']>
}

export type CyaniteMetaDataInstrumentPresence = {
  __typename?: 'CyaniteMetaDataInstrumentPresence'
  accordion?: Maybe<InstrumentPresenceLabel>
  acousticGuitar?: Maybe<InstrumentPresenceLabel>
  africanPercussion?: Maybe<InstrumentPresenceLabel>
  asianFlute?: Maybe<InstrumentPresenceLabel>
  asianStrings?: Maybe<InstrumentPresenceLabel>
  banjo?: Maybe<InstrumentPresenceLabel>
  bass?: Maybe<InstrumentPresenceLabel>
  bassGuitar?: Maybe<InstrumentPresenceLabel>
  bells?: Maybe<InstrumentPresenceLabel>
  bongoConga?: Maybe<InstrumentPresenceLabel>
  brass?: Maybe<InstrumentPresenceLabel>
  celeste?: Maybe<InstrumentPresenceLabel>
  cello?: Maybe<InstrumentPresenceLabel>
  churchOrgan?: Maybe<InstrumentPresenceLabel>
  clarinet?: Maybe<InstrumentPresenceLabel>
  doubleBass?: Maybe<InstrumentPresenceLabel>
  drumKit?: Maybe<InstrumentPresenceLabel>
  electricGuitar?: Maybe<InstrumentPresenceLabel>
  electricOrgan?: Maybe<InstrumentPresenceLabel>
  electricPiano?: Maybe<InstrumentPresenceLabel>
  electronicDrums?: Maybe<InstrumentPresenceLabel>
  flute?: Maybe<InstrumentPresenceLabel>
  frenchHorn?: Maybe<InstrumentPresenceLabel>
  glockenspiel?: Maybe<InstrumentPresenceLabel>
  harp?: Maybe<InstrumentPresenceLabel>
  harpsichord?: Maybe<InstrumentPresenceLabel>
  id: Scalars['ID']
  luteOud?: Maybe<InstrumentPresenceLabel>
  mandolin?: Maybe<InstrumentPresenceLabel>
  marimba?: Maybe<InstrumentPresenceLabel>
  oboe?: Maybe<InstrumentPresenceLabel>
  percussion?: Maybe<InstrumentPresenceLabel>
  piano?: Maybe<InstrumentPresenceLabel>
  pizzicato?: Maybe<InstrumentPresenceLabel>
  sax?: Maybe<InstrumentPresenceLabel>
  sitar?: Maybe<InstrumentPresenceLabel>
  steelDrums?: Maybe<InstrumentPresenceLabel>
  strings?: Maybe<InstrumentPresenceLabel>
  synth?: Maybe<InstrumentPresenceLabel>
  tabla?: Maybe<InstrumentPresenceLabel>
  taiko?: Maybe<InstrumentPresenceLabel>
  trumpet?: Maybe<InstrumentPresenceLabel>
  tuba?: Maybe<InstrumentPresenceLabel>
  ukulele?: Maybe<InstrumentPresenceLabel>
  vibraphone?: Maybe<InstrumentPresenceLabel>
  violin?: Maybe<InstrumentPresenceLabel>
  woodwinds?: Maybe<InstrumentPresenceLabel>
  xylophone?: Maybe<InstrumentPresenceLabel>
}

export type CyaniteMetaDataKeyword = {
  __typename?: 'CyaniteMetaDataKeyword'
  id: Scalars['ID']
  keyword: Scalars['String']
  weight: Scalars['Float']
}

export type CyaniteMetaDataTag = {
  __typename?: 'CyaniteMetaDataTag'
  id: Scalars['ID']
  tagType: TagType
  value: Scalars['String']
}

export type DateRange = {
  high: Scalars['AWSDate']
  low: Scalars['AWSDate']
}

export enum DistributionChannelStatus {
  Accepted = 'Accepted',
  Available = 'Available',
  Delivered = 'Delivered',
  Delivering = 'Delivering',
  NotApplicable = 'NotApplicable',
  PendingApproval = 'PendingApproval',
  PendingReleaseDate = 'PendingReleaseDate',
  PendingShareAcceptance = 'PendingShareAcceptance',
  Rejected = 'Rejected',
  RejectedFinal = 'RejectedFinal',
  TakenDown = 'TakenDown',
  Unknown = 'Unknown',
}

export enum DistributionGenre {
  Alternative = 'alternative',
  Blues = 'blues',
  ChildrensMusic = 'childrensMusic',
  Classical = 'classical',
  Country = 'country',
  Dance = 'dance',
  Electronic = 'electronic',
  Folk = 'folk',
  HipHopRap = 'hipHopRap',
  Holiday = 'holiday',
  Jazz = 'jazz',
  Latin = 'latin',
  NewAge = 'newAge',
  Pop = 'pop',
  RbSoul = 'rbSoul',
  Reggae = 'reggae',
  Rock = 'rock',
  Soundtrack = 'soundtrack',
  Vocal = 'vocal',
  World = 'world',
}

export enum DistributionSubgenre {
  AfroHouse = 'afroHouse',
  BassHouse = 'bassHouse',
  BigRoom = 'bigRoom',
  Breaks = 'breaks',
  Dance = 'dance',
  DeepHouse = 'deepHouse',
  DrumBass = 'drumBass',
  Dubstep = 'dubstep',
  ElectroHouse = 'electroHouse',
  ElectronicaDowntempo = 'electronicaDowntempo',
  FunkyGrooveJackinHouse = 'funkyGrooveJackinHouse',
  FutureHouse = 'futureHouse',
  GarageBasslineGrime = 'garageBasslineGrime',
  HardDanceHardcore = 'hardDanceHardcore',
  HipHopRb = 'hipHopRB',
  House = 'house',
  IndieDance = 'indieDance',
  LeftfieldBass = 'leftfieldBass',
  LeftfieldHouseTechno = 'leftfieldHouseTechno',
  MelodicHouseTechno = 'melodicHouseTechno',
  MinimalDeepTech = 'minimalDeepTech',
  NuDiscoDisco = 'nuDiscoDisco',
  ProgressiveHouse = 'progressiveHouse',
  PsyTrance = 'psyTrance',
  ReggaeDancehallDub = 'reggaeDancehallDub',
  TechHouse = 'techHouse',
  TechnoPeakTimeDrivingHard = 'technoPeakTimeDrivingHard',
  TechnoRawDeepHypnotic = 'technoRawDeepHypnotic',
  Trance = 'trance',
  TrapFutureBass = 'trapFutureBass',
}

export type EditFugaAssetInput = {
  alternateGenre?: InputMaybe<DistributionGenre>
  alternateSubgenre?: InputMaybe<DistributionSubgenre>
  artists?: InputMaybe<Array<FugaAssetArtistInput>>
  audio?: InputMaybe<Scalars['String']>
  audioLanguage?: InputMaybe<Scalars['String']>
  contributors?: InputMaybe<Array<FugaContributorInput>>
  genre?: InputMaybe<DistributionGenre>
  isrc?: InputMaybe<Scalars['String']>
  language?: InputMaybe<Scalars['String']>
  lyrics?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  parentalAdvisory?: InputMaybe<ParentalAdvisory>
  phonogramCopyrightText?: InputMaybe<Scalars['String']>
  phonogramCopyrightYear?: InputMaybe<Scalars['Int']>
  previewLength?: InputMaybe<Scalars['Int']>
  previewStart?: InputMaybe<Scalars['Int']>
  recordingLocation?: InputMaybe<Scalars['String']>
  recordingYear?: InputMaybe<Scalars['Int']>
  subgenre?: InputMaybe<DistributionSubgenre>
  version?: InputMaybe<Scalars['String']>
}

export type EditFugaAssetIsrcInput = {
  isrc: Scalars['String']
}

export type EditFugaProductInput = {
  alternateGenre?: InputMaybe<DistributionGenre>
  alternateSubgenre?: InputMaybe<DistributionSubgenre>
  artists?: InputMaybe<Array<FugaProductArtist>>
  artwork?: InputMaybe<Scalars['String']>
  catalogNumber: Scalars['String']
  consumerReleaseDate: Scalars['AWSDate']
  copyrightText: Scalars['String']
  copyrightYear: Scalars['Int']
  genre: DistributionGenre
  label: Scalars['String']
  language: Scalars['String']
  name: Scalars['String']
  originalReleaseDate: Scalars['AWSDate']
  parentalAdvisory: ParentalAdvisory
  phonogramCopyrightText: Scalars['String']
  phonogramCopyrightYear: Scalars['Int']
  recordingLocation?: InputMaybe<Scalars['String']>
  recordingYear?: InputMaybe<Scalars['Int']>
  releaseVersion?: InputMaybe<Scalars['String']>
  subgenre?: InputMaybe<DistributionSubgenre>
  upc?: InputMaybe<Scalars['String']>
}

export type EditFugaProductUpcInput = {
  upc: Scalars['String']
}

export type EditMusicLibraryConfigSettingInput = {
  musicLibraryConfigSetting?: InputMaybe<MusicLibraryConfigSetting>
}

export type EditMusicLibraryProductConfigInput = {
  advertisementAllMedia: Scalars['String']
  advertisementAudioOnly: Scalars['String']
  advertisementOnlineOnly: Scalars['String']
  corporateNonProfitCharity: Scalars['String']
  corporateNonProfitCompanyPresentation: Scalars['String']
  corporateNonProfitEducational: Scalars['String']
  durations: Array<SyncLicenseDurations>
  enabled: Scalars['Boolean']
  filmLargeAllMedia: Scalars['String']
  filmLargeOnlineOnly: Scalars['String']
  filmLargeTrailer: Scalars['String']
  filmSmallAllMedia: Scalars['String']
  filmSmallOnlineOnly: Scalars['String']
  filmSmallTrailer: Scalars['String']
  gamesAppsInAppGame: Scalars['String']
  gamesAppsTrailerPromo: Scalars['String']
  podcastPrivateBranded: Scalars['String']
  podcastPrivateNonBranded: Scalars['String']
  productId: Scalars['ID']
  radioTvAudioOnly: Scalars['String']
  radioTvTrailer: Scalars['String']
  radioTvTvShow: Scalars['String']
  socialMediaBranded: Scalars['String']
  socialMediaNonBranded: Scalars['String']
}

export type EditMusicLibraryTrackConfigInput = {
  advertisementAllMedia: Scalars['String']
  advertisementAudioOnly: Scalars['String']
  advertisementOnlineOnly: Scalars['String']
  corporateNonProfitCharity: Scalars['String']
  corporateNonProfitCompanyPresentation: Scalars['String']
  corporateNonProfitEducational: Scalars['String']
  durations: Array<SyncLicenseDurations>
  enabled: Scalars['Boolean']
  filmLargeAllMedia: Scalars['String']
  filmLargeOnlineOnly: Scalars['String']
  filmLargeTrailer: Scalars['String']
  filmSmallAllMedia: Scalars['String']
  filmSmallOnlineOnly: Scalars['String']
  filmSmallTrailer: Scalars['String']
  gamesAppsInAppGame: Scalars['String']
  gamesAppsTrailerPromo: Scalars['String']
  podcastPrivateBranded: Scalars['String']
  podcastPrivateNonBranded: Scalars['String']
  radioTvAudioOnly: Scalars['String']
  radioTvTrailer: Scalars['String']
  radioTvTvShow: Scalars['String']
  socialMediaBranded: Scalars['String']
  socialMediaNonBranded: Scalars['String']
  trackId: Scalars['ID']
}

export type EditNftCollectionInput = {
  collectionId: Scalars['ID']
  price: Scalars['Float']
}

export type EditProductInput = {
  alternateGenre?: InputMaybe<DistributionGenre>
  alternateSubgenre?: InputMaybe<DistributionSubgenre>
  artwork?: InputMaybe<Scalars['String']>
  catalogNumber: Scalars['String']
  consumerReleaseDate: Scalars['AWSDate']
  copyrightText: Scalars['String']
  copyrightYear: Scalars['Int']
  ean?: InputMaybe<Scalars['String']>
  genre: DistributionGenre
  label: Scalars['String']
  language: Scalars['String']
  name: Scalars['String']
  originalReleaseDate: Scalars['AWSDate']
  parentalAdvisory: ParentalAdvisory
  phonogramCopyrightText: Scalars['String']
  phonogramCopyrightYear: Scalars['Int']
  recordingLocation?: InputMaybe<Scalars['String']>
  recordingYear?: InputMaybe<Scalars['Int']>
  releaseVersion?: InputMaybe<Scalars['String']>
  subgenre?: InputMaybe<DistributionSubgenre>
}

export type EditTrackInput = {
  alternateGenre?: InputMaybe<DistributionGenre>
  alternateSubgenre?: InputMaybe<DistributionSubgenre>
  audio?: InputMaybe<Scalars['String']>
  audioLanguage?: InputMaybe<Scalars['String']>
  bpm: Scalars['Int']
  genre: DistributionGenre
  isrc?: InputMaybe<Scalars['String']>
  language?: InputMaybe<Scalars['String']>
  lyrics?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  originalFilename?: InputMaybe<Scalars['String']>
  parentalAdvisory: ParentalAdvisory
  previewLength?: InputMaybe<Scalars['Int']>
  previewStart: Scalars['Int']
  recordingLocation?: InputMaybe<Scalars['String']>
  recordingYear?: InputMaybe<Scalars['Int']>
  subgenre?: InputMaybe<DistributionSubgenre>
  version?: InputMaybe<Scalars['String']>
}

export type EditUserProfileInput = {
  avatar?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
}

export enum EmotionalDynamics {
  High = 'high',
  Low = 'low',
  Medium = 'medium',
}

export enum EmotionalProfile {
  Balanced = 'balanced',
  Negative = 'negative',
  Positive = 'positive',
  Variable = 'variable',
}

export enum EnergyDynamics {
  High = 'high',
  Low = 'low',
  Medium = 'medium',
}

export enum EnergyLevel {
  High = 'high',
  Low = 'low',
  Medium = 'medium',
  Variable = 'variable',
}

export type File = {
  __typename?: 'File'
  expiresAt: Scalars['String']
  expiresIn: Scalars['Int']
  path: Scalars['String']
  url: Scalars['String']
}

export type FilteredGroupPaginatedResponse = {
  __typename?: 'FilteredGroupPaginatedResponse'
  groups?: Maybe<Array<Maybe<PublicGroup>>>
  totalCount: Scalars['Int']
}

export type FilteredManagerProductsPaginatedResponse = {
  __typename?: 'FilteredManagerProductsPaginatedResponse'
  data?: Maybe<Array<Maybe<Product>>>
  total: Scalars['Int']
}

export type FilteredMusicLibraryTracksPaginatedResponse = {
  __typename?: 'FilteredMusicLibraryTracksPaginatedResponse'
  totalCount: Scalars['Int']
  tracks?: Maybe<Array<PublicTrack>>
}

export type FilteredNftCollectionPaginatedResponse = {
  __typename?: 'FilteredNftCollectionPaginatedResponse'
  collections?: Maybe<Array<Maybe<NonFungibleTokenCollection>>>
  totalCount: Scalars['Int']
}

export type FinalizeDeliveryInput = {
  influencerMessage?: InputMaybe<Scalars['String']>
  url?: InputMaybe<Scalars['String']>
}

export type Followers = {
  __typename?: 'Followers'
  high?: Maybe<Scalars['Int']>
  low?: Maybe<Scalars['Int']>
}

export type FollowersInput = {
  high?: InputMaybe<Scalars['Int']>
  low: Scalars['Int']
}

export type FollowersRange = {
  high?: InputMaybe<Scalars['Int']>
  low?: InputMaybe<Scalars['Int']>
}

export type FugaAsset = {
  __typename?: 'FugaAsset'
  id: Scalars['ID']
  name: Scalars['String']
}

export type FugaAssetArtist = {
  __typename?: 'FugaAssetArtist'
  id: Scalars['ID']
  primary?: Maybe<Scalars['Boolean']>
}

export type FugaAssetArtistInput = {
  id: Scalars['ID']
  primary?: InputMaybe<Scalars['Boolean']>
}

export type FugaAssetInput = {
  alternateGenre?: InputMaybe<DistributionGenre>
  alternateSubgenre?: InputMaybe<DistributionSubgenre>
  artists: Array<FugaAssetArtistInput>
  audio: Scalars['String']
  audioLanguage?: InputMaybe<Scalars['String']>
  contributors: Array<FugaContributorInput>
  genre: DistributionGenre
  isrc?: InputMaybe<Scalars['String']>
  language?: InputMaybe<Scalars['String']>
  lyrics?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  parentalAdvisory: ParentalAdvisory
  phonogramCopyrightText?: InputMaybe<Scalars['String']>
  phonogramCopyrightYear?: InputMaybe<Scalars['Int']>
  previewLength?: InputMaybe<Scalars['Int']>
  previewStart: Scalars['Int']
  recordingLocation?: InputMaybe<Scalars['String']>
  recordingYear?: InputMaybe<Scalars['Int']>
  subgenre?: InputMaybe<DistributionSubgenre>
  version?: InputMaybe<Scalars['String']>
}

export type FugaContributorInput = {
  name: Scalars['String']
  publisher?: InputMaybe<Scalars['String']>
  role: ContributorRole
}

export type FugaDeliveryState = {
  __typename?: 'FugaDeliveryState'
  dsps?: Maybe<Array<Maybe<FugaDsp>>>
  productState?: Maybe<FugaProductState>
}

export type FugaDsp = {
  __typename?: 'FugaDsp'
  dspState?: Maybe<FugaDspState>
  name?: Maybe<Scalars['String']>
}

export enum FugaDspState {
  Added = 'added',
  Blocked = 'blocked',
  Cancelled = 'cancelled',
  Deleted = 'deleted',
  Delivered = 'delivered',
  Error = 'error',
  NotAdded = 'notAdded',
  Processing = 'processing',
  Redelivered = 'redelivered',
  Rejected = 'rejected',
  Scheduled = 'scheduled',
  Stalled = 'stalled',
  TakenDown = 'takenDown',
  Unblocked = 'unblocked',
  Unknown = 'unknown',
}

export type FugaLabel = {
  __typename?: 'FugaLabel'
  id: Scalars['ID']
  name: Scalars['String']
}

export type FugaLabelInput = {
  name: Scalars['String']
}

export type FugaProduct = {
  __typename?: 'FugaProduct'
  id: Scalars['ID']
  name: Scalars['String']
}

export type FugaProductArtist = {
  id: Scalars['ID']
  primary?: InputMaybe<Scalars['Boolean']>
}

export enum FugaProductState {
  Deleted = 'deleted',
  Delivered = 'delivered',
  Pending = 'pending',
  Published = 'published',
  Rejected = 'rejected',
  ToBeAccepted = 'toBeAccepted',
}

export enum GeneralTermsStatus {
  AcceptFirstTime = 'acceptFirstTime',
  AcceptUpdate = 'acceptUpdate',
  IsAccepted = 'isAccepted',
}

export type Genre = {
  __typename?: 'Genre'
  id: Scalars['ID']
  name: Scalars['String']
  subgenres?: Maybe<Array<Maybe<Subgenre>>>
}

export enum GenreTag {
  Afro = 'afro',
  Ambient = 'ambient',
  Arab = 'arab',
  Asian = 'asian',
  Blues = 'blues',
  ChildrenJingle = 'childrenJingle',
  Classical = 'classical',
  ElectronicDance = 'electronicDance',
  FolkCountry = 'folkCountry',
  FunkSoul = 'funkSoul',
  Indian = 'indian',
  Jazz = 'jazz',
  Latin = 'latin',
  Metal = 'metal',
  Pop = 'pop',
  RapHipHop = 'rapHipHop',
  Reggae = 'reggae',
  Rnb = 'rnb',
  Rock = 'rock',
  SingerSongwriters = 'singerSongwriters',
  Sound = 'sound',
  Soundtrack = 'soundtrack',
  SpokenWord = 'spokenWord',
}

export type Group = {
  __typename?: 'Group'
  address?: Maybe<Address>
  artistCollaborationSettings?: Maybe<CollaborationSettings>
  avatar?: Maybe<Image>
  bankAccount?: Maybe<BankAccount>
  biography?: Maybe<Scalars['String']>
  brandType?: Maybe<BrandType>
  budgetHigh?: Maybe<Scalars['Int']>
  budgetLow?: Maybe<Scalars['Int']>
  configured: Scalars['Boolean']
  contentTypes?: Maybe<Array<ContentType>>
  countryCode?: Maybe<Scalars['String']>
  createdAt: Scalars['AWSDateTime']
  disabledAt?: Maybe<Scalars['AWSDateTime']>
  genres?: Maybe<Array<GenreTag>>
  id: Scalars['ID']
  influencerCollaborationSettings?: Maybe<CollaborationSettings>
  interests?: Maybe<Array<Interest>>
  invoices?: Maybe<Array<Maybe<Invoice>>>
  manager?: Maybe<Manager>
  members?: Maybe<Array<GroupMember>>
  myMenu?: Maybe<Array<Maybe<MyMenuType>>>
  name: Scalars['String']
  notifications?: Maybe<Array<Maybe<Notification>>>
  oldRoles?: Maybe<Array<OldGroupRoles>>
  paymentSettings?: Maybe<GroupPaymentSettings>
  payoutSettings?: Maybe<GroupPayoutSettings>
  paypalAccount?: Maybe<PaypalAccount>
  platformAccounts?: Maybe<Array<PlatformAccount>>
  role?: Maybe<GroupRole>
  slug: Scalars['String']
  supportedChats: SupportedChats
  updatedAt: Scalars['AWSDateTime']
  vatDetails?: Maybe<VatDetails>
  website?: Maybe<Scalars['String']>
}

export type GroupChannel = {
  __typename?: 'GroupChannel'
  channelUrl: Scalars['String']
  currentGroupMember: GroupMember
}

export type GroupFilters = {
  collaborationTypes: Array<InputMaybe<CollaborationType>>
  contentCategories: Array<InputMaybe<Scalars['String']>>
  followersRanges: Array<InputMaybe<FollowersRange>>
  genres: Array<InputMaybe<Scalars['ID']>>
  locations: Array<InputMaybe<Scalars['String']>>
  platforms: Array<InputMaybe<PlatformId>>
  roles: Array<InputMaybe<CollaborationSettingsGroupRole>>
  search?: InputMaybe<Scalars['String']>
}

export type GroupInput = {
  avatar?: InputMaybe<Scalars['String']>
  biography?: InputMaybe<Scalars['String']>
  brandType?: InputMaybe<BrandType>
  budgetHigh?: InputMaybe<Scalars['Int']>
  budgetLow?: InputMaybe<Scalars['Int']>
  contentTypes?: InputMaybe<Array<ContentType>>
  countryCode: Scalars['String']
  genres?: InputMaybe<Array<DistributionGenre>>
  interests?: InputMaybe<Array<Interest>>
  name: Scalars['String']
  role: GroupRole
  slug?: InputMaybe<Scalars['String']>
  website?: InputMaybe<Scalars['String']>
}

export type GroupMember = {
  __typename?: 'GroupMember'
  groupId: Scalars['ID']
  role: GroupMemberRole
  sendbirdId?: Maybe<Scalars['String']>
  share?: Maybe<Scalars['Float']>
  userId: Scalars['ID']
  userProfile?: Maybe<UserProfile>
}

export type GroupMemberInput = {
  role: GroupMemberRoleInput
}

export type GroupMemberInvitation = {
  __typename?: 'GroupMemberInvitation'
  email: Scalars['ID']
  expiresAt: Scalars['String']
  groupId: Scalars['ID']
  id: Scalars['ID']
}

export type GroupMemberInvitationInput = {
  email: Scalars['String']
}

export enum GroupMemberRole {
  Admin = 'admin',
  Member = 'member',
  Owner = 'owner',
}

export enum GroupMemberRoleInput {
  Admin = 'admin',
  Member = 'member',
}

export type GroupPaymentSettings = {
  __typename?: 'GroupPaymentSettings'
  entityType: PaymentSettingsEntityType
  groupId: Scalars['ID']
  id: Scalars['ID']
}

export type GroupPaymentSettingsInput = {
  entityType: PaymentSettingsEntityType
}

export type GroupPayoutSettings = {
  __typename?: 'GroupPayoutSettings'
  groupId: Scalars['ID']
  id: Scalars['ID']
  payoutType: GroupPayoutSettingsType
}

export type GroupPayoutSettingsInput = {
  payoutType: GroupPayoutSettingsType
}

export enum GroupPayoutSettingsType {
  Group = 'group',
  User = 'user',
}

export enum GroupRole {
  Artist = 'artist',
  Creative = 'creative',
  Fan = 'fan',
  Influencer = 'influencer',
  Label = 'label',
}

export type GroupShare = {
  __typename?: 'GroupShare'
  acceptedAt?: Maybe<Scalars['AWSDateTime']>
  createdAt: Scalars['AWSDateTime']
  declinedAt?: Maybe<Scalars['AWSDateTime']>
  group?: Maybe<PublicGroup>
  groupId: Scalars['ID']
  share: Scalars['Float']
  trackId: Scalars['ID']
  type: RoyaltyRevenueType
  version: Scalars['Int']
}

export type GroupShareInput = {
  groupId: Scalars['ID']
  share: Scalars['Float']
  type: RoyaltyRevenueType
}

export enum GroupSortOptions {
  AgeAsc = 'AGE_ASC',
  AgeDesc = 'AGE_DESC',
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  FollowersAsc = 'FOLLOWERS_ASC',
  FollowersDesc = 'FOLLOWERS_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  PopularAsc = 'POPULAR_ASC',
  PopularDesc = 'POPULAR_DESC',
}

export type GroupUserShare = {
  __typename?: 'GroupUserShare'
  groupId: Scalars['ID']
  id: Scalars['ID']
  share: Scalars['Float']
  userId: Scalars['ID']
  userProfile?: Maybe<UserProfile>
}

export type GroupUserShareInput = {
  share: Scalars['Float']
  userId: Scalars['ID']
}

export type IbanBankAccountDetails = {
  __typename?: 'IbanBankAccountDetails'
  iban: Scalars['String']
}

export type Image = {
  __typename?: 'Image'
  large?: Maybe<File>
  medium?: Maybe<File>
  small?: Maybe<File>
}

export enum InstrumentPresenceLabel {
  Absent = 'absent',
  Frequently = 'frequently',
  Partially = 'partially',
  Throughout = 'throughout',
}

export enum InstrumentTag {
  Accordion = 'accordion',
  AcousticGuitar = 'acousticGuitar',
  AfricanPercussion = 'africanPercussion',
  AsianFlute = 'asianFlute',
  AsianStrings = 'asianStrings',
  Banjo = 'banjo',
  Bass = 'bass',
  BassGuitar = 'bassGuitar',
  Bells = 'bells',
  BongoConga = 'bongoConga',
  Brass = 'brass',
  Celeste = 'celeste',
  Cello = 'cello',
  ChurchOrgan = 'churchOrgan',
  Clarinet = 'clarinet',
  DoubleBass = 'doubleBass',
  DrumKit = 'drumKit',
  ElectricGuitar = 'electricGuitar',
  ElectricOrgan = 'electricOrgan',
  ElectricPiano = 'electricPiano',
  ElectronicDrums = 'electronicDrums',
  Flute = 'flute',
  FrenchHorn = 'frenchHorn',
  Glockenspiel = 'glockenspiel',
  Harp = 'harp',
  Harpsichord = 'harpsichord',
  LuteOud = 'luteOud',
  Mandolin = 'mandolin',
  Marimba = 'marimba',
  Oboe = 'oboe',
  Percussion = 'percussion',
  Piano = 'piano',
  Pizzicato = 'pizzicato',
  Sax = 'sax',
  Sitar = 'sitar',
  SteelDrums = 'steelDrums',
  Strings = 'strings',
  Synth = 'synth',
  Tabla = 'tabla',
  Taiko = 'taiko',
  Trumpet = 'trumpet',
  Tuba = 'tuba',
  Ukulele = 'ukulele',
  Vibraphone = 'vibraphone',
  Violin = 'violin',
  Woodwinds = 'woodwinds',
  Xylophone = 'xylophone',
}

export enum Interest {
  Distribution = 'distribution',
  Licensing = 'licensing',
  Nft = 'nft',
  Promotion = 'promotion',
}

export type Invoice = {
  __typename?: 'Invoice'
  amount?: Maybe<Scalars['Float']>
  id: Scalars['ID']
  invoiceNumber?: Maybe<Scalars['String']>
  paymentId?: Maybe<Scalars['String']>
  pdf?: Maybe<File>
  processedAt?: Maybe<Scalars['AWSDateTime']>
  requestedBy?: Maybe<Scalars['String']>
  type?: Maybe<InvoiceType>
}

export enum InvoiceType {
  FlatFee = 'flatFee',
  Nft = 'nft',
  SelfBilling = 'selfBilling',
  SyncLicense = 'syncLicense',
}

export type IssueNewApprovalInput = {
  channels?: InputMaybe<Array<Scalars['String']>>
  productId: Scalars['ID']
}

export enum KeyPredictionValue {
  AMajor = 'aMajor',
  AMinor = 'aMinor',
  AbMajor = 'abMajor',
  BMajor = 'bMajor',
  BMinor = 'bMinor',
  BbMajor = 'bbMajor',
  BbMinor = 'bbMinor',
  CMajor = 'cMajor',
  CMinor = 'cMinor',
  CsMinor = 'csMinor',
  DMajor = 'dMajor',
  DMinor = 'dMinor',
  DbMajor = 'dbMajor',
  DsMinor = 'dsMinor',
  EMajor = 'eMajor',
  EMinor = 'eMinor',
  EbMajor = 'ebMajor',
  FMajor = 'fMajor',
  FMinor = 'fMinor',
  FsMajor = 'fsMajor',
  FsMinor = 'fsMinor',
  GMajor = 'gMajor',
  GMinor = 'gMinor',
  GsMinor = 'gsMinor',
}

export type LifetimeRoyaltyEarnings = {
  __typename?: 'LifetimeRoyaltyEarnings'
  amount: Scalars['String']
}

export type Manager = {
  __typename?: 'Manager'
  filteredProducts: FilteredManagerProductsPaginatedResponse
  group: Group
  groupId: Scalars['ID']
  id: Scalars['ID']
  products?: Maybe<Array<Product>>
  tracks?: Maybe<Array<Track>>
}

export type ManagerFilteredProductsArgs = {
  filters?: InputMaybe<ManagerPaginatedProductsFilters>
}

export type ManagerProductsArgs = {
  filters?: InputMaybe<ManagerProductsFilters>
}

export type ManagerPaginatedProductsFilters = {
  isDistributed?: InputMaybe<Scalars['Boolean']>
  offset: Scalars['Int']
  size: Scalars['Int']
}

export type ManagerProductsFilters = {
  isDistributed?: InputMaybe<Scalars['Boolean']>
}

export enum MoodAdvancedTag {
  Adventurous = 'adventurous',
  Aggressive = 'aggressive',
  Agitated = 'agitated',
  Angry = 'angry',
  Anthemic = 'anthemic',
  Anxious = 'anxious',
  AweInspiring = 'aweInspiring',
  Barren = 'barren',
  Bittersweet = 'bittersweet',
  Blue = 'blue',
  Boingy = 'boingy',
  Boisterous = 'boisterous',
  Bright = 'bright',
  Calm = 'calm',
  Celebratory = 'celebratory',
  Cheerful = 'cheerful',
  Cold = 'cold',
  Comedic = 'comedic',
  Concerned = 'concerned',
  Confident = 'confident',
  Contented = 'contented',
  Cool = 'cool',
  Courageous = 'courageous',
  Creepy = 'creepy',
  Dangerous = 'dangerous',
  Dark = 'dark',
  Delicate = 'delicate',
  Depressing = 'depressing',
  Determined = 'determined',
  Dignified = 'dignified',
  Disturbing = 'disturbing',
  Dreamy = 'dreamy',
  Eccentric = 'eccentric',
  Eerie = 'eerie',
  Emotional = 'emotional',
  Energetic = 'energetic',
  Epic = 'epic',
  Euphoric = 'euphoric',
  Evil = 'evil',
  Excited = 'excited',
  Exciting = 'exciting',
  Exhilarating = 'exhilarating',
  Fearful = 'fearful',
  FeelGood = 'feelGood',
  Fiery = 'fiery',
  Frightening = 'frightening',
  Fun = 'fun',
  Funny = 'funny',
  Gloomy = 'gloomy',
  Graceful = 'graceful',
  Happy = 'happy',
  Heavy = 'heavy',
  Heroic = 'heroic',
  Hopeful = 'hopeful',
  Horror = 'horror',
  Innocent = 'innocent',
  Inspirational = 'inspirational',
  Intense = 'intense',
  Intimate = 'intimate',
  Introspective = 'introspective',
  Joyous = 'joyous',
  Kind = 'kind',
  LaidBack = 'laidBack',
  Leisurely = 'leisurely',
  Light = 'light',
  Lighthearted = 'lighthearted',
  Lonely = 'lonely',
  Loving = 'loving',
  Lyrical = 'lyrical',
  Majestic = 'majestic',
  Melancholic = 'melancholic',
  Menacing = 'menacing',
  Mischievous = 'mischievous',
  Motivational = 'motivational',
  Mournful = 'mournful',
  Mysterious = 'mysterious',
  Nervous = 'nervous',
  Nightmarish = 'nightmarish',
  Noble = 'noble',
  Nostalgic = 'nostalgic',
  Ominous = 'ominous',
  Optimistic = 'optimistic',
  PanicStricken = 'panicStricken',
  Passionate = 'passionate',
  Peaceful = 'peaceful',
  Perky = 'perky',
  Playful = 'playful',
  Poignant = 'poignant',
  Ponderous = 'ponderous',
  Positive = 'positive',
  Powerful = 'powerful',
  Prestigious = 'prestigious',
  Proud = 'proud',
  Quiet = 'quiet',
  Quirky = 'quirky',
  Reflective = 'reflective',
  Relaxed = 'relaxed',
  Relentless = 'relentless',
  Resolute = 'resolute',
  Restless = 'restless',
  Rollicking = 'rollicking',
  Romantic = 'romantic',
  Sad = 'sad',
  Scary = 'scary',
  Seductive = 'seductive',
  Sentimental = 'sentimental',
  Serene = 'serene',
  Serious = 'serious',
  Sexy = 'sexy',
  Soaring = 'soaring',
  Soft = 'soft',
  Solemn = 'solemn',
  Soothing = 'soothing',
  Spiritual = 'spiritual',
  Spooky = 'spooky',
  Strange = 'strange',
  Strong = 'strong',
  Supernatural = 'supernatural',
  Suspenseful = 'suspenseful',
  Sweet = 'sweet',
  Tender = 'tender',
  Tense = 'tense',
  Thoughtful = 'thoughtful',
  Tranquil = 'tranquil',
  Triumphant = 'triumphant',
  Upbeat = 'upbeat',
  Uplifting = 'uplifting',
  Victorious = 'victorious',
  Violent = 'violent',
  Warm = 'warm',
  Weird = 'weird',
  Whimsical = 'whimsical',
}

export enum MoodTag {
  Aggressive = 'aggressive',
  Ambiguous = 'ambiguous',
  Calm = 'calm',
  Chilled = 'chilled',
  Dark = 'dark',
  Energetic = 'energetic',
  Epic = 'epic',
  Ethereal = 'ethereal',
  Happy = 'happy',
  Romantic = 'romantic',
  Sad = 'sad',
  Scary = 'scary',
  Sexy = 'sexy',
  Uplifting = 'uplifting',
}

export enum MovementTag {
  Bouncy = 'bouncy',
  Driving = 'driving',
  Flowing = 'flowing',
  Groovy = 'groovy',
  Nonrhythmic = 'nonrhythmic',
  Pulsing = 'pulsing',
  Robotic = 'robotic',
  Running = 'running',
  Steady = 'steady',
  Stomping = 'stomping',
}

export type MusicLibraryArtist = {
  __typename?: 'MusicLibraryArtist'
  biography?: Maybe<Scalars['String']>
  countryCode?: Maybe<Scalars['String']>
  genres: Array<Scalars['String']>
  id: Scalars['ID']
  imageUrl?: Maybe<Scalars['String']>
  name: Scalars['String']
  settings: MusicLibrarySettings
  slug: Scalars['String']
  socialAccounts?: Maybe<Array<MusicLibrarySocialAccount>>
  website?: Maybe<Scalars['String']>
}

export type MusicLibraryArtistInput = {
  _?: InputMaybe<Scalars['Boolean']>
}

export type MusicLibraryArtistReference = {
  __typename?: 'MusicLibraryArtistReference'
  id: Scalars['String']
  imageUrl?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  primary?: Maybe<Scalars['Boolean']>
  slug: Scalars['String']
}

export type MusicLibraryAutocompleteInput = {
  search: Scalars['String']
  size?: InputMaybe<Scalars['Int']>
}

export type MusicLibraryAutocompleteKeyword = {
  __typename?: 'MusicLibraryAutocompleteKeyword'
  name: Scalars['String']
}

export type MusicLibraryAutocompleteNameWithImage = {
  __typename?: 'MusicLibraryAutocompleteNameWithImage'
  id: Scalars['String']
  imageUrl?: Maybe<Scalars['String']>
  name: Scalars['String']
}

export type MusicLibraryAutocompleteRelease = {
  __typename?: 'MusicLibraryAutocompleteRelease'
  id: Scalars['String']
  imageUrl?: Maybe<Scalars['String']>
  name: Scalars['String']
}

export type MusicLibraryAutocompleteResponse = {
  __typename?: 'MusicLibraryAutocompleteResponse'
  artists?: Maybe<Array<MusicLibraryAutocompleteNameWithImage>>
  characters?: Maybe<Array<MusicLibraryAutocompleteKeyword>>
  genres?: Maybe<Array<MusicLibraryAutocompleteKeyword>>
  keywords?: Maybe<Array<MusicLibraryAutocompleteKeyword>>
  moods?: Maybe<Array<MusicLibraryAutocompleteKeyword>>
  movements?: Maybe<Array<MusicLibraryAutocompleteKeyword>>
  tracks?: Maybe<Array<MusicLibraryAutocompleteTrack>>
}

export type MusicLibraryAutocompleteTrack = {
  __typename?: 'MusicLibraryAutocompleteTrack'
  artists: Array<MusicLibraryAutocompleteNameWithImage>
  id: Scalars['String']
  name: Scalars['String']
  release: MusicLibraryAutocompleteRelease
}

export type MusicLibraryBucketFacet = {
  __typename?: 'MusicLibraryBucketFacet'
  count: Scalars['Int']
  key: Scalars['String']
}

export type MusicLibraryBulkImportInput = {
  fileName: Scalars['String']
}

export enum MusicLibraryClearanceEditType {
  Approve = 'approve',
  Decline = 'decline',
}

export enum MusicLibraryClearanceType {
  Approved = 'approved',
  Declined = 'declined',
  None = 'none',
  Pending = 'pending',
}

export enum MusicLibraryConfigSetting {
  Product = 'product',
  Track = 'track',
}

export type MusicLibraryContributor = {
  __typename?: 'MusicLibraryContributor'
  name?: Maybe<Scalars['String']>
  publisher?: Maybe<Scalars['String']>
  role?: Maybe<MusicLibraryContributorRole>
}

export enum MusicLibraryContributorRole {
  Composer = 'composer',
  Lyricist = 'lyricist',
  Producer = 'producer',
  Publisher = 'publisher',
  Remixer = 'remixer',
  Writer = 'writer',
}

export type MusicLibraryDecimalRangeFacet = {
  __typename?: 'MusicLibraryDecimalRangeFacet'
  count: Scalars['Int']
  key: Scalars['String']
  max: Scalars['Float']
  min: Scalars['Float']
}

export type MusicLibraryDiscoverItem = {
  __typename?: 'MusicLibraryDiscoverItem'
  artistName: Scalars['String']
  artworkImageUrl?: Maybe<Scalars['String']>
  backgroundImageUrl: Scalars['String']
  id: Scalars['ID']
  name: Scalars['String']
  playlistId?: Maybe<Scalars['String']>
  productId?: Maybe<Scalars['String']>
  trackId?: Maybe<Scalars['String']>
  type: MusicLibraryDiscoverItemType
}

export enum MusicLibraryDiscoverItemType {
  Playlist = 'playlist',
  Product = 'product',
  Track = 'track',
}

export type MusicLibraryDiscoverItems = {
  __typename?: 'MusicLibraryDiscoverItems'
  hits: Array<MusicLibraryDiscoverItem>
}

export type MusicLibraryFilterSettings = {
  __typename?: 'MusicLibraryFilterSettings'
  genres?: Maybe<Array<Maybe<GenreTag>>>
  instruments?: Maybe<Array<Maybe<InstrumentTag>>>
  keys?: Maybe<Array<Maybe<KeyPredictionValue>>>
  keywords?: Maybe<Array<Maybe<CyaniteMetaDataKeyword>>>
  moods?: Maybe<Array<Maybe<MoodTag>>>
  predominantVoiceGenders?: Maybe<Array<Maybe<PredominantVoiceGender>>>
  subgenres?: Maybe<Array<Maybe<SubgenreTag>>>
}

export type MusicLibraryFiltersInput = {
  arousal?: InputMaybe<MusicLibraryFloatRangeFilter>
  artistIds?: InputMaybe<MusicLibraryTermsFilter>
  audioLength?: InputMaybe<MusicLibraryIntRangeFilter>
  bpm?: InputMaybe<MusicLibraryIntRangeFilter>
  characters?: InputMaybe<MusicLibraryTermsFilter>
  contentType?: InputMaybe<MusicLibraryTermsFilter>
  genres?: InputMaybe<MusicLibraryTermsFilter>
  ids?: InputMaybe<MusicLibraryTermsFilter>
  instruments?: InputMaybe<MusicLibraryTermsFilter>
  keys?: InputMaybe<MusicLibraryTermsFilter>
  keywords?: InputMaybe<MusicLibraryTermsFilter>
  licenseFee?: InputMaybe<MusicLibraryLicenseFeeFilter>
  moods?: InputMaybe<MusicLibraryTermsFilter>
  movements?: InputMaybe<MusicLibraryTermsFilter>
  predominantVoiceGender?: InputMaybe<MusicLibraryTermsFilter>
  recordingYear?: InputMaybe<MusicLibraryIntRangeFilter>
  valence?: InputMaybe<MusicLibraryFloatRangeFilter>
  voiceoverDegree?: InputMaybe<MusicLibraryFloatRangeFilter>
}

export type MusicLibraryFloatRangeFilter = {
  max?: InputMaybe<Scalars['Float']>
  min?: InputMaybe<Scalars['Float']>
}

export type MusicLibraryInput = {
  discoverItemsSize?: InputMaybe<Scalars['Int']>
  facetSize?: InputMaybe<Scalars['Int']>
  filters?: InputMaybe<MusicLibraryFiltersInput>
  page?: InputMaybe<Scalars['Int']>
  pageSize?: InputMaybe<Scalars['Int']>
  playlistsSize?: InputMaybe<Scalars['Int']>
  sort?: InputMaybe<MusicLibrarySort>
}

export type MusicLibraryIntRangeFilter = {
  max?: InputMaybe<Scalars['Int']>
  min?: InputMaybe<Scalars['Int']>
}

export type MusicLibraryKey = {
  __typename?: 'MusicLibraryKey'
  predictionConfidence?: Maybe<Scalars['Float']>
  predictionValue?: Maybe<Scalars['String']>
}

export type MusicLibraryKeyword = {
  __typename?: 'MusicLibraryKeyword'
  value: Scalars['String']
}

export type MusicLibraryLicenseBasePrices = {
  __typename?: 'MusicLibraryLicenseBasePrices'
  advertisementAllMedia: Scalars['String']
  advertisementAudioOnly: Scalars['String']
  advertisementOnlineOnly: Scalars['String']
  corporateNonProfitCharity: Scalars['String']
  corporateNonProfitCompanyPresentation: Scalars['String']
  corporateNonProfitEducational: Scalars['String']
  filmLargeAllMedia: Scalars['String']
  filmLargeOnlineOnly: Scalars['String']
  filmLargeTrailer: Scalars['String']
  filmSmallAllMedia: Scalars['String']
  filmSmallOnlineOnly: Scalars['String']
  filmSmallTrailer: Scalars['String']
  gamesAppsInAppGame: Scalars['String']
  gamesAppsTrailerPromo: Scalars['String']
  podcastPrivateBranded: Scalars['String']
  podcastPrivateNonBranded: Scalars['String']
  radioTvAudioOnly: Scalars['String']
  radioTvTrailer: Scalars['String']
  radioTvTvShow: Scalars['String']
  socialMediaBranded: Scalars['String']
  socialMediaNonBranded: Scalars['String']
}

export type MusicLibraryLicenseFee = {
  __typename?: 'MusicLibraryLicenseFee'
  amount: Scalars['Float']
  licenseType: Scalars['String']
}

export type MusicLibraryLicenseFeeFacet = {
  __typename?: 'MusicLibraryLicenseFeeFacet'
  buckets: Array<MusicLibraryBucketFacet>
  count: Scalars['Int']
  key: Scalars['String']
  max?: Maybe<Scalars['Float']>
  min: Scalars['Float']
}

export type MusicLibraryLicenseFeeFilter = {
  licenseType: Scalars['String']
  range: MusicLibraryFloatRangeFilter
}

export type MusicLibraryPlaylist = {
  __typename?: 'MusicLibraryPlaylist'
  id: Scalars['ID']
  imageUrl?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  query?: Maybe<Scalars['String']>
}

export type MusicLibraryPlaylists = {
  __typename?: 'MusicLibraryPlaylists'
  hits: Array<MusicLibraryPlaylist>
}

export type MusicLibraryPriceRange = {
  from?: InputMaybe<Scalars['Float']>
  to?: InputMaybe<Scalars['Float']>
}

export type MusicLibraryProductConfig = {
  __typename?: 'MusicLibraryProductConfig'
  advertisementAllMedia: Scalars['String']
  advertisementAudioOnly: Scalars['String']
  advertisementOnlineOnly: Scalars['String']
  corporateNonProfitCharity: Scalars['String']
  corporateNonProfitCompanyPresentation: Scalars['String']
  corporateNonProfitEducational: Scalars['String']
  durations: Array<SyncLicenseDurations>
  enabled: Scalars['Boolean']
  filmLargeAllMedia: Scalars['String']
  filmLargeOnlineOnly: Scalars['String']
  filmLargeTrailer: Scalars['String']
  filmSmallAllMedia: Scalars['String']
  filmSmallOnlineOnly: Scalars['String']
  filmSmallTrailer: Scalars['String']
  gamesAppsInAppGame: Scalars['String']
  gamesAppsTrailerPromo: Scalars['String']
  id: Scalars['ID']
  podcastPrivateBranded: Scalars['String']
  podcastPrivateNonBranded: Scalars['String']
  product: PublicProduct
  radioTvAudioOnly: Scalars['String']
  radioTvTrailer: Scalars['String']
  radioTvTvShow: Scalars['String']
  socialMediaBranded: Scalars['String']
  socialMediaNonBranded: Scalars['String']
}

export type MusicLibraryRange = {
  __typename?: 'MusicLibraryRange'
  key: Scalars['String']
  max?: Maybe<Scalars['Int']>
  min: Scalars['Int']
}

export type MusicLibraryRangeFacet = {
  __typename?: 'MusicLibraryRangeFacet'
  count: Scalars['Int']
  key: Scalars['String']
  max?: Maybe<Scalars['Int']>
  min: Scalars['Int']
}

export type MusicLibraryRelatedArtistsInput = {
  size: Scalars['Int']
}

export type MusicLibraryRelatedArtistsResponse = {
  __typename?: 'MusicLibraryRelatedArtistsResponse'
  hits: Array<MusicLibraryArtistReference>
}

export type MusicLibraryRelatedTracksInput = {
  size: Scalars['Int']
}

export type MusicLibraryRelatedTracksResponse = {
  __typename?: 'MusicLibraryRelatedTracksResponse'
  hits: Array<MusicLibraryTrack>
}

export type MusicLibraryReleaseReference = {
  __typename?: 'MusicLibraryReleaseReference'
  id: Scalars['String']
  imageUrl?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type MusicLibraryResponse = {
  __typename?: 'MusicLibraryResponse'
  discoverItems?: Maybe<MusicLibraryDiscoverItems>
  facets?: Maybe<MusicLibraryTrackFacets>
  hits: Array<MusicLibraryTrack>
  playlists?: Maybe<MusicLibraryPlaylists>
  ranges?: Maybe<MusicLibraryTrackRanges>
  total: Scalars['Int']
}

export type MusicLibrarySettings = {
  __typename?: 'MusicLibrarySettings'
  _?: Maybe<Scalars['Boolean']>
}

export type MusicLibrarySocialAccount = {
  __typename?: 'MusicLibrarySocialAccount'
  avatarUrl?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  platform?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  verified?: Maybe<Scalars['Boolean']>
}

export enum MusicLibrarySort {
  PopularAsc = 'POPULAR_ASC',
  PopularDesc = 'POPULAR_DESC',
}

export enum MusicLibrarySortOption {
  ConsumerDateAsc = 'CONSUMER_DATE_ASC',
  ConsumerDateDesc = 'CONSUMER_DATE_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  PopularAsc = 'POPULAR_ASC',
  PopularDesc = 'POPULAR_DESC',
}

export type MusicLibraryTermsFilter = {
  terms?: InputMaybe<Array<Scalars['String']>>
}

export type MusicLibraryTrack = {
  __typename?: 'MusicLibraryTrack'
  arousal?: Maybe<Scalars['Float']>
  artists?: Maybe<Array<MusicLibraryArtistReference>>
  audioLength?: Maybe<Scalars['Int']>
  audioPreview?: Maybe<MusicLibraryTrackAudioPreview>
  audioWaveForm?: Maybe<Array<Scalars['Float']>>
  bpm?: Maybe<Scalars['Int']>
  characters?: Maybe<Array<MusicLibraryKeyword>>
  contributors?: Maybe<Array<MusicLibraryContributor>>
  emotionalDynamics?: Maybe<MusicLibraryKeyword>
  emotionalProfile?: Maybe<MusicLibraryKeyword>
  energyDynamics?: Maybe<MusicLibraryKeyword>
  energyLevel?: Maybe<MusicLibraryKeyword>
  genres?: Maybe<Array<MusicLibraryKeyword>>
  id: Scalars['ID']
  instruments?: Maybe<Array<MusicLibraryKeyword>>
  isrc?: Maybe<Scalars['String']>
  key?: Maybe<MusicLibraryKey>
  keywords?: Maybe<Array<MusicLibraryKeyword>>
  licenseFees?: Maybe<Array<MusicLibraryLicenseFee>>
  moods?: Maybe<Array<MusicLibraryKeyword>>
  movements?: Maybe<Array<MusicLibraryKeyword>>
  musicalEraTag?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  predominantVoiceGender?: Maybe<MusicLibraryKeyword>
  previewAudio?: Maybe<Scalars['String']>
  rating?: Maybe<Scalars['Float']>
  recordingLocation?: Maybe<Scalars['String']>
  recordingYear?: Maybe<Scalars['Int']>
  release?: Maybe<MusicLibraryReleaseReference>
  releaseDate?: Maybe<Scalars['String']>
  valence?: Maybe<Scalars['Float']>
  version?: Maybe<Scalars['String']>
  voiceoverDegree?: Maybe<Scalars['Float']>
  voiceoverExist?: Maybe<Scalars['Boolean']>
}

export type MusicLibraryTrackAudioPreview = {
  __typename?: 'MusicLibraryTrackAudioPreview'
  length?: Maybe<Scalars['Int']>
  start?: Maybe<Scalars['Int']>
}

export type MusicLibraryTrackConfig = {
  __typename?: 'MusicLibraryTrackConfig'
  advertisementAllMedia: Scalars['String']
  advertisementAudioOnly: Scalars['String']
  advertisementOnlineOnly: Scalars['String']
  corporateNonProfitCharity: Scalars['String']
  corporateNonProfitCompanyPresentation: Scalars['String']
  corporateNonProfitEducational: Scalars['String']
  createdAt: Scalars['AWSDateTime']
  durations: Array<SyncLicenseDurations>
  enabled: Scalars['Boolean']
  filmLargeAllMedia: Scalars['String']
  filmLargeOnlineOnly: Scalars['String']
  filmLargeTrailer: Scalars['String']
  filmSmallAllMedia: Scalars['String']
  filmSmallOnlineOnly: Scalars['String']
  filmSmallTrailer: Scalars['String']
  gamesAppsInAppGame: Scalars['String']
  gamesAppsTrailerPromo: Scalars['String']
  id: Scalars['ID']
  podcastPrivateBranded: Scalars['String']
  podcastPrivateNonBranded: Scalars['String']
  radioTvAudioOnly: Scalars['String']
  radioTvTrailer: Scalars['String']
  radioTvTvShow: Scalars['String']
  socialMediaBranded: Scalars['String']
  socialMediaNonBranded: Scalars['String']
  submittedAt?: Maybe<Scalars['AWSDateTime']>
  track: PublicTrack
  updatedAt: Scalars['AWSDateTime']
}

export type MusicLibraryTrackFacets = {
  __typename?: 'MusicLibraryTrackFacets'
  audioLength: Array<MusicLibraryRangeFacet>
  bpm: Array<MusicLibraryRangeFacet>
  characters: Array<MusicLibraryBucketFacet>
  contentType: Array<MusicLibraryBucketFacet>
  genres: Array<MusicLibraryBucketFacet>
  instruments: Array<MusicLibraryBucketFacet>
  keys: Array<MusicLibraryBucketFacet>
  keywords: Array<MusicLibraryBucketFacet>
  licenseFees: Array<MusicLibraryLicenseFeeFacet>
  moods: Array<MusicLibraryBucketFacet>
  movements: Array<MusicLibraryBucketFacet>
  predominantVoiceGender: Array<MusicLibraryBucketFacet>
}

export type MusicLibraryTrackFilters = {
  bpmRange?: InputMaybe<MusicLibraryTrackRange>
  contentType?: InputMaybe<SyncLicenseCategory>
  genres?: InputMaybe<Array<GenreTag>>
  instruments?: InputMaybe<Array<InstrumentTag>>
  keys?: InputMaybe<Array<KeyPredictionValue>>
  keywords?: InputMaybe<Array<Scalars['String']>>
  moods?: InputMaybe<Array<MoodTag>>
  priceRange?: InputMaybe<MusicLibraryPriceRange>
  search?: InputMaybe<Scalars['String']>
  subgenres?: InputMaybe<Array<SubgenreTag>>
  trackLength?: InputMaybe<MusicLibraryTrackRange>
  voiceGenders?: InputMaybe<Array<PredominantVoiceGender>>
}

export type MusicLibraryTrackRange = {
  from?: InputMaybe<Scalars['Int']>
  to?: InputMaybe<Scalars['Int']>
}

export type MusicLibraryTrackRanges = {
  __typename?: 'MusicLibraryTrackRanges'
  audioLength: Array<MusicLibraryRange>
  bpm: Array<MusicLibraryRange>
  licenseFees: Array<MusicLibraryRange>
}

export type Mutation = {
  __typename?: 'Mutation'
  acceptGeneralTerms?: Maybe<UserProfile>
  acceptGroupMemberInvitation?: Maybe<PublicGroupMember>
  addAcceptedTerms?: Maybe<Array<Maybe<AcceptedTerms>>>
  addArtist?: Maybe<Artist>
  addCollaboration?: Maybe<Collaboration>
  addCollaborationSettings?: Maybe<CollaborationSettings>
  addContributor?: Maybe<Contributor>
  addDraftDelivery?: Maybe<ProofOfDelivery>
  addFugaAsset?: Maybe<FugaAsset>
  addFugaProduct?: Maybe<FugaProduct>
  addGroup?: Maybe<Group>
  addGroupAddress?: Maybe<Address>
  addGroupBankAccount?: Maybe<BankAccount>
  addGroupPaymentSettings?: Maybe<GroupPaymentSettings>
  addGroupPayoutSettings?: Maybe<GroupPayoutSettings>
  addGroupPaypalAccount?: Maybe<PaypalAccount>
  addGroupUserDivision?: Maybe<Array<Maybe<GroupUserShare>>>
  addGroupVatDetails?: Maybe<VatDetails>
  addManager?: Maybe<Manager>
  addMyAddress?: Maybe<Address>
  addMyBankAccount?: Maybe<BankAccount>
  addMyPaymentSettings?: Maybe<UserPaymentSettings>
  addMyPaypalAccount?: Maybe<PaypalAccount>
  addMyUserProfile?: Maybe<UserProfile>
  addMyVatDetails?: Maybe<VatDetails>
  addPlatformAccount?: Maybe<PlatformAccount>
  addProduct?: Maybe<Product>
  addProductArtist?: Maybe<ProductArtist>
  addProductGroupDivision?: Maybe<Array<Maybe<GroupShare>>>
  addTrack?: Maybe<Track>
  addTrackArtist?: Maybe<TrackArtist>
  addTrackGroupDivision?: Maybe<Array<Maybe<GroupShare>>>
  addUserProfileAndGroup?: Maybe<UserProfile>
  adminAddPaymentPriceModelPromotion: PaymentPriceModelPromotion
  adminAddPlaylist?: Maybe<Playlist>
  adminAnonymizeUserProfile?: Maybe<UserProfile>
  adminArtistsBulkImport?: Maybe<Scalars['Boolean']>
  adminCancelCollaboration?: Maybe<Collaboration>
  adminCreateDiscoverItem: AdminDiscoverItem
  adminDeleteDiscoverItem: AdminDiscoverItem
  adminDeletePaymentPriceModelPromotion: PaymentPriceModelPromotion
  adminDisableGroup?: Maybe<Group>
  adminDisableMusicLibraryProductConfig?: Maybe<MusicLibraryProductConfig>
  adminDisableMusicLibraryTrackConfig?: Maybe<MusicLibraryTrackConfig>
  adminDisableUserProfile?: Maybe<UserProfile>
  adminDisableUserProfiles?: Maybe<Array<Maybe<UserProfile>>>
  adminEditPlaylist?: Maybe<Playlist>
  adminEnableGroup?: Maybe<Group>
  adminEnableUserProfile?: Maybe<UserProfile>
  adminHardRemoveProduct?: Maybe<Scalars['Boolean']>
  adminLinkArtistToArtistGroup?: Maybe<Artist>
  adminMusicLibraryBulkImport?: Maybe<Scalars['Boolean']>
  adminRecoverProduct?: Maybe<Product>
  adminRemovePlaylist?: Maybe<Playlist>
  adminRemoveProduct?: Maybe<Product>
  adminRemoveProducts?: Maybe<Array<Maybe<Product>>>
  adminSyncFugaProduct?: Maybe<Product>
  adminTriggerQCMigration?: Maybe<Product>
  adminUpdateDiscoverItem: AdminDiscoverItem
  adminUpdateGroupRating?: Maybe<AdminGroup>
  approveProduct: Array<ProductApprovalStatus>
  capturePaypalOrder?: Maybe<Scalars['Boolean']>
  claimAvailableProductCodes?: Maybe<Array<Maybe<ProductCode>>>
  claimAvailableTrackCodes?: Maybe<Array<Maybe<TrackCode>>>
  claimNft?: Maybe<ClaimNftResponse>
  closeCollaboration?: Maybe<Collaboration>
  connectWallet?: Maybe<UserProfile>
  createCollaborationCounterProposal?: Maybe<Collaboration>
  createCollaborationOrder?: Maybe<Scalars['String']>
  createFlatFeeOrder?: Maybe<CreateFlatFeeOrderResponse>
  createNftCollection?: Maybe<NonFungibleTokenCollection>
  createNftPurchaseOrder?: Maybe<CreateNftPurchaseOrderResponse>
  createSyncLicenseOrder?: Maybe<CreateSyncLicenseOrderResponse>
  disableMusicLibraryProductConfig?: Maybe<MusicLibraryProductConfig>
  disableMusicLibraryTrackConfig?: Maybe<MusicLibraryTrackConfig>
  disconnectWallet?: Maybe<UserProfile>
  editArtist?: Maybe<Artist>
  editCollaborationSettings?: Maybe<CollaborationSettings>
  editContributor?: Maybe<Contributor>
  editFugaAsset?: Maybe<FugaAsset>
  editFugaAssetIsrc?: Maybe<FugaAsset>
  editFugaProduct?: Maybe<FugaProduct>
  editFugaProductUpc?: Maybe<FugaProduct>
  editGroup?: Maybe<Group>
  editGroupPaymentSettings?: Maybe<GroupPaymentSettings>
  editGroupPayoutSettings?: Maybe<GroupPayoutSettings>
  editGroupRole?: Maybe<Scalars['Boolean']>
  editMusicLibraryConfigSetting?: Maybe<Product>
  editMusicLibraryProductConfig?: Maybe<MusicLibraryProductConfig>
  editMusicLibraryTrackConfig?: Maybe<MusicLibraryTrackConfig>
  editMyPaymentSettings?: Maybe<UserPaymentSettings>
  editMyUserProfile?: Maybe<UserProfile>
  editNftCollection?: Maybe<NonFungibleTokenCollection>
  editPlatformAccount?: Maybe<PlatformAccount>
  editProduct?: Maybe<Product>
  editPublishingTrackGroupShare?: Maybe<GroupShare>
  editTrack?: Maybe<Track>
  enableMusicLibraryProductConfig?: Maybe<MusicLibraryProductConfig>
  enableMusicLibraryTrackConfig?: Maybe<MusicLibraryTrackConfig>
  finalizeDelivery?: Maybe<ProofOfDelivery>
  inviteGroupMember?: Maybe<GroupMemberInvitation>
  issueNewApproval: Array<ProductApprovalStatus>
  publishProduct?: Maybe<Product>
  refreshSendbirdSession: SendbirdSession
  rejectProduct: Array<ProductApprovalStatus>
  removeContributor?: Maybe<Contributor>
  removeGroupMember?: Maybe<GroupMember>
  removePaypalMerchant?: Maybe<Array<Maybe<CollaborationSettings>>>
  removePlatformAccount?: Maybe<PlatformAccount>
  removeProductArtist?: Maybe<ProductArtist>
  removeTrackArtist?: Maybe<TrackArtist>
  resendEmailVerification?: Maybe<Scalars['Boolean']>
  reviewDraftDelivery?: Maybe<ProofOfDelivery>
  searchSpotifyArtists: SearchSpotifyArtistsResult
  setProductPaymentPreference?: Maybe<Product>
  startChat?: Maybe<GroupChannel>
  startPaypalOnboarding?: Maybe<PaypalRedirect>
  submitCampaignRequest: NewCampaignSubmissionResult
  syncFugaProduct?: Maybe<Product>
  updatePaypalMerchant?: Maybe<Array<Maybe<CollaborationSettings>>>
  updateTrackOrder?: Maybe<Product>
  updateTrackRating?: Maybe<AdminTrack>
  verifyGroupNameAndSlug?: Maybe<VerifyGroupNameAndSlugResult>
  verifySocialAccounts: VerifySocialAccountsResult
  withdrawGroupPayoutBalance?: Maybe<PayoutBalance>
  withdrawMyPayoutBalance?: Maybe<PayoutBalance>
}

export type MutationAcceptGroupMemberInvitationArgs = {
  code: Scalars['ID']
  email: Scalars['String']
}

export type MutationAddAcceptedTermsArgs = {
  input?: InputMaybe<AcceptedTermsInput>
}

export type MutationAddArtistArgs = {
  input: ArtistInput
  managerId: Scalars['ID']
}

export type MutationAddCollaborationArgs = {
  input?: InputMaybe<CollaborationInput>
}

export type MutationAddCollaborationSettingsArgs = {
  groupId: Scalars['ID']
  input: CollaborationSettingsInput
}

export type MutationAddContributorArgs = {
  input: ContributorInput
  trackId: Scalars['ID']
}

export type MutationAddDraftDeliveryArgs = {
  collaborationPostId: Scalars['ID']
  input?: InputMaybe<AddDraftDeliveryInput>
}

export type MutationAddFugaAssetArgs = {
  fugaProductId: Scalars['ID']
  input: FugaAssetInput
  trackId: Scalars['ID']
}

export type MutationAddFugaProductArgs = {
  input: AddFugaProductInput
  productId: Scalars['ID']
}

export type MutationAddGroupArgs = {
  input: GroupInput
}

export type MutationAddGroupAddressArgs = {
  groupId: Scalars['ID']
  input: AddressInput
}

export type MutationAddGroupBankAccountArgs = {
  groupId: Scalars['ID']
  input: BankAccountInput
}

export type MutationAddGroupPaymentSettingsArgs = {
  groupId: Scalars['ID']
  input: GroupPaymentSettingsInput
}

export type MutationAddGroupPayoutSettingsArgs = {
  groupId: Scalars['ID']
  input: GroupPayoutSettingsInput
}

export type MutationAddGroupPaypalAccountArgs = {
  groupId: Scalars['ID']
  input: PaypalAccountInput
}

export type MutationAddGroupUserDivisionArgs = {
  groupId: Scalars['ID']
  input?: InputMaybe<Array<GroupUserShareInput>>
}

export type MutationAddGroupVatDetailsArgs = {
  groupId: Scalars['ID']
  input: VatDetailsInput
}

export type MutationAddManagerArgs = {
  groupId: Scalars['ID']
}

export type MutationAddMyAddressArgs = {
  input: AddressInput
}

export type MutationAddMyBankAccountArgs = {
  input: BankAccountInput
}

export type MutationAddMyPaymentSettingsArgs = {
  input: UserPaymentSettingsInput
}

export type MutationAddMyPaypalAccountArgs = {
  input: PaypalAccountInput
}

export type MutationAddMyUserProfileArgs = {
  input: AddUserProfileInput
}

export type MutationAddMyVatDetailsArgs = {
  input: VatDetailsInput
}

export type MutationAddPlatformAccountArgs = {
  groupId: Scalars['ID']
  input: PlatformAccountInput
}

export type MutationAddProductArgs = {
  input: AddProductInput
  managerId: Scalars['ID']
}

export type MutationAddProductArtistArgs = {
  artistId: Scalars['ID']
  input: ProductArtistInput
  productId: Scalars['ID']
}

export type MutationAddProductGroupDivisionArgs = {
  groupId: Scalars['ID']
  input?: InputMaybe<Array<GroupShareInput>>
  productId: Scalars['ID']
}

export type MutationAddTrackArgs = {
  input: AddTrackInput
  managerId: Scalars['ID']
  productId: Scalars['ID']
}

export type MutationAddTrackArtistArgs = {
  artistId: Scalars['ID']
  input: TrackArtistInput
  trackId: Scalars['ID']
}

export type MutationAddTrackGroupDivisionArgs = {
  groupId: Scalars['ID']
  input?: InputMaybe<Array<TrackGroupShareInput>>
  productId: Scalars['ID']
}

export type MutationAddUserProfileAndGroupArgs = {
  input: AddUserProfileAndGroupInput
}

export type MutationAdminAddPaymentPriceModelPromotionArgs = {
  input: AdminAddPaymentPriceModelPromotionInput
}

export type MutationAdminAddPlaylistArgs = {
  input?: InputMaybe<PlaylistInput>
}

export type MutationAdminAnonymizeUserProfileArgs = {
  userId: Scalars['ID']
}

export type MutationAdminArtistsBulkImportArgs = {
  input?: InputMaybe<ArtistsBulkImportInput>
}

export type MutationAdminCancelCollaborationArgs = {
  collaborationId: Scalars['ID']
}

export type MutationAdminCreateDiscoverItemArgs = {
  input: AdminCreateOrUpdateDiscoverItemInput
}

export type MutationAdminDeleteDiscoverItemArgs = {
  id: Scalars['ID']
}

export type MutationAdminDeletePaymentPriceModelPromotionArgs = {
  id: Scalars['ID']
}

export type MutationAdminDisableGroupArgs = {
  groupId: Scalars['ID']
}

export type MutationAdminDisableMusicLibraryProductConfigArgs = {
  productId: Scalars['ID']
}

export type MutationAdminDisableMusicLibraryTrackConfigArgs = {
  trackId: Scalars['ID']
}

export type MutationAdminDisableUserProfileArgs = {
  userId: Scalars['ID']
}

export type MutationAdminDisableUserProfilesArgs = {
  userIds: Array<Scalars['ID']>
}

export type MutationAdminEditPlaylistArgs = {
  id: Scalars['ID']
  input?: InputMaybe<PlaylistInput>
}

export type MutationAdminEnableGroupArgs = {
  groupId: Scalars['ID']
}

export type MutationAdminEnableUserProfileArgs = {
  userId: Scalars['ID']
}

export type MutationAdminHardRemoveProductArgs = {
  id: Scalars['ID']
}

export type MutationAdminLinkArtistToArtistGroupArgs = {
  artistId?: InputMaybe<Scalars['ID']>
  groupId: Scalars['ID']
}

export type MutationAdminMusicLibraryBulkImportArgs = {
  input?: InputMaybe<MusicLibraryBulkImportInput>
}

export type MutationAdminRecoverProductArgs = {
  id: Scalars['ID']
}

export type MutationAdminRemovePlaylistArgs = {
  id: Scalars['ID']
}

export type MutationAdminRemoveProductArgs = {
  id: Scalars['ID']
}

export type MutationAdminRemoveProductsArgs = {
  ids: Array<Scalars['ID']>
}

export type MutationAdminSyncFugaProductArgs = {
  id: Scalars['ID']
}

export type MutationAdminTriggerQcMigrationArgs = {
  id: Scalars['ID']
}

export type MutationAdminUpdateDiscoverItemArgs = {
  id: Scalars['ID']
  input: AdminCreateOrUpdateDiscoverItemInput
}

export type MutationAdminUpdateGroupRatingArgs = {
  groupId: Scalars['ID']
  rating?: InputMaybe<Scalars['Int']>
}

export type MutationApproveProductArgs = {
  input: AcceptProductInput
}

export type MutationCapturePaypalOrderArgs = {
  paypalOrderId?: InputMaybe<Scalars['String']>
}

export type MutationClaimAvailableProductCodesArgs = {
  amount: Scalars['Int']
}

export type MutationClaimAvailableTrackCodesArgs = {
  amount: Scalars['Int']
}

export type MutationClaimNftArgs = {
  nftId: Scalars['ID']
  signature?: InputMaybe<Scalars['String']>
}

export type MutationCloseCollaborationArgs = {
  collaborationId: Scalars['ID']
  collaborationTermsId: Scalars['ID']
  input?: InputMaybe<CollaborationCloseInput>
}

export type MutationConnectWalletArgs = {
  signature?: InputMaybe<Scalars['String']>
  walletAddress?: InputMaybe<Scalars['String']>
}

export type MutationCreateCollaborationCounterProposalArgs = {
  collaborationId: Scalars['ID']
  collaborationTermsId: Scalars['ID']
  input?: InputMaybe<CollaborationCounterProposalInput>
}

export type MutationCreateCollaborationOrderArgs = {
  input?: InputMaybe<CreateCollaborationOrderInput>
}

export type MutationCreateFlatFeeOrderArgs = {
  managerId: Scalars['ID']
  productId: Scalars['ID']
}

export type MutationCreateNftCollectionArgs = {
  input?: InputMaybe<CreateNftCollectionInput>
}

export type MutationCreateNftPurchaseOrderArgs = {
  input?: InputMaybe<CreateNftPurchaseOrderInput>
}

export type MutationCreateSyncLicenseOrderArgs = {
  input?: InputMaybe<CreateSyncLicenseOrderInput>
}

export type MutationDisableMusicLibraryProductConfigArgs = {
  productId: Scalars['ID']
}

export type MutationDisableMusicLibraryTrackConfigArgs = {
  trackId: Scalars['ID']
}

export type MutationEditArtistArgs = {
  id: Scalars['ID']
  input: ArtistInput
}

export type MutationEditCollaborationSettingsArgs = {
  id: Scalars['ID']
  input: CollaborationSettingsInput
}

export type MutationEditContributorArgs = {
  id: Scalars['ID']
  input: ContributorInput
}

export type MutationEditFugaAssetArgs = {
  id: Scalars['ID']
  input: EditFugaAssetInput
}

export type MutationEditFugaAssetIsrcArgs = {
  id: Scalars['ID']
  input: EditFugaAssetIsrcInput
}

export type MutationEditFugaProductArgs = {
  id: Scalars['ID']
  input: EditFugaProductInput
}

export type MutationEditFugaProductUpcArgs = {
  id: Scalars['ID']
  input: EditFugaProductUpcInput
}

export type MutationEditGroupArgs = {
  id: Scalars['ID']
  input: GroupInput
}

export type MutationEditGroupPaymentSettingsArgs = {
  groupId: Scalars['ID']
  input: GroupPaymentSettingsInput
}

export type MutationEditGroupPayoutSettingsArgs = {
  groupId: Scalars['ID']
  input: GroupPayoutSettingsInput
}

export type MutationEditGroupRoleArgs = {
  groupId: Scalars['ID']
  role: GroupRole
}

export type MutationEditMusicLibraryConfigSettingArgs = {
  input?: InputMaybe<EditMusicLibraryConfigSettingInput>
  productId: Scalars['ID']
}

export type MutationEditMusicLibraryProductConfigArgs = {
  input: EditMusicLibraryProductConfigInput
}

export type MutationEditMusicLibraryTrackConfigArgs = {
  input: EditMusicLibraryTrackConfigInput
}

export type MutationEditMyPaymentSettingsArgs = {
  input: UserPaymentSettingsInput
}

export type MutationEditMyUserProfileArgs = {
  input: EditUserProfileInput
}

export type MutationEditNftCollectionArgs = {
  input?: InputMaybe<EditNftCollectionInput>
}

export type MutationEditPlatformAccountArgs = {
  id: Scalars['ID']
  input: PlatformAccountInput
}

export type MutationEditProductArgs = {
  id: Scalars['ID']
  input: EditProductInput
}

export type MutationEditPublishingTrackGroupShareArgs = {
  action: MusicLibraryClearanceEditType
  groupId: Scalars['ID']
  trackId: Scalars['ID']
}

export type MutationEditTrackArgs = {
  id: Scalars['ID']
  input: EditTrackInput
}

export type MutationEnableMusicLibraryProductConfigArgs = {
  productId: Scalars['ID']
}

export type MutationEnableMusicLibraryTrackConfigArgs = {
  trackId: Scalars['ID']
}

export type MutationFinalizeDeliveryArgs = {
  id: Scalars['ID']
  input?: InputMaybe<FinalizeDeliveryInput>
}

export type MutationInviteGroupMemberArgs = {
  groupId: Scalars['ID']
  input: GroupMemberInvitationInput
}

export type MutationIssueNewApprovalArgs = {
  input: IssueNewApprovalInput
}

export type MutationPublishProductArgs = {
  id: Scalars['ID']
  input: PublishProductInput
}

export type MutationRefreshSendbirdSessionArgs = {
  input: RefreshSendbirdSessionInput
}

export type MutationRejectProductArgs = {
  input: RejectProductInput
}

export type MutationRemoveContributorArgs = {
  id: Scalars['ID']
}

export type MutationRemoveGroupMemberArgs = {
  groupId: Scalars['ID']
  userId: Scalars['ID']
}

export type MutationRemovePaypalMerchantArgs = {
  groupId: Scalars['ID']
}

export type MutationRemovePlatformAccountArgs = {
  id: Scalars['ID']
}

export type MutationRemoveProductArtistArgs = {
  artistId: Scalars['ID']
  productId: Scalars['ID']
}

export type MutationRemoveTrackArtistArgs = {
  artistId: Scalars['ID']
  trackId: Scalars['ID']
}

export type MutationResendEmailVerificationArgs = {
  input: ResendEmailVerificationInput
}

export type MutationReviewDraftDeliveryArgs = {
  id: Scalars['ID']
  input?: InputMaybe<ReviewDraftDeliveryInput>
}

export type MutationSearchSpotifyArtistsArgs = {
  input: SearchSpotifyArtistsInput
}

export type MutationSetProductPaymentPreferenceArgs = {
  id: Scalars['ID']
  input: ProductPaymentPreference
}

export type MutationStartChatArgs = {
  input: ChatInput
}

export type MutationStartPaypalOnboardingArgs = {
  groupId: Scalars['ID']
  url: Scalars['String']
}

export type MutationSubmitCampaignRequestArgs = {
  input: NewCampaignSubmissionInput
}

export type MutationSyncFugaProductArgs = {
  id: Scalars['ID']
}

export type MutationUpdatePaypalMerchantArgs = {
  groupId: Scalars['ID']
  paypalMerchantId: Scalars['String']
}

export type MutationUpdateTrackOrderArgs = {
  input: Array<InputMaybe<Scalars['ID']>>
  productId: Scalars['ID']
}

export type MutationUpdateTrackRatingArgs = {
  id: Scalars['ID']
  rating?: InputMaybe<Scalars['Int']>
}

export type MutationVerifyGroupNameAndSlugArgs = {
  input: VerifyGroupNameAndSlugInput
}

export type MutationVerifySocialAccountsArgs = {
  input: VerifySocialAccountsInput
}

export type MutationWithdrawGroupPayoutBalanceArgs = {
  groupId: Scalars['ID']
  payoutMethod: PayoutMethod
}

export type MutationWithdrawMyPayoutBalanceArgs = {
  payoutMethod: PayoutMethod
}

export enum MyMenuType {
  Collabs = 'collabs',
  MlLicenses = 'mlLicenses',
  Nfts = 'nfts',
  PublishingSplits = 'publishingSplits',
  Releases = 'releases',
  RoyaltySplits = 'royaltySplits',
  Stats = 'stats',
}

export type NewCampaignSubmissionInput = {
  data: Array<CampaignSubmissionDataItem>
  subject: Scalars['String']
}

export type NewCampaignSubmissionResult = {
  __typename?: 'NewCampaignSubmissionResult'
  createdAt: Scalars['AWSDateTime']
  submissionId: Scalars['ID']
}

export type NftCollectionFilters = {
  genres: Array<InputMaybe<Scalars['ID']>>
  maximumPrice?: InputMaybe<Scalars['Float']>
  minimumPrice?: InputMaybe<Scalars['Float']>
  textFilter?: InputMaybe<Scalars['String']>
}

export enum NftCollectionSortOptions {
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
}

export type NonFungibleToken = {
  __typename?: 'NonFungibleToken'
  claimedRoyalties: Scalars['Float']
  collection: NonFungibleTokenCollection
  createdAt: Scalars['AWSDateTime']
  editionIndex: Scalars['Int']
  editionsInCollection: Scalars['Int']
  id: Scalars['ID']
  pendingRoyalties: Scalars['Float']
  share: Scalars['Float']
}

export type NonFungibleTokenCollection = {
  __typename?: 'NonFungibleTokenCollection'
  available: Scalars['Int']
  availableTokens?: Maybe<Array<NonFungibleToken>>
  createdAt: Scalars['AWSDateTime']
  id: Scalars['ID']
  index: Scalars['Int']
  pricePerToken: Scalars['Float']
  sharePerToken: Scalars['Float']
  sold: Scalars['Int']
  soldTokens?: Maybe<Array<SoldNonFungibleToken>>
  totalShare: Scalars['Float']
  track: PublicTrack
}

export type NonFungibleTokenPurchase = {
  __typename?: 'NonFungibleTokenPurchase'
  buyer?: Maybe<PublicUserProfile>
  createdAt: Scalars['AWSDateTime']
  id: Scalars['ID']
}

export type NonFungibleTokenStatement = {
  __typename?: 'NonFungibleTokenStatement'
  createdAt?: Maybe<Scalars['AWSDateTime']>
  id: Scalars['ID']
  nonFungibleToken: SoldNonFungibleToken
  payoutAmount: Scalars['Float']
  track: PublicTrack
  trackId: Scalars['ID']
  trackPayoutAmount: Scalars['Float']
}

export type Notification = {
  __typename?: 'Notification'
  count: Scalars['Int']
  type: NotificationType
}

export enum NotificationType {
  PendingPublishingRequests = 'pendingPublishingRequests',
}

export enum OldGroupRoles {
  Artist = 'artist',
  Influencer = 'influencer',
  NftCollector = 'nftCollector',
}

export type Order = {
  __typename?: 'Order'
  createdAt?: Maybe<Scalars['AWSDateTime']>
  id: Scalars['ID']
  paypalTransactions: Array<PaypalTransaction>
  totalAmount: Scalars['Float']
  type: Scalars['String']
  updatedAt?: Maybe<Scalars['AWSDateTime']>
  vatAmount: Scalars['Float']
}

export type PaginationFilter = {
  offset: Scalars['Int']
  size: Scalars['Int']
}

export enum ParentalAdvisory {
  Clean = 'clean',
  Explicit = 'explicit',
  NotExplicit = 'notExplicit',
}

export type PaymentPriceModelPromotion = {
  __typename?: 'PaymentPriceModelPromotion'
  amount?: Maybe<Scalars['Float']>
  createdAt: Scalars['AWSDateTime']
  endDate?: Maybe<Scalars['AWSDateTime']>
  groupId?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  paymentPriceModel: ProductPaymentPriceModel
  percentage?: Maybe<Scalars['Int']>
  startDate: Scalars['AWSDateTime']
  updatedAt: Scalars['AWSDateTime']
}

export enum PaymentSettingsEntityType {
  Business = 'business',
  Personal = 'personal',
}

export type PayoutBalance = {
  __typename?: 'PayoutBalance'
  amount: Scalars['Float']
  currencyCode: CurrencyCode
  withdrawable: Scalars['Boolean']
}

export enum PayoutMethod {
  Paypal = 'paypal',
  Wise = 'wise',
}

export type PaypalAccount = {
  __typename?: 'PaypalAccount'
  email?: Maybe<Scalars['String']>
  id: Scalars['ID']
  paypalId?: Maybe<Scalars['String']>
}

export type PaypalAccountInput = {
  email: Scalars['String']
}

export type PaypalRedirect = {
  __typename?: 'PaypalRedirect'
  link?: Maybe<Scalars['String']>
}

export type PaypalTransaction = {
  __typename?: 'PaypalTransaction'
  createdAt?: Maybe<Scalars['AWSDateTime']>
  id: Scalars['ID']
  orderId?: Maybe<Scalars['ID']>
  paypalCaptureId?: Maybe<Scalars['String']>
  paypalOrderId?: Maybe<Scalars['String']>
  state: Scalars['String']
  updatedAt?: Maybe<Scalars['AWSDateTime']>
}

export type Platform = {
  __typename?: 'Platform'
  id: Scalars['ID']
  name: Scalars['String']
  type: PlatformType
}

export type PlatformAccount = {
  __typename?: 'PlatformAccount'
  data?: Maybe<PlatformAccountData>
  followers?: Maybe<Followers>
  groupId?: Maybe<Scalars['ID']>
  id?: Maybe<Scalars['ID']>
  platform?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export type PlatformAccountData = {
  __typename?: 'PlatformAccountData'
  details?: Maybe<PlatformAccountDetails>
  statistics?: Maybe<PlatformAccountStatistics>
}

export type PlatformAccountDetails = {
  __typename?: 'PlatformAccountDetails'
  avatarUrl?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  verified?: Maybe<Scalars['Boolean']>
}

export type PlatformAccountInput = {
  followers?: InputMaybe<FollowersInput>
  platform: Scalars['String']
  url: Scalars['String']
}

export type PlatformAccountStatistics = {
  __typename?: 'PlatformAccountStatistics'
  contentCount?: Maybe<Scalars['Int']>
  followerCount?: Maybe<Scalars['Int']>
  followingCount?: Maybe<Scalars['Int']>
  likesCount?: Maybe<Scalars['Int']>
  viewCount?: Maybe<Scalars['Int']>
}

export enum PlatformId {
  Facebook = 'facebook',
  Instagram = 'instagram',
  Snapchat = 'snapchat',
  Soundcloud = 'soundcloud',
  Spotify = 'spotify',
  TikTok = 'tikTok',
  Triller = 'triller',
  Twitch = 'twitch',
  Twitter = 'twitter',
  Youtube = 'youtube',
}

export enum PlatformType {
  Social = 'social',
  Streaming = 'streaming',
}

export type Playlist = {
  __typename?: 'Playlist'
  artwork?: Maybe<Image>
  createdAt: Scalars['AWSDateTime']
  darkText: Scalars['Boolean']
  id: Scalars['ID']
  name: Scalars['String']
  position: Scalars['Int']
  query: Scalars['String']
  updatedAt: Scalars['AWSDateTime']
}

export type PlaylistInput = {
  artwork?: InputMaybe<Scalars['String']>
  darkText: Scalars['Boolean']
  name: Scalars['String']
  position: Scalars['Int']
  query: Scalars['String']
}

export type PlaylistPaginationResults = {
  __typename?: 'PlaylistPaginationResults'
  data?: Maybe<Array<Maybe<Playlist>>>
  total: Scalars['Int']
}

export enum PostFrequencyPeriod {
  Monthly = 'monthly',
  Weekly = 'weekly',
  Yearly = 'yearly',
}

export enum PredominantVoiceGender {
  Female = 'female',
  Male = 'male',
  None = 'none',
}

export type Process = {
  __typename?: 'Process'
  attemptedAt?: Maybe<Scalars['AWSDateTime']>
  attempts?: Maybe<Scalars['Int']>
  errorCode?: Maybe<ProcessErrorCode>
  id: Scalars['ID']
  messages?: Maybe<ProcessFailureMessage>
  processedAt?: Maybe<Scalars['AWSDateTime']>
  status?: Maybe<ProcessStatus>
}

export enum ProcessErrorCode {
  ChargedBack = 'chargedBack',
  CyaniteMetaDataError = 'cyaniteMetaDataError',
  FugaDistributionError = 'fugaDistributionError',
  FugaUploadError = 'fugaUploadError',
  InputDataError = 'inputDataError',
  InvalidAmount = 'invalidAmount',
  InvalidBankAccount = 'invalidBankAccount',
  InvalidDivision = 'invalidDivision',
  InvalidPaymentSettings = 'invalidPaymentSettings',
  InvoiceAddressNotFound = 'invoiceAddressNotFound',
  InvoiceBankDetailsNotFound = 'invoiceBankDetailsNotFound',
  InvoiceErrorUnknown = 'invoiceErrorUnknown',
  InvoiceGroupNotFound = 'invoiceGroupNotFound',
  InvoiceInputDataError = 'invoiceInputDataError',
  InvoiceMailCouldNotBeSent = 'invoiceMailCouldNotBeSent',
  InvoicePdfCouldNotBeGenerated = 'invoicePdfCouldNotBeGenerated',
  InvoiceSpecificationError = 'invoiceSpecificationError',
  InvoiceUserNotFound = 'invoiceUserNotFound',
  InvoiceVatDetailsNotFound = 'invoiceVatDetailsNotFound',
  LicenseDocumentAddressNotFound = 'licenseDocumentAddressNotFound',
  LicenseDocumentBankDetailsNotFound = 'licenseDocumentBankDetailsNotFound',
  LicenseDocumentErrorUnknown = 'licenseDocumentErrorUnknown',
  LicenseDocumentPdfCouldNotBeGenerated = 'licenseDocumentPdfCouldNotBeGenerated',
  LicenseDocumentUserNotFound = 'licenseDocumentUserNotFound',
  MaxPayoutRetries = 'maxPayoutRetries',
  NftMintingError = 'nftMintingError',
  NoAddress = 'noAddress',
  NoBankAccount = 'noBankAccount',
  NoGroupUserShares = 'noGroupUserShares',
  NoPaymentSettings = 'noPaymentSettings',
  NoTrackGroupShares = 'noTrackGroupShares',
  NoTrackUserShares = 'noTrackUserShares',
  NoVatDetails = 'noVatDetails',
  TooManyAttempts = 'tooManyAttempts',
  Unknown = 'unknown',
  WithdrawBalanceError = 'withdrawBalanceError',
}

export type ProcessFailureMessage = {
  __typename?: 'ProcessFailureMessage'
  context?: Maybe<Scalars['String']>
  data?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  subject?: Maybe<ProcessFailureSubject>
}

export type ProcessFailureSubject = {
  __typename?: 'ProcessFailureSubject'
  id: Scalars['ID']
  subjectType: ProcessFailureSubjectType
}

export enum ProcessFailureSubjectType {
  Group = 'Group',
  Track = 'Track',
  UserProfile = 'UserProfile',
}

export type ProcessPaginationResults = {
  __typename?: 'ProcessPaginationResults'
  data?: Maybe<Array<Maybe<Process>>>
  total: Scalars['Int']
}

export enum ProcessStatus {
  ActionRequired = 'actionRequired',
  Failed = 'failed',
  InProgress = 'inProgress',
  Open = 'open',
  Pending = 'pending',
  Success = 'success',
  Unknown = 'unknown',
}

export type Product = {
  __typename?: 'Product'
  alternateGenre?: Maybe<Scalars['String']>
  alternateSubgenre?: Maybe<Scalars['String']>
  approvals?: Maybe<Array<ProductApprovalStatus>>
  artwork?: Maybe<Image>
  canBeEdited: Scalars['Boolean']
  canBeHardRemoved: Scalars['Boolean']
  catalogNumber: Scalars['String']
  clearance?: Maybe<MusicLibraryClearanceType>
  consumerReleaseDate: Scalars['AWSDate']
  copyrightText: Scalars['String']
  copyrightYear: Scalars['Int']
  createdAt: Scalars['AWSDateTime']
  deletedAt?: Maybe<Scalars['AWSDateTime']>
  distributeRequest?: Maybe<Scalars['AWSDateTime']>
  distributedAt?: Maybe<Scalars['AWSDateTime']>
  distributionChannels: Array<ProductDistributionChannel>
  distributionExpiryDate?: Maybe<Scalars['AWSDateTime']>
  fugaDeliveryState?: Maybe<FugaDeliveryState>
  fugaId?: Maybe<Scalars['ID']>
  genre: Scalars['String']
  id: Scalars['ID']
  invoiceSetting?: Maybe<ProductPaymentInvoiceSetting>
  isDistributable?: Maybe<Scalars['Boolean']>
  label: Scalars['String']
  language: Scalars['String']
  lastDeliveryDate?: Maybe<Scalars['AWSDateTime']>
  lastEditedAt?: Maybe<Scalars['AWSDateTime']>
  lastProcess?: Maybe<Process>
  managers?: Maybe<Array<Manager>>
  musicLibraryConfigSetting?: Maybe<MusicLibraryConfigSetting>
  musicLibraryProductConfig?: Maybe<MusicLibraryProductConfig>
  name: Scalars['String']
  originalReleaseDate: Scalars['AWSDate']
  parentalAdvisory: ParentalAdvisory
  paymentPriceModel?: Maybe<ProductPaymentPriceModel>
  paymentPriceModelPromotion?: Maybe<PaymentPriceModelPromotion>
  phonogramCopyrightText: Scalars['String']
  phonogramCopyrightYear: Scalars['Int']
  priceModelAmount?: Maybe<Scalars['Float']>
  priceModelCommissionPercentage?: Maybe<Scalars['Int']>
  productArtists?: Maybe<Array<ProductArtist>>
  productCode?: Maybe<ProductCode>
  publishedAt?: Maybe<Scalars['AWSDateTime']>
  recordingLocation?: Maybe<Scalars['String']>
  recordingYear?: Maybe<Scalars['Int']>
  releaseFormat?: Maybe<ReleaseFormat>
  releaseVersion?: Maybe<Scalars['String']>
  subgenre?: Maybe<Scalars['String']>
  tracks?: Maybe<Array<Track>>
  updatedAt: Scalars['AWSDateTime']
  useQCFlow: Scalars['Boolean']
}

export type ProductDistributionChannelsArgs = {
  input?: InputMaybe<ProductDistributionChannelsInput>
}

export type ProductLastProcessArgs = {
  filter?: InputMaybe<ProductLastProcessFilter>
}

export type ProductTracksArgs = {
  filters?: InputMaybe<ProductTracksFilter>
}

export type ProductApprovalStatus = {
  __typename?: 'ProductApprovalStatus'
  channelId: Scalars['String']
  createdAt: Scalars['AWSDateTime']
  id: Scalars['ID']
  latest: Scalars['Boolean']
  productId: Scalars['ID']
  reasons?: Maybe<Array<Maybe<RejectionReason>>>
  status: Scalars['String']
  updatedAt: Scalars['AWSDateTime']
  user?: Maybe<UserProfile>
  userId?: Maybe<Scalars['ID']>
}

export type ProductArtist = {
  __typename?: 'ProductArtist'
  artist: Artist
  artistId: Scalars['ID']
  position: Scalars['Int']
  primary: Scalars['Boolean']
  productId: Scalars['ID']
}

export type ProductArtistInput = {
  position: Scalars['Int']
  primary: Scalars['Boolean']
}

export type ProductCode = {
  __typename?: 'ProductCode'
  code: Scalars['String']
}

export type ProductDistributionChannel = {
  __typename?: 'ProductDistributionChannel'
  channelId: Scalars['String']
  id: Scalars['ID']
  name: Scalars['String']
  status: DistributionChannelStatus
  subChannels: Array<ProductDistributionSubChannel>
}

export type ProductDistributionChannelsFilterInput = {
  channelIds?: InputMaybe<Array<Scalars['String']>>
  status?: InputMaybe<ProductDistributionChannelsStatusFilterInput>
}

export type ProductDistributionChannelsInput = {
  filters?: InputMaybe<ProductDistributionChannelsFilterInput>
}

export type ProductDistributionChannelsStatusFilterInput = {
  exclude?: InputMaybe<Array<DistributionChannelStatus>>
  include?: InputMaybe<Array<DistributionChannelStatus>>
}

export type ProductDistributionSubChannel = {
  __typename?: 'ProductDistributionSubChannel'
  name: Scalars['String']
  status: DistributionChannelStatus
}

export type ProductLastProcessFilter = {
  statuses?: InputMaybe<Array<ProcessStatus>>
}

export type ProductPaginationResults = {
  __typename?: 'ProductPaginationResults'
  data?: Maybe<Array<Maybe<Product>>>
  total: Scalars['Int']
}

export enum ProductPaymentInvoiceSetting {
  Group = 'group',
  User = 'user',
}

export type ProductPaymentPreference = {
  invoiceSetting?: InputMaybe<ProductPaymentInvoiceSetting>
  priceModel?: InputMaybe<ProductPaymentPriceModel>
}

export enum ProductPaymentPriceModel {
  FlatFee = 'flatFee',
  RoyaltyCommission = 'royaltyCommission',
}

export type ProductSearchResults = {
  __typename?: 'ProductSearchResults'
  data?: Maybe<Array<Maybe<Product>>>
  total: Scalars['Int']
}

export type ProductTracksFilter = {
  inMusicLibrary?: InputMaybe<Scalars['Boolean']>
}

export enum ProductsOrder {
  ApprovalStatus = 'APPROVAL_STATUS',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  ReleaseDateAsc = 'RELEASE_DATE_ASC',
  ReleaseDateDesc = 'RELEASE_DATE_DESC',
}

export type ProductsSearchFilter = {
  approvalChannel?: InputMaybe<ChannelFilter>
  offset: Scalars['Int']
  orderBy?: InputMaybe<ProductsOrder>
  productCodes?: InputMaybe<Array<Scalars['String']>>
  search?: InputMaybe<Scalars['String']>
  size: Scalars['Int']
}

export type ProofOfDelivery = {
  __typename?: 'ProofOfDelivery'
  artistMessage?: Maybe<Scalars['String']>
  artistUserId?: Maybe<Scalars['String']>
  created?: Maybe<Scalars['AWSDateTime']>
  draftInfluencerMessage?: Maybe<Scalars['String']>
  draftInfluencerUserId?: Maybe<Scalars['String']>
  draftUrl?: Maybe<Scalars['String']>
  id: Scalars['ID']
  influencerMessage?: Maybe<Scalars['String']>
  influencerUserId?: Maybe<Scalars['String']>
  reasonForDeclining?: Maybe<Scalars['String']>
  status?: Maybe<ProofOfDeliveryStatus>
  url?: Maybe<Scalars['String']>
}

export enum ProofOfDeliveryStatus {
  Closed = 'CLOSED',
  DraftAccepted = 'DRAFT_ACCEPTED',
  DraftDelivered = 'DRAFT_DELIVERED',
  DraftFeedback = 'DRAFT_FEEDBACK',
}

export enum ProofOfDeliveryStatusInput {
  DraftAccepted = 'DRAFT_ACCEPTED',
  DraftFeedback = 'DRAFT_FEEDBACK',
}

export type PublicArtist = {
  __typename?: 'PublicArtist'
  fugaId?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  products?: Maybe<Array<Maybe<PublicProduct>>>
}

export type PublicGroup = {
  __typename?: 'PublicGroup'
  artistCollaborationSettings?: Maybe<CollaborationSettings>
  avatar?: Maybe<Image>
  biography?: Maybe<Scalars['String']>
  countryCode?: Maybe<Scalars['String']>
  id: Scalars['ID']
  influencerCollaborationSettings?: Maybe<CollaborationSettings>
  manager?: Maybe<PublicManager>
  name: Scalars['String']
  oldRoles?: Maybe<Array<OldGroupRoles>>
  platformAccounts?: Maybe<Array<PlatformAccount>>
  role?: Maybe<GroupRole>
  slug: Scalars['String']
  supportedChats: SupportedChats
  website?: Maybe<Scalars['String']>
}

export type PublicGroupMember = {
  __typename?: 'PublicGroupMember'
  groupId: Scalars['ID']
  role: GroupMemberRole
  userId: Scalars['ID']
  userProfile?: Maybe<PublicUserProfile>
}

export type PublicManager = {
  __typename?: 'PublicManager'
  group: PublicGroup
  groupId: Scalars['ID']
  id: Scalars['ID']
  products?: Maybe<Array<Maybe<PublicProduct>>>
}

export type PublicManagerProductsArgs = {
  filters?: InputMaybe<ManagerProductsFilters>
}

export type PublicProduct = {
  __typename?: 'PublicProduct'
  artwork?: Maybe<Image>
  consumerReleaseDate: Scalars['AWSDate']
  copyrightText: Scalars['String']
  copyrightYear: Scalars['Int']
  distributeRequest?: Maybe<Scalars['AWSDateTime']>
  distributedAt?: Maybe<Scalars['AWSDateTime']>
  distributionExpiryDate?: Maybe<Scalars['AWSDateTime']>
  label: Scalars['String']
  name: Scalars['String']
  releaseFormat?: Maybe<ReleaseFormat>
  tracks?: Maybe<Array<Maybe<PublicTrack>>>
}

export type PublicProductTracksArgs = {
  filters?: InputMaybe<ProductTracksFilter>
}

export type PublicTrack = {
  __typename?: 'PublicTrack'
  alternateGenre?: Maybe<DistributionGenre>
  alternateSubgenre?: Maybe<DistributionSubgenre>
  audioLength?: Maybe<Scalars['Int']>
  audioWaveForm?: Maybe<Scalars['String']>
  bpm: Scalars['Int']
  clearance?: Maybe<MusicLibraryClearanceType>
  compressedAudio?: Maybe<File>
  contributors?: Maybe<Array<Contributor>>
  cyaniteMetaData?: Maybe<CyaniteMetaData>
  genre: DistributionGenre
  id: Scalars['ID']
  managers?: Maybe<Array<PublicManager>>
  musicLibraryTrackConfig?: Maybe<MusicLibraryTrackConfig>
  name: Scalars['String']
  previewAudio?: Maybe<File>
  product: PublicProduct
  recordingLocation?: Maybe<Scalars['String']>
  recordingYear?: Maybe<Scalars['Int']>
  subgenre?: Maybe<DistributionSubgenre>
  trackArtists?: Maybe<Array<PublicTrackArtist>>
  trackCode?: Maybe<TrackCode>
  version?: Maybe<Scalars['String']>
}

export type PublicTrackArtist = {
  __typename?: 'PublicTrackArtist'
  artist: PublicArtist
  artistId: Scalars['ID']
  position: Scalars['Int']
  primary: Scalars['Boolean']
  trackId: Scalars['ID']
}

export type PublicTracksSearchResults = {
  __typename?: 'PublicTracksSearchResults'
  data?: Maybe<Array<Maybe<PublicTrack>>>
  total: Scalars['Int']
}

export type PublicUserProfile = {
  __typename?: 'PublicUserProfile'
  avatar?: Maybe<Image>
  city?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  groups?: Maybe<Array<PublicGroup>>
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  userId: Scalars['ID']
}

export type PublishProductInput = {
  channelId: Scalars['String']
}

export type Query = {
  __typename?: 'Query'
  adminGetDiscoverItems: AdminGetDiscoverItemsResponse
  adminGetPaymentPriceModelPromotions: AdminGetPaymentPriceModelPromotionsResponse
  assetsGetTrack?: Maybe<AssetDetails>
  assetsGetTracks?: Maybe<AssetTrackPaginationResults>
  filterAvailableNfts?: Maybe<FilteredNftCollectionPaginatedResponse>
  filterGroups?: Maybe<FilteredGroupPaginatedResponse>
  filterMusicLibraryTracks?: Maybe<FilteredMusicLibraryTracksPaginatedResponse>
  getAdminArtist?: Maybe<AdminArtist>
  getAdminArtists?: Maybe<AdminArtistResults>
  getAdminCollaboration?: Maybe<Collaboration>
  getAdminCollaborations?: Maybe<CollaborationPaginationResults>
  getAdminFailedProcesses?: Maybe<ProcessPaginationResults>
  getAdminGroup?: Maybe<AdminGroup>
  getAdminGroups?: Maybe<Array<Maybe<AdminGroup>>>
  getAdminMonthlyAccounting?: Maybe<AccountingResult>
  getAdminProduct?: Maybe<Product>
  getAdminProducts?: Maybe<ProductPaginationResults>
  getAdminSearchCollaborations?: Maybe<CollaborationSearchResults>
  getAdminSearchGroups?: Maybe<AdminGroupSearchResults>
  getAdminSearchProducts?: Maybe<ProductSearchResults>
  getAdminSearchTracks?: Maybe<AdminTracksSearchResults>
  getAdminSearchUserProfiles?: Maybe<UserProfileSearchResults>
  getAdminSyncLicensesExport: File
  getAdminTrack?: Maybe<AdminTrack>
  getAdminUserProfile?: Maybe<UserProfile>
  getAdminUserProfiles?: Maybe<Array<Maybe<UserProfile>>>
  getArtist?: Maybe<PublicArtist>
  getAvailableContributors: AvailableContributors
  getCollaboration?: Maybe<Collaboration>
  getCollaborationSettings?: Maybe<CollaborationSettings>
  getContentCategories?: Maybe<Array<Maybe<ContentCategory>>>
  getCreatedNftCsv: File
  getFugaTrendsChart?: Maybe<Array<Maybe<AnalyticsChartResponse>>>
  getFugaTrendsChartByTrack?: Maybe<Array<Maybe<AnalyticsChartResponse>>>
  getFugaTrendsList?: Maybe<Array<Maybe<AnalyticsListResponse>>>
  getFugaTrendsListByTrack?: Maybe<Array<Maybe<AnalyticsListResponse>>>
  getFugaTrendsOverview?: Maybe<StatsOverviewResponse>
  getFugaTrendsTotals?: Maybe<AnalyticsTotalsResponse>
  getFugaTrendsTotalsByTrack?: Maybe<AnalyticsTotalsResponse>
  getGenres?: Maybe<Array<Maybe<Genre>>>
  getGroup?: Maybe<PublicGroup>
  getGroupLifetimeRoyaltyEarnings: LifetimeRoyaltyEarnings
  getGroupMLStatementsExport: File
  getGroupNftStatementsExport: File
  getGroupNonFungibleTokenStatements?: Maybe<Array<NonFungibleTokenStatement>>
  getGroupPayoutBalance?: Maybe<PayoutBalance>
  getGroupRoyaltyStatement?: Maybe<RoyaltyStatementDetails>
  getGroupRoyaltyStatements?: Maybe<RoyaltyStatementsPaginatedResponse>
  getGroupSyncLicenseStatements?: Maybe<Array<SyncLicenseStatement>>
  getGroupUserDivision?: Maybe<Array<GroupUserShare>>
  getGroups?: Maybe<Array<Maybe<PublicGroup>>>
  getGroupsByEmail?: Maybe<Array<PublicGroup>>
  getManager?: Maybe<PublicManager>
  getManagers?: Maybe<Array<Maybe<PublicManager>>>
  getMaximumSharableRoyalties?: Maybe<Scalars['Float']>
  getMusicLibraryFilterSettings?: Maybe<MusicLibraryFilterSettings>
  getMusicLibraryLicenseBasePrices: MusicLibraryLicenseBasePrices
  getMusicLibraryLicensePrice: Scalars['Float']
  getMyAcceptedTerms?: Maybe<Array<Maybe<AcceptedTerms>>>
  getMyCollaborations?: Maybe<Array<Maybe<Collaboration>>>
  getMyGroup?: Maybe<Group>
  getMyGroupMember?: Maybe<GroupMember>
  getMyGroupMemberInvitations?: Maybe<Array<Maybe<GroupMemberInvitation>>>
  getMyGroupMembers?: Maybe<Array<Maybe<GroupMember>>>
  getMyGroups?: Maybe<Array<Maybe<Group>>>
  getMyLifetimeRoyaltyEarnings: LifetimeRoyaltyEarnings
  getMyMLStatementsExport: File
  getMyManager?: Maybe<Manager>
  getMyNftStatementsExport: File
  getMyPayoutBalance?: Maybe<PayoutBalance>
  getMyProduct?: Maybe<Product>
  getMyProducts?: Maybe<ProductPaginationResults>
  getMyRoyaltyStatement?: Maybe<RoyaltyStatementDetails>
  getMyRoyaltyStatements?: Maybe<RoyaltyStatementsPaginatedResponse>
  getMySyncLicenses?: Maybe<Array<SyncLicense>>
  getMyTrack?: Maybe<Track>
  getMyTrackWithPublishingGroupShare?: Maybe<TrackGroupShareTrack>
  getMyTracks?: Maybe<Array<Maybe<Track>>>
  getMyTracksWithGroupShares?: Maybe<TrackGroupShareTracksSearchResults>
  getMyUserProfile?: Maybe<UserProfile>
  getNftCollection: NonFungibleTokenCollection
  getNftCollectionsForRelease?: Maybe<Array<Maybe<NonFungibleTokenCollection>>>
  getNftCollectionsForTrack?: Maybe<Array<Maybe<NonFungibleTokenCollection>>>
  getPaymentPriceModelPromotion?: Maybe<PaymentPriceModelPromotion>
  getPlatformAccounts?: Maybe<Array<Maybe<PlatformAccount>>>
  getPlatforms?: Maybe<Array<Maybe<Platform>>>
  getPlaylist?: Maybe<Playlist>
  getPlaylists?: Maybe<PlaylistPaginationResults>
  getProductCsv: File
  getProductGroupDivisions?: Maybe<Array<GroupShare>>
  getProofOfDelivery?: Maybe<ProofOfDelivery>
  getPublicTrack?: Maybe<PublicTrack>
  getRejectionReasons: Array<RejectionReason>
  getRemainingNftShareForTrack: RemainingNftShare
  getSoldNftCsv: File
  getSyncLicense?: Maybe<SyncLicense>
  getTrackGroupDivision?: Maybe<Array<GroupShare>>
  getUserNonFungibleTokenStatements?: Maybe<Array<NonFungibleTokenStatement>>
  getUserProfiles?: Maybe<Array<Maybe<PublicUserProfile>>>
  getUserSyncLicenseStatements?: Maybe<Array<SyncLicenseStatement>>
  musicLibrary: MusicLibraryResponse
  musicLibraryArtist: MusicLibraryArtist
  musicLibraryAutocomplete: MusicLibraryAutocompleteResponse
  musicLibraryPlaylist: MusicLibraryPlaylist
  musicLibraryRelatedArtists: MusicLibraryRelatedArtistsResponse
  musicLibraryRelatedTracks: MusicLibraryRelatedTracksResponse
  searchArtists?: Maybe<ArtistSearchResults>
  searchMusicLibrary?: Maybe<SearchMusicLibraryResponse>
  searchTrackKeywords?: Maybe<Array<Maybe<CyaniteMetaDataKeyword>>>
}

export type QueryAdminGetDiscoverItemsArgs = {
  input: AdminGetDiscoverItemsInput
}

export type QueryAdminGetPaymentPriceModelPromotionsArgs = {
  input: AdminGetPaymentPriceModelPromotionsInput
}

export type QueryAssetsGetTrackArgs = {
  groupId: Scalars['ID']
  trackId: Scalars['ID']
}

export type QueryAssetsGetTracksArgs = {
  filter: AssetsTracksFilter
  groupId: Scalars['ID']
}

export type QueryFilterAvailableNftsArgs = {
  filters?: InputMaybe<NftCollectionFilters>
  page?: InputMaybe<Scalars['Int']>
  size?: InputMaybe<Scalars['Int']>
  sort?: InputMaybe<NftCollectionSortOptions>
}

export type QueryFilterGroupsArgs = {
  filters?: InputMaybe<GroupFilters>
  page?: InputMaybe<Scalars['Int']>
  size?: InputMaybe<Scalars['Int']>
  sort?: InputMaybe<GroupSortOptions>
}

export type QueryFilterMusicLibraryTracksArgs = {
  filters: MusicLibraryTrackFilters
  limit?: InputMaybe<Scalars['Int']>
  page?: InputMaybe<Scalars['Int']>
  size?: InputMaybe<Scalars['Int']>
  sort?: InputMaybe<MusicLibrarySortOption>
}

export type QueryGetAdminArtistArgs = {
  id: Scalars['ID']
}

export type QueryGetAdminArtistsArgs = {
  filter: ArtistSearchFilter
}

export type QueryGetAdminCollaborationArgs = {
  collaborationId: Scalars['ID']
}

export type QueryGetAdminCollaborationsArgs = {
  filter?: InputMaybe<CollaborationPaginationFilter>
}

export type QueryGetAdminFailedProcessesArgs = {
  filter?: InputMaybe<PaginationFilter>
}

export type QueryGetAdminGroupArgs = {
  id: Scalars['ID']
}

export type QueryGetAdminMonthlyAccountingArgs = {
  month?: InputMaybe<Scalars['Int']>
  year?: InputMaybe<Scalars['Int']>
}

export type QueryGetAdminProductArgs = {
  id: Scalars['ID']
}

export type QueryGetAdminProductsArgs = {
  filter?: InputMaybe<PaginationFilter>
}

export type QueryGetAdminSearchCollaborationsArgs = {
  filter?: InputMaybe<CollaborationSearchFilter>
}

export type QueryGetAdminSearchGroupsArgs = {
  filter?: InputMaybe<SearchFilter>
}

export type QueryGetAdminSearchProductsArgs = {
  filter?: InputMaybe<ProductsSearchFilter>
}

export type QueryGetAdminSearchTracksArgs = {
  filter?: InputMaybe<SearchTrackFilter>
  sort?: InputMaybe<TrackSortOptions>
}

export type QueryGetAdminSearchUserProfilesArgs = {
  filter?: InputMaybe<SearchUserFilter>
}

export type QueryGetAdminTrackArgs = {
  id: Scalars['ID']
}

export type QueryGetAdminUserProfileArgs = {
  id: Scalars['ID']
}

export type QueryGetArtistArgs = {
  id: Scalars['ID']
}

export type QueryGetCollaborationArgs = {
  collaborationId: Scalars['ID']
}

export type QueryGetCollaborationSettingsArgs = {
  groupId: Scalars['ID']
  role: CollaborationSettingsGroupRole
}

export type QueryGetFugaTrendsChartArgs = {
  filters?: InputMaybe<AnalyticsFilters>
  groupId: Scalars['ID']
  input?: InputMaybe<AnalyticsChartInput>
}

export type QueryGetFugaTrendsChartByTrackArgs = {
  groupId: Scalars['ID']
  input?: InputMaybe<AnalyticsChartInput>
  trackId: Scalars['ID']
}

export type QueryGetFugaTrendsListArgs = {
  filters?: InputMaybe<AnalyticsFilters>
  groupId: Scalars['ID']
  input?: InputMaybe<AnalyticsListInput>
}

export type QueryGetFugaTrendsListByTrackArgs = {
  groupId: Scalars['ID']
  input?: InputMaybe<AnalyticsListInput>
  trackId: Scalars['ID']
}

export type QueryGetFugaTrendsOverviewArgs = {
  groupId: Scalars['ID']
}

export type QueryGetFugaTrendsTotalsArgs = {
  filters?: InputMaybe<AnalyticsFilters>
  groupId: Scalars['ID']
  input?: InputMaybe<AnalyticsTotalsInput>
}

export type QueryGetFugaTrendsTotalsByTrackArgs = {
  groupId: Scalars['ID']
  input?: InputMaybe<AnalyticsTotalsInput>
  trackId: Scalars['ID']
}

export type QueryGetGroupArgs = {
  id: Scalars['ID']
}

export type QueryGetGroupLifetimeRoyaltyEarningsArgs = {
  groupId: Scalars['ID']
}

export type QueryGetGroupMlStatementsExportArgs = {
  filters?: InputMaybe<GetSyncLicenseStatementsFilters>
  groupId: Scalars['ID']
}

export type QueryGetGroupNftStatementsExportArgs = {
  filters?: InputMaybe<GetNonFungibleTokenStatementsFilters>
  groupId: Scalars['ID']
}

export type QueryGetGroupNonFungibleTokenStatementsArgs = {
  filters: GetNonFungibleTokenStatementsFilters
  groupId: Scalars['ID']
}

export type QueryGetGroupPayoutBalanceArgs = {
  groupId: Scalars['ID']
}

export type QueryGetGroupRoyaltyStatementArgs = {
  groupId: Scalars['ID']
  royaltyStatementId: Scalars['ID']
}

export type QueryGetGroupRoyaltyStatementsArgs = {
  after?: InputMaybe<Scalars['String']>
  first: Scalars['Int']
  groupId: Scalars['ID']
}

export type QueryGetGroupSyncLicenseStatementsArgs = {
  filters: GetSyncLicenseStatementsFilters
  groupId: Scalars['ID']
}

export type QueryGetGroupUserDivisionArgs = {
  groupId: Scalars['ID']
}

export type QueryGetGroupsByEmailArgs = {
  email: Scalars['String']
}

export type QueryGetManagerArgs = {
  id: Scalars['ID']
}

export type QueryGetMaximumSharableRoyaltiesArgs = {
  period?: InputMaybe<DateRange>
  trackId: Scalars['ID']
}

export type QueryGetMusicLibraryLicensePriceArgs = {
  licenseCategory?: InputMaybe<SyncLicenseCategory>
  licenseDuration: SyncLicenseDurations
  licenseSetting: Scalars['String']
  licenseTrackLength: SyncLicenseTrackLengths
}

export type QueryGetMyCollaborationsArgs = {
  groupId: Scalars['ID']
}

export type QueryGetMyGroupArgs = {
  id: Scalars['ID']
}

export type QueryGetMyGroupMemberArgs = {
  groupId: Scalars['ID']
  userId: Scalars['ID']
}

export type QueryGetMyGroupMemberInvitationsArgs = {
  groupId: Scalars['ID']
}

export type QueryGetMyGroupMembersArgs = {
  groupId: Scalars['ID']
}

export type QueryGetMyMlStatementsExportArgs = {
  filters?: InputMaybe<GetSyncLicenseStatementsFilters>
}

export type QueryGetMyManagerArgs = {
  id: Scalars['ID']
}

export type QueryGetMyNftStatementsExportArgs = {
  filters?: InputMaybe<GetNonFungibleTokenStatementsFilters>
}

export type QueryGetMyProductArgs = {
  id: Scalars['ID']
}

export type QueryGetMyProductsArgs = {
  filters?: InputMaybe<PaginationFilter>
  managerId: Scalars['ID']
}

export type QueryGetMyRoyaltyStatementArgs = {
  royaltyStatementId: Scalars['ID']
}

export type QueryGetMyRoyaltyStatementsArgs = {
  after?: InputMaybe<Scalars['String']>
  first: Scalars['Int']
}

export type QueryGetMySyncLicensesArgs = {
  groupId: Scalars['ID']
}

export type QueryGetMyTrackArgs = {
  id: Scalars['ID']
}

export type QueryGetMyTrackWithPublishingGroupShareArgs = {
  groupId: Scalars['ID']
  trackId: Scalars['ID']
}

export type QueryGetMyTracksArgs = {
  productId: Scalars['ID']
}

export type QueryGetMyTracksWithGroupSharesArgs = {
  filter: TrackGroupShareTrackSearchFilter
  groupId: Scalars['ID']
}

export type QueryGetNftCollectionArgs = {
  collectionId: Scalars['ID']
}

export type QueryGetNftCollectionsForReleaseArgs = {
  productId: Scalars['ID']
}

export type QueryGetNftCollectionsForTrackArgs = {
  trackId: Scalars['ID']
}

export type QueryGetPaymentPriceModelPromotionArgs = {
  groupId?: InputMaybe<Scalars['ID']>
}

export type QueryGetPlatformAccountsArgs = {
  groupId: Scalars['ID']
}

export type QueryGetPlaylistArgs = {
  id: Scalars['ID']
}

export type QueryGetPlaylistsArgs = {
  filter?: InputMaybe<PaginationFilter>
}

export type QueryGetProductGroupDivisionsArgs = {
  productId: Scalars['ID']
}

export type QueryGetProofOfDeliveryArgs = {
  id: Scalars['ID']
}

export type QueryGetPublicTrackArgs = {
  id: Scalars['ID']
}

export type QueryGetRemainingNftShareForTrackArgs = {
  trackId: Scalars['ID']
}

export type QueryGetSyncLicenseArgs = {
  syncLicenseId: Scalars['ID']
}

export type QueryGetTrackGroupDivisionArgs = {
  trackId: Scalars['ID']
}

export type QueryGetUserNonFungibleTokenStatementsArgs = {
  filters: GetNonFungibleTokenStatementsFilters
}

export type QueryGetUserSyncLicenseStatementsArgs = {
  filters: GetSyncLicenseStatementsFilters
}

export type QueryMusicLibraryArgs = {
  input: MusicLibraryInput
}

export type QueryMusicLibraryArtistArgs = {
  input: MusicLibraryArtistInput
  slug: Scalars['ID']
}

export type QueryMusicLibraryAutocompleteArgs = {
  input: MusicLibraryAutocompleteInput
}

export type QueryMusicLibraryPlaylistArgs = {
  id: Scalars['ID']
}

export type QueryMusicLibraryRelatedArtistsArgs = {
  artistId: Scalars['ID']
  input: MusicLibraryRelatedArtistsInput
}

export type QueryMusicLibraryRelatedTracksArgs = {
  input: MusicLibraryRelatedTracksInput
  trackId: Scalars['ID']
}

export type QuerySearchArtistsArgs = {
  filter: ArtistSearchFilter
}

export type QuerySearchMusicLibraryArgs = {
  filter?: InputMaybe<SearchMusicLibraryFilter>
}

export type QuerySearchTrackKeywordsArgs = {
  filter?: InputMaybe<SearchTrackKeywordFilter>
}

export type RefreshSendbirdSessionInput = {
  groupId: Scalars['ID']
}

export type RejectProductInput = {
  channels: Array<Scalars['String']>
  productId: Scalars['ID']
  reasons: Array<RejectionReasonInput>
}

export type RejectionReason = {
  __typename?: 'RejectionReason'
  category: Scalars['String']
  importance: Scalars['String']
  internalComment?: Maybe<Scalars['String']>
  reason: Scalars['String']
  userComment?: Maybe<Scalars['String']>
}

export type RejectionReasonInput = {
  category: Scalars['String']
  importance: Scalars['String']
  internalComment?: InputMaybe<Scalars['String']>
  reason: Scalars['String']
  userComment?: InputMaybe<Scalars['String']>
}

export enum ReleaseFormat {
  Album = 'ALBUM',
  Boxset = 'BOXSET',
  Ep = 'EP',
  Single = 'SINGLE',
}

export type RemainingNftShare = {
  __typename?: 'RemainingNftShare'
  share: Scalars['Float']
}

export type ResendEmailVerificationInput = {
  email: Scalars['String']
}

export type ReviewDraftDeliveryInput = {
  artistMessage: Scalars['String']
  reasonForDeclining?: InputMaybe<Scalars['String']>
  status: ProofOfDeliveryStatusInput
}

export enum RoyaltyRevenueType {
  Publishing = 'publishing',
  Recording = 'recording',
}

export type RoyaltyStatement = {
  __typename?: 'RoyaltyStatement'
  amount?: Maybe<Scalars['String']>
  createdAt: Scalars['AWSDateTime']
  csv?: Maybe<File>
  id: Scalars['ID']
  type?: Maybe<RoyaltyStatementType>
}

export type RoyaltyStatementDetails = {
  __typename?: 'RoyaltyStatementDetails'
  amount?: Maybe<Scalars['String']>
  createdAt: Scalars['AWSDateTime']
  csv: File
  id: Scalars['ID']
  type?: Maybe<RoyaltyStatementType>
}

export type RoyaltyStatementEdge = {
  __typename?: 'RoyaltyStatementEdge'
  cursor: Scalars['String']
  node: RoyaltyStatement
}

export type RoyaltyStatementPageInfo = {
  __typename?: 'RoyaltyStatementPageInfo'
  endCursor?: Maybe<Scalars['String']>
  hasNextPage: Scalars['Boolean']
}

export enum RoyaltyStatementType {
  Group = 'group',
  Nft = 'nft',
  User = 'user',
}

export type RoyaltyStatementsPaginatedResponse = {
  __typename?: 'RoyaltyStatementsPaginatedResponse'
  edges: Array<Maybe<RoyaltyStatementEdge>>
  pageInfo: RoyaltyStatementPageInfo
  totalCount: Scalars['Int']
}

export enum SaleType {
  Download = 'download',
  Stream = 'stream',
}

export type SearchFilter = {
  offset: Scalars['Int']
  search?: InputMaybe<Scalars['String']>
  size: Scalars['Int']
}

export type SearchMusicLibraryFilter = {
  search?: InputMaybe<Scalars['String']>
}

export type SearchMusicLibraryResponse = {
  __typename?: 'SearchMusicLibraryResponse'
  artists?: Maybe<Array<PublicArtist>>
}

export type SearchSpotifyArtist = {
  __typename?: 'SearchSpotifyArtist'
  externalUrl: Scalars['String']
  followers: Scalars['Int']
  id: Scalars['ID']
  images: Array<SearchSpotifyImage>
  name: Scalars['String']
  popularity: Scalars['Float']
  uri: Scalars['String']
}

export type SearchSpotifyArtistsInput = {
  limit?: InputMaybe<Scalars['Int']>
  name: Scalars['String']
  offset?: InputMaybe<Scalars['Int']>
}

export type SearchSpotifyArtistsResult = {
  __typename?: 'SearchSpotifyArtistsResult'
  items: Array<SearchSpotifyArtist>
  total: Scalars['Int']
}

export type SearchSpotifyImage = {
  __typename?: 'SearchSpotifyImage'
  height: Scalars['Int']
  url: Scalars['String']
  width: Scalars['Int']
}

export type SearchTrackFilter = {
  hasNfts?: InputMaybe<Scalars['Boolean']>
  inMusicLibrary?: InputMaybe<Scalars['Boolean']>
  offset: Scalars['Int']
  search?: InputMaybe<Scalars['String']>
  size: Scalars['Int']
  trackCodes?: InputMaybe<Array<Scalars['String']>>
}

export type SearchTrackKeywordFilter = {
  offset: Scalars['Int']
  search?: InputMaybe<Scalars['String']>
  size: Scalars['Int']
}

export type SearchUserFilter = {
  disabled?: InputMaybe<Scalars['Boolean']>
  offset: Scalars['Int']
  search?: InputMaybe<Scalars['String']>
  size: Scalars['Int']
}

export enum SelectionType {
  Asset = 'asset',
  Dsp = 'dsp',
  Product = 'product',
  Territory = 'territory',
}

export type SendbirdSession = {
  __typename?: 'SendbirdSession'
  expiresAt: Scalars['AWSDateTime']
  token: Scalars['String']
  userId: Scalars['String']
}

export type SoldNonFungibleToken = {
  __typename?: 'SoldNonFungibleToken'
  claimedRoyalties: Scalars['Float']
  collection: NonFungibleTokenCollection
  createdAt: Scalars['AWSDateTime']
  editionIndex: Scalars['Int']
  editionsInCollection: Scalars['Int']
  id: Scalars['ID']
  mintedAt: Scalars['AWSDateTime']
  pendingRoyalties: Scalars['Float']
  purchase: NonFungibleTokenPurchase
  share: Scalars['Float']
  soldFor: Scalars['Float']
  tokenId: Scalars['String']
}

export type SortCodeBankAccountDetails = {
  __typename?: 'SortCodeBankAccountDetails'
  accountNumber: Scalars['String']
  sortCode: Scalars['String']
}

export type StatsOverviewResponse = {
  __typename?: 'StatsOverviewResponse'
  productStats?: Maybe<Array<Maybe<StatsProductOverviewResponse>>>
}

export type StatsProductOverviewResponse = {
  __typename?: 'StatsProductOverviewResponse'
  product: Product
  stats?: Maybe<Array<Maybe<AnalyticsListResponse>>>
  trackStats?: Maybe<Array<Maybe<StatsTrackOverviewResponse>>>
}

export type StatsTrackOverviewResponse = {
  __typename?: 'StatsTrackOverviewResponse'
  stats?: Maybe<Array<Maybe<AnalyticsListResponse>>>
  track: Track
}

export type Subgenre = {
  __typename?: 'Subgenre'
  genreId?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  name: Scalars['String']
}

export enum SubgenreTag {
  AbstractIdmLeftfield = 'abstractIDMLeftfield',
  Bebop = 'bebop',
  BigBandSwing = 'bigBandSwing',
  BlackMetal = 'blackMetal',
  BluesRock = 'bluesRock',
  BreakbeatDnB = 'breakbeatDnB',
  ContemporaryJazz = 'contemporaryJazz',
  ContemporaryRnB = 'contemporaryRnB',
  Country = 'country',
  DeathMetal = 'deathMetal',
  DeepHouse = 'deepHouse',
  Disco = 'disco',
  DoomMetal = 'doomMetal',
  EasyListening = 'easyListening',
  Electro = 'electro',
  Folk = 'folk',
  FolkRock = 'folkRock',
  Funk = 'funk',
  Fusion = 'fusion',
  Gangsta = 'gangsta',
  Gospel = 'gospel',
  HardRock = 'hardRock',
  HeavyMetal = 'heavyMetal',
  House = 'house',
  IndieAlternative = 'indieAlternative',
  JazzyHipHop = 'jazzyHipHop',
  LatinJazz = 'latinJazz',
  Metalcore = 'metalcore',
  Minimal = 'minimal',
  NeoSoul = 'neoSoul',
  NuMetal = 'nuMetal',
  PopRap = 'popRap',
  PopSoftRock = 'popSoftRock',
  PsychedelicProgressiveRock = 'psychedelicProgressiveRock',
  Punk = 'punk',
  RockAndRoll = 'rockAndRoll',
  SmoothJazz = 'smoothJazz',
  Soul = 'soul',
  SynthPop = 'synthPop',
  TechHouse = 'techHouse',
  Techno = 'techno',
  Trance = 'trance',
  Trap = 'trap',
}

export type SupportedChats = {
  __typename?: 'SupportedChats'
  profileToProfile: Scalars['Boolean']
}

export type SyncLicense = {
  __typename?: 'SyncLicense'
  buyer: PublicUserProfile
  createdAt?: Maybe<Scalars['AWSDateTime']>
  group: PublicGroup
  groupId: Scalars['ID']
  id: Scalars['ID']
  invoice?: Maybe<File>
  licenseCategory: SyncLicenseCategory
  licenseDuration: SyncLicenseDurations
  licenseTrackLength: SyncLicenseTrackLengths
  order: Order
  projectDescription: Scalars['String']
  projectName: Scalars['String']
  receipt?: Maybe<File>
  track: PublicTrack
  trackAudio?: Maybe<File>
  trackId: Scalars['ID']
  updatedAt?: Maybe<Scalars['AWSDateTime']>
}

export enum SyncLicenseCategory {
  AdvertisementAllMedia = 'advertisementAllMedia',
  AdvertisementAudioOnly = 'advertisementAudioOnly',
  AdvertisementOnlineOnly = 'advertisementOnlineOnly',
  CorporateNonProfitCharity = 'corporateNonProfitCharity',
  CorporateNonProfitCompanyPresentation = 'corporateNonProfitCompanyPresentation',
  CorporateNonProfitEducational = 'corporateNonProfitEducational',
  FilmLargeAllMedia = 'filmLargeAllMedia',
  FilmLargeOnlineOnly = 'filmLargeOnlineOnly',
  FilmLargeTrailer = 'filmLargeTrailer',
  FilmSmallAllMedia = 'filmSmallAllMedia',
  FilmSmallOnlineOnly = 'filmSmallOnlineOnly',
  FilmSmallTrailer = 'filmSmallTrailer',
  GamesAppsInAppGame = 'gamesAppsInAppGame',
  GamesAppsTrailerPromo = 'gamesAppsTrailerPromo',
  PodcastPrivateBranded = 'podcastPrivateBranded',
  PodcastPrivateNonBranded = 'podcastPrivateNonBranded',
  RadioTvAudioOnly = 'radioTvAudioOnly',
  RadioTvTrailer = 'radioTvTrailer',
  RadioTvTvShow = 'radioTvTvShow',
  SocialMediaBranded = 'socialMediaBranded',
  SocialMediaNonBranded = 'socialMediaNonBranded',
}

export enum SyncLicenseDurations {
  OneYear = 'oneYear',
  Perpetual = 'perpetual',
  ThreeYears = 'threeYears',
}

export type SyncLicenseStatement = {
  __typename?: 'SyncLicenseStatement'
  createdAt?: Maybe<Scalars['AWSDateTime']>
  id: Scalars['ID']
  payoutAmount: Scalars['Float']
  syncLicense?: Maybe<SyncLicense>
  track: PublicTrack
  trackId: Scalars['ID']
  trackPayoutAmount: Scalars['Float']
}

export enum SyncLicenseTrackLengths {
  LessThan30Seconds = 'lessThan30Seconds',
  MoreThan30Seconds = 'moreThan30Seconds',
  MoreThan60Seconds = 'moreThan60Seconds',
}

export enum TagType {
  Character = 'character',
  Genre = 'genre',
  Instrument = 'instrument',
  Mood = 'mood',
  MoodAdvanced = 'moodAdvanced',
  Movement = 'movement',
  Subgenre = 'subgenre',
}

export type Track = {
  __typename?: 'Track'
  alternateGenre?: Maybe<DistributionGenre>
  alternateSubgenre?: Maybe<DistributionSubgenre>
  audio: File
  audioLanguage?: Maybe<Scalars['String']>
  audioLength?: Maybe<Scalars['Int']>
  audioWaveForm?: Maybe<Scalars['String']>
  bpm: Scalars['Int']
  clearance?: Maybe<MusicLibraryClearanceType>
  collaborations?: Maybe<Array<Maybe<Collaboration>>>
  compressedAudio?: Maybe<File>
  contributors?: Maybe<Array<Contributor>>
  createdAt: Scalars['AWSDateTime']
  cyaniteMetaData?: Maybe<CyaniteMetaData>
  deletedAt?: Maybe<Scalars['AWSDateTime']>
  fugaId?: Maybe<Scalars['ID']>
  genre: DistributionGenre
  groupShares?: Maybe<Array<Maybe<GroupShare>>>
  groups?: Maybe<Array<Maybe<Group>>>
  hasNfts: Scalars['Boolean']
  id: Scalars['ID']
  inMusicLibrary: Scalars['Boolean']
  language?: Maybe<Scalars['String']>
  lyrics?: Maybe<Scalars['String']>
  managers?: Maybe<Array<Manager>>
  musicLibraryTrackConfig?: Maybe<MusicLibraryTrackConfig>
  name: Scalars['String']
  nonFungibleTokenCollections?: Maybe<Array<Maybe<NonFungibleTokenCollection>>>
  originalFilename?: Maybe<Scalars['String']>
  parentalAdvisory: ParentalAdvisory
  previewAudio?: Maybe<File>
  previewLength?: Maybe<Scalars['Int']>
  previewStart: Scalars['Int']
  product?: Maybe<Product>
  recordingLocation?: Maybe<Scalars['String']>
  recordingYear?: Maybe<Scalars['Int']>
  subgenre?: Maybe<DistributionSubgenre>
  trackArtists?: Maybe<Array<TrackArtist>>
  trackCode?: Maybe<TrackCode>
  updatedAt: Scalars['AWSDateTime']
  version?: Maybe<Scalars['String']>
}

export type TrackArtist = {
  __typename?: 'TrackArtist'
  artist: Artist
  artistId: Scalars['ID']
  position: Scalars['Int']
  primary: Scalars['Boolean']
  trackId: Scalars['ID']
}

export type TrackArtistInput = {
  position: Scalars['Int']
  primary: Scalars['Boolean']
}

export type TrackCode = {
  __typename?: 'TrackCode'
  code: Scalars['String']
}

export type TrackGroupShareInput = {
  groupId: Scalars['ID']
  share: Scalars['Float']
  trackId: Scalars['ID']
  type: RoyaltyRevenueType
}

export type TrackGroupShareTrack = {
  __typename?: 'TrackGroupShareTrack'
  alternateGenre?: Maybe<DistributionGenre>
  alternateSubgenre?: Maybe<DistributionSubgenre>
  audioLength?: Maybe<Scalars['Int']>
  audioWaveForm?: Maybe<Scalars['String']>
  bpm: Scalars['Int']
  clearance?: Maybe<MusicLibraryClearanceType>
  compressedAudio?: Maybe<File>
  contributors?: Maybe<Array<Contributor>>
  genre: DistributionGenre
  getCurrentPublishingGroupShares?: Maybe<Array<GroupShare>>
  getMyCurrentPublishingGroupShare: GroupShare
  id: Scalars['ID']
  managers?: Maybe<Array<PublicManager>>
  musicLibraryTrackConfig?: Maybe<MusicLibraryTrackConfig>
  name: Scalars['String']
  previewAudio?: Maybe<File>
  product: PublicProduct
  recordingLocation?: Maybe<Scalars['String']>
  recordingYear?: Maybe<Scalars['Int']>
  subgenre?: Maybe<DistributionSubgenre>
  trackArtists?: Maybe<Array<PublicTrackArtist>>
  trackCode?: Maybe<TrackCode>
  version?: Maybe<Scalars['String']>
}

export type TrackGroupShareTrackGetMyCurrentPublishingGroupShareArgs = {
  groupId: Scalars['ID']
}

export type TrackGroupShareTrackSearchFilter = {
  clearance?: InputMaybe<MusicLibraryClearanceType>
  offset: Scalars['Int']
  size: Scalars['Int']
  type: RoyaltyRevenueType
}

export type TrackGroupShareTracksSearchResults = {
  __typename?: 'TrackGroupShareTracksSearchResults'
  data?: Maybe<Array<Maybe<TrackGroupShareTrack>>>
  total: Scalars['Int']
}

export type TrackRoyalties = {
  __typename?: 'TrackRoyalties'
  group?: Maybe<Array<GroupShare>>
  user?: Maybe<GroupUserShare>
}

export enum TrackSortOptions {
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  NftAsc = 'NFT_ASC',
  NftDesc = 'NFT_DESC',
  RatingAsc = 'RATING_ASC',
  RatingDesc = 'RATING_DESC',
}

export type TracksSearchResults = {
  __typename?: 'TracksSearchResults'
  data?: Maybe<Array<Maybe<Track>>>
  total: Scalars['Int']
}

export type UserPaymentSettings = {
  __typename?: 'UserPaymentSettings'
  entityType: PaymentSettingsEntityType
  id: Scalars['ID']
  userId: Scalars['ID']
}

export type UserPaymentSettingsInput = {
  entityType: PaymentSettingsEntityType
}

export type UserProfile = {
  __typename?: 'UserProfile'
  acceptedTerms?: Maybe<Array<Maybe<AcceptedTerms>>>
  address?: Maybe<Address>
  avatar?: Maybe<Image>
  bankAccount?: Maybe<BankAccount>
  createdAt: Scalars['AWSDateTime']
  disabledAt?: Maybe<Scalars['AWSDateTime']>
  email?: Maybe<Scalars['String']>
  emailVerified?: Maybe<Scalars['Boolean']>
  generalTermsStatus: GeneralTermsStatus
  groups?: Maybe<Array<Maybe<Group>>>
  id: Scalars['ID']
  invoices?: Maybe<Array<Maybe<Invoice>>>
  lastLoginDate?: Maybe<Scalars['AWSDateTime']>
  name?: Maybe<Scalars['String']>
  paymentSettings?: Maybe<UserPaymentSettings>
  paypalAccount?: Maybe<PaypalAccount>
  userId: Scalars['ID']
  vatDetails?: Maybe<VatDetails>
  walletAddress?: Maybe<Scalars['String']>
}

export type UserProfileSearchResults = {
  __typename?: 'UserProfileSearchResults'
  data?: Maybe<Array<Maybe<UserProfile>>>
  total: Scalars['Int']
}

export type VatDetails = {
  __typename?: 'VatDetails'
  chamberOfCommerceNumber?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isVatLiable: Scalars['Boolean']
  vatNumber?: Maybe<Scalars['String']>
}

export type VatDetailsInput = {
  chamberOfCommerceNumber?: InputMaybe<Scalars['String']>
  isVatLiable: Scalars['Boolean']
  vatNumber?: InputMaybe<Scalars['String']>
}

export type VerifyGroupNameAndSlugInput = {
  id?: InputMaybe<Scalars['ID']>
  name: Scalars['String']
  slug: Scalars['String']
}

export type VerifyGroupNameAndSlugResult = {
  __typename?: 'VerifyGroupNameAndSlugResult'
  nameAvailable: Scalars['Boolean']
  normalizedSlug: Scalars['String']
  slugAvailable: Scalars['Boolean']
}

export type VerifySocialAccountInput = {
  platform: PlatformId
  urlOrHandle: Scalars['String']
}

export type VerifySocialAccountResult = {
  __typename?: 'VerifySocialAccountResult'
  data: PlatformAccountData
  platform: PlatformId
  url: Scalars['String']
}

export type VerifySocialAccountsInput = {
  accounts: Array<VerifySocialAccountInput>
}

export type VerifySocialAccountsResult = {
  __typename?: 'VerifySocialAccountsResult'
  results: Array<VerifySocialAccountResult>
}

export type GetNonFungibleTokenStatementsFilters = {
  period?: InputMaybe<DateRange>
}

export type GetSyncLicenseStatementsFilters = {
  period?: InputMaybe<DateRange>
}

export type SearchArtistsQueryVariables = Exact<{
  filter: ArtistSearchFilter
}>

export type SearchArtistsQuery = {
  __typename?: 'Query'
  searchArtists?: {
    __typename?: 'ArtistSearchResults'
    data?: Array<{ __typename?: 'PublicArtist'; id: string; name?: string | null } | null> | null
  } | null
}

export type AddArtistMutationVariables = Exact<{
  input: ArtistInput
  managerId: Scalars['ID']
}>

export type AddArtistMutation = {
  __typename?: 'Mutation'
  addArtist?: { __typename?: 'Artist'; id: string; name?: string | null } | null
}

export type GetArtistQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetArtistQuery = {
  __typename?: 'Query'
  getArtist?: { __typename?: 'PublicArtist'; id: string; fugaId?: string | null } | null
}

export type GetTracksForArtistQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetTracksForArtistQuery = {
  __typename?: 'Query'
  getArtist?: {
    __typename?: 'PublicArtist'
    products?: Array<{
      __typename?: 'PublicProduct'
      tracks?: Array<{ __typename?: 'PublicTrack'; id: string; name: string; version?: string | null } | null> | null
    } | null> | null
  } | null
}

export type AssetsTrackItemFragment = {
  __typename?: 'AssetTrack'
  id: string
  name: string
  fugaId?: string | null
  version?: string | null
  bpm: number
  audioLength?: number | null
  trackArtists?: Array<{
    __typename?: 'PublicTrackArtist'
    primary: boolean
    position: number
    artist: { __typename?: 'PublicArtist'; name?: string | null; fugaId?: string | null; id: string }
  }> | null
  cyaniteMetaData?: {
    __typename?: 'CyaniteMetaData'
    genres?: Array<GenreTag | null> | null
    subgenres?: Array<SubgenreTag | null> | null
    moods?: Array<MoodTag | null> | null
    instruments?: Array<InstrumentTag | null> | null
    keyPredictionValue?: KeyPredictionValue | null
    predominantVoiceGender?: PredominantVoiceGender | null
    bpmRangeAdjusted?: number | null
  } | null
  compressedAudio?: { __typename?: 'File'; expiresAt: string; expiresIn: number; path: string; url: string } | null
  product: {
    __typename?: 'PublicProduct'
    name: string
    label: string
    distributedAt?: any | null
    distributionExpiryDate?: any | null
    consumerReleaseDate: any
    artwork?: {
      __typename?: 'Image'
      small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
      medium?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
      large?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
    } | null
  }
}

export type AssetLicensesFragment = {
  __typename?: 'SyncLicense'
  licenseDuration: SyncLicenseDurations
  licenseCategory: SyncLicenseCategory
  projectDescription: string
  createdAt?: any | null
  receipt?: { __typename?: 'File'; url: string; path: string } | null
  order: { __typename?: 'Order'; totalAmount: number; vatAmount: number }
  trackAudio?: { __typename?: 'File'; url: string; path: string } | null
  track: {
    __typename?: 'PublicTrack'
    id: string
    name: string
    version?: string | null
    trackArtists?: Array<{
      __typename?: 'PublicTrackArtist'
      position: number
      primary: boolean
      artist: { __typename?: 'PublicArtist'; name?: string | null }
    }> | null
  }
}

export type AssetRoyaltySplitsFragment = {
  __typename?: 'GroupShare'
  share: number
  type: RoyaltyRevenueType
  group?: {
    __typename?: 'PublicGroup'
    name: string
    avatar?: {
      __typename?: 'Image'
      small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
    } | null
  } | null
}

export type AssetsGetTracksQueryVariables = Exact<{
  filter: AssetsTracksFilter
  groupId: Scalars['ID']
}>

export type AssetsGetTracksQuery = {
  __typename?: 'Query'
  assetsGetTracks?: {
    __typename?: 'AssetTrackPaginationResults'
    total: number
    data?: Array<{
      __typename?: 'AssetTrack'
      id: string
      name: string
      fugaId?: string | null
      version?: string | null
      bpm: number
      audioLength?: number | null
      trackArtists?: Array<{
        __typename?: 'PublicTrackArtist'
        primary: boolean
        position: number
        artist: { __typename?: 'PublicArtist'; name?: string | null; fugaId?: string | null; id: string }
      }> | null
      cyaniteMetaData?: {
        __typename?: 'CyaniteMetaData'
        genres?: Array<GenreTag | null> | null
        subgenres?: Array<SubgenreTag | null> | null
        moods?: Array<MoodTag | null> | null
        instruments?: Array<InstrumentTag | null> | null
        keyPredictionValue?: KeyPredictionValue | null
        predominantVoiceGender?: PredominantVoiceGender | null
        bpmRangeAdjusted?: number | null
      } | null
      compressedAudio?: { __typename?: 'File'; expiresAt: string; expiresIn: number; path: string; url: string } | null
      product: {
        __typename?: 'PublicProduct'
        name: string
        label: string
        distributedAt?: any | null
        distributionExpiryDate?: any | null
        consumerReleaseDate: any
        artwork?: {
          __typename?: 'Image'
          small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
          medium?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
          large?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
        } | null
      }
    } | null> | null
  } | null
}

export type AssetCollaborationFragment = {
  __typename?: 'Collaboration'
  id: string
  state?: CollaborationState | null
  createdAt: any
  from: {
    __typename?: 'PublicGroup'
    name: string
    id: string
    avatar?: {
      __typename?: 'Image'
      small?: { __typename?: 'File'; url: string; expiresIn: number; expiresAt: string } | null
    } | null
  }
  to: {
    __typename?: 'PublicGroup'
    name: string
    id: string
    avatar?: {
      __typename?: 'Image'
      small?: { __typename?: 'File'; url: string; expiresIn: number; expiresAt: string } | null
    } | null
  }
  terms?: Array<{
    __typename?: 'CollaborationTerms'
    fixedFee?: number | null
    version?: number | null
    royaltySharePercentage?: number | null
    collaborationStartDate?: any | null
    collaborationEndDate?: any | null
    paymentStartDate?: any | null
    paymentEndDate?: any | null
    id: string
    type?: CollaborationType | null
    posts?: Array<{
      __typename?: 'CollaborationPost'
      id: string
      platform?: { __typename?: 'Platform'; type: PlatformType; id: string; name: string } | null
    } | null> | null
  } | null> | null
}

export type AssetNftFragment = {
  __typename?: 'SoldNonFungibleToken'
  claimedRoyalties: number
  pendingRoyalties: number
  id: string
  share: number
  tokenId: string
  mintedAt: any
  editionIndex: number
  editionsInCollection: number
  collection: { __typename?: 'NonFungibleTokenCollection'; index: number }
}

export type ClaimNftMutationVariables = Exact<{
  signature: Scalars['String']
  nftId: Scalars['ID']
}>

export type ClaimNftMutation = {
  __typename?: 'Mutation'
  claimNft?: { __typename?: 'ClaimNftResponse'; total: string } | null
}

export type AssetItemFragment = {
  __typename?: 'AssetDetails'
  track?: {
    __typename?: 'AssetTrack'
    id: string
    name: string
    fugaId?: string | null
    version?: string | null
    bpm: number
    audioLength?: number | null
    trackArtists?: Array<{
      __typename?: 'PublicTrackArtist'
      primary: boolean
      position: number
      artist: { __typename?: 'PublicArtist'; name?: string | null; fugaId?: string | null; id: string }
    }> | null
    cyaniteMetaData?: {
      __typename?: 'CyaniteMetaData'
      genres?: Array<GenreTag | null> | null
      subgenres?: Array<SubgenreTag | null> | null
      moods?: Array<MoodTag | null> | null
      instruments?: Array<InstrumentTag | null> | null
      keyPredictionValue?: KeyPredictionValue | null
      predominantVoiceGender?: PredominantVoiceGender | null
      bpmRangeAdjusted?: number | null
    } | null
    compressedAudio?: { __typename?: 'File'; expiresAt: string; expiresIn: number; path: string; url: string } | null
    product: {
      __typename?: 'PublicProduct'
      name: string
      label: string
      distributedAt?: any | null
      distributionExpiryDate?: any | null
      consumerReleaseDate: any
      artwork?: {
        __typename?: 'Image'
        small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
        medium?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
        large?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
      } | null
    }
  } | null
  licenses?: Array<{
    __typename?: 'SyncLicense'
    licenseDuration: SyncLicenseDurations
    licenseCategory: SyncLicenseCategory
    projectDescription: string
    createdAt?: any | null
    receipt?: { __typename?: 'File'; url: string; path: string } | null
    order: { __typename?: 'Order'; totalAmount: number; vatAmount: number }
    trackAudio?: { __typename?: 'File'; url: string; path: string } | null
    track: {
      __typename?: 'PublicTrack'
      id: string
      name: string
      version?: string | null
      trackArtists?: Array<{
        __typename?: 'PublicTrackArtist'
        position: number
        primary: boolean
        artist: { __typename?: 'PublicArtist'; name?: string | null }
      }> | null
    }
  }> | null
  nfts?: Array<{
    __typename?: 'SoldNonFungibleToken'
    claimedRoyalties: number
    pendingRoyalties: number
    id: string
    share: number
    tokenId: string
    mintedAt: any
    editionIndex: number
    editionsInCollection: number
    collection: { __typename?: 'NonFungibleTokenCollection'; index: number }
  }> | null
  royaltySplits?: {
    __typename?: 'TrackRoyalties'
    group?: Array<{
      __typename?: 'GroupShare'
      share: number
      type: RoyaltyRevenueType
      group?: {
        __typename?: 'PublicGroup'
        name: string
        avatar?: {
          __typename?: 'Image'
          small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
        } | null
      } | null
    }> | null
  } | null
  collaborations?: Array<{
    __typename?: 'Collaboration'
    id: string
    state?: CollaborationState | null
    createdAt: any
    from: {
      __typename?: 'PublicGroup'
      name: string
      id: string
      avatar?: {
        __typename?: 'Image'
        small?: { __typename?: 'File'; url: string; expiresIn: number; expiresAt: string } | null
      } | null
    }
    to: {
      __typename?: 'PublicGroup'
      name: string
      id: string
      avatar?: {
        __typename?: 'Image'
        small?: { __typename?: 'File'; url: string; expiresIn: number; expiresAt: string } | null
      } | null
    }
    terms?: Array<{
      __typename?: 'CollaborationTerms'
      fixedFee?: number | null
      version?: number | null
      royaltySharePercentage?: number | null
      collaborationStartDate?: any | null
      collaborationEndDate?: any | null
      paymentStartDate?: any | null
      paymentEndDate?: any | null
      id: string
      type?: CollaborationType | null
      posts?: Array<{
        __typename?: 'CollaborationPost'
        id: string
        platform?: { __typename?: 'Platform'; type: PlatformType; id: string; name: string } | null
      } | null> | null
    } | null> | null
  }> | null
}

export type AssetsGetTrackQueryVariables = Exact<{
  groupId: Scalars['ID']
  trackId: Scalars['ID']
}>

export type AssetsGetTrackQuery = {
  __typename?: 'Query'
  assetsGetTrack?: {
    __typename?: 'AssetDetails'
    track?: {
      __typename?: 'AssetTrack'
      id: string
      name: string
      fugaId?: string | null
      version?: string | null
      bpm: number
      audioLength?: number | null
      trackArtists?: Array<{
        __typename?: 'PublicTrackArtist'
        primary: boolean
        position: number
        artist: { __typename?: 'PublicArtist'; name?: string | null; fugaId?: string | null; id: string }
      }> | null
      cyaniteMetaData?: {
        __typename?: 'CyaniteMetaData'
        genres?: Array<GenreTag | null> | null
        subgenres?: Array<SubgenreTag | null> | null
        moods?: Array<MoodTag | null> | null
        instruments?: Array<InstrumentTag | null> | null
        keyPredictionValue?: KeyPredictionValue | null
        predominantVoiceGender?: PredominantVoiceGender | null
        bpmRangeAdjusted?: number | null
      } | null
      compressedAudio?: { __typename?: 'File'; expiresAt: string; expiresIn: number; path: string; url: string } | null
      product: {
        __typename?: 'PublicProduct'
        name: string
        label: string
        distributedAt?: any | null
        distributionExpiryDate?: any | null
        consumerReleaseDate: any
        artwork?: {
          __typename?: 'Image'
          small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
          medium?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
          large?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
        } | null
      }
    } | null
    licenses?: Array<{
      __typename?: 'SyncLicense'
      licenseDuration: SyncLicenseDurations
      licenseCategory: SyncLicenseCategory
      projectDescription: string
      createdAt?: any | null
      receipt?: { __typename?: 'File'; url: string; path: string } | null
      order: { __typename?: 'Order'; totalAmount: number; vatAmount: number }
      trackAudio?: { __typename?: 'File'; url: string; path: string } | null
      track: {
        __typename?: 'PublicTrack'
        id: string
        name: string
        version?: string | null
        trackArtists?: Array<{
          __typename?: 'PublicTrackArtist'
          position: number
          primary: boolean
          artist: { __typename?: 'PublicArtist'; name?: string | null }
        }> | null
      }
    }> | null
    nfts?: Array<{
      __typename?: 'SoldNonFungibleToken'
      claimedRoyalties: number
      pendingRoyalties: number
      id: string
      share: number
      tokenId: string
      mintedAt: any
      editionIndex: number
      editionsInCollection: number
      collection: { __typename?: 'NonFungibleTokenCollection'; index: number }
    }> | null
    royaltySplits?: {
      __typename?: 'TrackRoyalties'
      group?: Array<{
        __typename?: 'GroupShare'
        share: number
        type: RoyaltyRevenueType
        group?: {
          __typename?: 'PublicGroup'
          name: string
          avatar?: {
            __typename?: 'Image'
            small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
          } | null
        } | null
      }> | null
    } | null
    collaborations?: Array<{
      __typename?: 'Collaboration'
      id: string
      state?: CollaborationState | null
      createdAt: any
      from: {
        __typename?: 'PublicGroup'
        name: string
        id: string
        avatar?: {
          __typename?: 'Image'
          small?: { __typename?: 'File'; url: string; expiresIn: number; expiresAt: string } | null
        } | null
      }
      to: {
        __typename?: 'PublicGroup'
        name: string
        id: string
        avatar?: {
          __typename?: 'Image'
          small?: { __typename?: 'File'; url: string; expiresIn: number; expiresAt: string } | null
        } | null
      }
      terms?: Array<{
        __typename?: 'CollaborationTerms'
        fixedFee?: number | null
        version?: number | null
        royaltySharePercentage?: number | null
        collaborationStartDate?: any | null
        collaborationEndDate?: any | null
        paymentStartDate?: any | null
        paymentEndDate?: any | null
        id: string
        type?: CollaborationType | null
        posts?: Array<{
          __typename?: 'CollaborationPost'
          id: string
          platform?: { __typename?: 'Platform'; type: PlatformType; id: string; name: string } | null
        } | null> | null
      } | null> | null
    }> | null
  } | null
}

export type GetMyPayoutBalanceQueryVariables = Exact<{ [key: string]: never }>

export type GetMyPayoutBalanceQuery = {
  __typename?: 'Query'
  getMyPayoutBalance?: {
    __typename?: 'PayoutBalance'
    amount: number
    currencyCode: CurrencyCode
    withdrawable: boolean
  } | null
}

export type GetGroupPayoutBalanceQueryVariables = Exact<{
  groupId: Scalars['ID']
}>

export type GetGroupPayoutBalanceQuery = {
  __typename?: 'Query'
  getGroupPayoutBalance?: {
    __typename?: 'PayoutBalance'
    amount: number
    currencyCode: CurrencyCode
    withdrawable: boolean
  } | null
}

export type WithdrawMyPayoutBalanceMutationVariables = Exact<{
  payoutMethod: PayoutMethod
}>

export type WithdrawMyPayoutBalanceMutation = {
  __typename?: 'Mutation'
  withdrawMyPayoutBalance?: {
    __typename?: 'PayoutBalance'
    amount: number
    currencyCode: CurrencyCode
    withdrawable: boolean
  } | null
}

export type WithdrawGroupPayoutBalanceMutationVariables = Exact<{
  payoutMethod: PayoutMethod
  groupId: Scalars['ID']
}>

export type WithdrawGroupPayoutBalanceMutation = {
  __typename?: 'Mutation'
  withdrawGroupPayoutBalance?: {
    __typename?: 'PayoutBalance'
    amount: number
    currencyCode: CurrencyCode
    withdrawable: boolean
  } | null
}

export type CollabTermsFragment = {
  __typename?: 'CollaborationTerms'
  id: string
  version?: number | null
  type?: CollaborationType | null
  acceptedAt?: any | null
  declinedAt?: any | null
  collaborationStartDate?: any | null
  collaborationEndDate?: any | null
  paymentStartDate?: any | null
  paymentEndDate?: any | null
  fixedFee?: number | null
  royaltySharePercentage?: number | null
  track?: {
    __typename?: 'CollaborationTrack'
    name: string
    id: string
    trackArtists?: Array<{
      __typename?: 'PublicTrackArtist'
      primary: boolean
      position: number
      artist: { __typename?: 'PublicArtist'; name?: string | null }
    }> | null
    product: {
      __typename?: 'PublicProduct'
      artwork?: {
        __typename?: 'Image'
        small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
        medium?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
      } | null
    }
  } | null
  createdByGroup?: {
    __typename?: 'PublicGroup'
    id: string
    name: string
    avatar?: { __typename?: 'Image'; small?: { __typename?: 'File'; url: string; expiresAt: string } | null } | null
  } | null
  posts?: Array<{
    __typename?: 'CollaborationPost'
    id: string
    platform?: { __typename?: 'Platform'; name: string; type: PlatformType; id: string } | null
    proofOfDeliveries?: Array<{
      __typename?: 'ProofOfDelivery'
      status?: ProofOfDeliveryStatus | null
      id: string
    } | null> | null
  } | null> | null
  order?: {
    __typename?: 'Order'
    paypalTransactions: Array<{ __typename?: 'PaypalTransaction'; state: string }>
  } | null
}

export type MyCollabItemFragment = {
  __typename?: 'Collaboration'
  id: string
  createdAt: any
  canceledAt?: any | null
  state?: CollaborationState | null
  from: {
    __typename?: 'PublicGroup'
    id: string
    name: string
    avatar?: { __typename?: 'Image'; small?: { __typename?: 'File'; url: string; expiresAt: string } | null } | null
    artistCollaborationSettings?: {
      __typename?: 'CollaborationSettings'
      royaltyShareEnabled: boolean
      fixedFeeEnabled: boolean
      platforms?: Array<{
        __typename?: 'CollaborationPlatform'
        id: string
        platform?: { __typename?: 'Platform'; name: string } | null
      } | null> | null
      genres?: Array<{
        __typename?: 'CollaborationGenre'
        id: string
        genre: { __typename?: 'Genre'; name: string }
      } | null> | null
    } | null
    influencerCollaborationSettings?: {
      __typename?: 'CollaborationSettings'
      fixedFeeEnabled: boolean
      royaltyShareEnabled: boolean
      platforms?: Array<{
        __typename?: 'CollaborationPlatform'
        id: string
        platform?: { __typename?: 'Platform'; name: string } | null
      } | null> | null
      genres?: Array<{
        __typename?: 'CollaborationGenre'
        id: string
        genre: { __typename?: 'Genre'; name: string }
      } | null> | null
    } | null
  }
  to: {
    __typename?: 'PublicGroup'
    id: string
    name: string
    avatar?: { __typename?: 'Image'; small?: { __typename?: 'File'; url: string; expiresAt: string } | null } | null
  }
  terms?: Array<{
    __typename?: 'CollaborationTerms'
    id: string
    version?: number | null
    type?: CollaborationType | null
    acceptedAt?: any | null
    declinedAt?: any | null
    collaborationStartDate?: any | null
    collaborationEndDate?: any | null
    paymentStartDate?: any | null
    paymentEndDate?: any | null
    fixedFee?: number | null
    royaltySharePercentage?: number | null
    track?: {
      __typename?: 'CollaborationTrack'
      name: string
      id: string
      trackArtists?: Array<{
        __typename?: 'PublicTrackArtist'
        primary: boolean
        position: number
        artist: { __typename?: 'PublicArtist'; name?: string | null }
      }> | null
      product: {
        __typename?: 'PublicProduct'
        artwork?: {
          __typename?: 'Image'
          small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
          medium?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
        } | null
      }
    } | null
    createdByGroup?: {
      __typename?: 'PublicGroup'
      id: string
      name: string
      avatar?: { __typename?: 'Image'; small?: { __typename?: 'File'; url: string; expiresAt: string } | null } | null
    } | null
    posts?: Array<{
      __typename?: 'CollaborationPost'
      id: string
      platform?: { __typename?: 'Platform'; name: string; type: PlatformType; id: string } | null
      proofOfDeliveries?: Array<{
        __typename?: 'ProofOfDelivery'
        status?: ProofOfDeliveryStatus | null
        id: string
      } | null> | null
    } | null> | null
    order?: {
      __typename?: 'Order'
      paypalTransactions: Array<{ __typename?: 'PaypalTransaction'; state: string }>
    } | null
  } | null> | null
}

export type GetMyCollaborationsQueryVariables = Exact<{
  groupId: Scalars['ID']
}>

export type GetMyCollaborationsQuery = {
  __typename?: 'Query'
  getMyCollaborations?: Array<{
    __typename?: 'Collaboration'
    id: string
    createdAt: any
    canceledAt?: any | null
    state?: CollaborationState | null
    from: {
      __typename?: 'PublicGroup'
      id: string
      name: string
      avatar?: { __typename?: 'Image'; small?: { __typename?: 'File'; url: string; expiresAt: string } | null } | null
      artistCollaborationSettings?: {
        __typename?: 'CollaborationSettings'
        royaltyShareEnabled: boolean
        fixedFeeEnabled: boolean
        platforms?: Array<{
          __typename?: 'CollaborationPlatform'
          id: string
          platform?: { __typename?: 'Platform'; name: string } | null
        } | null> | null
        genres?: Array<{
          __typename?: 'CollaborationGenre'
          id: string
          genre: { __typename?: 'Genre'; name: string }
        } | null> | null
      } | null
      influencerCollaborationSettings?: {
        __typename?: 'CollaborationSettings'
        fixedFeeEnabled: boolean
        royaltyShareEnabled: boolean
        platforms?: Array<{
          __typename?: 'CollaborationPlatform'
          id: string
          platform?: { __typename?: 'Platform'; name: string } | null
        } | null> | null
        genres?: Array<{
          __typename?: 'CollaborationGenre'
          id: string
          genre: { __typename?: 'Genre'; name: string }
        } | null> | null
      } | null
    }
    to: {
      __typename?: 'PublicGroup'
      id: string
      name: string
      avatar?: { __typename?: 'Image'; small?: { __typename?: 'File'; url: string; expiresAt: string } | null } | null
    }
    terms?: Array<{
      __typename?: 'CollaborationTerms'
      id: string
      version?: number | null
      type?: CollaborationType | null
      acceptedAt?: any | null
      declinedAt?: any | null
      collaborationStartDate?: any | null
      collaborationEndDate?: any | null
      paymentStartDate?: any | null
      paymentEndDate?: any | null
      fixedFee?: number | null
      royaltySharePercentage?: number | null
      track?: {
        __typename?: 'CollaborationTrack'
        name: string
        id: string
        trackArtists?: Array<{
          __typename?: 'PublicTrackArtist'
          primary: boolean
          position: number
          artist: { __typename?: 'PublicArtist'; name?: string | null }
        }> | null
        product: {
          __typename?: 'PublicProduct'
          artwork?: {
            __typename?: 'Image'
            small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
            medium?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
          } | null
        }
      } | null
      createdByGroup?: {
        __typename?: 'PublicGroup'
        id: string
        name: string
        avatar?: { __typename?: 'Image'; small?: { __typename?: 'File'; url: string; expiresAt: string } | null } | null
      } | null
      posts?: Array<{
        __typename?: 'CollaborationPost'
        id: string
        platform?: { __typename?: 'Platform'; name: string; type: PlatformType; id: string } | null
        proofOfDeliveries?: Array<{
          __typename?: 'ProofOfDelivery'
          status?: ProofOfDeliveryStatus | null
          id: string
        } | null> | null
      } | null> | null
      order?: {
        __typename?: 'Order'
        paypalTransactions: Array<{ __typename?: 'PaypalTransaction'; state: string }>
      } | null
    } | null> | null
  } | null> | null
}

export type GetCollaborationByIdQueryVariables = Exact<{
  collaborationId: Scalars['ID']
}>

export type GetCollaborationByIdQuery = {
  __typename?: 'Query'
  getCollaboration?: {
    __typename?: 'Collaboration'
    canceledAt?: any | null
    canceledReason?: string | null
    createdAt: any
    state?: CollaborationState | null
    id: string
    terms?: Array<{
      __typename?: 'CollaborationTerms'
      id: string
      acceptedAt?: any | null
      createdAt: any
      declinedAt?: any | null
      declinedReason?: string | null
      fixedFee?: number | null
      message?: string | null
      postFrequency?: number | null
      postAmount?: number | null
      postPeriod?: PostFrequencyPeriod | null
      postsType?: string | null
      collaborationStartDate?: any | null
      collaborationEndDate?: any | null
      paymentStartDate?: any | null
      paymentEndDate?: any | null
      royaltySharePercentage?: number | null
      type?: CollaborationType | null
      version?: number | null
      createdByGroup?: {
        __typename?: 'PublicGroup'
        id: string
        name: string
        avatar?: { __typename?: 'Image'; small?: { __typename?: 'File'; url: string; expiresAt: string } | null } | null
      } | null
      posts?: Array<{
        __typename?: 'CollaborationPost'
        id: string
        platform?: { __typename?: 'Platform'; name: string; id: string } | null
        proofOfDeliveries?: Array<{
          __typename?: 'ProofOfDelivery'
          influencerMessage?: string | null
          created?: any | null
          status?: ProofOfDeliveryStatus | null
          id: string
          draftUrl?: string | null
          reasonForDeclining?: string | null
          artistMessage?: string | null
        } | null> | null
      } | null> | null
      track?: {
        __typename?: 'CollaborationTrack'
        id: string
        bpm: number
        name: string
        genre: DistributionGenre
        subgenre?: DistributionSubgenre | null
        alternateGenre?: DistributionGenre | null
        alternateSubgenre?: DistributionSubgenre | null
        audioLength?: number | null
        trackArtists?: Array<{
          __typename?: 'PublicTrackArtist'
          primary: boolean
          position: number
          artist: { __typename?: 'PublicArtist'; name?: string | null }
        }> | null
        product: {
          __typename?: 'PublicProduct'
          artwork?: {
            __typename?: 'Image'
            small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
            medium?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
          } | null
        }
        compressedAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
        previewAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
      } | null
      order?: {
        __typename?: 'Order'
        paypalTransactions: Array<{ __typename?: 'PaypalTransaction'; state: string }>
      } | null
    } | null> | null
    to: {
      __typename?: 'PublicGroup'
      name: string
      id: string
      avatar?: {
        __typename?: 'Image'
        small?: { __typename?: 'File'; url: string; expiresAt: string } | null
        medium?: { __typename?: 'File'; url: string; expiresAt: string } | null
      } | null
    }
    from: { __typename?: 'PublicGroup'; id: string; name: string }
  } | null
}

export type GetCollaborationQueryVariables = Exact<{
  collaborationId: Scalars['ID']
}>

export type GetCollaborationQuery = {
  __typename?: 'Query'
  getCollaboration?: {
    __typename?: 'Collaboration'
    canceledAt?: any | null
    canceledReason?: string | null
    createdAt: any
    id: string
    state?: CollaborationState | null
    terms?: Array<{
      __typename?: 'CollaborationTerms'
      id: string
      acceptedAt?: any | null
      createdAt: any
      declinedAt?: any | null
      declinedReason?: string | null
      fixedFee?: number | null
      message?: string | null
      postFrequency?: number | null
      postAmount?: number | null
      postPeriod?: PostFrequencyPeriod | null
      postsType?: string | null
      collaborationStartDate?: any | null
      collaborationEndDate?: any | null
      paymentStartDate?: any | null
      paymentEndDate?: any | null
      royaltySharePercentage?: number | null
      type?: CollaborationType | null
      version?: number | null
      createdByGroup?: {
        __typename?: 'PublicGroup'
        id: string
        name: string
        avatar?: { __typename?: 'Image'; small?: { __typename?: 'File'; url: string; expiresAt: string } | null } | null
      } | null
      posts?: Array<{
        __typename?: 'CollaborationPost'
        id: string
        platform?: { __typename?: 'Platform'; name: string; id: string } | null
        proofOfDeliveries?: Array<{
          __typename?: 'ProofOfDelivery'
          status?: ProofOfDeliveryStatus | null
          id: string
        } | null> | null
      } | null> | null
      track?: {
        __typename?: 'CollaborationTrack'
        id: string
        bpm: number
        name: string
        genre: DistributionGenre
        subgenre?: DistributionSubgenre | null
        alternateGenre?: DistributionGenre | null
        alternateSubgenre?: DistributionSubgenre | null
        audioLength?: number | null
        trackArtists?: Array<{
          __typename?: 'PublicTrackArtist'
          primary: boolean
          position: number
          artist: { __typename?: 'PublicArtist'; name?: string | null }
        }> | null
        product: {
          __typename?: 'PublicProduct'
          artwork?: {
            __typename?: 'Image'
            small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
            medium?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
          } | null
        }
        compressedAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
        previewAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
      } | null
      order?: {
        __typename?: 'Order'
        paypalTransactions: Array<{ __typename?: 'PaypalTransaction'; state: string }>
      } | null
    } | null> | null
    to: {
      __typename?: 'PublicGroup'
      name: string
      id: string
      avatar?: {
        __typename?: 'Image'
        small?: { __typename?: 'File'; url: string; expiresAt: string } | null
        medium?: { __typename?: 'File'; url: string; expiresAt: string } | null
      } | null
    }
    from: { __typename?: 'PublicGroup'; id: string; name: string }
  } | null
}

export type AddCollaborationMutationVariables = Exact<{
  input?: InputMaybe<CollaborationInput>
}>

export type AddCollaborationMutation = {
  __typename?: 'Mutation'
  addCollaboration?: { __typename?: 'Collaboration'; id: string } | null
}

export type CreateCollaborationCounterProposalMutationVariables = Exact<{
  collaborationId: Scalars['ID']
  collaborationTermsId: Scalars['ID']
  input?: InputMaybe<CollaborationCounterProposalInput>
}>

export type CreateCollaborationCounterProposalMutation = {
  __typename?: 'Mutation'
  createCollaborationCounterProposal?: { __typename?: 'Collaboration'; id: string } | null
}

export type DeclineCollaborationMutationVariables = Exact<{
  collaborationId: Scalars['ID']
  collaborationTermsId: Scalars['ID']
  reason?: InputMaybe<Scalars['String']>
}>

export type DeclineCollaborationMutation = {
  __typename?: 'Mutation'
  closeCollaboration?: {
    __typename?: 'Collaboration'
    terms?: Array<{ __typename?: 'CollaborationTerms'; declinedAt?: any | null } | null> | null
  } | null
}

export type CancelCollaborationMutationVariables = Exact<{
  collaborationId: Scalars['ID']
  collaborationTermsId: Scalars['ID']
  reason?: InputMaybe<Scalars['String']>
}>

export type CancelCollaborationMutation = {
  __typename?: 'Mutation'
  closeCollaboration?: { __typename?: 'Collaboration'; canceledAt?: any | null } | null
}

export type AcceptCollaborationMutationVariables = Exact<{
  collaborationId: Scalars['ID']
  collaborationTermsId: Scalars['ID']
}>

export type AcceptCollaborationMutation = {
  __typename?: 'Mutation'
  closeCollaboration?: {
    __typename?: 'Collaboration'
    terms?: Array<{ __typename?: 'CollaborationTerms'; acceptedAt?: any | null } | null> | null
  } | null
}

export type GetProofOfDeliveryQueryVariables = Exact<{
  proofOfDeliveryId: Scalars['ID']
}>

export type GetProofOfDeliveryQuery = {
  __typename?: 'Query'
  getProofOfDelivery?: {
    __typename?: 'ProofOfDelivery'
    url?: string | null
    status?: ProofOfDeliveryStatus | null
    influencerMessage?: string | null
    created?: any | null
    draftInfluencerMessage?: string | null
    draftUrl?: string | null
    reasonForDeclining?: string | null
    artistMessage?: string | null
  } | null
}

export type CreateCollaborationOrderMutationVariables = Exact<{
  input: CreateCollaborationOrderInput
}>

export type CreateCollaborationOrderMutation = { __typename?: 'Mutation'; createCollaborationOrder?: string | null }

export type CapturePaypalOrderCollabMutationVariables = Exact<{
  orderId: Scalars['String']
}>

export type CapturePaypalOrderCollabMutation = { __typename?: 'Mutation'; capturePaypalOrder?: boolean | null }

export type AddDraftDeliveryMutationVariables = Exact<{
  collaborationPostId: Scalars['ID']
  input?: InputMaybe<AddDraftDeliveryInput>
}>

export type AddDraftDeliveryMutation = {
  __typename?: 'Mutation'
  addDraftDelivery?: { __typename?: 'ProofOfDelivery'; id: string } | null
}

export type ReviewDraftDeliveryMutationVariables = Exact<{
  id: Scalars['ID']
  artistMessage: Scalars['String']
}>

export type ReviewDraftDeliveryMutation = {
  __typename?: 'Mutation'
  reviewDraftDelivery?: { __typename?: 'ProofOfDelivery'; id: string } | null
}

export type DeclineDraftDeliveryMutationVariables = Exact<{
  id: Scalars['ID']
  artistMessage: Scalars['String']
  reasonForDeclining?: InputMaybe<Scalars['String']>
}>

export type DeclineDraftDeliveryMutation = {
  __typename?: 'Mutation'
  reviewDraftDelivery?: { __typename?: 'ProofOfDelivery'; id: string } | null
}

export type FinalizeDeliveryMutationVariables = Exact<{
  id: Scalars['ID']
  input?: InputMaybe<FinalizeDeliveryInput>
}>

export type FinalizeDeliveryMutation = {
  __typename?: 'Mutation'
  finalizeDelivery?: { __typename?: 'ProofOfDelivery'; id: string } | null
}

export type GetAvailableContributorsQueryVariables = Exact<{ [key: string]: never }>

export type GetAvailableContributorsQuery = {
  __typename?: 'Query'
  getAvailableContributors: {
    __typename?: 'AvailableContributors'
    compositionAndLyrics: Array<{
      __typename?: 'AvailableContributor'
      name: string
      needsFullName: boolean
      needsPublisher: boolean
      required: boolean
      role: ContributorRole
    }>
    performers: Array<{
      __typename?: 'AvailableContributor'
      name: string
      needsFullName: boolean
      needsPublisher: boolean
      required: boolean
      role: ContributorRole
    }>
    productionAndEngineering: Array<{
      __typename?: 'AvailableContributor'
      name: string
      needsFullName: boolean
      needsPublisher: boolean
      required: boolean
      role: ContributorRole
    }>
  }
}

export type AddContributorMutationVariables = Exact<{
  trackId: Scalars['ID']
  input: ContributorInput
}>

export type AddContributorMutation = {
  __typename?: 'Mutation'
  addContributor?: {
    __typename?: 'Contributor'
    id: string
    trackId: string
    role: ContributorRole
    name: string
    publisher?: string | null
    position: number
  } | null
}

export type EditContributorMutationVariables = Exact<{
  id: Scalars['ID']
  input: ContributorInput
}>

export type EditContributorMutation = {
  __typename?: 'Mutation'
  editContributor?: {
    __typename?: 'Contributor'
    id: string
    trackId: string
    role: ContributorRole
    publisher?: string | null
    name: string
    position: number
  } | null
}

export type RemoveContributorMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type RemoveContributorMutation = {
  __typename?: 'Mutation'
  removeContributor?: {
    __typename?: 'Contributor'
    id: string
    trackId: string
    role: ContributorRole
    publisher?: string | null
    name: string
    position: number
  } | null
}

export type GroupCollaborationSettingsFragment = {
  __typename?: 'CollaborationSettings'
  id: string
  royaltyShareEnabled: boolean
  fixedFeeEnabled: boolean
  platforms?: Array<{
    __typename?: 'CollaborationPlatform'
    id: string
    platform?: { __typename?: 'Platform'; name: string } | null
  } | null> | null
  genres?: Array<{
    __typename?: 'CollaborationGenre'
    id: string
    genre: { __typename?: 'Genre'; name: string }
  } | null> | null
}

export type GetGroupsQueryVariables = Exact<{ [key: string]: never }>

export type GetGroupsQuery = {
  __typename?: 'Query'
  getGroups?: Array<{
    __typename?: 'PublicGroup'
    id: string
    role?: GroupRole | null
    name: string
    biography?: string | null
    countryCode?: string | null
    manager?: { __typename?: 'PublicManager'; id: string } | null
  } | null> | null
}

export type GetGroupsByEmailQueryVariables = Exact<{
  email: Scalars['String']
}>

export type GetGroupsByEmailQuery = {
  __typename?: 'Query'
  getGroupsByEmail?: Array<{
    __typename?: 'PublicGroup'
    id: string
    role?: GroupRole | null
    name: string
    countryCode?: string | null
    avatar?: {
      __typename?: 'Image'
      small?: { __typename?: 'File'; url: string; expiresAt: string } | null
      medium?: { __typename?: 'File'; url: string; expiresAt: string } | null
      large?: { __typename?: 'File'; url: string; expiresAt: string } | null
    } | null
  }> | null
}

export type GetMyGroupsQueryVariables = Exact<{ [key: string]: never }>

export type GetMyGroupsQuery = {
  __typename?: 'Query'
  getMyGroups?: Array<{
    __typename?: 'Group'
    id: string
    role?: GroupRole | null
    name: string
    website?: string | null
    biography?: string | null
    countryCode?: string | null
    avatar?: {
      __typename?: 'Image'
      small?: { __typename?: 'File'; url: string; expiresAt: string } | null
      medium?: { __typename?: 'File'; url: string; expiresAt: string } | null
      large?: { __typename?: 'File'; url: string; expiresAt: string } | null
    } | null
  } | null> | null
}

export type GetMyGroupQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetMyGroupQuery = {
  __typename?: 'Query'
  getMyGroup?: {
    __typename?: 'Group'
    id: string
    role?: GroupRole | null
    configured: boolean
    name: string
    biography?: string | null
    countryCode?: string | null
    website?: string | null
    myMenu?: Array<MyMenuType | null> | null
    avatar?: {
      __typename?: 'Image'
      small?: { __typename?: 'File'; url: string; expiresAt: string } | null
      medium?: { __typename?: 'File'; url: string; expiresAt: string } | null
    } | null
    supportedChats: { __typename?: 'SupportedChats'; profileToProfile: boolean }
    manager?: {
      __typename?: 'Manager'
      id: string
      groupId: string
      distributedProducts?: Array<{
        __typename?: 'Product'
        id: string
        name: string
        releaseVersion?: string | null
        consumerReleaseDate: any
        fugaId?: string | null
        publishedAt?: any | null
        artwork?: {
          __typename?: 'Image'
          small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
          medium?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
        } | null
        tracks?: Array<{
          __typename?: 'Track'
          id: string
          fugaId?: string | null
          name: string
          version?: string | null
          trackArtists?: Array<{
            __typename?: 'TrackArtist'
            primary: boolean
            position: number
            artist: { __typename?: 'Artist'; name?: string | null }
          }> | null
          compressedAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
        }> | null
      }> | null
      musicLibraryProducts?: Array<{
        __typename?: 'Product'
        id: string
        name: string
        releaseVersion?: string | null
        consumerReleaseDate: any
        fugaId?: string | null
        publishedAt?: any | null
        artwork?: {
          __typename?: 'Image'
          small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
          medium?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
        } | null
        tracks?: Array<{
          __typename?: 'Track'
          id: string
          fugaId?: string | null
          name: string
          version?: string | null
          trackArtists?: Array<{
            __typename?: 'TrackArtist'
            primary: boolean
            position: number
            artist: { __typename?: 'Artist'; name?: string | null }
          }> | null
          compressedAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
        }> | null
      }> | null
    } | null
    members?: Array<{
      __typename?: 'GroupMember'
      userId: string
      userProfile?: {
        __typename?: 'UserProfile'
        name?: string | null
        email?: string | null
        avatar?: {
          __typename?: 'Image'
          small?: { __typename?: 'File'; url: string; expiresAt: string } | null
          medium?: { __typename?: 'File'; url: string; expiresAt: string } | null
        } | null
      } | null
    }> | null
    platformAccounts?: Array<{
      __typename?: 'PlatformAccount'
      id?: string | null
      platform?: string | null
      url?: string | null
      followers?: { __typename?: 'Followers'; high?: number | null; low?: number | null } | null
    }> | null
    paymentSettings?: { __typename?: 'GroupPaymentSettings'; entityType: PaymentSettingsEntityType } | null
    payoutSettings?: { __typename?: 'GroupPayoutSettings'; payoutType: GroupPayoutSettingsType } | null
    address?: {
      __typename?: 'Address'
      name?: string | null
      companyName?: string | null
      addressLine1: string
      addressLine2?: string | null
      postalCode: string
      city: string
      district?: string | null
      country: string
    } | null
    bankAccount?: {
      __typename?: 'BankAccount'
      accountHolderName: string
      type: BankAccountType
      currencyCode: CurrencyCode
      details: { __typename?: 'IbanBankAccountDetails'; iban: string } | { __typename?: 'SortCodeBankAccountDetails' }
    } | null
    paypalAccount?: { __typename?: 'PaypalAccount'; email?: string | null } | null
    vatDetails?: {
      __typename?: 'VatDetails'
      isVatLiable: boolean
      vatNumber?: string | null
      chamberOfCommerceNumber?: string | null
    } | null
    influencerCollaborationSettings?: {
      __typename?: 'CollaborationSettings'
      id: string
      royaltyShareEnabled: boolean
      fixedFeeEnabled: boolean
      platforms?: Array<{
        __typename?: 'CollaborationPlatform'
        id: string
        platform?: { __typename?: 'Platform'; name: string } | null
      } | null> | null
      genres?: Array<{
        __typename?: 'CollaborationGenre'
        id: string
        genre: { __typename?: 'Genre'; name: string }
      } | null> | null
    } | null
    artistCollaborationSettings?: {
      __typename?: 'CollaborationSettings'
      id: string
      royaltyShareEnabled: boolean
      fixedFeeEnabled: boolean
      platforms?: Array<{
        __typename?: 'CollaborationPlatform'
        id: string
        platform?: { __typename?: 'Platform'; name: string } | null
      } | null> | null
      genres?: Array<{
        __typename?: 'CollaborationGenre'
        id: string
        genre: { __typename?: 'Genre'; name: string }
      } | null> | null
    } | null
  } | null
}

export type GetGroupQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetGroupQuery = {
  __typename?: 'Query'
  getGroup?: {
    __typename?: 'PublicGroup'
    id: string
    role?: GroupRole | null
    name: string
    biography?: string | null
    countryCode?: string | null
    avatar?: {
      __typename?: 'Image'
      small?: { __typename?: 'File'; url: string; expiresAt: string } | null
      medium?: { __typename?: 'File'; url: string; expiresAt: string } | null
      large?: { __typename?: 'File'; url: string; expiresAt: string } | null
    } | null
    supportedChats: { __typename?: 'SupportedChats'; profileToProfile: boolean }
    manager?: {
      __typename?: 'PublicManager'
      id: string
      distributedProducts?: Array<{
        __typename?: 'PublicProduct'
        name: string
        consumerReleaseDate: any
        artwork?: {
          __typename?: 'Image'
          small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
          medium?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
        } | null
        tracks?: Array<{
          __typename?: 'PublicTrack'
          id: string
          name: string
          version?: string | null
          alternateGenre?: DistributionGenre | null
          subgenre?: DistributionSubgenre | null
          genre: DistributionGenre
          alternateSubgenre?: DistributionSubgenre | null
          bpm: number
          audioLength?: number | null
          trackArtists?: Array<{
            __typename?: 'PublicTrackArtist'
            primary: boolean
            position: number
            artist: { __typename?: 'PublicArtist'; name?: string | null }
          }> | null
          trackCode?: { __typename?: 'TrackCode'; code: string } | null
          cyaniteMetaData?: {
            __typename?: 'CyaniteMetaData'
            moods?: Array<MoodTag | null> | null
            keyPredictionValue?: KeyPredictionValue | null
            predominantVoiceGender?: PredominantVoiceGender | null
            subgenres?: Array<SubgenreTag | null> | null
            genres?: Array<GenreTag | null> | null
            instruments?: Array<InstrumentTag | null> | null
            bpmRangeAdjusted?: number | null
          } | null
          product: {
            __typename?: 'PublicProduct'
            label: string
            artwork?: {
              __typename?: 'Image'
              small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
              medium?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
            } | null
          }
          contributors?: Array<{
            __typename?: 'Contributor'
            role: ContributorRole
            name: string
            publisher?: string | null
          }> | null
          previewAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
          compressedAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
          musicLibraryTrackConfig?: {
            __typename?: 'MusicLibraryTrackConfig'
            socialMediaBranded: string
            advertisementAllMedia: string
            advertisementAudioOnly: string
            advertisementOnlineOnly: string
            corporateNonProfitCharity: string
            corporateNonProfitCompanyPresentation: string
            corporateNonProfitEducational: string
            filmLargeOnlineOnly: string
            filmLargeAllMedia: string
            filmLargeTrailer: string
            filmSmallAllMedia: string
            filmSmallOnlineOnly: string
            filmSmallTrailer: string
            gamesAppsInAppGame: string
            gamesAppsTrailerPromo: string
            podcastPrivateBranded: string
            podcastPrivateNonBranded: string
            radioTvAudioOnly: string
            radioTvTrailer: string
            radioTvTvShow: string
            socialMediaNonBranded: string
            durations: Array<SyncLicenseDurations>
            enabled: boolean
            submittedAt?: any | null
          } | null
        } | null> | null
      } | null> | null
      musicLibraryProducts?: Array<{
        __typename?: 'PublicProduct'
        name: string
        consumerReleaseDate: any
        artwork?: {
          __typename?: 'Image'
          small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
          medium?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
        } | null
        tracks?: Array<{
          __typename?: 'PublicTrack'
          id: string
          name: string
          version?: string | null
          alternateGenre?: DistributionGenre | null
          subgenre?: DistributionSubgenre | null
          genre: DistributionGenre
          alternateSubgenre?: DistributionSubgenre | null
          bpm: number
          audioLength?: number | null
          trackArtists?: Array<{
            __typename?: 'PublicTrackArtist'
            primary: boolean
            position: number
            artist: { __typename?: 'PublicArtist'; name?: string | null }
          }> | null
          trackCode?: { __typename?: 'TrackCode'; code: string } | null
          cyaniteMetaData?: {
            __typename?: 'CyaniteMetaData'
            moods?: Array<MoodTag | null> | null
            keyPredictionValue?: KeyPredictionValue | null
            predominantVoiceGender?: PredominantVoiceGender | null
            subgenres?: Array<SubgenreTag | null> | null
            genres?: Array<GenreTag | null> | null
            instruments?: Array<InstrumentTag | null> | null
            bpmRangeAdjusted?: number | null
          } | null
          product: {
            __typename?: 'PublicProduct'
            label: string
            artwork?: {
              __typename?: 'Image'
              small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
              medium?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
            } | null
          }
          contributors?: Array<{
            __typename?: 'Contributor'
            role: ContributorRole
            name: string
            publisher?: string | null
          }> | null
          previewAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
          compressedAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
          musicLibraryTrackConfig?: {
            __typename?: 'MusicLibraryTrackConfig'
            socialMediaBranded: string
            advertisementAllMedia: string
            advertisementAudioOnly: string
            advertisementOnlineOnly: string
            corporateNonProfitCharity: string
            corporateNonProfitCompanyPresentation: string
            corporateNonProfitEducational: string
            filmLargeOnlineOnly: string
            filmLargeAllMedia: string
            filmLargeTrailer: string
            filmSmallAllMedia: string
            filmSmallOnlineOnly: string
            filmSmallTrailer: string
            gamesAppsInAppGame: string
            gamesAppsTrailerPromo: string
            podcastPrivateBranded: string
            podcastPrivateNonBranded: string
            radioTvAudioOnly: string
            radioTvTrailer: string
            radioTvTvShow: string
            socialMediaNonBranded: string
            durations: Array<SyncLicenseDurations>
            enabled: boolean
            submittedAt?: any | null
          } | null
        } | null> | null
      } | null> | null
    } | null
    artistCollaborationSettings?: {
      __typename?: 'CollaborationSettings'
      royaltyShareEnabled: boolean
      fixedFeeEnabled: boolean
      platforms?: Array<{
        __typename?: 'CollaborationPlatform'
        id: string
        platform?: { __typename?: 'Platform'; name: string } | null
      } | null> | null
      genres?: Array<{
        __typename?: 'CollaborationGenre'
        id: string
        genre: { __typename?: 'Genre'; name: string }
      } | null> | null
    } | null
    influencerCollaborationSettings?: {
      __typename?: 'CollaborationSettings'
      fixedFeeEnabled: boolean
      royaltyShareEnabled: boolean
      platforms?: Array<{
        __typename?: 'CollaborationPlatform'
        id: string
        platform?: { __typename?: 'Platform'; name: string } | null
      } | null> | null
      genres?: Array<{
        __typename?: 'CollaborationGenre'
        id: string
        genre: { __typename?: 'Genre'; name: string }
      } | null> | null
    } | null
    platformAccounts?: Array<{
      __typename?: 'PlatformAccount'
      id?: string | null
      platform?: string | null
      url?: string | null
      followers?: { __typename?: 'Followers'; high?: number | null; low?: number | null } | null
    }> | null
  } | null
}

export type AddGroupMutationVariables = Exact<{
  input: GroupInput
}>

export type AddGroupMutation = {
  __typename?: 'Mutation'
  addGroup?: {
    __typename?: 'Group'
    id: string
    role?: GroupRole | null
    name: string
    biography?: string | null
    countryCode?: string | null
    avatar?: {
      __typename?: 'Image'
      small?: { __typename?: 'File'; url: string; expiresAt: string } | null
      medium?: { __typename?: 'File'; url: string; expiresAt: string } | null
    } | null
  } | null
}

export type EditGroupMutationVariables = Exact<{
  id: Scalars['ID']
  input: GroupInput
}>

export type EditGroupMutation = {
  __typename?: 'Mutation'
  editGroup?: {
    __typename?: 'Group'
    id: string
    role?: GroupRole | null
    name: string
    biography?: string | null
    countryCode?: string | null
    avatar?: {
      __typename?: 'Image'
      small?: { __typename?: 'File'; url: string; expiresAt: string } | null
      medium?: { __typename?: 'File'; url: string; expiresAt: string } | null
    } | null
    manager?: {
      __typename?: 'Manager'
      id: string
      groupId: string
      products?: Array<{
        __typename?: 'Product'
        id: string
        name: string
        releaseVersion?: string | null
        consumerReleaseDate: any
        artwork?: {
          __typename?: 'Image'
          small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
          medium?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
        } | null
      }> | null
    } | null
  } | null
}

export type FilterGroupsQueryVariables = Exact<{
  filters?: InputMaybe<GroupFilters>
  sort?: InputMaybe<GroupSortOptions>
  page?: InputMaybe<Scalars['Int']>
  size?: InputMaybe<Scalars['Int']>
}>

export type FilterGroupsQuery = {
  __typename?: 'Query'
  filterGroups?: {
    __typename?: 'FilteredGroupPaginatedResponse'
    totalCount: number
    groups?: Array<{
      __typename?: 'PublicGroup'
      id: string
      name: string
      countryCode?: string | null
      avatar?: {
        __typename?: 'Image'
        medium?: { __typename?: 'File'; url: string; path: string; expiresIn: number; expiresAt: string } | null
      } | null
      platformAccounts?: Array<{
        __typename?: 'PlatformAccount'
        platform?: string | null
        url?: string | null
        followers?: { __typename?: 'Followers'; low?: number | null; high?: number | null } | null
      }> | null
      influencerCollaborationSettings?: {
        __typename?: 'CollaborationSettings'
        id: string
        royaltyShareEnabled: boolean
        fixedFeeEnabled: boolean
        platforms?: Array<{
          __typename?: 'CollaborationPlatform'
          id: string
          platform?: { __typename?: 'Platform'; name: string } | null
        } | null> | null
        genres?: Array<{
          __typename?: 'CollaborationGenre'
          id: string
          genre: { __typename?: 'Genre'; name: string }
        } | null> | null
      } | null
      artistCollaborationSettings?: {
        __typename?: 'CollaborationSettings'
        id: string
        royaltyShareEnabled: boolean
        fixedFeeEnabled: boolean
        platforms?: Array<{
          __typename?: 'CollaborationPlatform'
          id: string
          platform?: { __typename?: 'Platform'; name: string } | null
        } | null> | null
        genres?: Array<{
          __typename?: 'CollaborationGenre'
          id: string
          genre: { __typename?: 'Genre'; name: string }
        } | null> | null
      } | null
    } | null> | null
  } | null
}

export type GroupCardFragment = {
  __typename?: 'PublicGroup'
  id: string
  name: string
  countryCode?: string | null
  avatar?: {
    __typename?: 'Image'
    medium?: { __typename?: 'File'; url: string; path: string; expiresIn: number; expiresAt: string } | null
  } | null
  platformAccounts?: Array<{
    __typename?: 'PlatformAccount'
    platform?: string | null
    url?: string | null
    followers?: { __typename?: 'Followers'; low?: number | null; high?: number | null } | null
  }> | null
  influencerCollaborationSettings?: {
    __typename?: 'CollaborationSettings'
    id: string
    royaltyShareEnabled: boolean
    fixedFeeEnabled: boolean
    platforms?: Array<{
      __typename?: 'CollaborationPlatform'
      id: string
      platform?: { __typename?: 'Platform'; name: string } | null
    } | null> | null
    genres?: Array<{
      __typename?: 'CollaborationGenre'
      id: string
      genre: { __typename?: 'Genre'; name: string }
    } | null> | null
  } | null
  artistCollaborationSettings?: {
    __typename?: 'CollaborationSettings'
    id: string
    royaltyShareEnabled: boolean
    fixedFeeEnabled: boolean
    platforms?: Array<{
      __typename?: 'CollaborationPlatform'
      id: string
      platform?: { __typename?: 'Platform'; name: string } | null
    } | null> | null
    genres?: Array<{
      __typename?: 'CollaborationGenre'
      id: string
      genre: { __typename?: 'Genre'; name: string }
    } | null> | null
  } | null
}

export type VerifyGroupNameAndSlugMutationVariables = Exact<{
  input: VerifyGroupNameAndSlugInput
}>

export type VerifyGroupNameAndSlugMutation = {
  __typename?: 'Mutation'
  verifyGroupNameAndSlug?: {
    __typename?: 'VerifyGroupNameAndSlugResult'
    nameAvailable: boolean
    normalizedSlug: string
    slugAvailable: boolean
  } | null
}

export type EditGroupRoleMutationVariables = Exact<{
  groupId: Scalars['ID']
  role: GroupRole
}>

export type EditGroupRoleMutation = { __typename?: 'Mutation'; editGroupRole?: boolean | null }

export type AddGroupUserDivisionMutationVariables = Exact<{
  groupId: Scalars['ID']
  input?: InputMaybe<Array<GroupUserShareInput> | GroupUserShareInput>
}>

export type AddGroupUserDivisionMutation = {
  __typename?: 'Mutation'
  addGroupUserDivision?: Array<{
    __typename?: 'GroupUserShare'
    groupId: string
    id: string
    userId: string
    userProfile?: {
      __typename?: 'UserProfile'
      name?: string | null
      email?: string | null
      avatar?: { __typename?: 'Image'; small?: { __typename?: 'File'; url: string; expiresAt: string } | null } | null
    } | null
  } | null> | null
}

export type GetGroupUserDivisionQueryVariables = Exact<{
  groupId: Scalars['ID']
}>

export type GetGroupUserDivisionQuery = {
  __typename?: 'Query'
  getGroupUserDivision?: Array<{
    __typename?: 'GroupUserShare'
    share: number
    id: string
    userId: string
    userProfile?: {
      __typename?: 'UserProfile'
      name?: string | null
      email?: string | null
      avatar?: { __typename?: 'Image'; small?: { __typename?: 'File'; url: string; expiresAt: string } | null } | null
    } | null
  }> | null
}

export type HomeNotificationsQueryVariables = Exact<{
  groupId: Scalars['ID']
}>

export type HomeNotificationsQuery = {
  __typename?: 'Query'
  getMyGroup?: {
    __typename?: 'Group'
    notifications?: Array<{ __typename?: 'Notification'; type: NotificationType; count: number } | null> | null
  } | null
}

export type AddManagerMutationVariables = Exact<{
  groupId: Scalars['ID']
}>

export type AddManagerMutation = { __typename?: 'Mutation'; addManager?: { __typename?: 'Manager'; id: string } | null }

export type AllMusicLibraryLicenseBasePricesFragment = {
  __typename?: 'MusicLibraryLicenseBasePrices'
  socialMediaBranded: string
  advertisementAllMedia: string
  advertisementAudioOnly: string
  advertisementOnlineOnly: string
  corporateNonProfitCharity: string
  corporateNonProfitCompanyPresentation: string
  corporateNonProfitEducational: string
  filmLargeOnlineOnly: string
  filmLargeAllMedia: string
  filmLargeTrailer: string
  filmSmallAllMedia: string
  filmSmallOnlineOnly: string
  filmSmallTrailer: string
  gamesAppsInAppGame: string
  gamesAppsTrailerPromo: string
  podcastPrivateBranded: string
  podcastPrivateNonBranded: string
  radioTvAudioOnly: string
  radioTvTrailer: string
  radioTvTvShow: string
  socialMediaNonBranded: string
}

export type GetMusicLibraryLicenseBasePricesQueryVariables = Exact<{ [key: string]: never }>

export type GetMusicLibraryLicenseBasePricesQuery = {
  __typename?: 'Query'
  getMusicLibraryLicenseBasePrices: {
    __typename?: 'MusicLibraryLicenseBasePrices'
    socialMediaBranded: string
    advertisementAllMedia: string
    advertisementAudioOnly: string
    advertisementOnlineOnly: string
    corporateNonProfitCharity: string
    corporateNonProfitCompanyPresentation: string
    corporateNonProfitEducational: string
    filmLargeOnlineOnly: string
    filmLargeAllMedia: string
    filmLargeTrailer: string
    filmSmallAllMedia: string
    filmSmallOnlineOnly: string
    filmSmallTrailer: string
    gamesAppsInAppGame: string
    gamesAppsTrailerPromo: string
    podcastPrivateBranded: string
    podcastPrivateNonBranded: string
    radioTvAudioOnly: string
    radioTvTrailer: string
    radioTvTvShow: string
    socialMediaNonBranded: string
  }
}

export type EditMusicLibraryTrackConfigMutationVariables = Exact<{
  input: EditMusicLibraryTrackConfigInput
}>

export type EditMusicLibraryTrackConfigMutation = {
  __typename?: 'Mutation'
  editMusicLibraryTrackConfig?: { __typename?: 'MusicLibraryTrackConfig'; id: string } | null
}

export type EditMusicLibraryProductConfigMutationVariables = Exact<{
  input: EditMusicLibraryProductConfigInput
}>

export type EditMusicLibraryProductConfigMutation = {
  __typename?: 'Mutation'
  editMusicLibraryProductConfig?: { __typename?: 'MusicLibraryProductConfig'; id: string } | null
}

export type EnableMusicLibraryProductConfigMutationVariables = Exact<{
  productId: Scalars['ID']
}>

export type EnableMusicLibraryProductConfigMutation = {
  __typename?: 'Mutation'
  enableMusicLibraryProductConfig?: { __typename?: 'MusicLibraryProductConfig'; id: string } | null
}

export type DisableMusicLibraryProductConfigMutationVariables = Exact<{
  productId: Scalars['ID']
}>

export type DisableMusicLibraryProductConfigMutation = {
  __typename?: 'Mutation'
  disableMusicLibraryProductConfig?: { __typename?: 'MusicLibraryProductConfig'; id: string } | null
}

export type DisableMusicLibraryTrackConfigMutationVariables = Exact<{
  trackId: Scalars['ID']
}>

export type DisableMusicLibraryTrackConfigMutation = {
  __typename?: 'Mutation'
  disableMusicLibraryTrackConfig?: { __typename?: 'MusicLibraryTrackConfig'; id: string } | null
}

export type EditMusicLibraryConfigSettingMutationVariables = Exact<{
  productId: Scalars['ID']
  input: EditMusicLibraryConfigSettingInput
}>

export type EditMusicLibraryConfigSettingMutation = {
  __typename?: 'Mutation'
  editMusicLibraryConfigSetting?: { __typename?: 'Product'; id: string } | null
}

export type CreateSyncLicenseOrderMutationVariables = Exact<{
  input?: InputMaybe<CreateSyncLicenseOrderInput>
}>

export type CreateSyncLicenseOrderMutation = {
  __typename?: 'Mutation'
  createSyncLicenseOrder?: {
    __typename?: 'CreateSyncLicenseOrderResponse'
    paypalOrderId?: string | null
    price: number
    totalOrderAmount: number
    vatAmount?: number | null
    syncLicenseId: string
  } | null
}

export type SyncLicenseOrderInfoFragment = {
  __typename?: 'CreateSyncLicenseOrderResponse'
  paypalOrderId?: string | null
  price: number
  totalOrderAmount: number
  vatAmount?: number | null
  syncLicenseId: string
}

export type CapturePaypalOrderMutationVariables = Exact<{
  orderId?: InputMaybe<Scalars['String']>
}>

export type CapturePaypalOrderMutation = { __typename?: 'Mutation'; orderCaptured?: boolean | null }

export type GetSyncLicenseQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetSyncLicenseQuery = {
  __typename?: 'Query'
  getSyncLicense?: {
    __typename?: 'SyncLicense'
    trackAudio?: { __typename?: 'File'; expiresAt: string; url: string; path: string } | null
  } | null
}

export type MusicLibraryTrackFragment = {
  __typename?: 'PublicTrack'
  id: string
  name: string
  version?: string | null
  alternateGenre?: DistributionGenre | null
  subgenre?: DistributionSubgenre | null
  genre: DistributionGenre
  alternateSubgenre?: DistributionSubgenre | null
  bpm: number
  audioLength?: number | null
  trackArtists?: Array<{
    __typename?: 'PublicTrackArtist'
    primary: boolean
    position: number
    artist: { __typename?: 'PublicArtist'; name?: string | null }
  }> | null
  trackCode?: { __typename?: 'TrackCode'; code: string } | null
  cyaniteMetaData?: {
    __typename?: 'CyaniteMetaData'
    moods?: Array<MoodTag | null> | null
    keyPredictionValue?: KeyPredictionValue | null
    predominantVoiceGender?: PredominantVoiceGender | null
    subgenres?: Array<SubgenreTag | null> | null
    genres?: Array<GenreTag | null> | null
    instruments?: Array<InstrumentTag | null> | null
    bpmRangeAdjusted?: number | null
  } | null
  product: {
    __typename?: 'PublicProduct'
    label: string
    artwork?: {
      __typename?: 'Image'
      small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
      medium?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
    } | null
  }
  contributors?: Array<{
    __typename?: 'Contributor'
    role: ContributorRole
    name: string
    publisher?: string | null
  }> | null
  previewAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
  compressedAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
  musicLibraryTrackConfig?: {
    __typename?: 'MusicLibraryTrackConfig'
    socialMediaBranded: string
    advertisementAllMedia: string
    advertisementAudioOnly: string
    advertisementOnlineOnly: string
    corporateNonProfitCharity: string
    corporateNonProfitCompanyPresentation: string
    corporateNonProfitEducational: string
    filmLargeOnlineOnly: string
    filmLargeAllMedia: string
    filmLargeTrailer: string
    filmSmallAllMedia: string
    filmSmallOnlineOnly: string
    filmSmallTrailer: string
    gamesAppsInAppGame: string
    gamesAppsTrailerPromo: string
    podcastPrivateBranded: string
    podcastPrivateNonBranded: string
    radioTvAudioOnly: string
    radioTvTrailer: string
    radioTvTvShow: string
    socialMediaNonBranded: string
    durations: Array<SyncLicenseDurations>
    enabled: boolean
    submittedAt?: any | null
  } | null
}

export type MusicLibraryStatementItemFragment = {
  __typename?: 'SyncLicenseStatement'
  createdAt?: any | null
  payoutAmount: number
  syncLicense?: {
    __typename?: 'SyncLicense'
    createdAt?: any | null
    group: { __typename?: 'PublicGroup'; name: string }
  } | null
  track: {
    __typename?: 'PublicTrack'
    id: string
    name: string
    version?: string | null
    alternateGenre?: DistributionGenre | null
    subgenre?: DistributionSubgenre | null
    genre: DistributionGenre
    alternateSubgenre?: DistributionSubgenre | null
    bpm: number
    audioLength?: number | null
    trackArtists?: Array<{
      __typename?: 'PublicTrackArtist'
      primary: boolean
      position: number
      artist: { __typename?: 'PublicArtist'; name?: string | null }
    }> | null
    trackCode?: { __typename?: 'TrackCode'; code: string } | null
    cyaniteMetaData?: {
      __typename?: 'CyaniteMetaData'
      moods?: Array<MoodTag | null> | null
      keyPredictionValue?: KeyPredictionValue | null
      predominantVoiceGender?: PredominantVoiceGender | null
      subgenres?: Array<SubgenreTag | null> | null
      genres?: Array<GenreTag | null> | null
      instruments?: Array<InstrumentTag | null> | null
      bpmRangeAdjusted?: number | null
    } | null
    product: {
      __typename?: 'PublicProduct'
      label: string
      artwork?: {
        __typename?: 'Image'
        small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
        medium?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
      } | null
    }
    contributors?: Array<{
      __typename?: 'Contributor'
      role: ContributorRole
      name: string
      publisher?: string | null
    }> | null
    previewAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
    compressedAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
    musicLibraryTrackConfig?: {
      __typename?: 'MusicLibraryTrackConfig'
      socialMediaBranded: string
      advertisementAllMedia: string
      advertisementAudioOnly: string
      advertisementOnlineOnly: string
      corporateNonProfitCharity: string
      corporateNonProfitCompanyPresentation: string
      corporateNonProfitEducational: string
      filmLargeOnlineOnly: string
      filmLargeAllMedia: string
      filmLargeTrailer: string
      filmSmallAllMedia: string
      filmSmallOnlineOnly: string
      filmSmallTrailer: string
      gamesAppsInAppGame: string
      gamesAppsTrailerPromo: string
      podcastPrivateBranded: string
      podcastPrivateNonBranded: string
      radioTvAudioOnly: string
      radioTvTrailer: string
      radioTvTvShow: string
      socialMediaNonBranded: string
      durations: Array<SyncLicenseDurations>
      enabled: boolean
      submittedAt?: any | null
    } | null
  }
}

export type GetPublicTrackQueryVariables = Exact<{
  trackId: Scalars['ID']
}>

export type GetPublicTrackQuery = {
  __typename?: 'Query'
  getPublicTrack?: {
    __typename?: 'PublicTrack'
    id: string
    name: string
    version?: string | null
    bpm: number
    genre: DistributionGenre
    subgenre?: DistributionSubgenre | null
    alternateGenre?: DistributionGenre | null
    alternateSubgenre?: DistributionSubgenre | null
    audioLength?: number | null
    musicLibraryTrackConfig?: {
      __typename?: 'MusicLibraryTrackConfig'
      socialMediaBranded: string
      advertisementAllMedia: string
      advertisementAudioOnly: string
      advertisementOnlineOnly: string
      corporateNonProfitCharity: string
      corporateNonProfitCompanyPresentation: string
      corporateNonProfitEducational: string
      filmLargeOnlineOnly: string
      filmLargeAllMedia: string
      filmLargeTrailer: string
      filmSmallAllMedia: string
      filmSmallOnlineOnly: string
      filmSmallTrailer: string
      gamesAppsInAppGame: string
      gamesAppsTrailerPromo: string
      podcastPrivateBranded: string
      podcastPrivateNonBranded: string
      radioTvAudioOnly: string
      radioTvTrailer: string
      radioTvTvShow: string
      socialMediaNonBranded: string
      durations: Array<SyncLicenseDurations>
      enabled: boolean
    } | null
    product: {
      __typename?: 'PublicProduct'
      label: string
      artwork?: {
        __typename?: 'Image'
        small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
        medium?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
      } | null
    }
    trackCode?: { __typename?: 'TrackCode'; code: string } | null
    contributors?: Array<{ __typename?: 'Contributor'; name: string; role: ContributorRole }> | null
    trackArtists?: Array<{
      __typename?: 'PublicTrackArtist'
      primary: boolean
      position: number
      artist: { __typename?: 'PublicArtist'; name?: string | null }
    }> | null
    previewAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
    compressedAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
    cyaniteMetaData?: {
      __typename?: 'CyaniteMetaData'
      moods?: Array<MoodTag | null> | null
      keyPredictionValue?: KeyPredictionValue | null
      predominantVoiceGender?: PredominantVoiceGender | null
      subgenres?: Array<SubgenreTag | null> | null
      genres?: Array<GenreTag | null> | null
      instruments?: Array<InstrumentTag | null> | null
      bpmRangeAdjusted?: number | null
    } | null
  } | null
}

export type GetGroupSyncLicenseStatementsQueryVariables = Exact<{
  groupId: Scalars['ID']
  filters: GetSyncLicenseStatementsFilters
}>

export type GetGroupSyncLicenseStatementsQuery = {
  __typename?: 'Query'
  getGroupSyncLicenseStatements?: Array<{
    __typename?: 'SyncLicenseStatement'
    createdAt?: any | null
    payoutAmount: number
    syncLicense?: {
      __typename?: 'SyncLicense'
      createdAt?: any | null
      group: { __typename?: 'PublicGroup'; name: string }
    } | null
    track: {
      __typename?: 'PublicTrack'
      id: string
      name: string
      version?: string | null
      alternateGenre?: DistributionGenre | null
      subgenre?: DistributionSubgenre | null
      genre: DistributionGenre
      alternateSubgenre?: DistributionSubgenre | null
      bpm: number
      audioLength?: number | null
      trackArtists?: Array<{
        __typename?: 'PublicTrackArtist'
        primary: boolean
        position: number
        artist: { __typename?: 'PublicArtist'; name?: string | null }
      }> | null
      trackCode?: { __typename?: 'TrackCode'; code: string } | null
      cyaniteMetaData?: {
        __typename?: 'CyaniteMetaData'
        moods?: Array<MoodTag | null> | null
        keyPredictionValue?: KeyPredictionValue | null
        predominantVoiceGender?: PredominantVoiceGender | null
        subgenres?: Array<SubgenreTag | null> | null
        genres?: Array<GenreTag | null> | null
        instruments?: Array<InstrumentTag | null> | null
        bpmRangeAdjusted?: number | null
      } | null
      product: {
        __typename?: 'PublicProduct'
        label: string
        artwork?: {
          __typename?: 'Image'
          small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
          medium?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
        } | null
      }
      contributors?: Array<{
        __typename?: 'Contributor'
        role: ContributorRole
        name: string
        publisher?: string | null
      }> | null
      previewAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
      compressedAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
      musicLibraryTrackConfig?: {
        __typename?: 'MusicLibraryTrackConfig'
        socialMediaBranded: string
        advertisementAllMedia: string
        advertisementAudioOnly: string
        advertisementOnlineOnly: string
        corporateNonProfitCharity: string
        corporateNonProfitCompanyPresentation: string
        corporateNonProfitEducational: string
        filmLargeOnlineOnly: string
        filmLargeAllMedia: string
        filmLargeTrailer: string
        filmSmallAllMedia: string
        filmSmallOnlineOnly: string
        filmSmallTrailer: string
        gamesAppsInAppGame: string
        gamesAppsTrailerPromo: string
        podcastPrivateBranded: string
        podcastPrivateNonBranded: string
        radioTvAudioOnly: string
        radioTvTrailer: string
        radioTvTvShow: string
        socialMediaNonBranded: string
        durations: Array<SyncLicenseDurations>
        enabled: boolean
        submittedAt?: any | null
      } | null
    }
  }> | null
}

export type GetUserSyncLicenseStatementsQueryVariables = Exact<{
  filters: GetSyncLicenseStatementsFilters
}>

export type GetUserSyncLicenseStatementsQuery = {
  __typename?: 'Query'
  getUserSyncLicenseStatements?: Array<{
    __typename?: 'SyncLicenseStatement'
    createdAt?: any | null
    payoutAmount: number
    syncLicense?: {
      __typename?: 'SyncLicense'
      createdAt?: any | null
      group: { __typename?: 'PublicGroup'; name: string }
    } | null
    track: {
      __typename?: 'PublicTrack'
      id: string
      name: string
      version?: string | null
      alternateGenre?: DistributionGenre | null
      subgenre?: DistributionSubgenre | null
      genre: DistributionGenre
      alternateSubgenre?: DistributionSubgenre | null
      bpm: number
      audioLength?: number | null
      trackArtists?: Array<{
        __typename?: 'PublicTrackArtist'
        primary: boolean
        position: number
        artist: { __typename?: 'PublicArtist'; name?: string | null }
      }> | null
      trackCode?: { __typename?: 'TrackCode'; code: string } | null
      cyaniteMetaData?: {
        __typename?: 'CyaniteMetaData'
        moods?: Array<MoodTag | null> | null
        keyPredictionValue?: KeyPredictionValue | null
        predominantVoiceGender?: PredominantVoiceGender | null
        subgenres?: Array<SubgenreTag | null> | null
        genres?: Array<GenreTag | null> | null
        instruments?: Array<InstrumentTag | null> | null
        bpmRangeAdjusted?: number | null
      } | null
      product: {
        __typename?: 'PublicProduct'
        label: string
        artwork?: {
          __typename?: 'Image'
          small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
          medium?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
        } | null
      }
      contributors?: Array<{
        __typename?: 'Contributor'
        role: ContributorRole
        name: string
        publisher?: string | null
      }> | null
      previewAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
      compressedAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
      musicLibraryTrackConfig?: {
        __typename?: 'MusicLibraryTrackConfig'
        socialMediaBranded: string
        advertisementAllMedia: string
        advertisementAudioOnly: string
        advertisementOnlineOnly: string
        corporateNonProfitCharity: string
        corporateNonProfitCompanyPresentation: string
        corporateNonProfitEducational: string
        filmLargeOnlineOnly: string
        filmLargeAllMedia: string
        filmLargeTrailer: string
        filmSmallAllMedia: string
        filmSmallOnlineOnly: string
        filmSmallTrailer: string
        gamesAppsInAppGame: string
        gamesAppsTrailerPromo: string
        podcastPrivateBranded: string
        podcastPrivateNonBranded: string
        radioTvAudioOnly: string
        radioTvTrailer: string
        radioTvTvShow: string
        socialMediaNonBranded: string
        durations: Array<SyncLicenseDurations>
        enabled: boolean
        submittedAt?: any | null
      } | null
    }
  }> | null
}

export type GetMyMlStatementsExportQueryVariables = Exact<{
  filters: GetSyncLicenseStatementsFilters
}>

export type GetMyMlStatementsExportQuery = {
  __typename?: 'Query'
  getMyMLStatementsExport: { __typename?: 'File'; url: string; expiresAt: string }
}

export type GetGroupMlStatementsExportQueryVariables = Exact<{
  filters: GetSyncLicenseStatementsFilters
  groupId: Scalars['ID']
}>

export type GetGroupMlStatementsExportQuery = {
  __typename?: 'Query'
  getGroupMLStatementsExport: { __typename?: 'File'; url: string; expiresAt: string }
}

export type GetMySyncLicensesQueryVariables = Exact<{
  groupId: Scalars['ID']
}>

export type GetMySyncLicensesQuery = {
  __typename?: 'Query'
  getMySyncLicenses?: Array<{
    __typename?: 'SyncLicense'
    licenseCategory: SyncLicenseCategory
    projectName: string
    receipt?: { __typename?: 'File'; url: string; path: string } | null
    invoice?: { __typename?: 'File'; url: string; path: string } | null
    order: { __typename?: 'Order'; createdAt?: any | null; totalAmount: number; vatAmount: number }
    trackAudio?: { __typename?: 'File'; url: string; path: string } | null
    track: {
      __typename?: 'PublicTrack'
      id: string
      name: string
      version?: string | null
      alternateGenre?: DistributionGenre | null
      subgenre?: DistributionSubgenre | null
      genre: DistributionGenre
      alternateSubgenre?: DistributionSubgenre | null
      bpm: number
      audioLength?: number | null
      trackArtists?: Array<{
        __typename?: 'PublicTrackArtist'
        primary: boolean
        position: number
        artist: { __typename?: 'PublicArtist'; name?: string | null }
      }> | null
      trackCode?: { __typename?: 'TrackCode'; code: string } | null
      cyaniteMetaData?: {
        __typename?: 'CyaniteMetaData'
        moods?: Array<MoodTag | null> | null
        keyPredictionValue?: KeyPredictionValue | null
        predominantVoiceGender?: PredominantVoiceGender | null
        subgenres?: Array<SubgenreTag | null> | null
        genres?: Array<GenreTag | null> | null
        instruments?: Array<InstrumentTag | null> | null
        bpmRangeAdjusted?: number | null
      } | null
      product: {
        __typename?: 'PublicProduct'
        label: string
        artwork?: {
          __typename?: 'Image'
          small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
          medium?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
        } | null
      }
      contributors?: Array<{
        __typename?: 'Contributor'
        role: ContributorRole
        name: string
        publisher?: string | null
      }> | null
      previewAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
      compressedAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
      musicLibraryTrackConfig?: {
        __typename?: 'MusicLibraryTrackConfig'
        socialMediaBranded: string
        advertisementAllMedia: string
        advertisementAudioOnly: string
        advertisementOnlineOnly: string
        corporateNonProfitCharity: string
        corporateNonProfitCompanyPresentation: string
        corporateNonProfitEducational: string
        filmLargeOnlineOnly: string
        filmLargeAllMedia: string
        filmLargeTrailer: string
        filmSmallAllMedia: string
        filmSmallOnlineOnly: string
        filmSmallTrailer: string
        gamesAppsInAppGame: string
        gamesAppsTrailerPromo: string
        podcastPrivateBranded: string
        podcastPrivateNonBranded: string
        radioTvAudioOnly: string
        radioTvTrailer: string
        radioTvTvShow: string
        socialMediaNonBranded: string
        durations: Array<SyncLicenseDurations>
        enabled: boolean
        submittedAt?: any | null
      } | null
    }
  }> | null
}

export type MusicLibraryLicenseItemFragment = {
  __typename?: 'SyncLicense'
  licenseCategory: SyncLicenseCategory
  projectName: string
  receipt?: { __typename?: 'File'; url: string; path: string } | null
  invoice?: { __typename?: 'File'; url: string; path: string } | null
  order: { __typename?: 'Order'; createdAt?: any | null; totalAmount: number; vatAmount: number }
  trackAudio?: { __typename?: 'File'; url: string; path: string } | null
  track: {
    __typename?: 'PublicTrack'
    id: string
    name: string
    version?: string | null
    alternateGenre?: DistributionGenre | null
    subgenre?: DistributionSubgenre | null
    genre: DistributionGenre
    alternateSubgenre?: DistributionSubgenre | null
    bpm: number
    audioLength?: number | null
    trackArtists?: Array<{
      __typename?: 'PublicTrackArtist'
      primary: boolean
      position: number
      artist: { __typename?: 'PublicArtist'; name?: string | null }
    }> | null
    trackCode?: { __typename?: 'TrackCode'; code: string } | null
    cyaniteMetaData?: {
      __typename?: 'CyaniteMetaData'
      moods?: Array<MoodTag | null> | null
      keyPredictionValue?: KeyPredictionValue | null
      predominantVoiceGender?: PredominantVoiceGender | null
      subgenres?: Array<SubgenreTag | null> | null
      genres?: Array<GenreTag | null> | null
      instruments?: Array<InstrumentTag | null> | null
      bpmRangeAdjusted?: number | null
    } | null
    product: {
      __typename?: 'PublicProduct'
      label: string
      artwork?: {
        __typename?: 'Image'
        small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
        medium?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
      } | null
    }
    contributors?: Array<{
      __typename?: 'Contributor'
      role: ContributorRole
      name: string
      publisher?: string | null
    }> | null
    previewAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
    compressedAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
    musicLibraryTrackConfig?: {
      __typename?: 'MusicLibraryTrackConfig'
      socialMediaBranded: string
      advertisementAllMedia: string
      advertisementAudioOnly: string
      advertisementOnlineOnly: string
      corporateNonProfitCharity: string
      corporateNonProfitCompanyPresentation: string
      corporateNonProfitEducational: string
      filmLargeOnlineOnly: string
      filmLargeAllMedia: string
      filmLargeTrailer: string
      filmSmallAllMedia: string
      filmSmallOnlineOnly: string
      filmSmallTrailer: string
      gamesAppsInAppGame: string
      gamesAppsTrailerPromo: string
      podcastPrivateBranded: string
      podcastPrivateNonBranded: string
      radioTvAudioOnly: string
      radioTvTrailer: string
      radioTvTvShow: string
      socialMediaNonBranded: string
      durations: Array<SyncLicenseDurations>
      enabled: boolean
      submittedAt?: any | null
    } | null
  }
}

export type FilterMusicLibraryTracksQueryVariables = Exact<{
  filters: MusicLibraryTrackFilters
  page?: InputMaybe<Scalars['Int']>
  size?: InputMaybe<Scalars['Int']>
  sort?: InputMaybe<MusicLibrarySortOption>
  limit?: InputMaybe<Scalars['Int']>
}>

export type FilterMusicLibraryTracksQuery = {
  __typename?: 'Query'
  results?: {
    __typename?: 'FilteredMusicLibraryTracksPaginatedResponse'
    totalCount: number
    tracks?: Array<{
      __typename?: 'PublicTrack'
      id: string
      name: string
      version?: string | null
      alternateGenre?: DistributionGenre | null
      subgenre?: DistributionSubgenre | null
      genre: DistributionGenre
      alternateSubgenre?: DistributionSubgenre | null
      bpm: number
      audioLength?: number | null
      trackArtists?: Array<{
        __typename?: 'PublicTrackArtist'
        primary: boolean
        position: number
        artist: { __typename?: 'PublicArtist'; name?: string | null }
      }> | null
      trackCode?: { __typename?: 'TrackCode'; code: string } | null
      cyaniteMetaData?: {
        __typename?: 'CyaniteMetaData'
        moods?: Array<MoodTag | null> | null
        keyPredictionValue?: KeyPredictionValue | null
        predominantVoiceGender?: PredominantVoiceGender | null
        subgenres?: Array<SubgenreTag | null> | null
        genres?: Array<GenreTag | null> | null
        instruments?: Array<InstrumentTag | null> | null
        bpmRangeAdjusted?: number | null
      } | null
      product: {
        __typename?: 'PublicProduct'
        label: string
        artwork?: {
          __typename?: 'Image'
          small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
          medium?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
        } | null
      }
      contributors?: Array<{
        __typename?: 'Contributor'
        role: ContributorRole
        name: string
        publisher?: string | null
      }> | null
      previewAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
      compressedAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
      musicLibraryTrackConfig?: {
        __typename?: 'MusicLibraryTrackConfig'
        socialMediaBranded: string
        advertisementAllMedia: string
        advertisementAudioOnly: string
        advertisementOnlineOnly: string
        corporateNonProfitCharity: string
        corporateNonProfitCompanyPresentation: string
        corporateNonProfitEducational: string
        filmLargeOnlineOnly: string
        filmLargeAllMedia: string
        filmLargeTrailer: string
        filmSmallAllMedia: string
        filmSmallOnlineOnly: string
        filmSmallTrailer: string
        gamesAppsInAppGame: string
        gamesAppsTrailerPromo: string
        podcastPrivateBranded: string
        podcastPrivateNonBranded: string
        radioTvAudioOnly: string
        radioTvTrailer: string
        radioTvTvShow: string
        socialMediaNonBranded: string
        durations: Array<SyncLicenseDurations>
        enabled: boolean
        submittedAt?: any | null
      } | null
    }> | null
  } | null
  basePrices: {
    __typename?: 'MusicLibraryLicenseBasePrices'
    socialMediaBranded: string
    advertisementAllMedia: string
    advertisementAudioOnly: string
    advertisementOnlineOnly: string
    corporateNonProfitCharity: string
    corporateNonProfitCompanyPresentation: string
    corporateNonProfitEducational: string
    filmLargeOnlineOnly: string
    filmLargeAllMedia: string
    filmLargeTrailer: string
    filmSmallAllMedia: string
    filmSmallOnlineOnly: string
    filmSmallTrailer: string
    gamesAppsInAppGame: string
    gamesAppsTrailerPromo: string
    podcastPrivateBranded: string
    podcastPrivateNonBranded: string
    radioTvAudioOnly: string
    radioTvTrailer: string
    radioTvTvShow: string
    socialMediaNonBranded: string
  }
}

export type GetMusicLibraryLicensePriceQueryVariables = Exact<{
  licenseCategory: SyncLicenseCategory
  licenseDuration: SyncLicenseDurations
  licenseSetting: Scalars['String']
  licenseTrackLength: SyncLicenseTrackLengths
}>

export type GetMusicLibraryLicensePriceQuery = { __typename?: 'Query'; getMusicLibraryLicensePrice: number }

export type GetMusicLibraryFilterSettingsQueryVariables = Exact<{ [key: string]: never }>

export type GetMusicLibraryFilterSettingsQuery = {
  __typename?: 'Query'
  getMusicLibraryFilterSettings?: {
    __typename?: 'MusicLibraryFilterSettings'
    genres?: Array<GenreTag | null> | null
    subgenres?: Array<SubgenreTag | null> | null
    moods?: Array<MoodTag | null> | null
    instruments?: Array<InstrumentTag | null> | null
    keys?: Array<KeyPredictionValue | null> | null
    keywords?: Array<{
      __typename?: 'CyaniteMetaDataKeyword'
      id: string
      keyword: string
      weight: number
    } | null> | null
  } | null
}

export type SearchTrackKeywordsQueryVariables = Exact<{
  filter: SearchTrackKeywordFilter
}>

export type SearchTrackKeywordsQuery = {
  __typename?: 'Query'
  searchTrackKeywords?: Array<{
    __typename?: 'CyaniteMetaDataKeyword'
    id: string
    keyword: string
    weight: number
  } | null> | null
}

export type ProductNftCollectionFragment = {
  __typename?: 'NonFungibleTokenCollection'
  id: string
  pricePerToken: number
  totalShare: number
  available: number
  createdAt: any
  sold: number
  track: {
    __typename?: 'PublicTrack'
    id: string
    name: string
    version?: string | null
    trackArtists?: Array<{
      __typename?: 'PublicTrackArtist'
      artist: { __typename?: 'PublicArtist'; name?: string | null }
    }> | null
  }
  availableTokens?: Array<{
    __typename?: 'NonFungibleToken'
    id: string
    share: number
    pendingRoyalties: number
    createdAt: any
  }> | null
  soldTokens?: Array<{
    __typename?: 'SoldNonFungibleToken'
    id: string
    share: number
    mintedAt: any
    tokenId: string
    soldFor: number
  }> | null
}

export type GetNftCollectionsForReleaseQueryVariables = Exact<{
  productId: Scalars['ID']
}>

export type GetNftCollectionsForReleaseQuery = {
  __typename?: 'Query'
  getNftCollectionsForRelease?: Array<{
    __typename?: 'NonFungibleTokenCollection'
    id: string
    pricePerToken: number
    totalShare: number
    available: number
    createdAt: any
    sold: number
    track: {
      __typename?: 'PublicTrack'
      id: string
      name: string
      version?: string | null
      trackArtists?: Array<{
        __typename?: 'PublicTrackArtist'
        artist: { __typename?: 'PublicArtist'; name?: string | null }
      }> | null
    }
    availableTokens?: Array<{
      __typename?: 'NonFungibleToken'
      id: string
      share: number
      pendingRoyalties: number
      createdAt: any
    }> | null
    soldTokens?: Array<{
      __typename?: 'SoldNonFungibleToken'
      id: string
      share: number
      mintedAt: any
      tokenId: string
      soldFor: number
    }> | null
  } | null> | null
}

export type GetNftCollectionQueryVariables = Exact<{
  collectionId: Scalars['ID']
}>

export type GetNftCollectionQuery = {
  __typename?: 'Query'
  getNftCollection: {
    __typename?: 'NonFungibleTokenCollection'
    id: string
    pricePerToken: number
    totalShare: number
    available: number
    createdAt: any
    sold: number
    track: {
      __typename?: 'PublicTrack'
      id: string
      name: string
      version?: string | null
      trackArtists?: Array<{
        __typename?: 'PublicTrackArtist'
        artist: { __typename?: 'PublicArtist'; name?: string | null }
      }> | null
    }
    availableTokens?: Array<{
      __typename?: 'NonFungibleToken'
      id: string
      share: number
      pendingRoyalties: number
      createdAt: any
    }> | null
    soldTokens?: Array<{
      __typename?: 'SoldNonFungibleToken'
      id: string
      share: number
      mintedAt: any
      tokenId: string
      soldFor: number
    }> | null
  }
}

export type GetRemainingNftShareForTrackQueryVariables = Exact<{
  trackId: Scalars['ID']
}>

export type GetRemainingNftShareForTrackQuery = {
  __typename?: 'Query'
  getRemainingNftShareForTrack: { __typename?: 'RemainingNftShare'; share: number }
}

export type CreateNftCollectionMutationVariables = Exact<{
  input?: InputMaybe<CreateNftCollectionInput>
}>

export type CreateNftCollectionMutation = {
  __typename?: 'Mutation'
  createNftCollection?: {
    __typename?: 'NonFungibleTokenCollection'
    id: string
    pricePerToken: number
    totalShare: number
    available: number
    createdAt: any
    sold: number
    track: {
      __typename?: 'PublicTrack'
      id: string
      name: string
      version?: string | null
      trackArtists?: Array<{
        __typename?: 'PublicTrackArtist'
        artist: { __typename?: 'PublicArtist'; name?: string | null }
      }> | null
    }
    availableTokens?: Array<{
      __typename?: 'NonFungibleToken'
      id: string
      share: number
      pendingRoyalties: number
      createdAt: any
    }> | null
    soldTokens?: Array<{
      __typename?: 'SoldNonFungibleToken'
      id: string
      share: number
      mintedAt: any
      tokenId: string
      soldFor: number
    }> | null
  } | null
}

export type EditNftCollectionMutationVariables = Exact<{
  input?: InputMaybe<EditNftCollectionInput>
}>

export type EditNftCollectionMutation = {
  __typename?: 'Mutation'
  editNftCollection?: {
    __typename?: 'NonFungibleTokenCollection'
    id: string
    pricePerToken: number
    totalShare: number
    available: number
    createdAt: any
    sold: number
    track: {
      __typename?: 'PublicTrack'
      id: string
      name: string
      version?: string | null
      trackArtists?: Array<{
        __typename?: 'PublicTrackArtist'
        artist: { __typename?: 'PublicArtist'; name?: string | null }
      }> | null
    }
    availableTokens?: Array<{
      __typename?: 'NonFungibleToken'
      id: string
      share: number
      pendingRoyalties: number
      createdAt: any
    }> | null
    soldTokens?: Array<{
      __typename?: 'SoldNonFungibleToken'
      id: string
      share: number
      mintedAt: any
      tokenId: string
      soldFor: number
    }> | null
  } | null
}

export type AvailableNftsFragment = {
  __typename?: 'NonFungibleTokenCollection'
  id: string
  index: number
  createdAt: any
  available: number
  pricePerToken: number
  sharePerToken: number
  totalShare: number
  sold: number
  availableTokens?: Array<{
    __typename?: 'NonFungibleToken'
    claimedRoyalties: number
    pendingRoyalties: number
    share: number
  }> | null
  track: {
    __typename?: 'PublicTrack'
    id: string
    name: string
    version?: string | null
    alternateGenre?: DistributionGenre | null
    subgenre?: DistributionSubgenre | null
    genre: DistributionGenre
    alternateSubgenre?: DistributionSubgenre | null
    bpm: number
    audioLength?: number | null
    product: {
      __typename?: 'PublicProduct'
      distributionExpiryDate?: any | null
      label: string
      artwork?: {
        __typename?: 'Image'
        small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
        medium?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
      } | null
    }
    trackArtists?: Array<{
      __typename?: 'PublicTrackArtist'
      primary: boolean
      position: number
      artist: { __typename?: 'PublicArtist'; name?: string | null }
    }> | null
    trackCode?: { __typename?: 'TrackCode'; code: string } | null
    cyaniteMetaData?: {
      __typename?: 'CyaniteMetaData'
      moods?: Array<MoodTag | null> | null
      keyPredictionValue?: KeyPredictionValue | null
      predominantVoiceGender?: PredominantVoiceGender | null
      subgenres?: Array<SubgenreTag | null> | null
      genres?: Array<GenreTag | null> | null
      instruments?: Array<InstrumentTag | null> | null
      bpmRangeAdjusted?: number | null
    } | null
    contributors?: Array<{
      __typename?: 'Contributor'
      role: ContributorRole
      name: string
      publisher?: string | null
    }> | null
    previewAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
    compressedAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
    musicLibraryTrackConfig?: {
      __typename?: 'MusicLibraryTrackConfig'
      socialMediaBranded: string
      advertisementAllMedia: string
      advertisementAudioOnly: string
      advertisementOnlineOnly: string
      corporateNonProfitCharity: string
      corporateNonProfitCompanyPresentation: string
      corporateNonProfitEducational: string
      filmLargeOnlineOnly: string
      filmLargeAllMedia: string
      filmLargeTrailer: string
      filmSmallAllMedia: string
      filmSmallOnlineOnly: string
      filmSmallTrailer: string
      gamesAppsInAppGame: string
      gamesAppsTrailerPromo: string
      podcastPrivateBranded: string
      podcastPrivateNonBranded: string
      radioTvAudioOnly: string
      radioTvTrailer: string
      radioTvTvShow: string
      socialMediaNonBranded: string
      durations: Array<SyncLicenseDurations>
      enabled: boolean
      submittedAt?: any | null
    } | null
  }
}

export type FilterAvailableNftsQueryVariables = Exact<{
  filters?: InputMaybe<NftCollectionFilters>
  sort?: InputMaybe<NftCollectionSortOptions>
  page?: InputMaybe<Scalars['Int']>
  size?: InputMaybe<Scalars['Int']>
}>

export type FilterAvailableNftsQuery = {
  __typename?: 'Query'
  filterAvailableNfts?: {
    __typename?: 'FilteredNftCollectionPaginatedResponse'
    totalCount: number
    collections?: Array<{
      __typename?: 'NonFungibleTokenCollection'
      id: string
      index: number
      createdAt: any
      available: number
      pricePerToken: number
      sharePerToken: number
      totalShare: number
      sold: number
      availableTokens?: Array<{
        __typename?: 'NonFungibleToken'
        claimedRoyalties: number
        pendingRoyalties: number
        share: number
      }> | null
      track: {
        __typename?: 'PublicTrack'
        id: string
        name: string
        version?: string | null
        alternateGenre?: DistributionGenre | null
        subgenre?: DistributionSubgenre | null
        genre: DistributionGenre
        alternateSubgenre?: DistributionSubgenre | null
        bpm: number
        audioLength?: number | null
        product: {
          __typename?: 'PublicProduct'
          distributionExpiryDate?: any | null
          label: string
          artwork?: {
            __typename?: 'Image'
            small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
            medium?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
          } | null
        }
        trackArtists?: Array<{
          __typename?: 'PublicTrackArtist'
          primary: boolean
          position: number
          artist: { __typename?: 'PublicArtist'; name?: string | null }
        }> | null
        trackCode?: { __typename?: 'TrackCode'; code: string } | null
        cyaniteMetaData?: {
          __typename?: 'CyaniteMetaData'
          moods?: Array<MoodTag | null> | null
          keyPredictionValue?: KeyPredictionValue | null
          predominantVoiceGender?: PredominantVoiceGender | null
          subgenres?: Array<SubgenreTag | null> | null
          genres?: Array<GenreTag | null> | null
          instruments?: Array<InstrumentTag | null> | null
          bpmRangeAdjusted?: number | null
        } | null
        contributors?: Array<{
          __typename?: 'Contributor'
          role: ContributorRole
          name: string
          publisher?: string | null
        }> | null
        previewAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
        compressedAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
        musicLibraryTrackConfig?: {
          __typename?: 'MusicLibraryTrackConfig'
          socialMediaBranded: string
          advertisementAllMedia: string
          advertisementAudioOnly: string
          advertisementOnlineOnly: string
          corporateNonProfitCharity: string
          corporateNonProfitCompanyPresentation: string
          corporateNonProfitEducational: string
          filmLargeOnlineOnly: string
          filmLargeAllMedia: string
          filmLargeTrailer: string
          filmSmallAllMedia: string
          filmSmallOnlineOnly: string
          filmSmallTrailer: string
          gamesAppsInAppGame: string
          gamesAppsTrailerPromo: string
          podcastPrivateBranded: string
          podcastPrivateNonBranded: string
          radioTvAudioOnly: string
          radioTvTrailer: string
          radioTvTvShow: string
          socialMediaNonBranded: string
          durations: Array<SyncLicenseDurations>
          enabled: boolean
          submittedAt?: any | null
        } | null
      }
    } | null> | null
  } | null
}

export type CreateNftPurchaseOrderMutationVariables = Exact<{
  input?: InputMaybe<CreateNftPurchaseOrderInput>
}>

export type CreateNftPurchaseOrderMutation = {
  __typename?: 'Mutation'
  createNftPurchaseOrder?: {
    __typename?: 'CreateNftPurchaseOrderResponse'
    nftPurchaseId: string
    paypalOrderId?: string | null
    price: number
    totalOrderAmount: number
    vatAmount?: number | null
  } | null
}

export type NftStatementItemFragment = {
  __typename?: 'NonFungibleTokenStatement'
  createdAt?: any | null
  payoutAmount: number
  nonFungibleToken: {
    __typename?: 'SoldNonFungibleToken'
    share: number
    purchase: {
      __typename?: 'NonFungibleTokenPurchase'
      buyer?: { __typename?: 'PublicUserProfile'; name?: string | null } | null
    }
  }
  track: {
    __typename?: 'PublicTrack'
    id: string
    name: string
    version?: string | null
    alternateGenre?: DistributionGenre | null
    subgenre?: DistributionSubgenre | null
    genre: DistributionGenre
    alternateSubgenre?: DistributionSubgenre | null
    bpm: number
    audioLength?: number | null
    trackArtists?: Array<{
      __typename?: 'PublicTrackArtist'
      primary: boolean
      position: number
      artist: { __typename?: 'PublicArtist'; name?: string | null }
    }> | null
    trackCode?: { __typename?: 'TrackCode'; code: string } | null
    cyaniteMetaData?: {
      __typename?: 'CyaniteMetaData'
      moods?: Array<MoodTag | null> | null
      keyPredictionValue?: KeyPredictionValue | null
      predominantVoiceGender?: PredominantVoiceGender | null
      subgenres?: Array<SubgenreTag | null> | null
      genres?: Array<GenreTag | null> | null
      instruments?: Array<InstrumentTag | null> | null
      bpmRangeAdjusted?: number | null
    } | null
    product: {
      __typename?: 'PublicProduct'
      label: string
      artwork?: {
        __typename?: 'Image'
        small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
        medium?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
      } | null
    }
    contributors?: Array<{
      __typename?: 'Contributor'
      role: ContributorRole
      name: string
      publisher?: string | null
    }> | null
    previewAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
    compressedAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
    musicLibraryTrackConfig?: {
      __typename?: 'MusicLibraryTrackConfig'
      socialMediaBranded: string
      advertisementAllMedia: string
      advertisementAudioOnly: string
      advertisementOnlineOnly: string
      corporateNonProfitCharity: string
      corporateNonProfitCompanyPresentation: string
      corporateNonProfitEducational: string
      filmLargeOnlineOnly: string
      filmLargeAllMedia: string
      filmLargeTrailer: string
      filmSmallAllMedia: string
      filmSmallOnlineOnly: string
      filmSmallTrailer: string
      gamesAppsInAppGame: string
      gamesAppsTrailerPromo: string
      podcastPrivateBranded: string
      podcastPrivateNonBranded: string
      radioTvAudioOnly: string
      radioTvTrailer: string
      radioTvTvShow: string
      socialMediaNonBranded: string
      durations: Array<SyncLicenseDurations>
      enabled: boolean
      submittedAt?: any | null
    } | null
  }
}

export type GetMyNftStatementsExportQueryVariables = Exact<{
  filters: GetNonFungibleTokenStatementsFilters
}>

export type GetMyNftStatementsExportQuery = {
  __typename?: 'Query'
  getMyNftStatementsExport: { __typename?: 'File'; url: string; expiresAt: string }
}

export type GetGroupNftStatementsExportQueryVariables = Exact<{
  groupId: Scalars['ID']
  filters: GetNonFungibleTokenStatementsFilters
}>

export type GetGroupNftStatementsExportQuery = {
  __typename?: 'Query'
  getGroupNftStatementsExport: { __typename?: 'File'; url: string; expiresAt: string }
}

export type GetGroupNonFungibleTokenStatementsQueryVariables = Exact<{
  filters: GetNonFungibleTokenStatementsFilters
  groupId: Scalars['ID']
}>

export type GetGroupNonFungibleTokenStatementsQuery = {
  __typename?: 'Query'
  getGroupNonFungibleTokenStatements?: Array<{
    __typename?: 'NonFungibleTokenStatement'
    createdAt?: any | null
    payoutAmount: number
    nonFungibleToken: {
      __typename?: 'SoldNonFungibleToken'
      share: number
      purchase: {
        __typename?: 'NonFungibleTokenPurchase'
        buyer?: { __typename?: 'PublicUserProfile'; name?: string | null } | null
      }
    }
    track: {
      __typename?: 'PublicTrack'
      id: string
      name: string
      version?: string | null
      alternateGenre?: DistributionGenre | null
      subgenre?: DistributionSubgenre | null
      genre: DistributionGenre
      alternateSubgenre?: DistributionSubgenre | null
      bpm: number
      audioLength?: number | null
      trackArtists?: Array<{
        __typename?: 'PublicTrackArtist'
        primary: boolean
        position: number
        artist: { __typename?: 'PublicArtist'; name?: string | null }
      }> | null
      trackCode?: { __typename?: 'TrackCode'; code: string } | null
      cyaniteMetaData?: {
        __typename?: 'CyaniteMetaData'
        moods?: Array<MoodTag | null> | null
        keyPredictionValue?: KeyPredictionValue | null
        predominantVoiceGender?: PredominantVoiceGender | null
        subgenres?: Array<SubgenreTag | null> | null
        genres?: Array<GenreTag | null> | null
        instruments?: Array<InstrumentTag | null> | null
        bpmRangeAdjusted?: number | null
      } | null
      product: {
        __typename?: 'PublicProduct'
        label: string
        artwork?: {
          __typename?: 'Image'
          small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
          medium?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
        } | null
      }
      contributors?: Array<{
        __typename?: 'Contributor'
        role: ContributorRole
        name: string
        publisher?: string | null
      }> | null
      previewAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
      compressedAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
      musicLibraryTrackConfig?: {
        __typename?: 'MusicLibraryTrackConfig'
        socialMediaBranded: string
        advertisementAllMedia: string
        advertisementAudioOnly: string
        advertisementOnlineOnly: string
        corporateNonProfitCharity: string
        corporateNonProfitCompanyPresentation: string
        corporateNonProfitEducational: string
        filmLargeOnlineOnly: string
        filmLargeAllMedia: string
        filmLargeTrailer: string
        filmSmallAllMedia: string
        filmSmallOnlineOnly: string
        filmSmallTrailer: string
        gamesAppsInAppGame: string
        gamesAppsTrailerPromo: string
        podcastPrivateBranded: string
        podcastPrivateNonBranded: string
        radioTvAudioOnly: string
        radioTvTrailer: string
        radioTvTvShow: string
        socialMediaNonBranded: string
        durations: Array<SyncLicenseDurations>
        enabled: boolean
        submittedAt?: any | null
      } | null
    }
  }> | null
}

export type GetUserNonFungibleTokenStatementsQueryVariables = Exact<{
  filters: GetNonFungibleTokenStatementsFilters
}>

export type GetUserNonFungibleTokenStatementsQuery = {
  __typename?: 'Query'
  getUserNonFungibleTokenStatements?: Array<{
    __typename?: 'NonFungibleTokenStatement'
    createdAt?: any | null
    payoutAmount: number
    nonFungibleToken: {
      __typename?: 'SoldNonFungibleToken'
      share: number
      purchase: {
        __typename?: 'NonFungibleTokenPurchase'
        buyer?: { __typename?: 'PublicUserProfile'; name?: string | null } | null
      }
    }
    track: {
      __typename?: 'PublicTrack'
      id: string
      name: string
      version?: string | null
      alternateGenre?: DistributionGenre | null
      subgenre?: DistributionSubgenre | null
      genre: DistributionGenre
      alternateSubgenre?: DistributionSubgenre | null
      bpm: number
      audioLength?: number | null
      trackArtists?: Array<{
        __typename?: 'PublicTrackArtist'
        primary: boolean
        position: number
        artist: { __typename?: 'PublicArtist'; name?: string | null }
      }> | null
      trackCode?: { __typename?: 'TrackCode'; code: string } | null
      cyaniteMetaData?: {
        __typename?: 'CyaniteMetaData'
        moods?: Array<MoodTag | null> | null
        keyPredictionValue?: KeyPredictionValue | null
        predominantVoiceGender?: PredominantVoiceGender | null
        subgenres?: Array<SubgenreTag | null> | null
        genres?: Array<GenreTag | null> | null
        instruments?: Array<InstrumentTag | null> | null
        bpmRangeAdjusted?: number | null
      } | null
      product: {
        __typename?: 'PublicProduct'
        label: string
        artwork?: {
          __typename?: 'Image'
          small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
          medium?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
        } | null
      }
      contributors?: Array<{
        __typename?: 'Contributor'
        role: ContributorRole
        name: string
        publisher?: string | null
      }> | null
      previewAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
      compressedAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
      musicLibraryTrackConfig?: {
        __typename?: 'MusicLibraryTrackConfig'
        socialMediaBranded: string
        advertisementAllMedia: string
        advertisementAudioOnly: string
        advertisementOnlineOnly: string
        corporateNonProfitCharity: string
        corporateNonProfitCompanyPresentation: string
        corporateNonProfitEducational: string
        filmLargeOnlineOnly: string
        filmLargeAllMedia: string
        filmLargeTrailer: string
        filmSmallAllMedia: string
        filmSmallOnlineOnly: string
        filmSmallTrailer: string
        gamesAppsInAppGame: string
        gamesAppsTrailerPromo: string
        podcastPrivateBranded: string
        podcastPrivateNonBranded: string
        radioTvAudioOnly: string
        radioTvTrailer: string
        radioTvTvShow: string
        socialMediaNonBranded: string
        durations: Array<SyncLicenseDurations>
        enabled: boolean
        submittedAt?: any | null
      } | null
    }
  }> | null
}

export type GetPlaylistsQueryVariables = Exact<{
  filter?: InputMaybe<PaginationFilter>
}>

export type GetPlaylistsQuery = {
  __typename?: 'Query'
  getPlaylists?: {
    __typename?: 'PlaylistPaginationResults'
    data?: Array<{
      __typename?: 'Playlist'
      createdAt: any
      darkText: boolean
      name: string
      position: number
      query: string
      artwork?: {
        __typename?: 'Image'
        small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
        medium?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
      } | null
    } | null> | null
  } | null
}

export type GetMyProductsQueryVariables = Exact<{
  managerId: Scalars['ID']
  filters?: InputMaybe<PaginationFilter>
}>

export type GetMyProductsQuery = {
  __typename?: 'Query'
  getMyProducts?: {
    __typename?: 'ProductPaginationResults'
    total: number
    data?: Array<{
      __typename?: 'Product'
      id: string
      fugaId?: string | null
      name: string
      label: string
      catalogNumber: string
      releaseVersion?: string | null
      consumerReleaseDate: any
      originalReleaseDate: any
      recordingYear?: number | null
      updatedAt: any
      recordingLocation?: string | null
      copyrightYear: number
      copyrightText: string
      phonogramCopyrightYear: number
      phonogramCopyrightText: string
      genre: string
      subgenre?: string | null
      alternateGenre?: string | null
      alternateSubgenre?: string | null
      language: string
      parentalAdvisory: ParentalAdvisory
      artwork?: {
        __typename?: 'Image'
        small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
        medium?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
      } | null
      distributionChannels: Array<{
        __typename?: 'ProductDistributionChannel'
        id: string
        channelId: string
        name: string
        status: DistributionChannelStatus
        subChannels: Array<{
          __typename?: 'ProductDistributionSubChannel'
          name: string
          status: DistributionChannelStatus
        }>
      }>
      productCode?: { __typename?: 'ProductCode'; code: string } | null
    } | null> | null
  } | null
}

export type GetMyProductQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetMyProductQuery = {
  __typename?: 'Query'
  getMyProduct?: {
    __typename?: 'Product'
    id: string
    fugaId?: string | null
    name: string
    canBeEdited: boolean
    label: string
    catalogNumber: string
    releaseVersion?: string | null
    consumerReleaseDate: any
    originalReleaseDate: any
    recordingYear?: number | null
    updatedAt: any
    recordingLocation?: string | null
    copyrightYear: number
    copyrightText: string
    phonogramCopyrightYear: number
    phonogramCopyrightText: string
    genre: string
    subgenre?: string | null
    alternateGenre?: string | null
    alternateSubgenre?: string | null
    language: string
    parentalAdvisory: ParentalAdvisory
    invoiceSetting?: ProductPaymentInvoiceSetting | null
    distributionExpiryDate?: any | null
    paymentPriceModel?: ProductPaymentPriceModel | null
    isDistributable?: boolean | null
    clearance?: MusicLibraryClearanceType | null
    publishedAt?: any | null
    distributedAt?: any | null
    distributeRequest?: any | null
    priceModelCommissionPercentage?: number | null
    musicLibraryConfigSetting?: MusicLibraryConfigSetting | null
    lastDeliveryDate?: any | null
    useQCFlow: boolean
    lastEditedAt?: any | null
    approvals?: Array<{
      __typename?: 'ProductApprovalStatus'
      channelId: string
      createdAt: any
      updatedAt: any
      productId: string
      latest: boolean
      status: string
      id: string
      reasons?: Array<{
        __typename?: 'RejectionReason'
        category: string
        importance: string
        internalComment?: string | null
        reason: string
        userComment?: string | null
      } | null> | null
    }> | null
    artwork?: {
      __typename?: 'Image'
      small?: { __typename?: 'File'; url: string; path: string; expiresAt: string; expiresIn: number } | null
      medium?: { __typename?: 'File'; url: string; path: string; expiresAt: string; expiresIn: number } | null
    } | null
    distributionChannels: Array<{
      __typename?: 'ProductDistributionChannel'
      id: string
      channelId: string
      name: string
      status: DistributionChannelStatus
      subChannels: Array<{
        __typename?: 'ProductDistributionSubChannel'
        name: string
        status: DistributionChannelStatus
      }>
    }>
    productArtists?: Array<{
      __typename?: 'ProductArtist'
      primary: boolean
      position: number
      artist: { __typename?: 'Artist'; id: string; fugaId?: string | null; name?: string | null }
    }> | null
    productCode?: { __typename?: 'ProductCode'; code: string } | null
    tracks?: Array<{
      __typename?: 'Track'
      id: string
      fugaId?: string | null
      name: string
      version?: string | null
      bpm: number
      audioLanguage?: string | null
      lyrics?: string | null
      updatedAt: any
      parentalAdvisory: ParentalAdvisory
      previewStart: number
      previewLength?: number | null
      originalFilename?: string | null
      clearance?: MusicLibraryClearanceType | null
      audio: { __typename?: 'File'; url: string; path: string; expiresAt: string }
      contributors?: Array<{ __typename?: 'Contributor'; name: string; role: ContributorRole }> | null
      trackCode?: { __typename?: 'TrackCode'; code: string } | null
      musicLibraryTrackConfig?: {
        __typename?: 'MusicLibraryTrackConfig'
        socialMediaBranded: string
        advertisementAllMedia: string
        advertisementAudioOnly: string
        advertisementOnlineOnly: string
        corporateNonProfitCharity: string
        corporateNonProfitCompanyPresentation: string
        corporateNonProfitEducational: string
        filmLargeOnlineOnly: string
        filmLargeAllMedia: string
        filmLargeTrailer: string
        filmSmallAllMedia: string
        filmSmallOnlineOnly: string
        filmSmallTrailer: string
        gamesAppsInAppGame: string
        gamesAppsTrailerPromo: string
        podcastPrivateBranded: string
        podcastPrivateNonBranded: string
        radioTvAudioOnly: string
        radioTvTrailer: string
        radioTvTvShow: string
        socialMediaNonBranded: string
        durations: Array<SyncLicenseDurations>
        enabled: boolean
        submittedAt?: any | null
      } | null
      trackArtists?: Array<{
        __typename?: 'TrackArtist'
        artistId: string
        primary: boolean
        artist: { __typename?: 'Artist'; name?: string | null; fugaId?: string | null }
      }> | null
      groupShares?: Array<{
        __typename?: 'GroupShare'
        trackId: string
        version: number
        createdAt: any
        acceptedAt?: any | null
        declinedAt?: any | null
        share: number
        type: RoyaltyRevenueType
        group?: {
          __typename?: 'PublicGroup'
          name: string
          id: string
          avatar?: {
            __typename?: 'Image'
            small?: { __typename?: 'File'; url: string; path: string; expiresAt: string; expiresIn: number } | null
          } | null
        } | null
      } | null> | null
    }> | null
    musicLibraryProductConfig?: {
      __typename?: 'MusicLibraryProductConfig'
      id: string
      socialMediaBranded: string
      advertisementAllMedia: string
      advertisementAudioOnly: string
      advertisementOnlineOnly: string
      corporateNonProfitCharity: string
      corporateNonProfitCompanyPresentation: string
      corporateNonProfitEducational: string
      filmLargeOnlineOnly: string
      filmLargeAllMedia: string
      filmLargeTrailer: string
      filmSmallAllMedia: string
      filmSmallOnlineOnly: string
      filmSmallTrailer: string
      gamesAppsInAppGame: string
      gamesAppsTrailerPromo: string
      podcastPrivateBranded: string
      podcastPrivateNonBranded: string
      radioTvAudioOnly: string
      radioTvTrailer: string
      radioTvTvShow: string
      socialMediaNonBranded: string
      durations: Array<SyncLicenseDurations>
      enabled: boolean
    } | null
  } | null
}

export type AddProductMutationVariables = Exact<{
  managerId: Scalars['ID']
  input: AddProductInput
}>

export type AddProductMutation = {
  __typename?: 'Mutation'
  addProduct?: {
    __typename?: 'Product'
    id: string
    fugaId?: string | null
    name: string
    label: string
    catalogNumber: string
    releaseVersion?: string | null
    consumerReleaseDate: any
    originalReleaseDate: any
    recordingYear?: number | null
    recordingLocation?: string | null
    copyrightYear: number
    copyrightText: string
    phonogramCopyrightYear: number
    phonogramCopyrightText: string
    genre: string
    subgenre?: string | null
    alternateGenre?: string | null
    alternateSubgenre?: string | null
    language: string
    parentalAdvisory: ParentalAdvisory
    artwork?: {
      __typename?: 'Image'
      small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
    } | null
    productCode?: { __typename?: 'ProductCode'; code: string } | null
  } | null
}

export type EditProductMutationVariables = Exact<{
  id: Scalars['ID']
  input: EditProductInput
}>

export type EditProductMutation = {
  __typename?: 'Mutation'
  editProduct?: { __typename?: 'Product'; id: string } | null
}

export type PublishProductMutationVariables = Exact<{
  id: Scalars['ID']
  channelId: Scalars['String']
}>

export type PublishProductMutation = {
  __typename?: 'Mutation'
  publishProduct?: { __typename?: 'Product'; id: string; publishedAt?: any | null; distributedAt?: any | null } | null
}

export type UpdateTrackOrderMutationVariables = Exact<{
  productId: Scalars['ID']
  input: Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
}>

export type UpdateTrackOrderMutation = {
  __typename?: 'Mutation'
  updateTrackOrder?: { __typename?: 'Product'; id: string } | null
}

export type SyncFugaProductMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type SyncFugaProductMutation = {
  __typename?: 'Mutation'
  syncFugaProduct?: { __typename?: 'Product'; id: string; lastDeliveryDate?: any | null } | null
}

export type AddProductArtistMutationVariables = Exact<{
  productId: Scalars['ID']
  artistId: Scalars['ID']
  input: ProductArtistInput
}>

export type AddProductArtistMutation = {
  __typename?: 'Mutation'
  addProductArtist?: {
    __typename?: 'ProductArtist'
    productId: string
    artistId: string
    primary: boolean
    position: number
  } | null
}

export type RemoveProductArtistMutationVariables = Exact<{
  productId: Scalars['ID']
  artistId: Scalars['ID']
}>

export type RemoveProductArtistMutation = {
  __typename?: 'Mutation'
  removeProductArtist?: { __typename?: 'ProductArtist'; productId: string; artistId: string; primary: boolean } | null
}

export type UpdatePriceModelMutationVariables = Exact<{
  input: ProductPaymentPreference
  id: Scalars['ID']
}>

export type UpdatePriceModelMutation = {
  __typename?: 'Mutation'
  setProductPaymentPreference?: { __typename?: 'Product'; id: string } | null
}

export type CreateFlatFeeOrderMutationVariables = Exact<{
  managerId: Scalars['ID']
  productId: Scalars['ID']
}>

export type CreateFlatFeeOrderMutation = {
  __typename?: 'Mutation'
  createFlatFeeOrder?: { __typename?: 'CreateFlatFeeOrderResponse'; paypalOrderId?: string | null } | null
}

export type GetPaymentPriceModelPromotionQueryVariables = Exact<{
  groupId: Scalars['ID']
}>

export type GetPaymentPriceModelPromotionQuery = {
  __typename?: 'Query'
  getPaymentPriceModelPromotion?: {
    __typename?: 'PaymentPriceModelPromotion'
    id: string
    groupId?: string | null
    startDate: any
    endDate?: any | null
    paymentPriceModel: ProductPaymentPriceModel
    amount?: number | null
    percentage?: number | null
  } | null
}

export type PublishingClearanceOverviewItemFragment = {
  __typename?: 'TrackGroupShareTrack'
  id: string
  name: string
  version?: string | null
  clearance?: MusicLibraryClearanceType | null
  trackArtists?: Array<{
    __typename?: 'PublicTrackArtist'
    primary: boolean
    position: number
    artist: { __typename?: 'PublicArtist'; name?: string | null }
  }> | null
  managers?: Array<{
    __typename?: 'PublicManager'
    group: {
      __typename?: 'PublicGroup'
      id: string
      name: string
      avatar?: {
        __typename?: 'Image'
        small?: { __typename?: 'File'; url: string; path: string; expiresAt: string; expiresIn: number } | null
      } | null
    }
  }> | null
  product: {
    __typename?: 'PublicProduct'
    artwork?: {
      __typename?: 'Image'
      small?: { __typename?: 'File'; url: string; path: string; expiresAt: string; expiresIn: number } | null
    } | null
  }
  getMyCurrentPublishingGroupShare: {
    __typename?: 'GroupShare'
    acceptedAt?: any | null
    declinedAt?: any | null
    createdAt: any
    type: RoyaltyRevenueType
    share: number
  }
}

export type GetMyTracksWithGroupSharesQueryVariables = Exact<{
  groupId: Scalars['ID']
  filter: TrackGroupShareTrackSearchFilter
}>

export type GetMyTracksWithGroupSharesQuery = {
  __typename?: 'Query'
  getMyTracksWithGroupShares?: {
    __typename?: 'TrackGroupShareTracksSearchResults'
    total: number
    data?: Array<{
      __typename?: 'TrackGroupShareTrack'
      id: string
      name: string
      version?: string | null
      clearance?: MusicLibraryClearanceType | null
      trackArtists?: Array<{
        __typename?: 'PublicTrackArtist'
        primary: boolean
        position: number
        artist: { __typename?: 'PublicArtist'; name?: string | null }
      }> | null
      managers?: Array<{
        __typename?: 'PublicManager'
        group: {
          __typename?: 'PublicGroup'
          id: string
          name: string
          avatar?: {
            __typename?: 'Image'
            small?: { __typename?: 'File'; url: string; path: string; expiresAt: string; expiresIn: number } | null
          } | null
        }
      }> | null
      product: {
        __typename?: 'PublicProduct'
        artwork?: {
          __typename?: 'Image'
          small?: { __typename?: 'File'; url: string; path: string; expiresAt: string; expiresIn: number } | null
        } | null
      }
      getMyCurrentPublishingGroupShare: {
        __typename?: 'GroupShare'
        acceptedAt?: any | null
        declinedAt?: any | null
        createdAt: any
        type: RoyaltyRevenueType
        share: number
      }
    } | null> | null
  } | null
}

export type GetMyTrackWithPublishingGroupShareQueryVariables = Exact<{
  groupId: Scalars['ID']
  trackId: Scalars['ID']
}>

export type GetMyTrackWithPublishingGroupShareQuery = {
  __typename?: 'Query'
  getMyTrackWithPublishingGroupShare?: {
    __typename?: 'TrackGroupShareTrack'
    id: string
    name: string
    version?: string | null
    clearance?: MusicLibraryClearanceType | null
    musicLibraryTrackConfig?: {
      __typename?: 'MusicLibraryTrackConfig'
      socialMediaBranded: string
      advertisementAllMedia: string
      advertisementAudioOnly: string
      advertisementOnlineOnly: string
      corporateNonProfitCharity: string
      corporateNonProfitCompanyPresentation: string
      corporateNonProfitEducational: string
      filmLargeOnlineOnly: string
      filmLargeAllMedia: string
      filmLargeTrailer: string
      filmSmallAllMedia: string
      filmSmallOnlineOnly: string
      filmSmallTrailer: string
      gamesAppsInAppGame: string
      gamesAppsTrailerPromo: string
      podcastPrivateBranded: string
      podcastPrivateNonBranded: string
      radioTvAudioOnly: string
      radioTvTrailer: string
      radioTvTvShow: string
      socialMediaNonBranded: string
      durations: Array<SyncLicenseDurations>
      enabled: boolean
    } | null
    getCurrentPublishingGroupShares?: Array<{
      __typename?: 'GroupShare'
      share: number
      group?: { __typename?: 'PublicGroup'; name: string } | null
    }> | null
    trackArtists?: Array<{
      __typename?: 'PublicTrackArtist'
      primary: boolean
      position: number
      artist: { __typename?: 'PublicArtist'; name?: string | null }
    }> | null
    managers?: Array<{
      __typename?: 'PublicManager'
      group: {
        __typename?: 'PublicGroup'
        id: string
        name: string
        avatar?: {
          __typename?: 'Image'
          small?: { __typename?: 'File'; url: string; path: string; expiresAt: string; expiresIn: number } | null
        } | null
      }
    }> | null
    product: {
      __typename?: 'PublicProduct'
      artwork?: {
        __typename?: 'Image'
        small?: { __typename?: 'File'; url: string; path: string; expiresAt: string; expiresIn: number } | null
      } | null
    }
    getMyCurrentPublishingGroupShare: {
      __typename?: 'GroupShare'
      acceptedAt?: any | null
      declinedAt?: any | null
      createdAt: any
      type: RoyaltyRevenueType
      share: number
    }
  } | null
}

export type PublishingClearanceDetailFragment = {
  __typename?: 'TrackGroupShareTrack'
  id: string
  name: string
  version?: string | null
  clearance?: MusicLibraryClearanceType | null
  musicLibraryTrackConfig?: {
    __typename?: 'MusicLibraryTrackConfig'
    socialMediaBranded: string
    advertisementAllMedia: string
    advertisementAudioOnly: string
    advertisementOnlineOnly: string
    corporateNonProfitCharity: string
    corporateNonProfitCompanyPresentation: string
    corporateNonProfitEducational: string
    filmLargeOnlineOnly: string
    filmLargeAllMedia: string
    filmLargeTrailer: string
    filmSmallAllMedia: string
    filmSmallOnlineOnly: string
    filmSmallTrailer: string
    gamesAppsInAppGame: string
    gamesAppsTrailerPromo: string
    podcastPrivateBranded: string
    podcastPrivateNonBranded: string
    radioTvAudioOnly: string
    radioTvTrailer: string
    radioTvTvShow: string
    socialMediaNonBranded: string
    durations: Array<SyncLicenseDurations>
    enabled: boolean
  } | null
  getCurrentPublishingGroupShares?: Array<{
    __typename?: 'GroupShare'
    share: number
    group?: { __typename?: 'PublicGroup'; name: string } | null
  }> | null
  trackArtists?: Array<{
    __typename?: 'PublicTrackArtist'
    primary: boolean
    position: number
    artist: { __typename?: 'PublicArtist'; name?: string | null }
  }> | null
  managers?: Array<{
    __typename?: 'PublicManager'
    group: {
      __typename?: 'PublicGroup'
      id: string
      name: string
      avatar?: {
        __typename?: 'Image'
        small?: { __typename?: 'File'; url: string; path: string; expiresAt: string; expiresIn: number } | null
      } | null
    }
  }> | null
  product: {
    __typename?: 'PublicProduct'
    artwork?: {
      __typename?: 'Image'
      small?: { __typename?: 'File'; url: string; path: string; expiresAt: string; expiresIn: number } | null
    } | null
  }
  getMyCurrentPublishingGroupShare: {
    __typename?: 'GroupShare'
    acceptedAt?: any | null
    declinedAt?: any | null
    createdAt: any
    type: RoyaltyRevenueType
    share: number
  }
}

export type ProposalDetailEditMutationVariables = Exact<{
  trackId: Scalars['ID']
  groupId: Scalars['ID']
  action: MusicLibraryClearanceEditType
}>

export type ProposalDetailEditMutation = {
  __typename?: 'Mutation'
  editPublishingTrackGroupShare?: { __typename?: 'GroupShare'; declinedAt?: any | null; acceptedAt?: any | null } | null
}

export type StartSendbirdChatMutationVariables = Exact<{
  initiatingGroupId: Scalars['ID']
  targetGroupId: Scalars['ID']
}>

export type StartSendbirdChatMutation = {
  __typename?: 'Mutation'
  startChat?: { __typename?: 'GroupChannel'; channelUrl: string } | null
}

export type RefreshSendbirdSessionForGroupMutationVariables = Exact<{
  input: RefreshSendbirdSessionInput
}>

export type RefreshSendbirdSessionForGroupMutation = {
  __typename?: 'Mutation'
  refreshSendbirdSession: { __typename?: 'SendbirdSession'; userId: string; token: string; expiresAt: any }
}

export type GetFugaTrendsTotalsQueryVariables = Exact<{
  groupId: Scalars['ID']
  input?: InputMaybe<AnalyticsTotalsInput>
  filters?: InputMaybe<AnalyticsFilters>
}>

export type GetFugaTrendsTotalsQuery = {
  __typename?: 'Query'
  getFugaTrendsTotals?: {
    __typename?: 'AnalyticsTotalsResponse'
    stream_count?: number | null
    stream_percentage?: number | null
    download_count?: number | null
    download_percentage?: number | null
  } | null
}

export type GetFugaTrendsListQueryVariables = Exact<{
  groupId: Scalars['ID']
  input?: InputMaybe<AnalyticsListInput>
  filters?: InputMaybe<AnalyticsFilters>
}>

export type GetFugaTrendsListQuery = {
  __typename?: 'Query'
  getFugaTrendsList?: Array<{
    __typename?: 'AnalyticsListResponse'
    name?: string | null
    id?: string | null
    totals?: { __typename?: 'AnalyticsListTotals'; count?: number | null; percentage?: number | null } | null
    sub30Totals?: { __typename?: 'AnalyticsListTotals'; count?: number | null; percentage?: number | null } | null
  } | null> | null
}

export type GetFugaTrendsChartQueryVariables = Exact<{
  groupId: Scalars['ID']
  input?: InputMaybe<AnalyticsChartInput>
  filters?: InputMaybe<AnalyticsFilters>
}>

export type GetFugaTrendsChartQuery = {
  __typename?: 'Query'
  getFugaTrendsChart?: Array<{
    __typename?: 'AnalyticsChartResponse'
    name?: string | null
    totals?: Array<number | null> | null
  } | null> | null
}

export type GetFugaTrendsOverviewQueryVariables = Exact<{
  groupId: Scalars['ID']
}>

export type GetFugaTrendsOverviewQuery = {
  __typename?: 'Query'
  getFugaTrendsOverview?: {
    __typename?: 'StatsOverviewResponse'
    productStats?: Array<{
      __typename?: 'StatsProductOverviewResponse'
      stats?: Array<{
        __typename?: 'AnalyticsListResponse'
        id?: string | null
        name?: string | null
        totals?: { __typename?: 'AnalyticsListTotals'; count?: number | null; percentage?: number | null } | null
        sub30Totals?: { __typename?: 'AnalyticsListTotals'; count?: number | null; percentage?: number | null } | null
      } | null> | null
      trackStats?: Array<{
        __typename?: 'StatsTrackOverviewResponse'
        stats?: Array<{
          __typename?: 'AnalyticsListResponse'
          id?: string | null
          name?: string | null
          totals?: { __typename?: 'AnalyticsListTotals'; count?: number | null; percentage?: number | null } | null
          sub30Totals?: { __typename?: 'AnalyticsListTotals'; count?: number | null; percentage?: number | null } | null
        } | null> | null
        track: {
          __typename?: 'Track'
          id: string
          fugaId?: string | null
          name: string
          version?: string | null
          trackArtists?: Array<{
            __typename?: 'TrackArtist'
            primary: boolean
            position: number
            artist: { __typename?: 'Artist'; name?: string | null }
          }> | null
          compressedAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
        }
      } | null> | null
      product: {
        __typename?: 'Product'
        name: string
        fugaId?: string | null
        consumerReleaseDate: any
        artwork?: {
          __typename?: 'Image'
          small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
          medium?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
        } | null
      }
    } | null> | null
  } | null
}

export type GetMyTracksQueryVariables = Exact<{
  productId: Scalars['ID']
}>

export type GetMyTracksQuery = {
  __typename?: 'Query'
  getMyTracks?: Array<{
    __typename?: 'Track'
    id: string
    name: string
    version?: string | null
    bpm: number
    genre: DistributionGenre
    subgenre?: DistributionSubgenre | null
    alternateGenre?: DistributionGenre | null
    alternateSubgenre?: DistributionSubgenre | null
    language?: string | null
    audioLanguage?: string | null
    lyrics?: string | null
    parentalAdvisory: ParentalAdvisory
    previewStart: number
    previewLength?: number | null
    recordingYear?: number | null
    recordingLocation?: string | null
    trackCode?: { __typename?: 'TrackCode'; code: string } | null
  } | null> | null
}

export type GetMyTrackQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetMyTrackQuery = {
  __typename?: 'Query'
  getMyTrack?: {
    __typename?: 'Track'
    id: string
    fugaId?: string | null
    name: string
    version?: string | null
    bpm: number
    genre: DistributionGenre
    subgenre?: DistributionSubgenre | null
    alternateGenre?: DistributionGenre | null
    alternateSubgenre?: DistributionSubgenre | null
    language?: string | null
    audioLanguage?: string | null
    lyrics?: string | null
    parentalAdvisory: ParentalAdvisory
    previewStart: number
    previewLength?: number | null
    recordingYear?: number | null
    recordingLocation?: string | null
    originalFilename?: string | null
    musicLibraryTrackConfig?: {
      __typename?: 'MusicLibraryTrackConfig'
      socialMediaBranded: string
      advertisementAllMedia: string
      advertisementAudioOnly: string
      advertisementOnlineOnly: string
      corporateNonProfitCharity: string
      corporateNonProfitCompanyPresentation: string
      corporateNonProfitEducational: string
      filmLargeOnlineOnly: string
      filmLargeAllMedia: string
      filmLargeTrailer: string
      filmSmallAllMedia: string
      filmSmallOnlineOnly: string
      filmSmallTrailer: string
      gamesAppsInAppGame: string
      gamesAppsTrailerPromo: string
      podcastPrivateBranded: string
      podcastPrivateNonBranded: string
      radioTvAudioOnly: string
      radioTvTrailer: string
      radioTvTvShow: string
      socialMediaNonBranded: string
      durations: Array<SyncLicenseDurations>
      enabled: boolean
      submittedAt?: any | null
    } | null
    audio: { __typename?: 'File'; url: string; expiresAt: string }
    compressedAudio?: { __typename?: 'File'; url: string; expiresAt: string } | null
    trackArtists?: Array<{
      __typename?: 'TrackArtist'
      artistId: string
      primary: boolean
      position: number
      artist: { __typename?: 'Artist'; fugaId?: string | null; name?: string | null; id: string }
    }> | null
    trackCode?: { __typename?: 'TrackCode'; code: string } | null
    contributors?: Array<{
      __typename?: 'Contributor'
      id: string
      trackId: string
      role: ContributorRole
      name: string
      position: number
      publisher?: string | null
    }> | null
  } | null
}

export type AddTrackMutationVariables = Exact<{
  productId: Scalars['ID']
  managerId: Scalars['ID']
  input: AddTrackInput
}>

export type AddTrackMutation = {
  __typename?: 'Mutation'
  addTrack?: {
    __typename?: 'Track'
    id: string
    name: string
    version?: string | null
    bpm: number
    genre: DistributionGenre
    subgenre?: DistributionSubgenre | null
    alternateGenre?: DistributionGenre | null
    alternateSubgenre?: DistributionSubgenre | null
    language?: string | null
    audioLanguage?: string | null
    lyrics?: string | null
    parentalAdvisory: ParentalAdvisory
    previewStart: number
    previewLength?: number | null
    recordingYear?: number | null
    recordingLocation?: string | null
    originalFilename?: string | null
    trackCode?: { __typename?: 'TrackCode'; code: string } | null
  } | null
}

export type EditTrackMutationVariables = Exact<{
  id: Scalars['ID']
  input: EditTrackInput
}>

export type EditTrackMutation = {
  __typename?: 'Mutation'
  editTrack?: {
    __typename?: 'Track'
    id: string
    name: string
    version?: string | null
    bpm: number
    genre: DistributionGenre
    subgenre?: DistributionSubgenre | null
    fugaId?: string | null
    alternateGenre?: DistributionGenre | null
    alternateSubgenre?: DistributionSubgenre | null
    language?: string | null
    audioLanguage?: string | null
    lyrics?: string | null
    parentalAdvisory: ParentalAdvisory
    previewStart: number
    previewLength?: number | null
    recordingYear?: number | null
    recordingLocation?: string | null
    originalFilename?: string | null
    trackCode?: { __typename?: 'TrackCode'; code: string } | null
    trackArtists?: Array<{
      __typename?: 'TrackArtist'
      primary: boolean
      position: number
      artist: { __typename?: 'Artist'; name?: string | null; id: string }
    }> | null
    contributors?: Array<{
      __typename?: 'Contributor'
      id: string
      trackId: string
      role: ContributorRole
      name: string
      position: number
      publisher?: string | null
    }> | null
  } | null
}

export type GetMaximumSharableRoyaltiesQueryVariables = Exact<{
  trackId: Scalars['ID']
  period?: InputMaybe<DateRange>
}>

export type GetMaximumSharableRoyaltiesQuery = { __typename?: 'Query'; getMaximumSharableRoyalties?: number | null }

export type AddTrackArtistMutationVariables = Exact<{
  trackId: Scalars['ID']
  artistId: Scalars['ID']
  input: TrackArtistInput
}>

export type AddTrackArtistMutation = {
  __typename?: 'Mutation'
  addTrackArtist?: { __typename?: 'TrackArtist'; trackId: string; artistId: string; primary: boolean } | null
}

export type RemoveTrackArtistMutationVariables = Exact<{
  trackId: Scalars['ID']
  artistId: Scalars['ID']
}>

export type RemoveTrackArtistMutation = {
  __typename?: 'Mutation'
  removeTrackArtist?: { __typename?: 'TrackArtist'; trackId: string; artistId: string; primary: boolean } | null
}

export type GetTrackGroupDivisionQueryVariables = Exact<{
  trackId: Scalars['ID']
}>

export type GetTrackGroupDivisionQuery = {
  __typename?: 'Query'
  getTrackGroupDivision?: Array<{
    __typename?: 'GroupShare'
    trackId: string
    groupId: string
    share: number
    type: RoyaltyRevenueType
    group?: {
      __typename?: 'PublicGroup'
      name: string
      avatar?: {
        __typename?: 'Image'
        small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
      } | null
    } | null
  }> | null
}

export type GetProductGroupDivisionsQueryVariables = Exact<{
  productId: Scalars['ID']
}>

export type GetProductGroupDivisionsQuery = {
  __typename?: 'Query'
  getProductGroupDivisions?: Array<{
    __typename?: 'GroupShare'
    trackId: string
    groupId: string
    share: number
    type: RoyaltyRevenueType
    group?: {
      __typename?: 'PublicGroup'
      role?: GroupRole | null
      name: string
      avatar?: {
        __typename?: 'Image'
        small?: { __typename?: 'File'; url: string; expiresAt: string; expiresIn: number } | null
      } | null
    } | null
  }> | null
}

export type AddTrackGroupDivisionMutationVariables = Exact<{
  groupId: Scalars['ID']
  productId: Scalars['ID']
  input?: InputMaybe<Array<TrackGroupShareInput> | TrackGroupShareInput>
}>

export type AddTrackGroupDivisionMutation = {
  __typename?: 'Mutation'
  addTrackGroupDivision?: Array<{
    __typename?: 'GroupShare'
    share: number
    trackId: string
    type: RoyaltyRevenueType
    group?: {
      __typename?: 'PublicGroup'
      id: string
      role?: GroupRole | null
      name: string
      biography?: string | null
      countryCode?: string | null
      avatar?: {
        __typename?: 'Image'
        small?: { __typename?: 'File'; url: string; expiresAt: string } | null
        medium?: { __typename?: 'File'; url: string; expiresAt: string } | null
        large?: { __typename?: 'File'; url: string; expiresAt: string } | null
      } | null
    } | null
  } | null> | null
}

export type AddProductGroupDivisionMutationVariables = Exact<{
  groupId: Scalars['ID']
  productId: Scalars['ID']
  input?: InputMaybe<Array<GroupShareInput> | GroupShareInput>
}>

export type AddProductGroupDivisionMutation = {
  __typename?: 'Mutation'
  addProductGroupDivision?: Array<{
    __typename?: 'GroupShare'
    share: number
    trackId: string
    type: RoyaltyRevenueType
    group?: {
      __typename?: 'PublicGroup'
      id: string
      role?: GroupRole | null
      name: string
      biography?: string | null
      countryCode?: string | null
      avatar?: {
        __typename?: 'Image'
        small?: { __typename?: 'File'; url: string; expiresAt: string } | null
        medium?: { __typename?: 'File'; url: string; expiresAt: string } | null
        large?: { __typename?: 'File'; url: string; expiresAt: string } | null
      } | null
    } | null
  } | null> | null
}

export type GetFugaTrendsListByTrackQueryVariables = Exact<{
  trackId: Scalars['ID']
  groupId: Scalars['ID']
  input?: InputMaybe<AnalyticsListInput>
}>

export type GetFugaTrendsListByTrackQuery = {
  __typename?: 'Query'
  getFugaTrendsListByTrack?: Array<{
    __typename?: 'AnalyticsListResponse'
    name?: string | null
    id?: string | null
    totals?: { __typename?: 'AnalyticsListTotals'; count?: number | null; percentage?: number | null } | null
  } | null> | null
}

export type GetFugaTrendsChartByTrackQueryVariables = Exact<{
  trackId: Scalars['ID']
  groupId: Scalars['ID']
  input?: InputMaybe<AnalyticsChartInput>
}>

export type GetFugaTrendsChartByTrackQuery = {
  __typename?: 'Query'
  getFugaTrendsChartByTrack?: Array<{
    __typename?: 'AnalyticsChartResponse'
    name?: string | null
    totals?: Array<number | null> | null
  } | null> | null
}

export type ConnectWalletMutationVariables = Exact<{
  walletAddress?: InputMaybe<Scalars['String']>
  signature?: InputMaybe<Scalars['String']>
}>

export type ConnectWalletMutation = {
  __typename?: 'Mutation'
  connectWallet?: { __typename?: 'UserProfile'; walletAddress?: string | null } | null
}

export type DisconnectWalletMutationVariables = Exact<{ [key: string]: never }>

export type DisconnectWalletMutation = {
  __typename?: 'Mutation'
  disconnectWallet?: { __typename?: 'UserProfile'; walletAddress?: string | null } | null
}
