import React, { type FunctionComponent, useRef } from 'react'
import { v4 as uuid } from 'uuid'

import FormField, { type FormFieldProps } from '../FormField'
import Select, { type SelectProps } from '../Select'

export interface SelectFieldProps extends Omit<FormFieldProps, 'htmlFor'> {
  id?: string
  light?: boolean
  select: SelectProps
}

const SelectField: FunctionComponent<React.PropsWithChildren<SelectFieldProps>> = ({ id, select, light, ...props }) => {
  const idRef = useRef(id ?? uuid())

  return (
    <FormField {...props} htmlFor={idRef.current}>
      <Select {...select} id={idRef.current} light={light} disabled={props.disabled} error={!!props.error} />
    </FormField>
  )
}

export default SelectField
