export enum DistributionGenre {
  alternative = 'Alternative',
  blues = 'Blues',
  childrensMusic = 'Children’s Music',
  classical = 'Classical',
  country = 'Country',
  dance = 'Dance',
  electronic = 'Electronic',
  folk = 'Folk',
  hipHopRap = 'Hip Hop / Rap',
  holiday = 'Holiday',
  jazz = 'Jazz',
  latin = 'Latin',
  newAge = 'New Age',
  pop = 'Pop',
  rbSoul = 'R&B / Soul',
  reggae = 'Reggae',
  rock = 'Rock',
  soundtrack = 'Soundtrack',
  vocal = 'Vocal',
  world = 'World',
}

export enum BeatportGenre {
  afroHouse = 'Afro House',
  bassHouse = 'Bass House',
  bigRoom = 'Big Room',
  breaks = 'Breaks',
  dance = 'Dance',
  deepHouse = 'Deep House',
  drumBass = 'Drum & Bass',
  dubstep = 'Dubstep',
  electroHouse = 'Electro House',
  electronicaDowntempo = 'Electronica / Downtempo',
  funkyGrooveJackinHouse = "Funky / Groove / Jackin' House",
  futureHouse = 'Future House',
  garageBasslineGrime = 'Garage / Bassline / Grime',
  hardDanceHardcore = 'Hard Dance / Hardcore',
  hipHopRB = 'Hip Hop / R&B',
  house = 'House',
  indieDance = 'Indie Dance',
  leftfieldBass = 'Leftfield Bass',
  leftfieldHouseTechno = 'Leftfield House & Techno',
  melodicHouseTechno = 'Melodic House & Techno',
  minimalDeepTech = 'Minimal / Deep Tech',
  nuDiscoDisco = 'Nu Disco / Disco',
  progressiveHouse = 'Progressive House',
  psyTrance = 'Psy-Trance',
  reggaeDancehallDub = 'Reggae / Dancehall / Dub',
  techHouse = 'Tech House',
  technoPeakTimeDrivingHard = 'Techno (Peak Time / Driving / Hard)',
  technoRawDeepHypnotic = 'Techno (Raw / Deep / Hypnotic)',
  trance = 'Trance',
  trapFutureBass = 'Trap / Future Bass',
}

export const distributionSubgenres = {
  dance: BeatportGenre,
  electronic: BeatportGenre,
}

export const getLabels = (
  genre?: string,
  subgenre?: string,
  alternateGenre?: string,
  alternateSubgenre?: string,
): string => {
  const labels: string[] = []
  // normal genre and subgenre
  if (genre && DistributionGenre[genre]) {
    labels.push(DistributionGenre[genre])
  }
  if (genre && subgenre && distributionSubgenres[genre]?.[subgenre]) {
    labels.push(distributionSubgenres[genre][subgenre])
  }

  // alternate genre and alternate subgenre
  if (alternateGenre && DistributionGenre[alternateGenre]) {
    labels.push(DistributionGenre[alternateGenre])
  }
  if (alternateGenre && alternateSubgenre && distributionSubgenres[alternateGenre]?.[alternateSubgenre]) {
    labels.push(distributionSubgenres[alternateGenre][alternateSubgenre])
  }
  return labels.join(', ')
}
