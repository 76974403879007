// TODO: should eventually be replaced by BoxedRadio.tsx

import React, { type FunctionComponent, useRef } from 'react'
import styled, { css } from 'styled-components'
import { v4 as uuid } from 'uuid'

import { BrandColors, SystemColors } from '../../tokens/colors'
import { Opacities } from '../../tokens/opacities'
import { rem } from '../../utils/rem'
import Caption from '../Caption'
import Circle from '../Circle'
import Column from '../Column'
import Outline from '../Outline'
import Padding from '../Padding'
import { type RadioInputProps } from '../RadioInput'
import Row from '../Row'
import SizedBox from '../SizedBox'

export type BoxedRadioInputProps = RadioInputProps & {
  highlight?: boolean
}

const BoxedRadioInput: FunctionComponent<React.PropsWithChildren<BoxedRadioInputProps>> = ({
  error,
  label,
  description,
  disabledDescription,
  hideRadioButton,
  highlight,
  ...props
}) => {
  const idRef = useRef(uuid())
  return (
    <>
      <Input type="radio" id={idRef.current} {...props} />
      <Wrapper as="label" htmlFor={idRef.current} error={error} hideRadioButton={hideRadioButton} highlight={highlight}>
        <Padding padding={`${rem(14)} ${rem(16)}`}>
          <Row crossAxisAlignment="flex-start">
            <Outline offset="4px" />
            <Circle ghost size={{ desktop: '24px', mobile: '20px' }} color={BrandColors.white.toString()}>
              <Checkmark size={{ desktop: '12px', mobile: '10px' }} color="transparent" />
            </Circle>
            {label && (
              <>
                {!hideRadioButton && <SizedBox width={rem(12)} />}
                <TextColumn disabled={props.disabled}>
                  <Label>{label}</Label>
                  {description && (
                    <Caption
                      size="small"
                      color={props.disabled ? undefined : BrandColors.white.alpha(Opacities.fiftyfive).toString()}
                    >
                      {props.disabled && disabledDescription ? disabledDescription : description}
                    </Caption>
                  )}
                </TextColumn>
              </>
            )}
          </Row>
        </Padding>
      </Wrapper>
    </>
  )
}

export default BoxedRadioInput

const Input = styled.input<BoxedRadioInputProps>`
  position: absolute;
  appearance: none;
  opacity: 0;
`

const Label = styled(Caption)``

interface TextColumnProps {
  disabled?: boolean
}

const TextColumn = styled(Column)<TextColumnProps>`
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: ${Opacities.thirtyfive};
    `}
`

const Checkmark = styled(Circle).attrs({ color: 'transparent' })``

interface WrapperProps {
  error?: boolean
  hideRadioButton?: boolean
  highlight?: boolean
}

const Wrapper = styled(Row)<WrapperProps>`
  position: relative;
  display: flex;
  border: 1px solid ${BrandColors.white.alpha(Opacities.thirtyfive).toString()};
  padding: 1px;
  width: 100%;
  background: ${BrandColors.haiti.toString()};

  input:not(:disabled) + &:hover {
    border-color: ${BrandColors.white.alpha(Opacities.fiftyfive).toString()};
  }

  input:not(:focus) + & {
    ${Outline} {
      opacity: 0;
    }
  }

  input:not(:checked) + & {
    ${Circle} {
      opacity: ${Opacities.thirtyfive.toString()};
    }
  }

  input:checked + & {
    border-color: ${BrandColors.white.toString()};
    border-width: 2px;
    padding: 0;

    ${Checkmark} {
      background: ${BrandColors.white.toString()};
    }

    :hover {
      border-color: ${BrandColors.white.alpha(Opacities.seventyfive).toString()};
    }
  }

  ${({ error = false }) =>
    error &&
    css`
      border-color: ${SystemColors.danger.toString()}!important;

      ${Label} {
        color: ${BrandColors.white.toString()} !important;
      }
    `}

  ${({ highlight = false }) =>
    highlight &&
    css`
      border-color: ${BrandColors.white.alpha(Opacities.sixtyfive).toString()}!important;
      background-color: ${BrandColors.haiti.lighten(1.5).toString()};!important;
    `}

  input:disabled + & {
    pointer-events: none;
    border-color: ${BrandColors.white.alpha(Opacities.thirtyfive).toString()}!important;

    ${Circle} {
      border-color: ${BrandColors.white.alpha(Opacities.thirtyfive).toString()};
    }

    ${Checkmark} {
      background: ${BrandColors.white.alpha(Opacities.thirtyfive).toString()};
    }
  }

  ${({ hideRadioButton }) =>
    hideRadioButton &&
    `
    ${Circle}, ${Checkmark} {
      background-color: transparent;
      border-color: transparent;
      display: none;
    }`}
`
