import React, {
  type FunctionComponent,
  type MouseEvent,
  type PropsWithChildren,
  type ReactChild,
  useCallback,
  useEffect,
  useRef,
} from 'react'
import styled, { css } from 'styled-components'

import { BrandColors } from '../../tokens/colors'
import { Icons } from '../../tokens/icons'
import { MediaQueries } from '../../tokens/mediaQueries'
import { Portals } from '../../tokens/portals'
import { rem } from '../../utils/rem'
import Body from '../Body'
import Column, { type ColumnProps } from '../Column'
import IconButton from '../IconButton'
import Overlay from '../Overlay'
import Row from '../Row'
import ScrollView from '../ScrollView'
import SizedBox from '../SizedBox'
import Title from '../Title'

export type DialogSize = 'small' | 'medium' | 'large' | 'xl'

export type DialogCloseSource = 'portal' | 'button' | 'action'

export interface DialogProps extends ColumnProps {
  actions?: ReactChild
  background?: string
  body?: ReactChild
  className?: string
  extendedFooter?: boolean
  maxWidth?: number
  noContentPadding?: boolean
  onClose?: (source: DialogCloseSource) => void
  portal?: string
  size?: DialogSize
  title?: ReactChild
  visible: boolean
}

const Dialog: FunctionComponent<React.PropsWithChildren<PropsWithChildren<DialogProps>>> = ({
  visible,
  portal = Portals.dialog,
  size = 'small',
  title,
  body,
  actions,
  extendedFooter,
  onClose,
  children,
  background,
  noContentPadding,
  className,
}) => {
  const isSmall = size === 'small'
  const contentRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    // scroll to top on visibility changes
    if (visible) {
      contentRef.current?.scrollTo(0, 0)
    }
  }, [visible])

  const handleClose = useCallback(
    (event: MouseEvent) => {
      if (event.target) {
        const rawSource = (event.target as HTMLElement).getAttribute('data-close-source')
        const source = rawSource === 'portal' ? 'portal' : 'button'

        onClose?.(source)
      } else {
        onClose?.('button')
      }
    },
    [onClose],
  )

  return visible ? (
    <Overlay visible={visible} onClick={handleClose} portal={portal} data-close-source="portal">
      <StyledDialog background={background} size={size} data-gtm-name="portal-dialog" className={className}>
        {onClose && (
          <Header as="header" crossAxisAlignment="center" mainAxisAlignment="flex-end">
            <IconButton
              type="button"
              icon={Icons.cross}
              onClick={handleClose}
              data-close-source="button"
              data-gtm-name="portal-dialog-close"
            />
          </Header>
        )}
        {!onClose && <SizedBox height={{ desktop: rem(32), mobile: rem(24) }} />}
        <Content ref={contentRef} noContentPadding={noContentPadding}>
          {title && <Title size={isSmall ? 'xxSmall' : 'xSmall'}>{title}</Title>}
          {title && body && <SizedBox height={rem(16)} />}
          {body && <Body size={isSmall ? 'small' : 'medium'}>{body}</Body>}
          {children}
        </Content>
        {actions && (
          <Footer as="footer" crossAxisAlignment="center" mainAxisAlignment="flex-end" extendedFooter={extendedFooter}>
            {actions}
          </Footer>
        )}
      </StyledDialog>
    </Overlay>
  ) : null
}

export default Dialog

const StyledDialog = styled(Column)<{ background?: string; size: DialogSize }>(
  ({ size, background }) => css`
    background: ${background ? background : BrandColors.blackcurrant.toString()};
    color: ${BrandColors.white.toString()};
    padding: 0;
    max-height: 100%;

    ${() => {
      switch (size) {
        case 'xl':
          return css`
            max-width: ${rem(1400)};
            width: ${`calc(100% - ${rem(16 * 2)})`};
            max-height: ${`calc(100% - ${rem(16 * 2)})`};
          `

        case 'large':
          return css`
            max-width: ${rem(956)};
            width: ${`calc(100% - ${rem(16 * 2)})`};
            max-height: ${`calc(100% - ${rem(16 * 2)})`};
          `
        case 'medium':
          return css`
            max-width: ${rem(656)};
            width: ${`calc(100% - ${rem(16 * 2)})`};
            max-height: ${`calc(100% - ${rem(16 * 2)})`};
          `
        case 'small':
        default:
          return css`
            max-width: ${rem(448)};
            width: ${`calc(100% - ${rem(40 * 2)})`};
            max-height: ${`calc(100% - ${rem(40 * 2)})`};
          `
      }
    }}
  `,
)

const Header = styled(Row)`
  padding: ${rem(12)};

  ${MediaQueries.desktop} {
    padding: ${rem(16)};
  }
`

const Content = styled(ScrollView)<Pick<DialogProps, 'noContentPadding'>>`
  padding: 0 ${({ noContentPadding }) => (noContentPadding ? 0 : rem(24))};

  ${MediaQueries.desktop} {
    padding: 0 ${({ noContentPadding }) => (noContentPadding ? 0 : rem(32))};
  }
`

const Footer = styled(Row)<{ extendedFooter?: boolean }>`
  padding: ${rem(24)};

  ${MediaQueries.desktop} {
    padding: ${rem(32)};
  }

  ${({ extendedFooter }) =>
    extendedFooter &&
    css`
      button:first-child {
        margin-right: auto;
      }
    `}
`
