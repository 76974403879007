import styled, { css } from 'styled-components'

import { BrandColors } from '../../tokens/colors'
import { MediaQueries } from '../../tokens/mediaQueries'
import { Opacities } from '../../tokens/opacities'
import { Transitions } from '../../tokens/transitions'
import { rem } from '../../utils/rem'
import Button, { type ButtonProps } from '../Button'

export type TextButtonSize = 'small' | 'medium' | 'large'

export type TextButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'quaternary'

export interface TextButtonProps extends ButtonProps {
  // @TODO this prop doesn't make sense, you can't use the same value for
  // justify-content, align-items and text-align.
  alignment?: string
  display?: string
  size?: TextButtonSize
  to?: string
  variant?: TextButtonVariant
}

const TextButton = styled(Button)<TextButtonProps>(
  ({ size = 'medium', variant = 'primary', alignment = 'center', display = 'flex' }) => css`
    display: ${display};
    justify-content: ${alignment};
    align-items: ${alignment};
    text-align: ${alignment};
    font-weight: bold;
    text-decoration: none;
    background-repeat: no-repeat;
    background-position: 0 100%;
    background-size: 100% 1px;
    padding: 0 0 ${rem(2)};
    transition: ${Transitions.micro};
    outline: none;
    color: ${BrandColors.springGreen.toString()};

    :hover,
    :active {
      opacity: ${Opacities.full};
      background-image: linear-gradient(
        ${`${BrandColors.springGreen.toString()}, ${BrandColors.springGreen.toString()}`}
      );
    }

    :active {
      color: ${BrandColors.springGreen.alpha(Opacities.fiftyfive).toString()};
    }

    :focus {
      outline: 0;
      background-image: linear-gradient(${BrandColors.springGreen.toString()}, ${BrandColors.springGreen.toString()});
    }

    :disabled {
      opacity: ${Opacities.thirtyfive};
    }

    ${size === 'small' &&
    css`
      font-size: ${rem(14)};
      line-height: ${rem(16)};

      ${MediaQueries.desktop} {
        font-size: ${rem(16)};
        line-height: ${rem(20)};
      }
    `}

    ${size === 'medium' &&
    css`
      font-size: ${rem(16)};
      line-height: ${rem(20)};

      ${MediaQueries.desktop} {
        font-size: ${rem(18)};
        line-height: ${rem(20)};
      }
    `}

    ${size === 'large' &&
    css`
      font-size: ${rem(18)};
      line-height: ${rem(20)};

      ${MediaQueries.desktop} {
        font-size: ${rem(20)};
        line-height: ${rem(24)};
      }
    `}

    ${(variant === 'secondary' || variant === 'tertiary' || variant === 'quaternary') &&
    css`
      color: ${BrandColors.white.toString()};

      :hover,
      :active {
        opacity: ${Opacities.full};
        background-image: linear-gradient(${`${BrandColors.white.toString()}, ${BrandColors.white.toString()}`});
      }

      :active {
        color: ${BrandColors.white.alpha(Opacities.fiftyfive).toString()};
      }

      :disabled {
        opacity: ${Opacities.thirtyfive};
      }
    `}

    ${variant === 'tertiary' &&
    css`
      font-weight: normal;
    `}

    ${variant === 'quaternary' &&
    css`
      font-weight: normal;
      color: ${BrandColors.white.alpha(Opacities.fiftyfive).toString()};

      :hover,
      :active {
        color: ${BrandColors.white.alpha(Opacities.fiftyfive).toString()};
      }

      :active {
        color: ${BrandColors.white.toString()};
      }

      :focus {
        color: ${BrandColors.white.alpha(Opacities.fiftyfive).toString()};
      }
    `}
  `,
)
export default TextButton
