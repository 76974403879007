import { useLocation } from '@gatsbyjs/reach-router'
import { useEffect, useState } from 'react'

import { getQueryParameter } from '../utils/location'

/**
 * @deprecated use useQueryParameters instead.
 */
export const useQueryParameter = (key: string, fallback = null) => {
  const location = useLocation()
  const [value, setValue] = useState(getQueryParameter(location, key) || fallback)

  useEffect(() => {
    setValue(getQueryParameter(location, key) || fallback)
  }, [key, fallback, location, setValue])

  return { value }
}
