import React, { type FunctionComponent, useRef } from 'react'
import { v4 as uuid } from 'uuid'

import FormField, { type FormFieldProps } from '../FormField'
import Input, { type InputProps } from '../Input'

export interface NumberFieldProps extends Omit<FormFieldProps, 'htmlFor'> {
  id?: string
  input?: Omit<InputProps, 'disabled' | 'error'>
  light?: boolean
}

const NumberField: FunctionComponent<React.PropsWithChildren<NumberFieldProps>> = ({ id, input, ...props }) => {
  const idRef = useRef(id ?? uuid())

  return (
    <FormField {...props} htmlFor={idRef.current}>
      <Input
        inputMode="decimal"
        {...input}
        type="number"
        id={idRef.current}
        disabled={props.disabled}
        error={!!props.error}
        light={props.light}
      />
    </FormField>
  )
}

export default NumberField
