import { eachMonthOfInterval, endOfYear, format, startOfYear } from 'date-fns'

// FIXME(jari): why is this hardcoded and not just simply fetched from the API...
export const platforms = [
  { label: 'Facebook', value: 'facebook' },
  { label: 'Instagram', value: 'instagram' },
  { label: 'TikTok', value: 'tikTok' },
  { label: 'YouTube', value: 'youtube' },
  { label: 'Soundcloud', value: 'soundcloud' },
  { label: 'Triller', value: 'triller' },
  { label: 'X (formerly known as Twitter)', value: 'twitter' },
  { label: 'Snapchat', value: 'snapchat' },
  { label: 'Twitch', value: 'twitch' },
  { label: 'Spotify', value: 'spotify' },
]

const monthLabels = eachMonthOfInterval({
  end: endOfYear(new Date()),
  start: startOfYear(new Date()),
}).map((date) => format(date, 'MMMM'))

export const months = monthLabels.map((label, index) => ({
  label,
  value: String(index),
}))

export const years = [
  { label: '2021', value: '2021' },
  { label: '2022', value: '2022' },
  { label: '2023', value: '2023' },
  { label: '2024', value: '2024' },
  { label: '2025', value: '2025' },
  { label: '2026', value: '2026' },
  { label: '2027', value: '2027' },
  { label: '2028', value: '2028' },
  { label: '2029', value: '2029' },
  { label: '2030', value: '2030' },
]

export const collabTypes = [
  { label: 'Fixed fee', value: 'fixedFee' },
  { label: 'Royalty split', value: 'royaltyShare' },
]

export const postFrequencyPeriods = [
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Yearly', value: 'yearly' },
]

export const countries = [
  { code: 'AF', name: 'Afghanistan', prefix: '+93' },
  { code: 'AX', name: 'Åland Islands', prefix: '+358' },
  { code: 'AL', name: 'Albania', prefix: '+355' },
  { code: 'DZ', name: 'Algeria', prefix: '+213' },
  { code: 'AS', name: 'American Samoa', prefix: '+1-684' },
  { code: 'AD', name: 'Andorra', prefix: '+376' },
  { code: 'AO', name: 'Angola', prefix: '+244' },
  { code: 'AI', name: 'Anguilla', prefix: '+1-264' },
  { code: 'AQ', name: 'Antarctica', prefix: '+672' },
  { code: 'AG', name: 'Antigua and Barbuda', prefix: '+1-268' },
  { code: 'AR', name: 'Argentina', prefix: '+54' },
  { code: 'AM', name: 'Armenia', prefix: '+374' },
  { code: 'AW', name: 'Aruba', prefix: '+297' },
  { code: 'AU', name: 'Australia', prefix: '+61' },
  { code: 'AT', name: 'Austria', prefix: '+43' },
  { code: 'AZ', name: 'Azerbaijan', prefix: '+994' },
  { code: 'BS', name: 'Bahamas', prefix: '+1-242' },
  { code: 'BH', name: 'Bahrain', prefix: '+973' },
  { code: 'BD', name: 'Bangladesh', prefix: '+880' },
  { code: 'BB', name: 'Barbados', prefix: '+1-246' },
  { code: 'BY', name: 'Belarus', prefix: '+375' },
  { code: 'BE', name: 'Belgium', prefix: '+32' },
  { code: 'BZ', name: 'Belize', prefix: '+501' },
  { code: 'BJ', name: 'Benin', prefix: '+229' },
  { code: 'BM', name: 'Bermuda', prefix: '+1-441' },
  { code: 'BT', name: 'Bhutan', prefix: '+975' },
  { code: 'BO', name: 'Bolivia (Plurinational State of)', prefix: '+591' },
  { code: 'BQ', name: 'Bonaire, Sint Eustatius and Saba', prefix: '+599' },
  { code: 'BA', name: 'Bosnia and Herzegovina', prefix: '+387' },
  { code: 'BW', name: 'Botswana', prefix: '+267' },
  { code: 'BV', name: 'Bouvet Island', prefix: '' },
  { code: 'BR', name: 'Brazil', prefix: '+55' },
  { code: 'IO', name: 'British Indian Ocean Territory', prefix: '+246' },
  { code: 'BN', name: 'Brunei Darussalam', prefix: '+673' },
  { code: 'BG', name: 'Bulgaria', prefix: '+359' },
  { code: 'BF', name: 'Burkina Faso', prefix: '+226' },
  { code: 'BI', name: 'Burundi', prefix: '+257' },
  { code: 'CV', name: 'Cabo Verde', prefix: '+238' },
  { code: 'KH', name: 'Cambodia', prefix: '+855' },
  { code: 'CM', name: 'Cameroon', prefix: '+237' },
  { code: 'CA', name: 'Canada', prefix: '+1' },
  { code: 'KY', name: 'Cayman Islands', prefix: '+1-345' },
  { code: 'CF', name: 'Central African Republic', prefix: '+236' },
  { code: 'TD', name: 'Chad', prefix: '+235' },
  { code: 'CL', name: 'Chile', prefix: '+56' },
  { code: 'CN', name: 'China', prefix: '+86' },
  { code: 'CX', name: 'Christmas Island', prefix: '+61' },
  { code: 'CC', name: 'Cocos (Keeling) Islands', prefix: '+61' },
  { code: 'CO', name: 'Colombia', prefix: '+57' },
  { code: 'KM', name: 'Comoros', prefix: '+269' },
  { code: 'CG', name: 'Congo', prefix: '+242' },
  { code: 'CD', name: 'Congo, Democratic Republic of the', prefix: '+243' },
  { code: 'CK', name: 'Cook Islands', prefix: '+682' },
  { code: 'CR', name: 'Costa Rica', prefix: '+506' },
  { code: 'CI', name: "Côte d'Ivoire", prefix: '+225' },
  { code: 'HR', name: 'Croatia', prefix: '+385' },
  { code: 'CU', name: 'Cuba', prefix: '+53' },
  { code: 'CW', name: 'Curaçao', prefix: '+599' },
  { code: 'CY', name: 'Cyprus', prefix: '+357' },
  { code: 'CZ', name: 'Czechia', prefix: '+420' },
  { code: 'DK', name: 'Denmark', prefix: '+45' },
  { code: 'DJ', name: 'Djibouti', prefix: '+253' },
  { code: 'DM', name: 'Dominica', prefix: '+1-767' },
  { code: 'DO', name: 'Dominican Republic', prefix: '+1-809, +1-829, +1-849' },
  { code: 'EC', name: 'Ecuador', prefix: '+593' },
  { code: 'EG', name: 'Egypt', prefix: '+20' },
  { code: 'SV', name: 'El Salvador', prefix: '+503' },
  { code: 'GQ', name: 'Equatorial Guinea', prefix: '+240' },
  { code: 'ER', name: 'Eritrea', prefix: '+291' },
  { code: 'EE', name: 'Estonia', prefix: '+372' },
  { code: 'SZ', name: 'Eswatini', prefix: '+268' },
  { code: 'ET', name: 'Ethiopia', prefix: '+251' },
  { code: 'FK', name: 'Falkland Islands (Malvinas)', prefix: '+500' },
  { code: 'FO', name: 'Faroe Islands', prefix: '+298' },
  { code: 'FJ', name: 'Fiji', prefix: '+679' },
  { code: 'FI', name: 'Finland', prefix: '+358' },
  { code: 'FR', name: 'France', prefix: '+33' },
  { code: 'GF', name: 'French Guiana', prefix: '+594' },
  { code: 'PF', name: 'French Polynesia', prefix: '+689' },
  { code: 'TF', name: 'French Southern Territories', prefix: '' },
  { code: 'GA', name: 'Gabon', prefix: '+241' },
  { code: 'GM', name: 'Gambia', prefix: '+220' },
  { code: 'GE', name: 'Georgia', prefix: '+995' },
  { code: 'DE', name: 'Germany', prefix: '+49' },
  { code: 'GH', name: 'Ghana', prefix: '+233' },
  { code: 'GI', name: 'Gibraltar', prefix: '+350' },
  { code: 'GR', name: 'Greece', prefix: '+30' },
  { code: 'GL', name: 'Greenland', prefix: '+299' },
  { code: 'GD', name: 'Grenada', prefix: '+1-473' },
  { code: 'GP', name: 'Guadeloupe', prefix: '+590' },
  { code: 'GU', name: 'Guam', prefix: '+1-671' },
  { code: 'GT', name: 'Guatemala', prefix: '+502' },
  { code: 'GG', name: 'Guernsey', prefix: '+44-1481' },
  { code: 'GN', name: 'Guinea', prefix: '+224' },
  { code: 'GW', name: 'Guinea-Bissau', prefix: '+245' },
  { code: 'GY', name: 'Guyana', prefix: '+592' },
  { code: 'HT', name: 'Haiti', prefix: '+509' },
  { code: 'HM', name: 'Heard Island and McDonald Islands', prefix: '' },
  { code: 'VA', name: 'Holy See', prefix: '+379' },
  { code: 'HN', name: 'Honduras', prefix: '+504' },
  { code: 'HK', name: 'Hong Kong', prefix: '+852' },
  { code: 'HU', name: 'Hungary', prefix: '+36' },
  { code: 'IS', name: 'Iceland', prefix: '+354' },
  { code: 'IN', name: 'India', prefix: '+91' },
  { code: 'ID', name: 'Indonesia', prefix: '+62' },
  { code: 'IR', name: 'Iran (Islamic Republic of)', prefix: '+98' },
  { code: 'IQ', name: 'Iraq', prefix: '+964' },
  { code: 'IE', name: 'Ireland', prefix: '+353' },
  { code: 'IM', name: 'Isle of Man', prefix: '+44-1624' },
  { code: 'IL', name: 'Israel', prefix: '+972' },
  { code: 'IT', name: 'Italy', prefix: '+39' },
  { code: 'JM', name: 'Jamaica', prefix: '+1-876' },
  { code: 'JP', name: 'Japan', prefix: '+81' },
  { code: 'JE', name: 'Jersey', prefix: '+44-1534' },
  { code: 'JO', name: 'Jordan', prefix: '+962' },
  { code: 'KZ', name: 'Kazakhstan', prefix: '+7' },
  { code: 'KE', name: 'Kenya', prefix: '+254' },
  { code: 'KI', name: 'Kiribati', prefix: '+686' },
  {
    code: 'KP',
    name: "Korea (Democratic People's Republic of)",
    prefix: '+850',
  },
  { code: 'KS', name: 'Kosovo', prefix: '+383' },
  { code: 'KR', name: 'Korea, Republic of', prefix: '+82' },
  { code: 'KW', name: 'Kuwait', prefix: '+965' },
  { code: 'KG', name: 'Kyrgyzstan', prefix: '+996' },
  { code: 'LA', name: "Lao People's Democratic Republic", prefix: '+856' },
  { code: 'LV', name: 'Latvia', prefix: '+371' },
  { code: 'LB', name: 'Lebanon', prefix: '+961' },
  { code: 'LS', name: 'Lesotho', prefix: '+266' },
  { code: 'LR', name: 'Liberia', prefix: '+231' },
  { code: 'LY', name: 'Libya', prefix: '+218' },
  { code: 'LI', name: 'Liechtenstein', prefix: '+423' },
  { code: 'LT', name: 'Lithuania', prefix: '+370' },
  { code: 'LU', name: 'Luxembourg', prefix: '+352' },
  { code: 'MO', name: 'Macao', prefix: '+853' },
  { code: 'MG', name: 'Madagascar', prefix: '+261' },
  { code: 'MW', name: 'Malawi', prefix: '+265' },
  { code: 'MY', name: 'Malaysia', prefix: '+60' },
  { code: 'MV', name: 'Maldives', prefix: '+960' },
  { code: 'ML', name: 'Mali', prefix: '+223' },
  { code: 'MT', name: 'Malta', prefix: '+356' },
  { code: 'MH', name: 'Marshall Islands', prefix: '+692' },
  { code: 'MQ', name: 'Martinique', prefix: '+596' },
  { code: 'MR', name: 'Mauritania', prefix: '+222' },
  { code: 'MU', name: 'Mauritius', prefix: '+230' },
  { code: 'YT', name: 'Mayotte', prefix: '+262' },
  { code: 'MX', name: 'Mexico', prefix: '+52' },
  { code: 'FM', name: 'Micronesia (Federated States of)', prefix: '+691' },
  { code: 'MD', name: 'Moldova, Republic of', prefix: '+373' },
  { code: 'MC', name: 'Monaco', prefix: '+377' },
  { code: 'MN', name: 'Mongolia', prefix: '+976' },
  { code: 'ME', name: 'Montenegro', prefix: '+382' },
  { code: 'MS', name: 'Montserrat', prefix: '+1-664' },
  { code: 'MA', name: 'Morocco', prefix: '+212' },
  { code: 'MZ', name: 'Mozambique', prefix: '+258' },
  { code: 'MM', name: 'Myanmar', prefix: '+95' },
  { code: 'NA', name: 'Namibia', prefix: '+264' },
  { code: 'NR', name: 'Nauru', prefix: '+674' },
  { code: 'NP', name: 'Nepal', prefix: '+977' },
  { code: 'NL', name: 'The Netherlands', prefix: '+31' },
  { code: 'NC', name: 'New Caledonia', prefix: '+687' },
  { code: 'NZ', name: 'New Zealand', prefix: '+64' },
  { code: 'NI', name: 'Nicaragua', prefix: '+505' },
  { code: 'NE', name: 'Niger', prefix: '+227' },
  { code: 'NG', name: 'Nigeria', prefix: '+234' },
  { code: 'NU', name: 'Niue', prefix: '+683' },
  { code: 'NF', name: 'Norfolk Island', prefix: '+672' },
  { code: 'MK', name: 'North Macedonia', prefix: '+389' },
  { code: 'MP', name: 'Northern Mariana Islands', prefix: '+1-670' },
  { code: 'NO', name: 'Norway', prefix: '+47' },
  { code: 'OM', name: 'Oman', prefix: '+968' },
  { code: 'PK', name: 'Pakistan', prefix: '+92' },
  { code: 'PW', name: 'Palau', prefix: '+680' },
  { code: 'PS', name: 'Palestine, State of', prefix: '+970' },
  { code: 'PA', name: 'Panama', prefix: '+507' },
  { code: 'PG', name: 'Papua New Guinea', prefix: '+675' },
  { code: 'PY', name: 'Paraguay', prefix: '+595' },
  { code: 'PE', name: 'Peru', prefix: '+51' },
  { code: 'PH', name: 'Philippines', prefix: '+63' },
  { code: 'PN', name: 'Pitcairn', prefix: '+870' },
  { code: 'PL', name: 'Poland', prefix: '+48' },
  { code: 'PT', name: 'Portugal', prefix: '+351' },
  { code: 'PR', name: 'Puerto Rico', prefix: '+1-787, +1-939' },
  { code: 'QA', name: 'Qatar', prefix: '+974' },
  { code: 'RE', name: 'Réunion', prefix: '+262' },
  { code: 'RO', name: 'Romania', prefix: '+40' },
  { code: 'RU', name: 'Russian Federation', prefix: '+7' },
  { code: 'RW', name: 'Rwanda', prefix: '+250' },
  { code: 'BL', name: 'Saint Barthélemy', prefix: '+590' },
  {
    code: 'SH',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    prefix: '+290',
  },
  { code: 'KN', name: 'Saint Kitts and Nevis', prefix: '+1-869' },
  { code: 'LC', name: 'Saint Lucia', prefix: '+1-758' },
  { code: 'MF', name: 'Saint Martin (French part)', prefix: '+590' },
  { code: 'PM', name: 'Saint Pierre and Miquelon', prefix: '+508' },
  { code: 'VC', name: 'Saint Vincent and the Grenadines', prefix: '+1-784' },
  { code: 'WS', name: 'Samoa', prefix: '+685' },
  { code: 'SM', name: 'San Marino', prefix: '+378' },
  { code: 'ST', name: 'Sao Tome and Principe', prefix: '+239' },
  { code: 'SA', name: 'Saudi Arabia', prefix: '+966' },
  { code: 'SN', name: 'Senegal', prefix: '+221' },
  { code: 'SC', name: 'Seychelles', prefix: '+248' },
  { code: 'SL', name: 'Sierra Leone', prefix: '+232' },
  { code: 'SG', name: 'Singapore', prefix: '+65' },
  { code: 'SX', name: 'Sint Maarten (Dutch part)', prefix: '+1-721' },
  { code: 'SK', name: 'Slovakia', prefix: '+421' },
  { code: 'SI', name: 'Slovenia', prefix: '+386' },
  { code: 'SB', name: 'Solomon Islands', prefix: '+677' },
  { code: 'SO', name: 'Somalia', prefix: '+252' },
  { code: 'ZA', name: 'South Africa', prefix: '+27' },
  {
    code: 'GS',
    name: 'South Georgia and the South Sandwich Islands',
    prefix: '',
  },
  { code: 'SS', name: 'South Sudan', prefix: '+211' },
  { code: 'ES', name: 'Spain', prefix: '+34' },
  { code: 'LK', name: 'Sri Lanka', prefix: '+94' },
  { code: 'SD', name: 'Sudan', prefix: '+249' },
  { code: 'SR', name: 'Suriname', prefix: '+597' },
  { code: 'SJ', name: 'Svalbard and Jan Mayen', prefix: '+47' },
  { code: 'SE', name: 'Sweden', prefix: '+46' },
  { code: 'CH', name: 'Switzerland', prefix: '+41' },
  { code: 'SY', name: 'Syrian Arab Republic', prefix: '+963' },
  { code: 'TW', name: 'Taiwan, Province of China', prefix: '+886' },
  { code: 'TJ', name: 'Tajikistan', prefix: '+992' },
  { code: 'TZ', name: 'Tanzania, United Republic of', prefix: '+255' },
  { code: 'TH', name: 'Thailand', prefix: '+66' },
  { code: 'TL', name: 'Timor-Leste', prefix: '+670' },
  { code: 'TG', name: 'Togo', prefix: '+228' },
  { code: 'TK', name: 'Tokelau', prefix: '+690' },
  { code: 'TO', name: 'Tonga', prefix: '+676' },
  { code: 'TT', name: 'Trinidad and Tobago', prefix: '+1-868' },
  { code: 'TN', name: 'Tunisia', prefix: '+216' },
  { code: 'TR', name: 'Turkey', prefix: '+90' },
  { code: 'TM', name: 'Turkmenistan', prefix: '+993' },
  { code: 'TC', name: 'Turks and Caicos Islands', prefix: '+1-649' },
  { code: 'TV', name: 'Tuvalu', prefix: '+688' },
  { code: 'UG', name: 'Uganda', prefix: '+256' },
  { code: 'UA', name: 'Ukraine', prefix: '+380' },
  { code: 'AE', name: 'United Arab Emirates', prefix: '+971' },
  {
    code: 'GB',
    name: 'United Kingdom of Great Britain and Northern Ireland',
    prefix: '+44',
  },
  { code: 'US', name: 'United States of America', prefix: '+1' },
  { code: 'UM', name: 'United States Minor Outlying Islands', prefix: '' },
  { code: 'UY', name: 'Uruguay', prefix: '+598' },
  { code: 'UZ', name: 'Uzbekistan', prefix: '+998' },
  { code: 'VU', name: 'Vanuatu', prefix: '+678' },
  { code: 'VE', name: 'Venezuela (Bolivarian Republic of)', prefix: '+58' },
  { code: 'VN', name: 'Viet Nam', prefix: '+84' },
  { code: 'VG', name: 'Virgin Islands (British)', prefix: '+1-284' },
  { code: 'VI', name: 'Virgin Islands (U.S.)', prefix: '+1-340' },
  { code: 'WF', name: 'Wallis and Futuna', prefix: '+681' },
  { code: 'EH', name: 'Western Sahara', prefix: '+212' },
  { code: 'YE', name: 'Yemen', prefix: '+967' },
  { code: 'ZM', name: 'Zambia', prefix: '+260' },
  { code: 'ZW', name: 'Zimbabwe', prefix: '+263' },
]

export const countriesInEurope = [
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'EL',
  'ES',
  'FI',
  'FR',
  'HR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
]
