/**
 * Purpose: define steps, their flow, and map them to components
 */
import { RegistrationStep, RegistrationStepId, Role } from './types'

// Define initial steps
export const initialSteps: RegistrationStep[] = [new RegistrationStep({ id: RegistrationStepId.SelectRole })]

// Define steps after role is determined
export const roleBasedFlows: Record<Role, RegistrationStep[]> = {
  [Role.Undefined]: [],
  [Role.Artist]: [
    new RegistrationStep({ id: RegistrationStepId.WhatAreYouLookingFor }),
    new RegistrationStep({
      id: RegistrationStepId.CreateArtistProfile,
      skippable: true,
    }),
    new RegistrationStep({ id: RegistrationStepId.CompleteProfile }),
    new RegistrationStep({
      id: RegistrationStepId.InviteTeamMembers,
      skippable: true,
    }),
  ],
  [Role.Label]: [
    new RegistrationStep({ id: RegistrationStepId.WhatAreYouLookingFor }),
    new RegistrationStep({ id: RegistrationStepId.CompleteProfile }),
    new RegistrationStep({
      id: RegistrationStepId.InviteTeamMembers,
      skippable: true,
    }),
  ],
  [Role.Brand]: [
    new RegistrationStep({ id: RegistrationStepId.BrandType }),
    new RegistrationStep({ id: RegistrationStepId.BudgetForMusicLicense }),
    new RegistrationStep({ id: RegistrationStepId.CompleteProfile }),
    new RegistrationStep({
      id: RegistrationStepId.InviteTeamMembers,
      skippable: true,
    }),
  ],
  [Role.Influencer]: [
    new RegistrationStep({ id: RegistrationStepId.ContentType }),
    new RegistrationStep({ id: RegistrationStepId.ConnectSocials }),
    new RegistrationStep({ id: RegistrationStepId.CompleteProfile }),
    new RegistrationStep({
      id: RegistrationStepId.InviteTeamMembers,
      skippable: true,
    }),
  ],
  [Role.Fan]: [
    new RegistrationStep({ id: RegistrationStepId.InterestedGenres }),
    new RegistrationStep({ id: RegistrationStepId.CompleteProfile }),
  ],
}

// Define final step, regardless of role
export const finalStep = [
  new RegistrationStep({
    end: true,
    id: RegistrationStepId.Final,
  }),
]
