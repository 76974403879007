import styled, { css } from 'styled-components'

import { MediaQueries } from '../../tokens/mediaQueries'
import { Opacities } from '../../tokens/opacities'
import { rem } from '../../utils/rem'
import Text, { type TextProps } from '../Text'

export interface BodyProps extends TextProps {
  emphasis?: boolean
  size?: 'small' | 'medium' | 'large'
}

const Body = styled(Text).attrs({
  as: 'div',
})<BodyProps>(
  ({ size = 'medium', emphasis = false, ...props }) => css`
    color: ${emphasis
      ? props.theme.foreground.toString()
      : props.theme.foreground.alpha(Opacities.seventyfive).toString()};

    & > p {
      margin-bottom: ${rem(16)};

      &:last-child {
        margin-bottom: 0;
      }
    }

    ${size === 'small' &&
    css`
      font-size: ${rem(14)};
      line-height: ${rem(20)};

      ${MediaQueries.desktop} {
        font-size: ${rem(16)};
        line-height: ${rem(24)};
      }
    `}

    ${size === 'medium' &&
    css`
      font-size: ${rem(16)};
      line-height: ${rem(24)};

      ${MediaQueries.desktop} {
        font-size: ${rem(18)};
        line-height: ${rem(28)};
      }
    `}

    ${size === 'large' &&
    css`
      font-size: ${rem(18)};
      line-height: ${rem(28)};

      ${MediaQueries.desktop} {
        font-size: ${rem(20)};
      }
    `}
  `,
)

export default Body
