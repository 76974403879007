export const PREFIX = '/app/'

export interface Route {
  [key: string]: Route | string
}

export const rawRoutes = {
  '404': {
    path: '/404',
    url: '/404',
  },
  account: {
    basicInfo: {
      path: '/account/basic-info',
      url: '/account/basic-info',
    },
    overview: {
      path: '/account',
      url: '/account',
    },
    payouts: {
      path: '/account/payouts',
      url: '/account/payouts',
    },
    teams: {
      path: '/account/teams',
      url: '/account/teams',
    },
    updatePassword: {
      path: '/account/update-password',
      url: '/account/update-password',
    },
  },
  assets: {
    details: {
      collabs: {
        path: '/assets/:trackId/collabs',
        url: '/assets/:trackId/collabs',
      },
      licenses: {
        path: '/assets/:trackId/licenses',
        url: '/assets/:trackId/licenses',
      },
      nfts: {
        path: '/assets/:trackId/nfts',
        url: '/assets/:trackId/nfts',
      },
      path: '/assets/:trackId/*',
      royaltySplits: {
        path: '/assets/:trackId/royalty-splits',
        url: '/assets/:trackId/royalty-splits',
      },
      stats: {
        path: '/assets/:trackId/stats',
        url: '/assets/:trackId/stats',
      },
      url: '/assets/:trackId',
    },
    overview: {
      collabs: {
        path: '/assets/overview/collabs',
        url: '/assets/overview/collabs',
      },
      licenses: {
        path: '/assets/overview/licenses',
        url: '/assets/overview/licenses',
      },
      nfts: {
        path: '/assets/overview/nfts',
        url: '/assets/overview/nfts',
      },
      path: '/assets/overview/*',
      royaltySplits: {
        path: '/assets/overview/royalty-splits',
        url: '/assets/overview/royalty-splits',
      },
      url: '/assets/overview',
    },
    path: '/assets',
  },
  campaigns: {
    path: '/campaigns',
    url: '/campaigns',
  },
  chat: {
    contactProfile: {
      path: '/chat/:groupId',
      url: '/chat/:groupId',
    },
    path: '/chat',
    url: '/chat',
  },
  collaboration: {
    createProposal: {
      path: '/collab-market/proposal/create',
      url: '/collab-market/proposal/create',
    },
    details: {
      deliverDraft: {
        path: '/collab-market/:collaborationId/deliver-draft/:collaborationPostId',
        url: '/collab-market/:collaborationId/deliver-draft/:collaborationPostId',
      },
      deliverProofOfDelivery: {
        path: '/collab-market/:collaborationId/proof-of-delivery/:collaborationPostId',
        url: '/collab-market/:collaborationId/proof-of-delivery/:collaborationPostId',
      },
      path: '/collab-market/:collaborationId/*',
      payment: {
        path: '/collab-market/:collaborationId/payment',
        url: '/collab-market/:collaborationId/payment',
      },
      reviewDraft: {
        path: '/collab-market/:collaborationId/review-draft/:proofOfDeliveryId',
        url: '/collab-market/:collaborationId/review-draft/:proofOfDeliveryId',
      },
      reviewProofOfDelivery: {
        path: '/collab-market/:collaborationId/review-proof-of-delivery/:proofOfDeliveryId',
        url: '/collab-market/:collaborationId/review-proof-of-delivery/:proofOfDeliveryId',
      },
      url: '/collab-market/:collaborationId',
    },
    overview: {
      allTeams: {
        path: '/collab-market/overview/all-teams',
        url: '/collab-market/overview/all-teams',
      },
      path: '/collab-market/overview/*',
      url: '/collab-market/overview',
    },
    path: '/collab-market',
    publicGroup: {
      collab: {
        path: '/collab-market/:groupId/public/collab',
        url: '/collab-market/:groupId/public/collab',
      },
      path: '/collab-market/:groupId/public/*',
      tracks: {
        path: '/collab-market/:groupId/public/tracks',
        url: '/collab-market/:groupId/public/tracks',
      },
      url: '/collab-market/:groupId/public',
    },
  },
  copyrightPolicy: {
    path: '/copyright-policy',
    url: '/copyright-policy',
  },
  createGroup: {
    path: '/groups/create',
    url: '/groups/create',
  },
  external: {
    artist: {
      path: '/artist/:artistId',
      url: '/artist/:artistId',
    },
    library: {
      path: '/library',
      url: '/library',
    },
    playlist: {
      path: '/playlist/:playlistId',
      url: '/playlist/:playlistId',
    },
    track: {
      path: '/track/:trackId',
      url: '/track/:trackId',
    },
  },
  forgotPassword: {
    path: '/password/forgot',
    url: '/password/forgot',
  },
  group: {
    getPromoted: {
      path: '/settings/get-promoted',
      url: '/settings/get-promoted',
    },
    members: {
      path: '/settings/members',
      url: '/settings/members',
    },
    overview: {
      path: '/settings',
      url: '/settings',
    },
    payouts: {
      path: '/settings/payouts',
      url: '/settings/payouts',
    },
    promoteOthers: {
      path: '/settings/promote-others',
      url: '/settings/promote-others',
    },
    publicProfile: {
      path: '/settings/team-profile',
      url: '/settings/team-profile',
    },
    royaltySplits: {
      path: '/settings/royalty-splits',
      url: '/settings/royalty-splits',
    },
  },
  home: {
    path: '/',
    url: '/',
  },
  howToChat: {
    path: '/chat-help',
    url: '/chat-help',
  },
  invoices: {
    account: {
      path: '/invoices/account',
      url: '/invoices/account',
    },
    accountDistribution: {
      path: '/invoices/account/distribution',
      url: '/invoices/account/distribution',
    },
    accountML: {
      path: '/invoices/account/music-library',
      url: '/invoices/account/music-library',
    },
    accountNft: {
      path: '/invoices/account/nft',
      url: '/invoices/account/nft',
    },
    accountRoyalties: {
      path: '/invoices/account/royalties',
      url: '/invoices/account/royalties',
    },
    group: {
      path: '/invoices/group',
      url: '/invoices/group',
    },
    groupDistribution: {
      path: '/invoices/group/distribution',
      url: '/invoices/group/distribution',
    },
    groupML: {
      path: '/invoices/group/music-library',
      url: '/invoices/group/music-library',
    },
  },
  login: {
    path: '/login',
    url: '/login',
  },
  logout: {
    path: '/logout',
    url: '/logout',
  },
  musicLibrary: {
    licenseTrack: {
      path: '/music-library/track/license',
      url: '/music-library/track/license',
    },
    overview: {
      path: '/music-library/overview',
      url: '/music-library/overview',
    },
    path: '/music-library',
  },
  myCollabs: {
    overview: {
      cancelled: {
        path: '/my-collabs/overview/cancelled',
        url: '/my-collabs/overview/cancelled',
      },
      declined: {
        path: '/my-collabs/overview/declined',
        url: '/my-collabs/overview/declined',
      },
      done: {
        path: '/my-collabs/overview/done',
        url: '/my-collabs/overview/done',
      },
      ongoingPayment: {
        path: '/my-collabs/overview/ongoing-payment',
        url: '/my-collabs/overview/ongoing-payment',
      },
      path: '/my-collabs/overview/*',
      proposal: {
        path: '/my-collabs/overview/proposal',
        url: '/my-collabs/overview/proposal',
      },
      running: {
        path: '/my-collabs/overview/active',
        url: '/my-collabs/overview/active',
      },
      upcomingPayment: {
        path: '/my-collabs/overview/upcoming-payment',
        url: '/my-collabs/overview/upcoming-payment',
      },
      url: '/my-collabs/overview',
    },
    path: '/my-collabs',
    url: '/my-collabs',
  },
  nftMarket: {
    overview: {
      path: '/nft-market/overview',
      url: '/nft-market/overview',
    },
    path: '/nft-market',
  },
  paypalIframe: {
    path: '/paypal',
    url: '/paypal',
  },
  privacy: {
    path: '/privacy',
    url: '/privacy',
  },
  products: {
    create: {
      path: '/music-distribution/create',
      url: '/music-distribution/create',
    },
    overview: {
      path: '/music-distribution',
      url: '/music-distribution',
    },
    path: '/music-distribution',
    product: {
      createTrack: {
        path: '/music-distribution/:productId/tracks/create',
        url: '/music-distribution/:productId/tracks/create',
      },
      musicLibrary: {
        path: '/music-distribution/:productId/music-library',
        url: '/music-distribution/:productId/music-library',
      },
      nfts: {
        path: '/music-distribution/:productId/nfts',
        url: '/music-distribution/:productId/nfts',
      },
      overview: {
        path: '/music-distribution/:productId/*',
        url: '/music-distribution/:productId',
      },
      path: '/music-distribution/:productId/*',
      publish: {
        path: '/music-distribution/:productId/publish',
        url: '/music-distribution/:productId/publish',
      },
      releaseDetails: {
        path: '/music-distribution/:productId/details',
        url: '/music-distribution/:productId/details',
      },
      royaltySplits: {
        path: '/music-distribution/:productId/royalty-splits',
        url: '/music-distribution/:productId/royalty-splits',
      },
      trackOverview: {
        details: {
          path: '/music-distribution/:productId/tracks/:trackId/details',
          url: '/music-distribution/:productId/tracks/:trackId/details',
        },
        path: '/music-distribution/:productId/tracks/:trackId/*',
        url: '/music-distribution/:productId/tracks/:trackId',
      },
      tracks: {
        path: '/music-distribution/:productId/tracks',
        url: '/music-distribution/:productId/tracks',
      },
    },
  },
  publishingClearances: {
    details: {
      path: '/publishing-clearances/details/:trackId',
      url: '/publishing-clearances/details/:trackId',
    },
    overview: {
      accepted: {
        path: '/publishing-clearances/accepted',
        url: '/publishing-clearances/accepted',
      },
      path: '/publishing-clearances/*',
      pending: {
        path: '/publishing-clearances/pending',
        url: '/publishing-clearances/pending',
      },
      rejected: {
        path: '/publishing-clearances/rejected',
        url: '/publishing-clearances/rejected',
      },
      url: '/publishing-clearances',
    },
    path: '/publishing-clearances',
    url: '/publishing-clearances',
  },
  register: {
    path: '/register',
    url: '/register',
  },
  royalties: {
    account: {
      path: '/royalties/account',
      url: '/royalties/account',
    },
    accountMusicLibrary: {
      path: '/royalties/account/music-library',
      url: '/royalties/account/music-library',
    },
    accountNft: {
      path: '/royalties/account/nft',
      url: '/royalties/account/nft',
    },
    accountPayments: {
      path: '/royalties/account/payments',
      url: '/royalties/account/payments',
    },
    group: {
      path: '/royalties/group',
      url: '/royalties/group',
    },
    groupMusicLibrary: {
      path: '/royalties/group/music-library',
      url: '/royalties/group/music-library',
    },
    groupNft: {
      path: '/royalties/group/nft',
      url: '/royalties/group/nft',
    },
    groupPayments: {
      path: '/royalties/group/payments',
      url: '/royalties/group/payments',
    },
  },
  stats: {
    details: {
      path: '/stats/details',
      url: '/stats/details',
    },
    overview: {
      path: '/stats/overview',
      url: '/stats/overview',
    },
    path: '/stats/*',
  },
  terms: {
    general: {
      path: '/terms-general',
      url: '/terms-general',
    },
    musicLibrary: {
      path: '/terms-ml',
      url: '/terms-ml',
    },
    overview: {
      path: '/terms',
      url: '/terms',
    },
  },
} satisfies Route

const prefixRoutes = <RouteType extends Route>(route: RouteType, parentName?: string): RouteType => {
  const result = Object.fromEntries(
    Object.entries(route).map(([key, value]) =>
      (key === 'path' || key === 'url') &&
      typeof value === 'string' &&
      !value.match(/^https?:\/\//) &&
      (!parentName || !parentName.startsWith('external.'))
        ? [key, `${PREFIX}${value.replace(/^\//, '')}`]
        : [key, typeof value === 'string' ? value : prefixRoutes(value, `${parentName ? `${parentName}.` : ''}${key}`)],
    ),
  ) as RouteType

  return result
}

export const routes = prefixRoutes(rawRoutes)

export const getUrlWithParams = (
  url: string,
  params: {
    [key: string]: string
  },
): string => {
  let generatedUrl = url

  Object.keys(params).forEach((paramKey) => {
    generatedUrl = generatedUrl.replace(`:${paramKey}`, params[paramKey])
  })

  return generatedUrl
}

/**
 * Removes the '/*' and '/' postfix from a path pattern.
 */
export const removeWildcardFromPath = (path: string): string => {
  const withoutWildcard = path.endsWith('/*') ? path.slice(0, path.length - 2) : path

  return withoutWildcard.endsWith('/') ? withoutWildcard.slice(0, withoutWildcard.length - 1) : withoutWildcard
}

/**
 * Adds a '/*' postfix to a path pattern.
 */
export const addWildcardToPath = (path: string): string => {
  const withoutWildcard = removeWildcardFromPath(path)

  return withoutWildcard.endsWith('/') ? `${withoutWildcard}*` : `${withoutWildcard}/*`
}
