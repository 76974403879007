import { useLocation } from '@gatsbyjs/reach-router'
import React, { type FunctionComponent, type PropsWithChildren } from 'react'

import { PREFIX } from '@/utils/routes'

const APP_URL = process.env.APP_URL ?? 'https://ledomusic.is/app/'

const APP_HOST = new URL(APP_URL).host

type Props = PropsWithChildren

const EnsureCorrectHost: FunctionComponent<Props> = ({ children }) => {
  const location = useLocation()

  if (location.host && location.host.length > 0 && location.host !== APP_HOST && location.pathname.startsWith(PREFIX)) {
    const url = new URL(location.href)
    url.host = APP_HOST

    console.log(
      '\n=========================\n',
      `EnsureCorrectHost: Redirecting from ${location.href} to ${url} ...`,
      '\n=========================\n',
    )

    window.location.href = url.toString()
  }

  return <>{children}</>
}

export default EnsureCorrectHost
