import styled from 'styled-components'

export interface OpacityProps {
  opacity: number
}

/**
 * This component makes its children partially transparent.
 *
 * NB: This component is inspired by, but not identical to the Flutter
 * equivalent.
 * @see https://api.flutter.dev/flutter/widgets/Opacity-class.html
 */
const Opacity = styled.div.attrs<OpacityProps>((props) => ({
  style: {
    opacity: props.opacity,
  },
}))<OpacityProps>``

export default Opacity
