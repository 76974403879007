export const connectWallet = /* GraphQL */ `
  mutation ConnectWallet($walletAddress: String, $signature: String) {
    connectWallet(walletAddress: $walletAddress, signature: $signature) {
      walletAddress
    }
  }
`

export const disconnectWallet = /* GraphQL */ `
  mutation DisconnectWallet {
    disconnectWallet {
      walletAddress
    }
  }
`
