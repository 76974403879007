export const Opacities = {
  eight: 0.08,
  eighty: 0.8,
  fiftyfive: 0.55,
  full: 1,
  seventyfive: 0.75,
  sixteen: 0.16,
  sixtyfive: 0.65,
  thirtyfive: 0.35,
  twelve: 0.12,
  twenty: 0.2,
  twentyfour: 0.24,
}
