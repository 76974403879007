import * as React from 'react'

export const DangerIcon = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>((props, ref) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M17.5 2.5L23 12L17.5 21.5H6.5L1 12L6.5 2.5H17.5ZM16.347 4.5H7.653L3.311 12L7.653 19.5H16.347L20.689 12L16.347 4.5ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z"
      fill="currentColor"
    />
  </svg>
))

DangerIcon.displayName = 'DangerIcon'
