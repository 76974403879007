import styled from 'styled-components'

const BaseButton = styled.button`
  user-select: none;
  outline: none;
  margin: 0;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-family: inherit;
  background: transparent;
  border: none;
  color: inherit;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    pointer-events: none;
  }
`

export default BaseButton
