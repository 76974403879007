import styled from 'styled-components'

export interface ConstrainedBoxProps {
  height?: string
  maxHeight?: string
  maxWidth?: string
  minHeight?: string
  minWidth?: string
  width?: string
}

/**
 * A ConstrainedBox can be used to provide a component with a with a size that
 * will never exceed the provided minimum and maximum.
 *
 * NB: This component is inspired by, but not identical to the Flutter
 * equivalent.
 * @see https://api.flutter.dev/flutter/widgets/ConstrainedBox-class.html
 */
const ConstrainedBox = styled.div<ConstrainedBoxProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  min-width: ${(props) => props.minWidth};
  max-width: ${(props) => props.maxWidth};
  min-height: ${(props) => props.minHeight};
  max-height: ${(props) => props.maxHeight};
`

export default ConstrainedBox
