import React, { type FC, type ReactNode } from 'react'
import styled from 'styled-components'

import { BrandColors } from '../../tokens/colors'
import { Icons } from '../../tokens/icons'
import { Opacities } from '../../tokens/opacities'
import { rem } from '../../utils/rem'
import Icon from '../Icon'

interface Props {
  children: ReactNode
  clear?: boolean
  onClose?: () => void
}

const Chip: FC<Props> = ({ onClose, children, clear, ...rest }) => (
  <ChipWrapper onClick={onClose} {...rest} clear={clear}>
    {children}
    <IconWrapper>
      <Icon icon={Icons.cross} />
    </IconWrapper>
  </ChipWrapper>
)

export default Chip

const ChipWrapper = styled.div<{ clear?: boolean }>`
  padding-left: ${rem(8)};
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ clear }) =>
    !clear &&
    `
    border: 1px solid ${BrandColors.white.alpha(Opacities.twelve).toString()};
    background-color: ${BrandColors.white.alpha(Opacities.twelve).toString()};
  `}
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${rem(3)};
`
