import React, { type FunctionComponent, useRef } from 'react'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import { MediaQueries } from '../../tokens/mediaQueries'
import FormField, { type FormFieldProps } from '../FormField'
import IndeterminateProgress from '../IndeterminateProgress'
import Input, { type InputProps } from '../Input'
import { cn } from '../../utils/tailwind'
import Icon from '../Icon'
import { Icons } from '../../tokens'
import { rem } from '../../utils/rem'

export interface TextFieldProps extends Omit<FormFieldProps, 'htmlFor'> {
  id?: string
  input?: Omit<InputProps, 'disabled' | 'error'>
  isLoading?: boolean
  light?: boolean
  onReset?: () => void
  placeholder?: string
  withResetButton?: boolean
}

const TextField: FunctionComponent<React.PropsWithChildren<TextFieldProps>> = ({
  id,
  input,
  light,
  placeholder,
  isLoading,
  withResetButton = false,
  onReset,
  ...props
}) => {
  const idRef = useRef(id ?? uuid())

  return (
    <FormField {...props} htmlFor={idRef.current}>
      <div className="relative">
        <Input
          {...input}
          light={light}
          type="text"
          id={idRef.current}
          disabled={props.disabled}
          error={!!props.error}
          data-cy={input?.dataCy}
          placeholder={placeholder ?? input?.placeholder}
          className={cn({ '!pr-10': !isLoading && withResetButton && onReset }, input?.className)}
        />
        {!isLoading && withResetButton && onReset && (
          <div
            className={cn(
              'absolute top-0 right-0 bottom-0 w-10',
              'text-content-subdued hover:text-content-tertiary',
              'flex justify-center items-center',
              'cursor-pointer',
            )}
            onClick={() => onReset?.()}
          >
            <Icon icon={Icons.cross} size={rem(24)} />
          </div>
        )}
        {isLoading && (
          <IconContainer>
            <IndeterminateProgress />
          </IconContainer>
        )}
      </div>
    </FormField>
  )
}

const IconContainer = styled.div`
  position: absolute;
  right: 8px;
  top: 44px;

  ${MediaQueries.desktop} {
    right: 10px;
    top: 50px;
  }
`

export default TextField
