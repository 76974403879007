'use client'
import React, { createContext, useContext, useState } from 'react'
import { Drawer as DrawerPrimitive } from 'vaul'
import { cva } from 'class-variance-authority'

import { cn } from '../../utils/tailwind'
import { CrossIcon } from '../Icon/CrossIcon'

type DrawerProps = React.ComponentProps<typeof DrawerPrimitive.Root>

const DrawerContext = createContext<{
  direction?: DrawerProps['direction']
  selectedOptionTrigger: HTMLElement | null
  setSelectedOptionTrigger: (_trigger: HTMLElement | null) => void
}>({
  direction: 'right',
  selectedOptionTrigger: null,
  setSelectedOptionTrigger: (_trigger: HTMLElement | null) => {},
})

const Drawer = ({ shouldScaleBackground = true, direction = 'right', ...props }: DrawerProps) => {
  const [selectedOptionTrigger, setSelectedOptionTrigger] = useState<HTMLElement | null>(null)
  return (
    <DrawerContext.Provider value={{ direction, selectedOptionTrigger, setSelectedOptionTrigger }}>
      <DrawerPrimitive.Root shouldScaleBackground={shouldScaleBackground} direction={direction} {...props} />
    </DrawerContext.Provider>
  )
}
Drawer.displayName = 'Drawer'

const DrawerClose = DrawerPrimitive.Close

const DrawerOverlay = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Overlay
    ref={ref}
    className={cn('inset-0 bg-background-ui-overlay-modal backdrop-blur-lg fixed z-50', className)}
    {...props}
  />
))
DrawerOverlay.displayName = DrawerPrimitive.Overlay.displayName

const drawerContentVariants = cva(
  'fixed z-50 flex h-auto flex-col bg-background-primary top-0 bottom-0 md:w-[600px] w-full',
  {
    defaultVariants: {
      direction: 'right',
    },
    variants: {
      direction: {
        bottom: 'bottom-0 inset-x-0',
        left: 'left-0 inset-y-0 h-screen flex flex-col',
        right: 'right-0 inset-y-0 h-screen flex flex-col',
        top: 'top-0 inset-x-0',
      },
    },
  },
)

const DrawerTrigger = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Trigger>
>(({ className, onClick, ...props }, ref) => {
  const { setSelectedOptionTrigger } = useContext(DrawerContext)

  return (
    <DrawerPrimitive.Trigger
      ref={ref}
      className={className}
      onClick={(event) => {
        setSelectedOptionTrigger(event.currentTarget)
        onClick?.(event)
      }}
      {...props}
    />
  )
})

DrawerTrigger.displayName = 'DrawerTrigger'

Drawer.Trigger = DrawerTrigger
Drawer.Portal = DrawerPrimitive.Portal
Drawer.Overlay = DrawerOverlay

const DrawerContent = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Content>
>(({ className, onCloseAutoFocus, children, ...props }, ref) => {
  const { direction, selectedOptionTrigger } = useContext(DrawerContext)

  return (
    <>
      <Drawer.Overlay />
      <Drawer.Portal>
        <DrawerPrimitive.Content
          ref={ref}
          className={cn(drawerContentVariants({ className, direction }))}
          onCloseAutoFocus={(event) => {
            if (selectedOptionTrigger) {
              event.preventDefault()
              selectedOptionTrigger?.focus()
            }
            onCloseAutoFocus?.(event)
          }}
          {...props}
        >
          {children}
        </DrawerPrimitive.Content>
      </Drawer.Portal>
    </>
  )
})
DrawerContent.displayName = 'DrawerContent'

const DrawerHeader = ({ className, children, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className="flex items-center justify-between py-1 pl-5 pr-3 md:py-5 md:pr-6 md:pl-10 gap-9">
    <div className={cn('flex flex-col items-center gap-1.5 py-2 text-left', className)} {...props}>
      {children}
    </div>
    <DrawerClose asChild>
      <button
        className="self-start p-3 transition-colors duration-300 ease-in-out rounded-md bg-background-primary hover:bg-background-secondary"
        aria-label="Close"
      >
        <CrossIcon />
      </button>
    </DrawerClose>
  </div>
)
DrawerHeader.displayName = 'DrawerHeader'

const DrawerBody = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('px-5 md:px-10 overflow-y-auto', className)} {...props} />
)
DrawerBody.displayName = 'DrawerBody'

const DrawerFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('mt-auto flex justify-between items-center gap-2 md:px-10 px-5 py-4', className)} {...props} />
)
DrawerFooter.displayName = 'DrawerFooter'

const DrawerTitle = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Title ref={ref} className={cn('leading-none text-label-lg', className)} {...props} />
))
DrawerTitle.displayName = DrawerPrimitive.Title.displayName

Drawer.Close = DrawerClose
Drawer.Content = DrawerContent
Drawer.Header = DrawerHeader
Drawer.Body = DrawerBody
Drawer.Footer = DrawerFooter
Drawer.Title = DrawerTitle
export { Drawer }
export type { DrawerProps }
