import { useLocation } from '@gatsbyjs/reach-router'
import {
  BrandColors,
  Center,
  LegacyDrawer,
  Expanded,
  Icon,
  Icons,
  Padding,
  Portals,
  rem,
  Row,
  SecondaryButton,
  SizedBox,
} from '@collabhouse/shared'
import React, { type FunctionComponent, type HTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'

import ConnectWalletButton from '@/components/connect-wallet-button/ConnectWalletButton'
import InternalLink from '@/components/internal-link/InternalLink'
import NavigationList from '@/components/navigation-list/NavigationList'
import NavigationListItem from '@/components/navigation-list-tem/NavigationListItem'
import { useDrawer } from '@/hooks/useDrawer'
import { useGroup } from '@/hooks/useGroup'
import { useSendbird } from '@/hooks/useSendbird'
import IconChat from '@/templates/sendbird/IconChat'
import buildNavLinks, { type NavigationDropdown } from '@/utils/buildNavLinks'
import matchesActiveOnArray from '@/utils/matchesActiveOnArray'
import { routes } from '@/utils/routes'

const DrawerNavigation: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { group } = useGroup()
  const { drawerOpen, setDrawerOpen } = useDrawer()
  const { pathname } = useLocation()

  useEffect(
    () => () => {
      // Reset current drawer open state on component unmount
      setDrawerOpen(false)
    },
    [setDrawerOpen],
  )

  const handleNavigate = useCallback(() => {
    setDrawerOpen(false)
  }, [setDrawerOpen])

  const navigationItems = useMemo(() => buildNavLinks(group), [group])

  const featured = navigationItems.filter((i) => i.featured)
  const notFeatured = navigationItems.filter((i) => !i.featured)
  const { unreadMessageCount } = useSendbird()

  return (
    <LegacyDrawer open={drawerOpen} onClose={handleNavigate} portal={Portals.dialog}>
      <Panel>
        <Header as="header" crossAxisAlignment="center">
          <Button onClick={handleNavigate} data-gtm-name="drawer-navigation-close">
            <Center>
              <Icon icon={Icons.cross} />
            </Center>
          </Button>
        </Header>
        {[featured, notFeatured].map((list, index) => (
          <StyledNavigationList key={`navlistitem-${index}`} featured={index === 0} separator={<></>}>
            {list
              .filter(({ hidden }) => !hidden)
              .map((item, key) => {
                switch (item.type) {
                  case 'LINK':
                  case 'EXTERNAL': {
                    const { title, activeOn, type, ...navigationItemProps } = item
                    return (
                      <NavigationListItem
                        active={matchesActiveOnArray(pathname, activeOn)}
                        onClick={handleNavigate}
                        key={title}
                        {...navigationItemProps}
                      >
                        {title}
                      </NavigationListItem>
                    )
                  }
                  case 'DROPDOWN':
                    return <NavigationDrawerDropdown onClick={handleNavigate} key={key} {...item} />
                }
              })}
          </StyledNavigationList>
        ))}
        <SizedBox height={rem(24)} />
        <Padding horizontal={rem(18)}>
          <InternalLink key="chat" to={routes.chat.url} style={{ backgroundImage: 'none' }}>
            <NavItemWithBadge size="large">
              Open Chat
              <SizedBox width={rem(12)} />
              <Wrapper>
                <IconChat />
                {unreadMessageCount > 0 && <BadgeNumber>{unreadMessageCount}</BadgeNumber>}
              </Wrapper>
            </NavItemWithBadge>
          </InternalLink>
        </Padding>
        <SizedBox height={rem(24)} />
        <Padding horizontal={rem(18)}>
          <Expanded>
            <ConnectWalletButton onClick={() => handleNavigate()} />
          </Expanded>
        </Padding>
      </Panel>
    </LegacyDrawer>
  )
}

const NavigationDrawerDropdown = ({
  title,
  activeOn,
  children,
  onClick,
}: NavigationDropdown & {
  onClick: HTMLAttributes<HTMLElement>['onClick']
}) => {
  const { pathname } = useLocation()
  const [open, setOpen] = useState(!!children.find((c) => matchesActiveOnArray(pathname, c.activeOn)))

  return (
    <>
      <NavigationListItem
        active={matchesActiveOnArray(pathname, activeOn)}
        onClick={() => setOpen((open) => !open)}
        key={title}
      >
        <div>{title}</div>
        <Icon
          as="div"
          icon={Icons.chevronDown}
          style={{
            transform: open ? 'rotate(180deg)' : 'rotate(0)',
            transition: `transform .15s`,
          }}
        />
      </NavigationListItem>
      {open && (
        <>
          <SizedBox height={rem(18)} />

          <Dropdown>
            {children
              .filter((child) => !child.hidden)
              .map((child) => {
                const { type, activeOn, ...navigationItemProps } = child
                return (
                  <NavigationListItem
                    active={matchesActiveOnArray(pathname, activeOn)}
                    key={navigationItemProps.title}
                    onClick={onClick}
                    {...navigationItemProps}
                  >
                    {navigationItemProps.title}
                  </NavigationListItem>
                )
              })}
          </Dropdown>
        </>
      )}
    </>
  )
}

export default DrawerNavigation

const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
`

const Panel = styled.div`
  width: 100%;
  height: 100%;
  background: ${BrandColors.steelGray.toString()};
`

const Header = styled(Row)`
  width: 100%;
  height: calc(${rem(64)} - 1px);
  padding: 0 ${rem(20)};
  background: ${BrandColors.darkBlue.toString()};
`

const Button = styled.button`
  width: ${rem(32)};
  height: ${rem(32)};
  background: none;
  border: none;
  outline: none;
  color: inherit;
  padding: 0;

  :hover {
    cursor: pointer;
  }
`

const StyledNavigationList = styled(NavigationList)<{ featured?: boolean }>`
  ${({ featured }) =>
    featured &&
    css`
      background: ${BrandColors.darkBlue.toString()};
    `}
`

const NavItemWithBadge = styled(SecondaryButton)`
  padding: 0 ${rem(9)};
  height: ${rem(48)};
  width: 100%;
  background-color: ${({ active }) => (active ? 'inherit' : 'transparent')};
  font-weight: 500;
`

const BadgeNumber = styled.span`
  background-color: ${BrandColors.springGreen.toString()};
  border-radius: 50%;
  color: black;
  font-size: 0.75em;
  line-height: 100%;
  padding: 0.25em 0.5em;
  position: absolute;
  right: -${rem(12)};
  top: -${rem(6)};
`

const Wrapper = styled.div`
  position: relative;
  height: ${rem(22)};
`
