import { type NavigateOptions } from '@gatsbyjs/reach-router'
import { navigate } from 'gatsby'
import { Anchor, type AnchorProps } from '@collabhouse/shared'
import React, { type FunctionComponent, type MouseEvent, useCallback } from 'react'

interface InternalLinkProps extends Omit<AnchorProps, 'href' | 'onClick'> {
  options?: NavigateOptions<Record<string, unknown>>
  to: string
}

const InternalLink: FunctionComponent<React.PropsWithChildren<InternalLinkProps>> = ({ to, options, ...props }) => {
  const onClick = useCallback(
    (event: MouseEvent) => {
      if (to.includes('?')) {
        // Use native navigation when link includes query parameters.
        return
      }

      event.preventDefault()
      void navigate(to, options)
    },
    [to, options],
  )

  return <Anchor {...props} href={to} onClick={onClick} />
}

export default InternalLink
