import { type CognitoUser } from 'amazon-cognito-identity-js'
import { isAfter, isBefore } from 'date-fns'
import { Body, type IconData, Icons } from '@collabhouse/shared'
import { default as React, type ReactNode } from 'react'

import { type Storage } from '@/components/promotion-banner/PromotionBanner'
import { GroupRole } from '@/graphql/generated'
import { type Group } from '@/providers/GroupProvider'

export interface MaybeGetActiveDistributionPromotionOptions {
  group: Group | undefined
  isCreatingProfile: boolean
  user: CognitoUser | undefined
}

export interface DistributionPromotion {
  /**
   * Call to action button for the promotion.
   */
  cta?: { text: string; to: string }

  /**
   * The description of the promotiion (e.g. used by a promotional banner).
   */
  description: ReactNode | string

  /**
   * Promotional text to show in the dashboard to promote making releases.
   */
  distributionDescription: ReactNode | string

  /**
   * Start date of the promotion.
   */
  from: Date

  /**
   * The role of the group that the promotion is shown to.
   */
  groupRoles?: GroupRole[]

  /**
   * Icon to show in the promotion banner (optional).
   */
  icon?: IconData | ReactNode

  /**
   * Label to pull attention to the promotion.
   */
  label?: { icon: IconData | ReactNode; title?: string }

  /**
   * The lead text (shown in  bold) for the promotion banner.
   */
  lead?: string

  /**
   * Storage method to store if the promotion should be hidden or not.
   */
  storage: Storage

  /**
   * End date (exclusive) of the promotion.
   */
  until: Date

  /**
   * Unique identifier for the promotion, used to identify if the promotion has been hidden or not.
   */
  uuid: string
}

const promotions: DistributionPromotion[] = [
  {
    description: (
      <>
        Thanks to you, we&#39;re celebrating 100K members with <b>free distribution in July!</b>
      </>
    ),
    distributionDescription: (
      <Body size="medium">
        <p>
          Get your music on Spotify, YouTube, TikTok, and 60+ platforms for FREE. Keep 100% of your earnings, get paid
          monthly. This exclusive offer is only available in July. Don’t miss out.
        </p>
      </Body>
    ),
    from: new Date('2024-07-01T00:00:00Z'),
    icon: Icons.gift,
    label: { icon: Icons.gift, title: 'Free until July 31' },
    lead: 'REWARD',
    storage: 'cookie',
    until: new Date('2024-08-01T00:00:00Z'),
    uuid: 'free-distribution-july',
  },
  {
    description: (
      <>
        Submit your track for a chance to win a{' '}
        <a href="https://www.collabhouse.com/promotion/win-promo-budget">€5000 promotion bundle</a>. Ends September 9th.
      </>
    ),
    distributionDescription: (
      <Body size="medium">
        <p>
          <b>Win €5000 promo budget to skyrocket your music</b>.<br />
          Are you a talented producer looking to take your music career to the next level? Collabhouse is here to make
          that happen with an exclusive TikTok campaign.
        </p>
      </Body>
    ),
    from: new Date('2024-08-16T00:00:00Z'),
    icon: Icons.gift,
    label: { icon: Icons.gift, title: 'Upload and win!' },
    lead: 'CONTEST',
    storage: 'sessionStorage',
    until: new Date('2024-09-09T00:00:00Z'),
    uuid: '5k',
  },
  {
    description: <>Enjoy free music distribution to celebrate ADE until October 31st. Don’t miss out!</>,
    distributionDescription: (
      <Body size="medium">
        <p>
          Get your music on Spotify, YouTube, TikTok, and 60+ platforms for FREE. Keep 100% of your earnings, get paid
          monthly. This exclusive offer is only available between October 16st and November 1st. Don’t miss out.
        </p>
      </Body>
    ),
    from: new Date('2024-10-15T12:00:00Z'),
    icon: Icons.gift,
    label: { icon: Icons.gift, title: 'Unlock free distribution' },
    lead: 'UNLOCK THE DEAL',
    storage: 'cookie',
    until: new Date('2024-11-01T12:00:00Z'),
    uuid: 'ade-2024',
  },
  {
    cta: { text: 'Download stems', to: 'https://drive.google.com/drive/folders/1Y4JBnAPrz0DcyTngavHHnFmBzDpK1HJk' },
    description: (
      <p style={{ fontWeight: 700 }}>
        Get a chance to win amazing prizes by remixing Kitty Mack’s track ‘Home’ and submitting it to{' '}
        <a href="https://www.labelradar.com/contests/kittymackcontest/portal" target="labelradar">
          LabelRadar
        </a>
        !
      </p>
    ),
    distributionDescription: null,
    from: new Date('2024-11-05T06:00:00Z'),
    groupRoles: [GroupRole.Artist],
    icon: Icons.campaign,
    label: { icon: Icons.campaign, title: 'REMIX COMPETITION' },
    lead: 'REMIX COMPETITION',
    storage: 'cookie',
    until: new Date('2024-12-07T06:00:00Z'),
    uuid: 'remix-nov-2024',
  },
]

export const maybeGetActiveDistributionPromotion = ({
  group,
  user,
  isCreatingProfile,
}: MaybeGetActiveDistributionPromotionOptions): DistributionPromotion | undefined => {
  if (!user || isCreatingProfile) {
    return undefined
  }

  if (group?.role !== GroupRole.Artist && group?.role !== GroupRole.Label) {
    return undefined
  }

  const now = new Date()

  return promotions.find((promotion) => {
    if (promotion.groupRoles && !promotion.groupRoles.includes(group.role)) {
      return false
    }

    return isBefore(promotion.from, now) && isAfter(promotion.until, now)
  })
}
