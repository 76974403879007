import styled from 'styled-components'

import { MediaQueries } from '../../tokens/mediaQueries'

const DesktopOnly = styled.div`
  display: none;

  ${MediaQueries.desktop} {
    display: initial;
  }
`
export default DesktopOnly
