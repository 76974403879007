import { parse } from 'query-string'
import escape from 'regex-escape'

import { GroupRole } from '../graphql/generated'
import { type Group } from '../providers/GroupProvider'
import { getUrlWithParams, type Route, routes } from '../utils/routes'
import { inBrowser } from './inBrowser'

const WEB_URL = process.env.WEB_URL || 'https://www.ledomusic.is/'
const APP_URL = process.env.APP_URL || 'https://www.ledomusic.is/app/'

const buildName = (name: string, parentName?: string): string => `${parentName ? `${parentName}.` : ''}${name}`

export const fixSlashes = (url: string): string => url.replaceAll(/(?<!https?:)\/\//g, '/').replace(/\/$/, '')

const normalizeToAppUrl = (url: string): string => fixSlashes(new URL(url, APP_URL).toString())
const normalizeToWebUrl = (url: string): string => fixSlashes(new URL(url, WEB_URL).toString())
const normalizeUrl = (url: string, external?: boolean): string =>
  (external ?? !(url === '/app/' || url.startsWith('/app/'))) ? normalizeToWebUrl(url) : normalizeToAppUrl(url)

const flattenRoutes = (routes: Route, parentName?: string): Array<[string, string]> =>
  Object.entries(routes).flatMap(([name, route]) => {
    if (name === 'url' || name === 'path' || typeof route === 'string') {
      return []
    }

    return [
      ...('url' in route && typeof route.url === 'string'
        ? [
            [
              buildName(name, parentName),
              normalizeUrl(route.url, buildName(name, parentName).startsWith('external.')),
            ] satisfies [string, string],
          ]
        : 'path' in route && typeof route.path === 'string'
          ? [
              [
                buildName(name, parentName),
                normalizeUrl(route.path, buildName(name, parentName).startsWith('external.')),
              ] satisfies [string, string],
            ]
          : []),
      ...flattenRoutes(route, buildName(name, parentName)),
    ]
  })

export const flatRoutes = Object.fromEntries(flattenRoutes(routes))
const whiteListedUrls = Object.fromEntries(Object.entries(flatRoutes).map(([key, value]) => [value, key]))
const whiteListedUrlsRegexes = Array.from(
  Object.values(flatRoutes),
  (url) =>
    new RegExp(
      `^${url
        .split(/\/(?::.*\b|\*)/g)
        .map(escape)
        .join('/.*')}/?(\\?.*)?(#.*)?$`,
    ),
)

const maybeRewriteLocalhost = (url: string): string => {
  // Allow localhost?
  if (process.env.AWS_STAGE !== 'production' && url.startsWith('http://localhost:3000/')) {
    return url.replace('http://localhost:3000/', WEB_URL)
  }

  return url
}

export const parseRedirectQuery = (query: string): Record<string, string> => {
  const entries = Object.entries(parse(query)).flatMap(([key, value]) =>
    Array.isArray(value) ? (value.length === 0 ? [] : [[key, value[value.length - 1]]]) : [[key, value]],
  )

  // Make sure that long keys are sorted first.
  entries.sort(([a], [b]) => b.length - a.length)

  return Object.fromEntries(entries)
}

export const parseRedirect = (url?: string): string => {
  const [_url, query] = splitQueryFromUrl(url)
  const params = parseRedirectQuery(query)

  if (_url in flatRoutes) {
    // Build URL based on route name and parameters.
    return addQueryToUrl(getUrlWithParams(flatRoutes[_url], params), query)
  }

  const normalizedUrl = normalizeUrl(_url)
  const rewrittenUrl = maybeRewriteLocalhost(normalizedUrl)

  if (rewrittenUrl in whiteListedUrls || whiteListedUrlsRegexes.some((regex) => !!regex.test(rewrittenUrl))) {
    return addQueryToUrl(normalizedUrl, query)
  }

  return normalizeToAppUrl(routes.home.url)
}

export const splitQueryFromUrl = (url?: string): [url: string, query: string] => {
  const [urlOnly, query] = `${url ?? ''}`.split('?', 2)

  return [urlOnly, query ?? '']
}

export const addQueryToUrl = (url: string, query?: string): string =>
  `${url}${query !== undefined && query.length > 0 ? `?${query}` : ''}`

export const formatRedirect = (url?: string): string => {
  const [_url, query] = splitQueryFromUrl(url)

  return encodeURIComponent(addQueryToUrl(_url in whiteListedUrls ? whiteListedUrls[_url] : _url, query))
}

const doesPathContainWildcards = (path: string): boolean => path.includes('*') || path.includes('/:')

/**
 * Rewrites the pathname using the pattern 'from' (in the form '/some/path/*') to the pattern 'to' (in the form
 * '/some/other/path/*').
 *
 * @example
 * preparePathname('/catch-all/1/2/3', '/catch-all/*', '/numbers/*')
 * // => '/numbers/1/2/3'
 */
export const rewritePathnameFromTo = (pathname: string, from?: string, to?: string): string | undefined => {
  // Return early for simple 'to' paths.
  if (to !== undefined && to.length > 0 && !doesPathContainWildcards(to) && to !== pathname) {
    return to
  }

  // Without proper from/to props return the original path or undefined.
  if (from === undefined || from.length === 0 || to === undefined || to.length === 0) {
    const result = fixSlashes(to ?? pathname)
    return result === pathname ? undefined : result
  }

  // Try to parse the pathname using the 'from' path, find the wildcards, and rewrite it to the 'to' path using the
  // matching wildcards.
  // TODO: replace the found named wildcards using their names instead of the order.
  const splitRegex = /\*|(?<=\/|^):.*?(?=\/|$)/
  const wildcardMatchInner = '.*?'
  const wildcardMatch = `(${wildcardMatchInner})`
  const regexString = `^${from.split(splitRegex).map(escape).join(wildcardMatch)}$`.replace(
    `${escape('/')}${wildcardMatch}$`,
    `((?:${escape('/')}${wildcardMatchInner})?)$`,
  )
  const regex = new RegExp(regexString)
  const result = fixSlashes(
    pathname.replace(regex, (_, ...parts) =>
      parts.reduce((result: string, part) => result.replace(splitRegex, `${part}`), to),
    ),
  )

  // Return undefined when the resulting path is the same as the original path.
  return result === pathname ? undefined : result
}

/**
 * Will redirect to the login page (with a redirect back to the current page or a provided destination) when the user is
 * not logged in.
 *
 * Will return if the user was redirected or not.
 */
export const redirectToLoginIfNotLoggedIn = (isLoggedIn: boolean, ready: boolean, destination?: string): boolean => {
  // Force login when user is not logged in.
  if (inBrowser() && ready && !isLoggedIn) {
    console.log(
      '\n=========================\n',
      'Redirect to login because not logged in.',
      '\n=========================\n',
    )

    // Do not use 'navigate' as it doesn't support query parameters.
    window.location.href = `${routes.login.url}/?r=${formatRedirect(destination ?? window.location.href)}`

    return true
  }

  return false
}

/**
 * Will redirect to the home page (or a provided destination) when no group is selected. Will not redirect while the
 * group is not yet ready (so while still loading).
 *
 * Will return if the user was redirected or not.
 */
export const redirectIfNoGroup = (group: Group, ready: boolean, destination?: string): boolean => {
  // Force login when user is not logged in.
  if (ready && !group) {
    const redirect = parseRedirect(destination ?? 'home')

    console.log(
      '\n=========================\n',
      `Redirect to ${formatRedirect(redirect)} because no group is selected.`,
      '\n=========================\n',
    )

    // Do not use 'navigate' as it doesn't support query parameters.
    window.location.href = redirect

    return true
  }

  return false
}

/**
 * Will redirect to the home page (or a provided destination) when no group is selected. Will not redirect while the
 * group is not yet ready (so while still loading).
 *
 * Will return if the user was redirected or not.
 */
export const redirectIfNoArtistGroup = (group: Group, ready: boolean, destination?: string): boolean => {
  // Force login when user is not logged in.
  if ((ready && !group) || group.role !== GroupRole.Artist) {
    const redirect = parseRedirect(destination ?? 'home')

    console.log(
      '\n=========================\n',
      `Redirect to ${formatRedirect(redirect)} because no artist group is selected.`,
      '\n=========================\n',
    )

    // Do not use 'navigate' as it doesn't support query parameters.
    window.location.href = redirect

    return true
  }

  return false
}
