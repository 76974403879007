import { Auth } from 'aws-amplify'
import { type HubCapsule } from '@aws-amplify/core'

const TIMEOUT = 7_500

export const persistCognitoTokenForNextAuth = async (token: string): Promise<void> => {
  try {
    const csrfReq = await fetch('/api/auth/csrf', { signal: AbortSignal.timeout(TIMEOUT) })

    const { csrfToken } = (await csrfReq.json()) as { csrfToken: string }
    await fetch('/api/auth/callback/token', {
      body: new URLSearchParams({
        csrfToken,
        json: 'true',
        token,
      }),
      headers: {
        Accept: '*/*',
        ['Content-Type']: 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      signal: AbortSignal.timeout(TIMEOUT),
    })
  } catch (error) {
    console.error(error instanceof Error ? error.message : error)
  }
}

export const invalidateCognitoTokenForNextAuth = async () => {
  await fetch('/api/token/invalidate', { signal: AbortSignal.timeout(TIMEOUT) })
}

export const hubEventsListener = async ({ payload }: HubCapsule) => {
  try {
    console.log('hubEventsListener', payload.event)

    if (payload.event === 'tokenRefresh') {
      const session = await Auth.currentSession()

      if (!session.isValid()) {
        return
      }

      const token = session.getIdToken().getJwtToken()
      if (token) {
        await persistCognitoTokenForNextAuth(token)
      }
    } else if (payload.event === 'signOut' || payload.event === 'tokenRefresh_failure') {
      await invalidateCognitoTokenForNextAuth()
    }
  } catch (error) {
    console.error(error instanceof Error ? error.message : error)
  }
}
