import React, { type FunctionComponent } from 'react'

import { countries } from '../../tokens/countries'
import SelectField, { type SelectFieldProps } from '../SelectField'

interface CountryCodeSelectProps extends SelectFieldProps {
  select: SelectFieldProps['select']
}

const CountryCodeSelect: FunctionComponent<CountryCodeSelectProps> = ({ select, light, ...props }) => (
  <SelectField
    {...props}
    light={light}
    disabled={props.disabled}
    select={{
      options: countries.map((c) => ({
        label: (
          <>
            <div className={`fi fi-${c.code.toLowerCase()}`} style={{ marginRight: '8px' }} />
            {c.prefix}
          </>
        ),
        value: c.prefix,
      })),
      ...select,
    }}
  />
)

export default CountryCodeSelect
