import { createGlobalStyle } from 'styled-components'

import geomanistBold from '../../../assets/fonts/geomanist-bold/geomanist-bold-webfont.woff2'
import geomanistMedium from '../../../assets/fonts/geomanist-medium/geomanist-medium-webfont.woff2'
import geomanistRegular from '../../../assets/fonts/geomanist-regular/geomanist-regular-webfont.woff2'
import icons from '../../../assets/fonts/icons/regular.woff'

const GlobalFonts = createGlobalStyle`
  @font-face {
    src: url(${geomanistRegular}) format('woff2');
    font-family: 'Geomanist';
    font-weight: 400;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    src: url(${geomanistMedium}) format('woff2');
    font-family: 'Geomanist';
    font-weight: 500;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    src: url(${geomanistBold}) format('woff2');
    font-family: 'Geomanist';
    font-weight: 700;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    src: url(${icons}) format('woff');
    font-family: 'Icons';
    font-weight: 400;
    font-style: normal;
    font-display: block;
  }
`

export default GlobalFonts
