import Color from 'color'

export const BrandColors = {
  black: new Color('#000000'),
  blackcurrant: new Color('#13071F'),
  darkBlue: new Color('#4300CF'),
  haiti: new Color('#1d172f'),
  night: new Color('#0F070F'),
  purple: new Color('#180753'),
  springGreen: new Color('#00ef86'),
  steelGray: new Color('#251e3c'),
  tickleMePink: new Color('#FF758F'),
  violentViolet: new Color('#180753'),
  white: new Color('#ffffff'),
}

export const SystemColors = {
  danger: new Color('#ff3a46'),
  info: new Color('#51a0ff'),
  success: new Color('#42f333'),
  warning: new Color('#f3cc00'),
}
