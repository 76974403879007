exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-chat-help-tsx": () => import("./../../../src/pages/chat-help.tsx" /* webpackChunkName: "component---src-pages-chat-help-tsx" */),
  "component---src-pages-copyright-policy-tsx": () => import("./../../../src/pages/copyright-policy.tsx" /* webpackChunkName: "component---src-pages-copyright-policy-tsx" */),
  "component---src-pages-groups-confirm-invitation-tsx": () => import("./../../../src/pages/groups/confirm-invitation.tsx" /* webpackChunkName: "component---src-pages-groups-confirm-invitation-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-password-forgot-tsx": () => import("./../../../src/pages/password/forgot.tsx" /* webpackChunkName: "component---src-pages-password-forgot-tsx" */),
  "component---src-pages-password-reset-tsx": () => import("./../../../src/pages/password/reset.tsx" /* webpackChunkName: "component---src-pages-password-reset-tsx" */),
  "component---src-pages-paypal-tsx": () => import("./../../../src/pages/paypal.tsx" /* webpackChunkName: "component---src-pages-paypal-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-register-confirm-tsx": () => import("./../../../src/pages/register/confirm.tsx" /* webpackChunkName: "component---src-pages-register-confirm-tsx" */),
  "component---src-pages-register-index-tsx": () => import("./../../../src/pages/register/index.tsx" /* webpackChunkName: "component---src-pages-register-index-tsx" */),
  "component---src-pages-terms-general-new-tsx": () => import("./../../../src/pages/terms-general-new.tsx" /* webpackChunkName: "component---src-pages-terms-general-new-tsx" */),
  "component---src-pages-terms-general-tsx": () => import("./../../../src/pages/terms-general.tsx" /* webpackChunkName: "component---src-pages-terms-general-tsx" */),
  "component---src-pages-terms-ml-tsx": () => import("./../../../src/pages/terms-ml.tsx" /* webpackChunkName: "component---src-pages-terms-ml-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-templates-account-invoices-routes-account-invoices-routes-tsx": () => import("./../../../src/templates/account-invoices-routes/AccountInvoicesRoutes.tsx" /* webpackChunkName: "component---src-templates-account-invoices-routes-account-invoices-routes-tsx" */),
  "component---src-templates-account-routes-account-routes-tsx": () => import("./../../../src/templates/account-routes/AccountRoutes.tsx" /* webpackChunkName: "component---src-templates-account-routes-account-routes-tsx" */),
  "component---src-templates-account-royalties-routes-account-royalties-routes-tsx": () => import("./../../../src/templates/account-royalties-routes/AccountRoyaltiesRoutes.tsx" /* webpackChunkName: "component---src-templates-account-royalties-routes-account-royalties-routes-tsx" */),
  "component---src-templates-app-redirect-tsx": () => import("./../../../src/templates/app/Redirect.tsx" /* webpackChunkName: "component---src-templates-app-redirect-tsx" */),
  "component---src-templates-assets-routes-assets-routes-tsx": () => import("./../../../src/templates/assets-routes/AssetsRoutes.tsx" /* webpackChunkName: "component---src-templates-assets-routes-assets-routes-tsx" */),
  "component---src-templates-collaboration-routes-collaboration-routes-tsx": () => import("./../../../src/templates/collaboration-routes/CollaborationRoutes.tsx" /* webpackChunkName: "component---src-templates-collaboration-routes-collaboration-routes-tsx" */),
  "component---src-templates-group-detail-routes-group-detail-routes-tsx": () => import("./../../../src/templates/group-detail-routes/GroupDetailRoutes.tsx" /* webpackChunkName: "component---src-templates-group-detail-routes-group-detail-routes-tsx" */),
  "component---src-templates-group-invoices-group-invoices-tsx": () => import("./../../../src/templates/group-invoices/GroupInvoices.tsx" /* webpackChunkName: "component---src-templates-group-invoices-group-invoices-tsx" */),
  "component---src-templates-group-routes-group-routes-tsx": () => import("./../../../src/templates/group-routes/GroupRoutes.tsx" /* webpackChunkName: "component---src-templates-group-routes-group-routes-tsx" */),
  "component---src-templates-group-royalties-routes-group-royalties-routes-tsx": () => import("./../../../src/templates/group-royalties-routes/GroupRoyaltiesRoutes.tsx" /* webpackChunkName: "component---src-templates-group-royalties-routes-group-royalties-routes-tsx" */),
  "component---src-templates-music-library-routes-music-library-routes-tsx": () => import("./../../../src/templates/music-library-routes/MusicLibraryRoutes.tsx" /* webpackChunkName: "component---src-templates-music-library-routes-music-library-routes-tsx" */),
  "component---src-templates-my-collabs-routes-my-collabs-routes-tsx": () => import("./../../../src/templates/my-collabs-routes/MyCollabsRoutes.tsx" /* webpackChunkName: "component---src-templates-my-collabs-routes-my-collabs-routes-tsx" */),
  "component---src-templates-nfts-routes-nfts-routes-tsx": () => import("./../../../src/templates/nfts-routes/NftsRoutes.tsx" /* webpackChunkName: "component---src-templates-nfts-routes-nfts-routes-tsx" */),
  "component---src-templates-product-routes-product-routes-tsx": () => import("./../../../src/templates/product-routes/ProductRoutes.tsx" /* webpackChunkName: "component---src-templates-product-routes-product-routes-tsx" */),
  "component---src-templates-publishing-clearances-routes-publishing-clearances-routes-tsx": () => import("./../../../src/templates/publishing-clearances-routes/PublishingClearancesRoutes.tsx" /* webpackChunkName: "component---src-templates-publishing-clearances-routes-publishing-clearances-routes-tsx" */),
  "component---src-templates-sendbird-sendbird-routes-tsx": () => import("./../../../src/templates/sendbird/SendbirdRoutes.tsx" /* webpackChunkName: "component---src-templates-sendbird-sendbird-routes-tsx" */),
  "component---src-templates-stats-routes-stats-routes-tsx": () => import("./../../../src/templates/stats-routes/StatsRoutes.tsx" /* webpackChunkName: "component---src-templates-stats-routes-stats-routes-tsx" */)
}

