/* eslint-disable @typescript-eslint/no-empty-object-type */

import styled from 'styled-components'

export interface ScrollViewProps {}

const ScrollView = styled.div`
  height: 100%;
  overflow-y: auto;
`

export default ScrollView
