import { createGlobalStyle } from 'styled-components'

interface GlobalHeightProps {
  rootSelector: string
}

const GlobalHeight = createGlobalStyle<GlobalHeightProps>`
  html,
  body,
  ${(props) => props.rootSelector} {
    height: 100%;
  }`

export default GlobalHeight
