export const getMyProducts = /* GraphQL */ `
  query getMyProducts($managerId: ID!, $filters: PaginationFilter) {
    getMyProducts(managerId: $managerId, filters: $filters) {
      data {
        id
        fugaId
        name
        artwork {
          small {
            url
            expiresAt
            expiresIn
          }
          medium {
            url
            expiresAt
            expiresIn
          }
        }
        distributionChannels {
          id
          channelId
          name
          status
          subChannels {
            name
            status
          }
        }
        label
        catalogNumber
        releaseVersion
        consumerReleaseDate
        originalReleaseDate
        recordingYear
        updatedAt
        recordingLocation
        copyrightYear
        copyrightText
        phonogramCopyrightYear
        phonogramCopyrightText
        genre
        subgenre
        alternateGenre
        alternateSubgenre
        language
        parentalAdvisory
        productCode {
          code
        }
      }
      total
    }
  }
`

export const getMyProduct = /* GraphQL */ `
  query GetMyProduct($id: ID!) {
    getMyProduct(id: $id) {
      id
      fugaId
      name
      canBeEdited
      approvals {
        channelId
        createdAt
        updatedAt
        productId
        latest
        reasons {
          category
          importance
          internalComment
          reason
          userComment
        }
        status
        id
      }
      artwork {
        small {
          url
          path
          expiresAt
          expiresIn
        }
        medium {
          url
          path
          expiresAt
          expiresIn
        }
      }
      distributionChannels {
        id
        channelId
        name
        status
        subChannels {
          name
          status
        }
      }
      label
      catalogNumber
      releaseVersion
      consumerReleaseDate
      originalReleaseDate
      recordingYear
      updatedAt
      recordingLocation
      copyrightYear
      copyrightText
      phonogramCopyrightYear
      phonogramCopyrightText
      genre
      subgenre
      alternateGenre
      alternateSubgenre
      language
      parentalAdvisory
      invoiceSetting
      distributionExpiryDate
      paymentPriceModel
      isDistributable
      productArtists {
        primary
        artist {
          id
          fugaId
          name
        }
        position
      }
      productCode {
        code
      }
      clearance
      tracks {
        id
        fugaId
        name
        version
        bpm
        audioLanguage
        lyrics
        updatedAt
        parentalAdvisory
        previewStart
        previewLength
        audio {
          url
          path
          expiresAt
        }
        originalFilename
        contributors {
          name
          role
        }
        trackCode {
          code
        }
        musicLibraryTrackConfig {
          socialMediaBranded
          advertisementAllMedia
          advertisementAudioOnly
          advertisementOnlineOnly
          corporateNonProfitCharity
          corporateNonProfitCompanyPresentation
          corporateNonProfitEducational
          filmLargeOnlineOnly
          filmLargeAllMedia
          filmLargeTrailer
          filmSmallAllMedia
          filmSmallOnlineOnly
          filmSmallTrailer
          gamesAppsInAppGame
          gamesAppsTrailerPromo
          podcastPrivateBranded
          podcastPrivateNonBranded
          radioTvAudioOnly
          radioTvTrailer
          radioTvTvShow
          socialMediaNonBranded
          durations
          enabled
          submittedAt
        }
        trackArtists {
          artistId
          primary
          artist {
            name
            fugaId
          }
        }
        clearance
        groupShares {
          trackId
          version
          createdAt
          acceptedAt
          declinedAt
          share
          type
          group {
            avatar {
              small {
                url
                path
                expiresAt
                expiresIn
              }
            }
            name
            id
          }
        }
      }
      publishedAt
      distributedAt
      distributeRequest
      priceModelCommissionPercentage
      musicLibraryConfigSetting
      musicLibraryProductConfig {
        id
        socialMediaBranded
        advertisementAllMedia
        advertisementAudioOnly
        advertisementOnlineOnly
        corporateNonProfitCharity
        corporateNonProfitCompanyPresentation
        corporateNonProfitEducational
        filmLargeOnlineOnly
        filmLargeAllMedia
        filmLargeTrailer
        filmSmallAllMedia
        filmSmallOnlineOnly
        filmSmallTrailer
        gamesAppsInAppGame
        gamesAppsTrailerPromo
        podcastPrivateBranded
        podcastPrivateNonBranded
        radioTvAudioOnly
        radioTvTrailer
        radioTvTvShow
        socialMediaNonBranded
        durations
        enabled
      }
      lastDeliveryDate
      useQCFlow
      lastEditedAt
    }
  }
`

export const addProduct = /* GraphQL */ `
  mutation AddProduct($managerId: ID!, $input: AddProductInput!) {
    addProduct(managerId: $managerId, input: $input) {
      id
      fugaId
      name
      artwork {
        small {
          url
          expiresAt
          expiresIn
        }
      }
      label
      catalogNumber
      releaseVersion
      consumerReleaseDate
      originalReleaseDate
      recordingYear
      recordingLocation
      copyrightYear
      copyrightText
      phonogramCopyrightYear
      phonogramCopyrightText
      genre
      subgenre
      alternateGenre
      alternateSubgenre
      language
      parentalAdvisory
      productCode {
        code
      }
    }
  }
`

export const editProduct = /* GraphQL */ `
  mutation EditProduct($id: ID!, $input: EditProductInput!) {
    editProduct(id: $id, input: $input) {
      id
    }
  }
`

export const publishProduct = /* GraphQL */ `
  mutation PublishProduct($id: ID!, $channelId: String!) {
    publishProduct(id: $id, input: { channelId: $channelId }) {
      id
      publishedAt
      distributedAt
    }
  }
`

export const updateTrackOrder = /* GraphQL */ `
  mutation UpdateTrackOrder($productId: ID!, $input: [ID]!) {
    updateTrackOrder(productId: $productId, input: $input) {
      id
    }
  }
`

export const syncFugaProduct = /* GraphQL */ `
  mutation SyncFugaProduct($id: ID!) {
    syncFugaProduct(id: $id) {
      id
      lastDeliveryDate
    }
  }
`
