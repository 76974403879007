import React from 'react'

export const UsersIcon = ({ color = 'white', ...props }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M1 14H13V16H1V14Z" fill={color} />
    <circle cx="7" cy="8" r="5" stroke={color} strokeWidth="2" />
    <circle cx="18" cy="8" r="5" stroke={color} strokeWidth="2" />
    <path d="M16 14H24V16H16V15V14Z" fill={color} />
    <path d="M13 14L13 20L11 20L11 14L13 14Z" fill={color} />
    <path d="M24 14L24 20L22 20L22 14L24 14Z" fill={color} />
    <path d="M3 14L3 20L1 20L1 14L3 14Z" fill={color} />
  </svg>
)

export default UsersIcon
