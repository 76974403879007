export function isString(value: unknown): boolean {
  return typeof value === 'string'
}

/**
 * @deprecated since we want to start using the isobject NPM package
 * https://www.npmjs.com/package/isobject
 */
export function isObject(value: unknown): boolean {
  return typeof value === 'object'
}
