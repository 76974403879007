import * as React from 'react'

const SvgComponent = (props) => (
  <svg width={30} height={30} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 0C6.716 0 0 6.716 0 15c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15Z"
      fill="url(#a)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.162 15a8.838 8.838 0 1 1 17.676 0 8.838 8.838 0 0 1-17.676 0Zm6.58 2.848a.59.59 0 0 1-.59-.59v-4.516a.59.59 0 0 1 .59-.59h4.517a.59.59 0 0 1 .589.59v4.517a.59.59 0 0 1-.59.589h-4.516Z"
      fill="#fff"
    />
    <defs>
      <linearGradient id="a" x1={15} y1={30} x2={15} y2={0} gradientUnits="userSpaceOnUse">
        <stop stopColor="#2E66F8" />
        <stop offset={1} stopColor="#124ADB" />
      </linearGradient>
    </defs>
  </svg>
)

export default SvgComponent
