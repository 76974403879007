import { type GraphQLResult } from '@aws-amplify/api-graphql'
import { API, graphqlOperation } from 'aws-amplify'
import React, { createContext, type FunctionComponent, type ReactNode, useCallback, useEffect, useState } from 'react'

import * as genresOperations from '../graphql/genres'
import { useUser } from '../hooks/useUser'

export interface Subgenre {
  genreId: string
  id: string
  name: string
}

export interface Genre {
  id: string
  name: string
  subgenres: Subgenre[]
}

export interface GenresContext {
  genres?: Genre[]
}

export const genresContext = createContext<GenresContext>({
  genres: [],
})

interface GenresProviderProps {
  children: ReactNode | ((context: GenresContext) => ReactNode)
}

const GenresProvider: FunctionComponent<GenresProviderProps> = (props) => {
  const { isLoggedIn } = useUser()
  const [genres, setGenres] = useState([])

  /**
   * Retrieve genres.
   */
  const getGenres = useCallback(async () => {
    const result = (await API.graphql(graphqlOperation(genresOperations.getGenres))) as GraphQLResult<{
      getGenres: Genre[]
    }>

    return result.data?.getGenres
  }, [])

  /**
   * Refreshes all the necessary state.
   */
  const hydrate = useCallback(async () => {
    setGenres(await getGenres())
  }, [getGenres])

  /**
   * Clears the provider's state.
   */
  const clear = useCallback(() => {
    setGenres([])
  }, [])

  /**
   * Hydrate or clear provider based on logged in state.
   */
  useEffect(() => {
    if (isLoggedIn) {
      void hydrate()
    } else {
      clear()
    }
  }, [isLoggedIn, hydrate, clear])

  const context = {
    genres,
  }

  return (
    <genresContext.Provider value={context}>
      {typeof props.children === 'function' ? props.children(context) : props.children}
    </genresContext.Provider>
  )
}

export default GenresProvider

export const GenresConsumer = genresContext.Consumer
