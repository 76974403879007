import { Body, Center as BaseCenter, ConstrainedBox, MediaQueries, rem, SizedBox, Title } from '@collabhouse/shared'
import React, { type FunctionComponent, type ReactChild } from 'react'
import styled from 'styled-components'

import { default as BaseGrid, GridItem as BaseGridItem } from '../grid/Grid'
import PaddedContainer from '../padded-container/PaddedContainer'

interface CenteredPageTemplateProps {
  body?: ReactChild
  title?: ReactChild
}

const CenteredPageTemplate: FunctionComponent<React.PropsWithChildren<CenteredPageTemplateProps>> = ({
  title,
  body,
  children,
}) => (
  <ExpandedContainer>
    <Grid>
      <GridItem mobile={12} desktop={{ end: 10, start: 4 }}>
        <Center crossAxisAlignment="stretch" mainAxisAlignment="flex-start">
          <ConstrainedBox width="100%">
            {title && <Title>{title}</Title>}
            {title && body && <SizedBox height={rem(12)} />}
            {body && <Body size="large">{body}</Body>}
            {(title || body) && children && <SizedBox height={rem(40)} />}
            {children}
          </ConstrainedBox>
        </Center>
      </GridItem>
    </Grid>
  </ExpandedContainer>
)

export default CenteredPageTemplate

const ExpandedContainer = styled(PaddedContainer)`
  height: 100%;
`

const Grid = styled(BaseGrid)`
  height: 100%;
`

const GridItem = styled(BaseGridItem)`
  height: 100%;
  padding: ${rem(128)} 0 ${rem(80)};

  ${MediaQueries.desktop} {
    padding: ${rem(160)} 0;
  }
`

const Center = styled(BaseCenter)`
  ${MediaQueries.desktop} {
    justify-content: center;
  }
`
