import React, { type FC, type SVGProps } from 'react'

interface UpcomingIconProps extends SVGProps<SVGSVGElement> {
  size?: number
}

export const UpcomingIcon: FC<UpcomingIconProps> = ({ size = 24, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 24 24" {...props}>
    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeOpacity=".35" strokeWidth="2" />
  </svg>
)
