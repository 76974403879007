import React, { type FunctionComponent, type TextareaHTMLAttributes, useRef } from 'react'
import styled, { css, type DefaultTheme, type StyledComponent } from 'styled-components'
import { v4 as uuid } from 'uuid'

import { MediaQueries } from '../../tokens/mediaQueries'
import { rem } from '../../utils/rem'
import FormField, { type FormFieldProps } from '../FormField'
import Input from '../Input'
import { BrandColors } from '../../tokens/colors'
import { Opacities } from '../../tokens/opacities'

export interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: boolean
  light?: boolean
}

export interface TextareaFieldProps extends Omit<FormFieldProps, 'htmlFor'> {
  id?: string
  textarea?: Omit<TextareaProps, 'disabled' | 'error'>
}

const TextareaField: FunctionComponent<React.PropsWithChildren<TextareaFieldProps>> = ({ id, textarea, ...props }) => {
  const idRef = useRef(id ?? uuid())

  return (
    <FormField {...props} htmlFor={idRef.current}>
      <Textarea<StyledComponent<'textarea', DefaultTheme, TextareaFieldProps>>
        {...textarea}
        rows={4}
        id={idRef.current}
        disabled={props.disabled}
        error={!!props.error}
      />
    </FormField>
  )
}

const Textarea = styled(Input).attrs({
  as: 'textarea',
})`
  min-height: ${rem(48)};
  height: ${rem(120)};
  resize: vertical;

  ${MediaQueries.desktop} {
    height: ${rem(136)};
    min-height: ${rem(96)};
  }

  ${({ light }) =>
    !!light &&
    css`
      background: ${BrandColors.white.alpha(Opacities.eight).toString()};

      :hover,
      :focus,
      :active {
        background: ${BrandColors.white.alpha(Opacities.twelve).toString()};
      }
    `}
`

export default TextareaField
