import { API, graphqlOperation } from 'aws-amplify'
import { Form, Formik } from 'formik'
import {
  Anchor,
  Body,
  BrandColors,
  CheckboxField,
  Flex,
  IndeterminateProgress,
  Padding,
  PrimaryButton,
  rem,
  SizedBox,
  Title,
} from '@collabhouse/shared'
import React, { type FC, type PropsWithChildren, useCallback } from 'react'
import styled from 'styled-components'

import { GeneralTermsStatus } from '../../graphql/generated'
import { acceptGeneralTerms } from '../../graphql/userProfile'
import { useUser } from '../../hooks/useUser'
import BulletList from '../../ledo-web-content/components/BulletList'
import { GeneralTermsCopy } from '../../ledo-web-content/terms/TermsGeneral'
import { executeValidations, validateTrue } from '../../utils/validation'
import PaddedContainer from '../padded-container/PaddedContainer'
import Page from '../page/Page'
import WordMark from '../word-mark/WordMark'

interface TermsGateValues {
  terms: false
}

const TermsGate: FC<React.PropsWithChildren<PropsWithChildren<Record<string, unknown>>>> = ({ children }) => {
  const { userProfile, hydrate } = useUser()

  const handleSubmit = useCallback(async () => {
    await API.graphql(graphqlOperation(acceptGeneralTerms))
    await hydrate()
  }, [hydrate])

  const handleValidate = useCallback((values: TermsGateValues) => {
    const errors: { [key: string]: string | boolean } = {}

    errors.terms = executeValidations(values.terms, [validateTrue], {
      params: ['terms and conditions', { preposition: 'the', verb: 'accept' }],
    })

    if (Object.values(errors).filter(Boolean).length === 0) {
      return undefined
    }

    return errors
  }, [])

  if (!userProfile || userProfile?.generalTermsStatus === GeneralTermsStatus.IsAccepted) {
    return <>{children}</>
  }

  if (userProfile?.generalTermsStatus === GeneralTermsStatus.AcceptUpdate) {
    return (
      <Page>
        <PaddedContainer>
          <Container>
            <WordMark />
            <SizedBox height={rem(32)} />
            <Title size="xLarge">We’ve updated our terms and conditions!</Title>
            <SizedBox height={rem(16)} />
            <Body>
              In connection with the launch of our new publishing clearance feature, we have updated our General Terms
              and Conditions.
            </Body>
            <SizedBox height={rem(8)} />
            <Body>Here is a summary of key changes made:</Body>
            <SizedBox height={rem(16)} />
            <Body>
              - We’ve removed the ‘Distribution Terms’ as a separate set of terms and integrated the terms and
              conditions that apply to distribution, influencer marketing, creating NFTs and making available your music
              available in the Music Library in the new version of the General Terms and Conditions;
            </Body>
            <SizedBox height={rem(16)} />
            <Body>
              - We’ve updated the terms to incorporate Collabhouse’s new publishing clearances feature that allows:
            </Body>
            <SizedBox height={rem(16)} />
            <BulletList
              indentationLevel="one"
              listItems={[
                <Body key="item-one">
                  you as Music Maker to also include your music in the Music Library if you do not own/control all of
                  the underlying composition rights (publishing rights), subject to you providing correct publishing
                  ownership data and obtaining approval from such publishing rights owners for inclusion of your music
                  in the Music Library on the terms selected by you using our new publishing clearance feature;
                </Body>,
                <Body key="item-two">
                  you as Music Maker who owns/control any exclusive worldwide publishing rights into music uploaded to
                  Collabhouse by other rights holders (ie. artist or record label) to approve and license your
                  publishing rights into such music to Collabhouse for the purpose of granting non-exclusive
                  synchronization licenses to such music to users of the Music Library and to collect publishing
                  royalties.
                </Body>,
              ]}
            />
            <SizedBox height={rem(16)} />
            <Body>
              - We have clarified how royalty splits can be allocated and how the various types of royalties are
              calculated and accounted;
            </Body>
            <SizedBox height={rem(16)} />
            <Body>
              - We have updated our terms to include our new general support email address{' '}
              <a href="mailto:help@collabhouse.com">help@collabhouse.com</a>
            </Body>
            <SizedBox height={rem(16)} />
            <Body>
              - We’ve made some general improvements to wording and adjusted it to better reflect the contents of the
              services Collabhouse provides
            </Body>

            <SizedBox height={rem(48)} />
            <Body emphasis>Please read and review the updated General Terms and Conditions here below:</Body>
            <SizedBox height={rem(24)} />
            <TermsContainer>
              <Padding padding={{ desktop: rem(32), mobile: rem(16) }}>
                <Title size="xSmall">General Terms and Conditions</Title>
                <SizedBox height={rem(32)} />
                <GeneralTermsCopy />
              </Padding>
            </TermsContainer>

            <SizedBox height={rem(24)} />
            <Body>
              If you’re happy with these changes, click ‘Agree’ and the new version of the General Terms and Conditions
              will automatically apply when you continue using Collabhouse.
            </Body>
            <SizedBox height={rem(8)} />
            <Body>
              If you are not comfortable with the new terms, stop using Collabhouse. If you have any questions about the
              new terms, or if you like us to remove your Collabhouse account, please email{' '}
              <a href="mailto:help@collabhouse.com">help@collabhouse.com</a>.
            </Body>

            <Formik<TermsGateValues> initialValues={{ terms: false }} validate={handleValidate} onSubmit={handleSubmit}>
              {({ handleChange, handleBlur, values, touched, errors, isSubmitting }) => (
                <Form>
                  <Padding vertical={rem(32)}>
                    <Flex direction="row" mainAxisAlignment="space-between">
                      <CheckboxField
                        input={{
                          checked: values.terms,
                          name: 'terms',
                          onBlur: handleBlur,
                          onChange: handleChange,
                        }}
                        label={<Body emphasis>I hereby agree to the updated General Terms and Conditions.</Body>}
                        error={touched.terms && (errors.terms as unknown as boolean)}
                      />
                      <PrimaryButton
                        leading={isSubmitting && <IndeterminateProgress />}
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Continue
                      </PrimaryButton>
                    </Flex>
                  </Padding>
                </Form>
              )}
            </Formik>
          </Container>
        </PaddedContainer>
      </Page>
    )
  }

  return (
    <Page>
      <PaddedContainer>
        <Container>
          <WordMark />
          <SizedBox height={rem(32)} />
          <Title size="xLarge">Terms and Conditions</Title>
          <SizedBox height={rem(16)} />

          <Formik<TermsGateValues> initialValues={{ terms: false }} validate={handleValidate} onSubmit={handleSubmit}>
            {({ handleChange, handleBlur, values, touched, errors, isSubmitting }) => (
              <Form>
                <Padding vertical={rem(32)}>
                  <Flex direction="row" mainAxisAlignment="space-between">
                    <CheckboxField
                      input={{
                        checked: values.terms,
                        name: 'terms',
                        onBlur: handleBlur,
                        onChange: handleChange,
                      }}
                      label={
                        <Body emphasis>
                          I hereby agree to Collabhouse&apos;s{' '}
                          <Anchor href={`${process.env.WEB_URL}terms-general`} target="_blank">
                            General Terms and Conditions
                          </Anchor>{' '}
                          and I consent to the processing of my personal data in accordance with the{' '}
                          <Anchor href={`${process.env.WEB_URL}privacy`} target="_blank">
                            Privacy Policy
                          </Anchor>
                          .
                        </Body>
                      }
                      error={touched.terms && (errors.terms as unknown as boolean)}
                    />
                    <PrimaryButton
                      leading={isSubmitting && <IndeterminateProgress />}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Continue
                    </PrimaryButton>
                  </Flex>
                </Padding>
              </Form>
            )}
          </Formik>
        </Container>
      </PaddedContainer>
    </Page>
  )
}

const Container = styled.div`
  max-width: ${rem(800)};
  margin: 0 auto;
`

const TermsContainer = styled.div`
  background: ${BrandColors.steelGray.toString()};
  max-height: 640px;
  overflow-y: auto;
`

export default TermsGate
