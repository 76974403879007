import { colors } from './colors'
import { semanticFontSizes } from './fonts'

export const tailwindMergeExtension = {
  extend: {
    classGroups: {
      'font-size': Object.keys(semanticFontSizes).map((key) => `text-${key}`),
    },
    theme: {
      colors: Object.keys(colors),
    },
  },
}
