import React, { type FunctionComponent } from 'react'

import { rem } from '../../utils/rem'
import FormField, { type FormFieldProps } from '../FormField'
import List from '../List'
import RadioInput, { type RadioInputProps } from '../RadioInput'
import SizedBox from '../SizedBox'

export interface RadioFieldProps extends Omit<FormFieldProps, 'htmlFor'> {
  direction?: 'row' | 'column'
  inputs: RadioInputProps[]
}

const RadioField: FunctionComponent<React.PropsWithChildren<RadioFieldProps>> = ({
  inputs,
  direction = 'column',
  ...props
}) => (
  <FormField {...props}>
    <List direction={direction} separator={<SizedBox width={rem(16)} height={rem(12)} />}>
      {inputs.map((input, index) => (
        <RadioInput key={index} {...input} disabled={props.disabled} error={!!props.error} />
      ))}
    </List>
  </FormField>
)

export default RadioField
