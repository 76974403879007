import styled, { css } from 'styled-components'

import { MediaQueries } from '../../tokens/mediaQueries'
import Divider from '../Divider'

interface ResponsiveSize {
  desktop?: string | number
  mobile?: string | number
}

export interface VerticalDividerProps {
  size?: string | number | ResponsiveSize
}

const VerticalDivider = styled(Divider).attrs({
  as: 'div',
})<VerticalDividerProps>(({ size = '100%' }) => {
  let responsiveSize: ResponsiveSize

  switch (typeof size) {
    case 'object':
      responsiveSize = size
      break

    case 'string':
      responsiveSize = {
        desktop: size,
        mobile: size,
      }
      break

    case 'number':
    default:
      responsiveSize = {
        desktop: `${size}px`,
        mobile: `${size}px`,
      }

      break
  }

  return css`
    width: 1px;
    height: ${responsiveSize.mobile};

    ${MediaQueries.desktop} {
      height: ${responsiveSize.desktop};
    }
  `
})

export default VerticalDivider
