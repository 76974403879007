/**
 * Retrieve all available genres
 */
export const getGenres = /* GraphQL */ `
  query GetGenres {
    getGenres {
      id
      name
      subgenres {
        genreId
        id
        name
      }
    }
  }
`
