import React, { type FC } from 'react'

interface CrossIconProps {
  size?: number
}

export const CrossIcon: FC<CrossIconProps> = ({ size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0002 10.5858L16.95 5.63605L18.3642 7.05026L13.4144 12L18.3642 16.9498L16.9499 18.364L12.0002 13.4142L7.05046 18.364L5.63624 16.9498L10.586 12L5.63623 7.05026L7.05044 5.63605L12.0002 10.5858Z"
      fill="currentColor"
    />
  </svg>
)
