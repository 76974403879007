import { createGlobalStyle } from 'styled-components'

import { BrandColors } from '../../../tokens/colors'
import { FontFamily } from '../../../tokens/typography'
import { rem } from '../../../utils/rem'

const GlobalText = createGlobalStyle`
  body {
    color: ${BrandColors.white.toString()};
    font-family: ${FontFamily.regular};
    font-weight: 400;
    font-size: ${rem(16)};
    line-height: ${rem(24)};
  }
`

export default GlobalText
