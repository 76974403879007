import { countries as countriesList } from 'countries-list'

export const countries = Object.entries(countriesList).map(([code, country]) => ({
  code,
  name: country.name,
  prefix: country.phone.map((p) => `+${p}`).join(', '),
}))

export const countriesInEurope = [
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'EL',
  'ES',
  'FI',
  'FR',
  'HR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
]
