/**
 *
 * TODO: Mostly copied from ledo-web - should be re-used via @collabhouse/shared
 *
 */

import { BrandColors, MediaQueries, Opacities, rem, SecondaryButton } from '@collabhouse/shared'
import styled from 'styled-components'

const IconButton = styled(SecondaryButton)`
  border-color: ${BrandColors.white.alpha(Opacities.twenty).toString()};
  outline: 1px solid transparent;
  outline-offset: ${rem(2)};
  padding: 0;
  position: relative;
  text-decoration: none;

  :hover {
    border-color: ${BrandColors.white.toString()};
    color: ${BrandColors.white.toString()};
    opacity: 1;
  }

  :focus {
    border-color: ${BrandColors.white.toString()};
  }

  ${MediaQueries.desktop} {
    padding: 0;
  }

  width: ${rem(48)};
  height: ${rem(48)};

  ${MediaQueries.desktop} {
    width: ${rem(56)};
    height: ${rem(56)};
  }
`
export default IconButton
